import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useDispatch } from 'react-redux';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
    backgroundColor: '#FFCA1B',
  },
  [`&.${stepConnectorClasses.active}`]: {
    color: '#1DA571',
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#7FB1EE',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#7FB1EE',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[200],
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const Steppers = (props) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const { length, currentStep, answerList = [], setIndexAction } = props;

  const isAnswered = (index) => {
    return answerList.find((question, questionOrder) => questionOrder === index);
  };

  const handleStepperClick = (label) => {
    dispatch(setIndexAction(label));
  };

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper alternativeLabel activeStep={currentStep} connector={<QontoConnector />}>
        {length.map((label, index) => (
          <Step
            key={label}
            completed={isAnswered(index) && index !== currentStep}
            onClick={() => handleStepperClick(label)}
            className={styles.step}
          >
            <StepLabel className={styles.currentStep} />
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

export default Steppers;

const useStyles = makeStyles((theme) => ({
  step: {
    '& .MuiStepLabel-alternativeLabel': {
      cursor: 'pointer',
    },
  },
  currentStep: {
    '& .MuiStepLabel-alternativeLabel': {
      '& .Mui-active': {
        color: '#FFF',
        border: '2px #1976d2 solid',
        borderRadius: '50%',
        '& .MuiStepIcon-text': {
          fill: '#1976d2',
          fontSize: '1rem',
        },
      },
    },
  },
}));
