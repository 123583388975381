import { takeEvery, fork, put, call } from 'redux-saga/effects';
import profile from '../../services/profile';
import auth from '../../services/auth';
import { setUserPortfolio } from '../../redux/actions/profile.action';

import {
  FETCH_ERROR,
  FETCH_END,
  FETCH_PORTFOLIO_OK,
  POST_CUSTOM_OK,
  FETCH_PORTFOLIO_DATA,
  POST_CUSTOM_PORTFOLIO,
  GET_USER_PORTFOLIO,
  ASSIGN_USER_PORTFOLIO,
  GET_USER_SETTINGS,
  GET_USER_SETTINGS_OK,
} from '../constants/profile.constant';

import { SET_TRADE_BUILDER_SETUP_MAX_LOSS_ACTION } from '../constants/technical_analysis.constant';

import {
  SAVE_USER_DETAILS_ACTION,
  SAVE_USER_DETAILS_OK,
  SAVE_USER_DETAILS_END,
  SAVE_USER_DETAILS_ERROR,
  GET_USER_PERMISSION_ACTION,
  GET_USER_PERMISSION_OK,
  GET_USER_PERMISSION_END,
  GET_USER_PERMISSION_ERROR,
} from '../constants/auth.constant';

export function* fetchPortfolioData(action) {
  try {
    const xhr = yield call(profile.fetchPortfolioData, action.data);
    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: FETCH_PORTFOLIO_OK, ...xhr.data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: FETCH_ERROR, ...e });
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* fetchPortfolio() {
  yield takeEvery(FETCH_PORTFOLIO_DATA, fetchPortfolioData);
}

export function* fetchUserPortfolio() {
  try {
    const xhr = yield call(profile.getUserPortfolio);
    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: ASSIGN_USER_PORTFOLIO, ...xhr.data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: FETCH_ERROR, ...e });
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* userPortfolio() {
  yield takeEvery(GET_USER_PORTFOLIO, fetchUserPortfolio);
}

export function* postCustomData(action) {
  try {
    const xhr = yield call(profile.fetchCustomData, action);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: POST_CUSTOM_OK, ...xhr.data.data[0] });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: FETCH_ERROR, ...e });
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* postCustom() {
  yield takeEvery(POST_CUSTOM_PORTFOLIO, postCustomData);
}

export function* updateUserDetails(action) {
  const { enqueueSnackbar } = action;
  try {
    const xhr = yield call(auth.saveUserSettings, action.id, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }
    const data = xhr.data;
    yield put({ type: SAVE_USER_DETAILS_OK, ...xhr.data, enqueueSnackbar });
    yield put({
      type: SET_TRADE_BUILDER_SETUP_MAX_LOSS_ACTION,
      data: data?.data?.attributes?.max_loss_amount,
    });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: SAVE_USER_DETAILS_ERROR, ...e });
  } finally {
    yield put({ type: SAVE_USER_DETAILS_END });
  }
}

export function* updateUserSaga() {
  yield takeEvery(SAVE_USER_DETAILS_ACTION, updateUserDetails);
}

export function* getUserDetails(action) {
  try {
    const xhr = yield call(auth.getUserSettings, action.id);

    if (xhr.status >= 400) {
      throw xhr;
    }
    yield put({ type: GET_USER_SETTINGS_OK, ...xhr.data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: FETCH_ERROR, ...e });
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* getUserSaga() {
  yield takeEvery(GET_USER_SETTINGS, getUserDetails);
}

export function* getPermission(action) {
  try {
    const xhr = yield call(auth.getUserPermission);

    if (xhr.status >= 400) {
      throw xhr;
    }
    yield put({ type: GET_USER_PERMISSION_OK, ...xhr.data.data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: GET_USER_PERMISSION_ERROR, ...e });
  } finally {
    yield put({ type: GET_USER_PERMISSION_END });
  }
}

export function* getPermissionSaga() {
  yield takeEvery(GET_USER_PERMISSION_ACTION, getPermission);
}
const profile_sagas = [
  fork(fetchPortfolio),
  fork(userPortfolio),
  fork(postCustom),
  fork(updateUserSaga),
  fork(getPermissionSaga),
  fork(getUserSaga),
];
export default profile_sagas;
