import React from 'react';
import TERMS_OF_USE from '../../constants/terms_of_use';
import CommonPolicyContent from './CommonPolicyContent';

const RiskDisclosureAgreement = (props) => {
  return (
    <CommonPolicyContent
      title={TERMS_OF_USE.title}
      description={TERMS_OF_USE.description}
      items={TERMS_OF_USE.items}
    />
  );
};

export default RiskDisclosureAgreement;
