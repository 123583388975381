import API from './API';

const stripe = {
  fetchProducts: () => {
    return new API().get(`/payments/stripe/prices/`);
  },
  subscribeCustomer: (params) => {
    return new API().post(`/payments/stripe/subscribe/`, params);
  },
  fetchSubscriptions: () => {
    return new API().get(`/payments/stripe/subscriptions/`);
  },
  cancelSubscription: (id) => {
    return new API().delete(`/payments/stripe/subscriptions/${id}/`);
  },
  updateSubscription: (data) => {
    return new API().patch(`/payments/stripe/subscriptions/${data.id}/`, data.tickers);
  },
};

export default stripe;
