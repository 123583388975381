import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Chip, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

import Categories from './components/Categories';
import GeneratePieChart from './components/GeneratePieChart';
import RelatedVideos from './components/RelatedVideos';
import { CHART_COLORS, shadesOfBlue } from './constant';
import useStyle from './index.style';

import union from '../../assets/images/portfolio_management/union.png';
import { LoadingIndicator } from '../../components/ContainerIndicator';

import { format, isValid, parse } from 'date-fns';
import { profileSelector } from '../../redux/reselect/profileSelector';
import Performance from './components/Performance';

const Portfolio = ({ tabValue, disclaimers, startDate, endDate, ...props }) => {
  const styles = useStyle();
  const ps = useSelector(profileSelector);
  const [chartValues, setChartValues] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [performances, setPerformances] = useState([]);
  const [chartColors, setChartColors] = useState([]);

  useEffect(() => {
    if (ps?.currentPortfolioData?.length > 0) {
      const getValues = ps?.currentPortfolioData?.map((data, index) => {
        return data?.analysis?.aggregate
          ?.filter((aggregate) => {
            return aggregate?.allocation !== 0;
          })
          .map((value) => {
            return parseFloat(value?.allocation?.toFixed(2));
          });
      });

      const getLabels = ps?.currentPortfolioData[0]?.analysis?.aggregate
        .filter((aggregate) => {
          return aggregate?.allocation !== 0;
        })
        .map((value) => {
          return value?.class?.replace(/[\W_]+/g, ' ');
        });

      setChartValues(getValues);
      setChartLabels(getLabels);

      const getPerformance = ps?.currentPortfolioData?.filter((data, index) => {
        return isSelected(data);
      });

      const convertedPerformance = [];
      for (const [key = '', value = ''] of Object.entries(getPerformance[0]?.performance || [])) {
        if (key === 'CAGR' || key === 'Annualized Std' || key === 'Maximum Drawdown') {
          convertedPerformance.push({ label: key, value: (value * 100).toFixed(2) + '%' });
        } else if (key === 'Max Drawdown Duration') {
          convertedPerformance.push({ label: key, value: value });
        } else {
          convertedPerformance.push({ label: key, value: value.toFixed(2) });
        }
      }
      setPerformances(convertedPerformance);
    }
  }, [ps?.currentPortfolioData]);

  const isSelected = (data) => {
    return data?.portfolio === ps?.userPortfolio?.data[0]?.attributes?.name;
  };

  const getAggregate = () => {
    return ps?.currentPortfolioData
      ?.filter((data, index) => {
        return isSelected(data);
      })[0]
      ?.analysis?.aggregate?.filter((aggregate) => {
        return aggregate?.allocation !== 0;
      });
  };

  useEffect(() => {
    if (chartLabels?.length > 0) {
      const bgColor = [
        '#ff6361',
        '#58508d',
        '#bc5090',
        '#F0AA4B',
        '#c0392b',
        '#F0AA4B',
        '#a159ed',
        '#50a06d',
      ];
      let getColors = [];
      chartLabels?.forEach((label, index) => {
        if (label === 'Gold') {
          getColors.push('#FFC000');
        } else if (label.includes('Bond')) {
          getColors.push(shadesOfBlue[index]);
        } else {
          let color = getRandomColor();
          while (chartColors?.includes(color)) {
            color = getRandomColor();
          }
          getColors.push(color);
        }
      });

      setChartColors(getColors);
    }
  }, [chartLabels]);

  const getRandomColor = () => {
    return (
      'hsl(' +
      360 * Math.random() +
      ',' +
      (15 + 70 * Math.random()) +
      '%,' +
      (65 + 10 * Math.random()) +
      '%)'
    );
  };

  const formatDates = (date) => {
    const parsedDate = parse(date, 'yyyyMMM dd', new Date());
    if (!isValid(parsedDate))
      return {
        monthDayDate: '',
        yearDate: '',
        dayDate: '',
        suffix: '',
      };

    const regex = /[0-9]/g;
    const monthDayDate = format(parsedDate, 'MMM dd');
    const yearDate = format(parsedDate, 'yyyy');
    const dayDate = format(parsedDate, 'dd');
    const daySuffixDate = format(parsedDate, 'do');
    const suffix = daySuffixDate.replace(regex, '');

    return {
      monthDayDate,
      yearDate,
      dayDate,
      suffix,
    };
  };

  const formattedStartDate = formatDates(startDate);
  const formattedEndDate = formatDates(endDate);

  return (
    <Box className={styles.portfolioContainer}>
      {ps?.loading ? (
        <LoadingIndicator />
      ) : (
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          {/* <StartEndDate startDate={startDate} endDate={endDate} />  */}
          <Box display="flex" mb={8}>
            <Typography variant="h5" fontWeight="bold" color="white">
              {formattedStartDate.monthDayDate}
              <sup style={{ fontSize: '40%', verticalAlign: 'super' }}>
                {formattedStartDate.suffix}
              </sup>{' '}
              {formattedStartDate.yearDate}
            </Typography>
            <Typography color="white" mx={1}>
              {' - '}
            </Typography>
            <Typography variant="h5" fontWeight="bold" color="white">
              {formattedEndDate.monthDayDate}
              <sup style={{ fontSize: '40%', verticalAlign: 'super' }}>
                {formattedEndDate.suffix}
              </sup>{' '}
              {formattedEndDate.yearDate}
            </Typography>
          </Box>
          {chartValues?.length > 0 && (
            <Box className={styles.chartsContainer2}>
              {chartValues?.map((value, index) => {
                return (
                  <GeneratePieChart
                    values={value}
                    labels={chartLabels}
                    index={index}
                    colors={CHART_COLORS}
                    TYPE={2}
                  />
                );
              }) || 'No data'}
            </Box>
          )}
          <Box margin="16px auto">
            <Categories
              aggregates={getAggregate()}
              colors={CHART_COLORS}
              align="center"
              width="80%"
            />
            <Box display="flex" justifyContent="center">
              <Box display="flex" flexDirection="column" sx={{ color: '#FFFFFF' }}>
                {/* {disclaimers?.map((disclaimer) => {
                  return <span>{disclaimer}</span>;
                })} */}
              </Box>
            </Box>
          </Box>
          <Box mb={4} maxWidth="700px" width="100%">
            <Performance performances={performances} />
          </Box>
          <Box display="flex" justifyContent="center" width="100%">
            <Box className={styles.prosConsContainer}>
              <Box>
                <Chip
                  label={<Typography className={styles.textFocus}>Pros</Typography>}
                  className={styles.whitebackGround}
                  variant="outlined"
                ></Chip>
                <Typography className={styles.whiteText}>
                  Diversified portfolios utilitze many more asset classes than traditional
                  portfolios. During periods where bonds may not be as attractive, we have elimated
                  most bond asset classes, except for inflation-protected bonds. In order to
                  maintain the same risk profile, we have used asset class “substitutes” that
                  provide similar risk and return performance as bonds. For instance, we have
                  included long/short credit, equity market neutral, equity long/short, and managed
                  futures to fully or partially offset bond risk. long/short credit and equity
                  market neutral have sime risk and return profiles as medium risked bonds, while
                  long/short equity has risk return profile simlar to high-yield corporate bonds.
                </Typography>
              </Box>
              <Box mt={2}>
                <Chip
                  label={<Typography className={styles.textFocus2}>Cons</Typography>}
                  className={styles.whitebackGround}
                  variant="outlined"
                ></Chip>
                <Typography className={styles.whiteText}>
                  Diversified portfolios may seem harder to understand for investos as the asset
                  classes are less familiar with mainstream audiences.
                </Typography>
              </Box>
              <Box className={styles.infoContainer}>
                <Box className={styles.inlineDisplay}>
                  <img src={union} width={60} height={60} />
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Typography className={styles.whiteText}>
                    Currently, the United States as well as other Global countries are facting a
                    rising interest-rate environment. Using alternative asset classes to provide the
                    same risk and return profile that investors have become accostomed to in
                    previous years from traditional portfolios may allow investors to get the “ride”
                    they are comfortable with for portolio stabilty and growth.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <RelatedVideos />
        </Box>
      )}
    </Box>
  );
};

export default Portfolio;
