import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import useStyles from './index.style';

const CommonPolicyContent = ({ title = '', description = '', items = [] }) => {
  const classes = useStyles();
  return (
    // <Box height="fit-content">
    //   <Box textAlign="center" mb={6}>
    // <Typography variant="h4">{title}</Typography>
    //   </Box>
    //   <Box textAlign="center">
    // <Typography variant="subtitle2">{description}</Typography>
    //   </Box>
    //   <Box>
    // {items.map((t, i) => (
    //   <Box pt="1rem">
    // <Box>
    //   <Typography variant="subtitle2">{`${t.title}`}</Typography>
    // </Box>
    // <Box whiteSpace="pre-line" pl="1rem">
    //   <Typography variant="caption">{t.description}</Typography>
    // </Box>
    //   </Box>
    // ))}
    //   </Box>
    // </Box>
    <Box padding="4em" width="100%" height="100%">
      <Typography variant="h4" textAlign="center" mb={2}>
        {title}
      </Typography>
      <ReactMarkdown className={classes.markdown}>{description}</ReactMarkdown>
    </Box>
  );
};

export default CommonPolicyContent;
