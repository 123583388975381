import { createSelector } from 'reselect';

const selectStocks = (store) => {
  const stocks = store?.stocks;
  return stocks;
};

export const stocksList = createSelector([selectStocks], (stocks) => {
  const stocks_js = stocks?.toJS() || {};
  return stocks_js?.data || {};
});
