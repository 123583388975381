import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
const Rating = ({ card, color, label, value, ...props }) => {
  const limit = card ? 10 : 20;
  const percentage = Math.floor(value * (limit / 100));
  const flame_scale = computeFlameScale(value, 'positive');
  const classes = useStyles({ percentage: flame_scale, bg_color: color });
  const bars = [];

  /**
    flame scale logic
    positive = 200%
  **/
  function computeFlameScale(percent, sentiment) {
    let scale_value = percent;

    if (sentiment === 'positive') {
      scale_value += 100;
    }
    return scale_value;
  }

  for (let i = 1; i <= limit; i++) {
    bars.push(
      <Box
        key={i}
        className={clsx(classes.bar, {
          [classes.cardBar]: card,
          [classes.active]: percentage >= i,
        })}
      />
    );
  }

  return (
    <Box>
      <Box>
        <Typography className={clsx(classes.label, { [classes.cardLabel]: card })}>
          {label}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box>
          <Typography variant="h6" className={clsx(classes.value, { [classes.cardValue]: card })}>
            {value || '0'}%
          </Typography>
        </Box>
        <Box display="flex" marginLeft="5px">
          {bars}
        </Box>
      </Box>
    </Box>
  );
};

export default Rating;

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '.875rem',
  },
  value: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  bar: {
    width: '3px',
    height: '14px',
    borderRadius: '3px',
    margin: '0 1.5px',
  },
  cardLabel: {
    fontSize: '.875rem',
  },
  cardValue: {
    fontSize: '1rem',
  },
  cardBar: {
    width: '3px',
    height: '13px',
    background: '#C4C4C4',
  },
  active: {
    background: (props) => props.bg_color,
  },
  flame: {
    '& span': {
      transform: (props) => `scale(${props.percentage}%)`,
      transition: 'all ease 1s',
    },
  },
}));
