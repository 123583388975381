import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EducationSVG } from '../../../../assets/icons/header/education.svg';
import { ReactComponent as GlobalMacroSVG } from '../../../../assets/icons/header/global_macro.svg';
import { ReactComponent as PortfolioManagementSVG } from '../../../../assets/icons/header/portfolio_management.svg';
import { ReactComponent as StockAnalysisSVG } from '../../../../assets/icons/header/stock_analysis.svg';
import { ReactComponent as StockSentimentSVG } from '../../../../assets/icons/header/stock_sentiment.svg';
import useStyles from '../index.style';

const Menu = ({ onClose }) => {
  const styles = useStyles();
  const history = useHistory();

  const menu_items = [
    {
      title: 'Stock Analysis',
      slug: 'technical-analysis',
      icon: <StockAnalysisSVG width="25" height="25" />,
    },
    {
      title: 'Portfolio Management',
      slug: 'risk-aware-profile',
      icon: <PortfolioManagementSVG width="25" height="25" />,
    },
    {
      title: 'Global Macro',
      slug: 'macroeconomics',
      icon: <GlobalMacroSVG width="25" height="25" />,
    },
    {
      title: 'Stock Sentiment',
      slug: 'sentiment',
      icon: <StockSentimentSVG width="25" height="25" />,
    },
    { title: 'Education', slug: 'challenge', icon: <EducationSVG width="25" height="25" /> },
  ];

  return (
    <Box className={styles.list} sx={{ width: '100%', maxWidth: 360 }}>
      <List disablePadding>
        {menu_items.map((item, key) => (
          <ListItem key={`menu-list-item-${key}`} className={styles.listItem} disableGutters>
            <ListItemButton
              className={styles.listItemButton}
              // disableGutters
              onClick={() => {
                onClose();
                history.push('/app/' + item.slug);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText className={styles.listItemText} primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Menu;
