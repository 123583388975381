import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GradientIcon from '../GradientIcon';
import Modal from '../Modal';

const SuccessModal = ({ open, handleClose, message, subsMessage, gotoBtn, ...props }) => {
  const classes = useStyles();

  const closeModal = () => {
    handleClose(false);
  };

  return (
    <Modal open={open} handleClose={closeModal}>
      <Box className={classes.container}>
        <Box display="flex" justifyContent="center">
          <GradientIcon
            Icon={<CheckCircleOutlineRoundedIcon style={{ fill: 'url(#blue-gradient)' }} />}
            height="80px"
            width="80px"
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingTop="1.5rem"
          textAlign="center"
          flexDirection="column"
        >
          <Typography variant="h5" className={classes.checkEmail}>
            {message}
          </Typography>
          <Box mt={1} mb={2}>
            {subsMessage}
          </Box>
          {gotoBtn}
        </Box>
      </Box>
    </Modal>
  );
};

export default SuccessModal;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '2rem 5rem',
    maxWidth: '700px',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 2rem',
    },
  },
  checkEmail: {
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    whiteSpace: 'pre-line',
  },
  tryOtherEmailLink: {
    color: theme.palette.peter_river,
  },
}));
