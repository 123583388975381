import { makeStyles } from '@mui/styles';
import colors from '../../../styles/colors';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    minHeight: '544px',
    padding: '80px 96px',
    borderRadius: '24px',
    background: colors.n8,
    boxShadow: '0px 64px 64px -48px rgba(15, 15, 15, 0.1)',

    [theme.breakpoints.down('xl')]: {
      padding: '80px',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'block',
      padding: 0,
      minHeight: 'auto',
      boxShadow: 'none',
      background: 'none',
    },
  },

  preview: {
    position: 'absolute',
    top: '-30px',
    right: '-30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '563px',
    height: '563px',
    [theme.breakpoints.down('xl')]: {
      top: '20px',
      width: '500px',
      height: '500px',
    },
    [theme.breakpoints.down('lg')]: {
      position: 'static',
      margin: '0 auto',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '320px',
    },

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },

  wrap: {
    position: 'relative',
    zIndex: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: '352px',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      maxWidth: '500px',
      margin: '0 auto',
      textAlign: 'center',
    },
  },
  stage: {
    marginTop: 'auto',
  },

  title: {
    marginBottom: '16px',
    testAlign: 'left',
    fontSize: '24px',
    lineHeight: 'calc(32 / 24) * 1px',
    letterSpacing: '-.01em',
  },

  content: {
    marginBottom: 'auto',
    textAlign: 'left',
    fontSize: '16px',
    lineHeight: 'calc(24 / 16) * 1px',
    color: colors.n4,
  },

  active: {
    background: colors.n3,
    color: colors.n8,
    ':root :global(.dark-mode)': {
      background: colors.n3,
      color: colors.n8,
    },
  },

  button: {
    marginTop: '48px',
    color: '#FFF',
    '& svg': {
      fill: colors.n4,
    },
    '&:hover': {},
    '&.active': {
      background: colors.n2,
      boxShadow: `0 0 0 2px ${colors.n2} inset`,
      color: colors.n8,
      '& svg': {
        fill: colors.n8,
      },
    },

    [theme.breakpoints.down('sm')]: {
      '& .MuiButton-root': {
        height: '40px',
        width: 'min-content !important',
        borderRadius: '20px',
        padding: '0 16px',
        fontSize: '14px',
      },
    },
  },
}));

export default useStyles;
