import { Box, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { makeStyles, withStyles } from '@mui/styles';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 1rem 1rem 1rem',
    display: 'flex',
    alignItems: 'center',
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const CustomSlider = withStyles({
  rail: {
    height: '15px',
    borderRadius: '24px',
    opacity: 1,
    background: 'linear-gradient(.25turn, #EF5DA8, rgba(93, 95, 239, 0.79), #41E7B0)',
  },
  track: {
    opacity: 0,
  },
  thumb: {
    height: '25px',
    width: '25px',
    backgroundColor: '#554fc1',
    border: '2px solid #FFFF',
    boxShadow: '2.70433px 0px 5.40867px rgba(0, 0, 0, 0.15)',
  },
})(Slider);

const GradientSlider = ({ label, percent, style = undefined, variant, ...props }) => {
  const classes = useStyles();
  const [value, setValue] = useState(percent);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box display="flex" flexDirection="row">
      <Box display="flex" textAlign="center" flexDirection="column">
        <Typography className={style} variant="h6">
          {label}
        </Typography>
        <Typography className={style} variant="h4">
          {percent}
        </Typography>
      </Box>
      <Box className={classes.root}>
        <CustomSlider size="medium" value={value} onChange={handleChange} min={-100} max={100} />
      </Box>
    </Box>
  );
};

export default GradientSlider;
