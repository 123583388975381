import { makeStyles } from '@mui/styles';
import colors from '../../styles/colors';
const useStyles = makeStyles((theme) => ({
  section: {
    '@media(minWidth: 767px)': {
      position: 'relative',
      paddingBottom: '80px',
    },
  },
  container: {
    border: '2px solid #85B4ED',
    borderRadius: '20px',
    padding: '2rem',
  },
  title: {
    color: '#3F8ED7',
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '40px',
    '@media(minWidth: 767px)': {
      display: 'block',
      marginBottom: '32px',
    },
    '& button': {
      flexShrink: 0,
      marginLeft: '40px',
      '@media(minWidth: 767px)': {
        position: 'absolute',
        left: '32px',
        right: '32px',
        bottom: 0,
        width: 'calc(100% - 64px)',
        margin: 0,
      },
    },
  },
  nav: {
    display: 'flex',
    marginBottom: '64px',
    '@media(minWidth: 767px)': {
      display: 'none',
    },
  },
  dropdown: {
    display: 'none',
    '@media(minWidth: 767px)': {
      display: 'block',
      marginBottom: '32px',
    },
  },
  link: {
    display: 'flex',
    padding: '6px 12px',
    borderRadius: '14px',
    background: 'none',
    fontSize: theme.typography.button2.fontSize,
    lineHeight: theme.typography.button2.lineHeight,
    fontWeight: theme.typography.button2.fontWeight,
    color: colors.n4,
    transition: 'all .2s',
    '&:hover': {
      color: colors.n3,
      ':root :global(.dark-mode)': {
        color: colors.n6,
      },
    },
    '&.active': {
      background: colors.n3,
      color: colors.n8,
      ':root :global(.dark-mode)': {
        background: colors.n3,
        color: colors.n8,
      },
    },
    '&:not(:last-child)': {
      marginRight: '24px',
    },
  },
  active: {
    background: colors.n3,
    color: colors.n8,
    ':root :global(.dark-mode)': {
      background: colors.n3,
      color: colors.n8,
    },
  },
  table: {
    display: 'table',
    width: '100%',
  },
  row: {
    display: 'table-row',
    color: colors.n2,
    textAlign: 'center',
    justifyContent: 'space-evenly',
    '&:first-child': {
      '& $col': {
        padding: '24px 16px',
        borderbottom: `1px solid ${colors.n6}`,
        color: colors.n4,
      },
      '@media(minWidth: 767px)': {
        padding: '8px',
        '&:nth-child(2)': {
          paddingLeft: 0,
        },
        '&:last-child': {
          paddingRight: 0,
        },
      },
      ':root :global(.dark-mode)': {
        background: colors.n3,
      },
    },
    '&:not(:first-child)': {
      transition: 'background .2s',
      '& $col': {
        verticalAlign: 'middle',
        fontSize: theme.typography.bodyBold1.fontSize,
        lineHeight: theme.typography.bodyBold1.lineHeight,
        fontWeight: theme.typography.bodyBold1.fontWeight,
        letterSpacing: theme.typography.bodyBold1.letterSpacing,
        '@media(minWidth: 767px)': {
          fontSize: '12px',
        },
        '&:first-child': {
          borderRadius: '12px 0 0 12px',
          color: colors.n4,
          '@media(minWidth: 1023px)': {
            borderRadius: 0,
          },
        },
        '&:last-child': {
          borderRadius: '0 12px 12px 0',
          '@media(minWidth: 1023px)': {
            borderRadius: 0,
          },
        },
      },
      '&:hover': {
        background: colors.n7,
        '@media(minWidth: 1023px)': {
          background: 'none',
        },
        ':root :global(.dark-mode)': {
          background: colors.n2,
        },
      },
    },
  },
  col: {
    display: 'table-cell',
    padding: '20px 16px',
    '@media(minWidth: 1023px)': {
      '&:nth-child(5)': {
        display: 'none',
      },
    },
    '@media(minWidth: 767px)': {
      padding: '6px 8px',
      fontSize: '12px',
      '&:first-child': {},
      '&:nth-child(6)': {
        display: 'none',
      },
      '&:nth-child(2)': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
    ':root :global(.dark-mode)': {
      color: colors.n8,
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    flexshrink: 0,
    width: '40px',
    marginright: '32px',
    '@media(minWidth: 767px)': {
      marginRight: '16px',
    },
    '& img': {
      maxWidth: '100%',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingRight: '16px',
    '@media(minWidth: 1179px)': {
      display: 'block',
      padding: 0,
    },
  },
  currency: {
    marginLeft: '12px',
    color: colors.n5,
    '@media(minWidth: 767px)': {
      display: 'none',
    },
  },
  positive: {
    color: colors.p4,
  },
  negative: {
    color: colors.p3,
  },
  chart: {
    width: '136px',
    height: '40px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 2.92783px 7.31959px -0.731959px rgba(0, 0, 0, 0.2)',
    borderRadius: '18.299px',
    padding: '2rem 1rem 2rem 2rem',
  },
  coloredTitle: {
    color: '#5294D7',
    fontWeight: 800,
  },
  gradientText: {
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    fontSize: '21px',
    display: 'inline',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  borderGradient: {
    boxShadow: '0px 0.987013px 4.93506px rgb(0 0 0 / 25%)',
    borderRadius: '14.8052px',
    position: 'relative',
    background: 'linear-gradient(130.56deg, #5D5FEF 6.65%, #41E7B0 96.36%)',
    padding: '3px',
    '& > div': {
      background: '#FFFFFF',
      borderRadius: '14.8052px',
      padding: '0.75rem',
    },
  },
}));

export default useStyles;
