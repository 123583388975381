import ArrowDropDown from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUp from '@mui/icons-material/ArrowDropUpRounded';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo } from 'react';

const Chart1 = ({ upTrend = false, value, ...props }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.title}>
        <Typography>{props.title}</Typography>
        <Typography>{props.trend}</Typography>
      </Box>
      <Box pl=".5rem">{props.children}</Box>
      <Box className={clsx(classes.downTrend, { [classes.upTrend]: upTrend })}>
        <Typography>
          {!upTrend ? '-' : ''}
          {value}
        </Typography>
        {upTrend ? <ArrowDropUp /> : <ArrowDropDown />}
      </Box>
    </Box>
  );
};

const areEqual = (a, b) => {
  return a.upTrend === b.upTrend && a.value === b.value;
};

export default memo(Chart1, areEqual);

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    height: '4rem',

    '& p': {
      fontWeight: 'bold',
    },
  },
  downTrend: {
    display: 'flex',
    color: theme.palette.red1,
  },
  upTrend: {
    color: theme.palette.green_1,
  },
}));
