export const RESET_ACTION = 'login/RESET_ACTION';

export const EDIT_FORM_ACTION = 'login/EDIT_FORM_ACTION';

export const SET_ERROR_ACTION = 'login/SET_ERROR_ACTION';

export const MODAL_CLOSE_ACTION = 'login/MODAL_CLOSE_ACTION';

export const LOGIN_ACTION = 'login/LOGIN_ACTION';
export const LOGIN_OK = 'login/LOGIN_OK';
export const LOGIN_ERROR = 'login/LOGIN_ERROR';
export const LOGIN_END = 'login/LOGIN_END';
export const LOGIN_FETCH = 'login/LOGIN_FETCH';
export const LOGIN_FETCH_OK = 'login/LOGIN_FETCH_OK';
export const LOGIN_SET_DETAILS = 'login/LOGIN_SET_DETAILS';

export const UPDATE_METADATA_ACTION = 'login/UPDATE_METADATA_ACTION';
export const UPDATE_METADATA_OK = 'login/UPDATE_METADATA_OK';
export const UPDATE_METADATA_ERROR = 'login/UPDATE_METADATA_ERROR';
export const UPDATE_METADATA_END = 'login/UPDATE_METADATA_END';
