import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { Contained as ContainedButton } from '../../components/Button';
import TextInput from '../../components/TextInput';
import { ERROR, INFO } from '../../constants';

const CreditCard = ({
  validForm,
  form,
  errors,
  loading,

  handleSubmit,
  handleOnChange,
  handleValidate,
  handleInputFocus,
}) => {
  const classes = useStyles();

  const [focused, setFocused] = useState('');

  const handleFocus = (e) => {
    setFocused(e.target.name);
  };

  return (
    <Box className={classes.container}>
      <Box>
        <Cards
          cvc={form.cvc}
          expiry={form.expiry}
          focused={focused}
          name={form.name}
          number={form.number}
        />
      </Box>
      <form>
        <Grid container justifyContent="space-evenly" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box paddingTop="1rem">
              <TextInput
                id="email"
                name="email"
                label="E-mail"
                placeholder="E-mail"
                required
                conditions={errors?.email}
                inputState={errors?.email?.length ? ERROR : INFO}
                value={form?.email || ''}
                onChange={handleOnChange}
                onBlur={handleValidate}
                onFocus={handleFocus}
              />
            </Box>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Box>
              <TextInput
                id="number"
                name="number"
                label="Card Number"
                placeholder="XXXX XXXX XXXX XXXX"
                required
                conditions={errors?.number}
                inputState={errors?.number?.length ? ERROR : INFO}
                value={form?.number || ''}
                onChange={handleOnChange}
                onBlur={handleValidate}
                onFocus={handleFocus}
                inputMode={'numeric'}
              />
            </Box>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Box>
              <TextInput
                id="name"
                name="name"
                label="Name on Card"
                placeholder="Name on Card"
                required
                conditions={errors?.name}
                inputState={errors?.name?.length ? ERROR : INFO}
                value={form?.name || ''}
                onChange={handleOnChange}
                onBlur={handleValidate}
                onFocus={handleFocus}
              />
            </Box>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Box>
              <TextInput
                id="expiry"
                name="expiry"
                label="Valid Thru"
                placeholder="Valid Thru"
                required
                conditions={errors?.expiry}
                inputState={errors?.expiry?.length ? ERROR : INFO}
                value={form?.expiry || ''}
                onChange={handleOnChange}
                onBlur={handleValidate}
                onFocus={handleFocus}
                inputProps={{ inputMode: 'numeric', pattern: 'dd/dd' }}
              />
            </Box>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Box>
              <TextInput
                id="cvc"
                name="cvc"
                label="CVC"
                placeholder="CVC"
                required
                conditions={errors?.cvc}
                inputState={errors?.cvc?.length ? ERROR : INFO}
                value={form?.cvc || ''}
                onChange={handleOnChange}
                onBlur={handleValidate}
                onFocus={handleFocus}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <ContainedButton
                fullWidth
                miniRounded
                onClick={handleSubmit}
                disabled={loading || !validForm}
              >
                Pay
              </ContainedButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '550px',
  },
}));

export default CreditCard;
