import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Components Import
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { Box, Container, Hidden, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Contained as ContainedButton } from '../../components/Button';
import ProgressCircular from '../../components/ProgressCircular';
import MobileSteppers from './MobileSteppers';
import Options from './Options';
import Steppers from './Steppers';

import brain2 from '../../assets/images/challenge/brain2.png';
import fire from '../../assets/images/challenge/fire.png';
import { ErrorIndicator, LoadingIndicator } from '../../components/ContainerIndicator';

// Redux Import
import { useDispatch, useSelector } from 'react-redux';

import {
  appendAnswerAction,
  modalToggle,
  saveCategoryAnswers,
  saveUserAnswer,
  setIndexAction,
  uploadQuizData,
} from '../../redux/actions/quiz.action';

// Assets Import
import useStyle from './index.style';

import { QUIZ_ENTRY_MODAL } from '../../constants/modal';

const KnowledgeQuiz = (props) => {
  const [showExitPrompt, setShowExitPrompt] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const dispatch = useDispatch();
  const styles = useStyle();
  const history = useHistory();
  const location = useLocation();

  const {
    userQuizDetails = {},
    currentQuestion = {},
    questionNumber,
    answerList = [],
    quizLength,
    quizName,
    quizSlug,
    questionList = [],
    showModal,
    isLoading,
    showFinishedModal,
    categoryQuestionNum,
    isError,
  } = useSelector((store) => {
    const quiz_store = store.quiz;

    if (quiz_store) return quiz_store.toJS();

    return {};
  });

  const { currentCategory } = useSelector((store) => {
    const training_store = store.training;

    if (training_store) return training_store.toJS();

    return {};
  });

  const initBeforeUnLoad = (showExitPrompt) => {};

  window.onbeforeunload = (event) => {
    // Show prompt based on state
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };

  window.onload = function () {
    initBeforeUnLoad(showExitPrompt);
  };

  const handleGoBack = () => {
    if (questionNumber >= 1) {
      dispatch(setIndexAction(questionNumber - 1));
      handlePostAnswer();
      setSubmitted(false);
    }
  };

  const handleGoNext = () => {
    if (submitted) {
      if (questionNumber < quizLength) {
        dispatch(setIndexAction(questionNumber + 1));
        handlePostAnswer();
        setSubmitted(false);
      }
    } else {
      setSubmitted(true);
    }
  };

  const handlePostAnswer = () => {
    const getQuestion = getCurrentQuestion();

    if (getQuestion) {
      const payload = getPayload();
      const getFunction = getPostFunction(payload);
      dispatch(getFunction(payload));
    }
  };

  const getPostFunction = () => {
    return questionNumber === questionList.length ? uploadQuizData : saveUserAnswer;
  };

  const isQuizFinished = () => {
    return questionNumber === questionList.length;
  };

  const getCurrentQuestion = () => {
    return answerList.find((item) => item.question === currentQuestion.id);
  };

  const getPayload = () => {
    const question = getCurrentQuestion();
    return {
      quiztaker: userQuizDetails.id,
      question: question.question,
      answer: question.id,
    };
  };

  const handleAnswerClick = (option) => {
    const isExisting = answerList.find((item) => item.question === option.question);

    dispatch(
      appendAnswerAction(
        isExisting
          ? [...answerList.filter((item) => item.question !== option.question), option]
          : [...answerList, option]
      )
    );
  };

  const handleModalClose = () => {
    dispatch(modalToggle(false));
    localStorage.setItem(QUIZ_ENTRY_MODAL, true);
  };

  const handleSubmit = () => {
    dispatch(saveCategoryAnswers(quizSlug, getPayload()));
    setShowResult(true);
  };

  const handleClick = () => {
    history.goBack();
  };

  const handleScore = (value) => {
    if (questionNumber + 1 === quizLength) {
      const total = score + value;
      setScore(total);
    }
  };

  const checkWidth = () => {
    return window.innerWidth < 720;
  };

  const result = (
    <Box className={styles.resultContainer}>
      <Box mb={4}>
        <img src={fire} />
      </Box>
      <Typography className={styles.scoreText} variant="h3">
        {score} out of {quizLength}
      </Typography>
      <Typography className={styles.scoreText} variant="h4">
        Good job, Kiddo!
      </Typography>
      <Box mt={4}>
        <Button
          className={styles.backLessonButton}
          variant="contained"
          size="medium"
          disableElevation
          onClick={() => history.push({ pathname: `/app/challenge/` })}
        >
          Back to Lessons
        </Button>
      </Box>
    </Box>
  );

  const content = (
    <Box paddingTop="1.5em" paddingBottom="3em">
      {isLoading ? (
        <ProgressCircular loadingText={'Analyzing...'} />
      ) : (
        <>
          <Box display="flex" justifyContent="center" mb={3} className={styles.quizText}>
            <Typography variant="h4">
              {currentCategory?.attributes?.name} - {quizName}
            </Typography>
          </Box>
          <Box alignItems="center" display="flex" flex="1" flexDirection="column" width="100%">
            <Hidden smDown>
              <Steppers
                length={[...Array(quizLength).keys()]}
                currentStep={categoryQuestionNum}
                answerList={questionList?.map((question) =>
                  answerList.find((item) => item.question === question.id)
                )}
              />
            </Hidden>
            <Box>
              <Typography variant="h5" className={styles.title}>
                {currentQuestion.label}
              </Typography>
              <Options
                choices={currentQuestion.answer_set}
                id={currentQuestion.id}
                answerList={answerList}
                onAnswerClick={(e) => handleAnswerClick(e)}
                submitted={submitted}
                handleScore={handleScore}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <Hidden mdUp>
              <MobileSteppers
                quizLength={quizLength}
                activeStep={categoryQuestionNum}
                handleGoNext={handleGoNext}
                handleGoBack={handleGoBack}
              />
            </Hidden>
          </Box>
          <Hidden smDown>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Box display="flex" flexDirection="row" mt={6} mr={12}>
                {questionNumber !== 0 ? (
                  <div className={styles.backBtn} onClick={handleGoBack}>
                    {/* <img className={styles.imgBackIcon} src={left_arrow} alt="back" /> */}
                    PREVIOUS
                  </div>
                ) : (
                  ''
                )}
                {questionNumber !== quizLength - 1 || submitted === false ? (
                  <ContainedButton className={styles.nextBtn} rounded onClick={handleGoNext}>
                    {submitted === true ? 'SUBMIT' : 'NEXT'}
                    {/* <img className={styles.imgIcon} src={right_arrow} alt="next-button" /> */}
                  </ContainedButton>
                ) : (
                  <ContainedButton className={styles.nextBtn} rounded onClick={handleSubmit}>
                    FINISH QUIZ
                  </ContainedButton>
                )}
              </Box>
            </Box>
          </Hidden>
        </>
      )}
    </Box>
  );

  const isFinished = isError ? (
    <ErrorIndicator
      title={`Ooops! Something Went Wrong!`}
      description={'We were not able to fetch your quiz data. Please try again.'}
    />
  ) : (
    <Container className={styles.container}>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <button onClick={handleClick}>
          <Box display="flex" flexDirection="row" mb={2}>
            <ArrowBackIosNewOutlinedIcon className={styles.arrowIcon} fontSize="large" />
            <Box className={styles.quizText}>
              <Typography variant="h4">
                <img src={brain2} className={styles.brainIcon} /> Quiz
              </Typography>
            </Box>
          </Box>
        </button>
      </Box>
      {showResult ? result : content}
      {/* <Mobile
        handleGoBack={handleGoBack}
        handleGoNext={handleGoNext}
        handleAnswerClick={handleAnswerClick}
        submitted={submitted}
        handleScore={handleScore}
      /> */}
    </Container>
  );

  return (
    <Container className={styles.container}>
      {isLoading && !currentQuestion ? <LoadingIndicator /> : isFinished}
    </Container>
  );
};

export default KnowledgeQuiz;
