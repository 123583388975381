import { Box, Container, Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import useStyle from './index.style';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

const Company = (props) => {
  // const [value, setValue] = useState(new Date());
  const styles = useStyle();
  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };

  return (
    <Container>
      <Box mt={12}>
        <Header />
        <Box mt={2} id="company">
          <Typography variant="h4" className={styles.title}>
            The Company
          </Typography>
          <Box display="flex" justifyContent="center">
            <Skeleton variant="rectangular" width={1100} height={600} />
          </Box>
        </Box>
        <Box mb={2} mt={2} id="aboutUs">
          <Typography variant="h4" className={styles.title}>
            About Us
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Box display="flex" justifyContent="center">
                <Stack spacing={1}>
                  <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Skeleton variant="rectangular" width={300} height={600} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Skeleton variant="text" width={300} />
                      <Skeleton variant="text" width={330} />
                      <Skeleton variant="text" width={350} />
                      <Skeleton variant="text" width={350} />
                      <Skeleton variant="text" width={350} />
                      <Skeleton variant="text" width={350} height={620} />
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Box display="flex" justifyContent="center" ml={2}>
                <Skeleton variant="rectangular" width={500} height={600} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mb={2} mt={2} id="team">
          <Typography variant="h4" className={styles.title}>
            Meet the Team
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Box display="flex" justifyContent="center">
                <Stack spacing={1}>
                  <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Skeleton variant="rectangular" width={290} height={300} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Skeleton variant="text" width={290} />
                      <Skeleton variant="text" width={310} />
                      <Skeleton variant="text" width={310} height={320} />
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Box display="flex" justifyContent="center" ml={2}>
                <Stack spacing={1}>
                  <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Skeleton variant="rectangular" width={290} height={300} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Skeleton variant="text" width={290} />
                      <Skeleton variant="text" width={310} />
                      <Skeleton variant="text" width={310} height={320} />
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Box display="flex" justifyContent="center">
                <Stack spacing={1}>
                  <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Skeleton variant="rectangular" width={290} height={300} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Skeleton variant="text" width={290} />
                      <Skeleton variant="text" width={310} />
                      <Skeleton variant="text" width={310} height={320} />
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Box display="flex" justifyContent="center" ml={2}>
                <Stack spacing={1}>
                  <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Skeleton variant="rectangular" width={290} height={290} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Skeleton variant="text" width={290} />
                      <Skeleton variant="text" width={310} />
                      <Skeleton variant="text" width={310} height={320} />
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Box display="flex" justifyContent="center">
                <Stack spacing={1}>
                  <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Skeleton variant="rectangular" width={290} height={300} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Skeleton variant="text" width={290} />
                      <Skeleton variant="text" width={310} />
                      <Skeleton variant="text" width={310} height={320} />
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Box display="flex" justifyContent="center" ml={2}>
                <Stack spacing={1}>
                  <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Skeleton variant="rectangular" width={290} height={300} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Skeleton variant="text" width={290} />
                      <Skeleton variant="text" width={310} />
                      <Skeleton variant="text" width={310} height={320} />
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2} id="contactUs">
          <Typography variant="h4" className={styles.title}>
            Contact Us
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box display="flex" justifyContent="center">
                <Skeleton variant="rectangular" width={700} height={50} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box display="flex" justifyContent="center" mt={2}>
                <Skeleton variant="rectangular" width={700} height={500} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box display="flex" justifyContent="center" mt={2}>
                <Skeleton variant="rectangular" width={150} height={50} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </Container>
  );
};

export default Company;

export { Company };
