import { Box, Container, Typography } from '@mui/material';
import cn from 'classnames';
import React from 'react';
import useStyles from './index.style';

const items = [
  {
    title: 'Sign up',
    image2x: '/images/content/step-1@2x.png',
    image: '/images/content/step-1.png',
    content:
      'Join the community of active traders. ' +
      'Learn how to set up trades, screen for opportunities, manage your risk, and place trades.',
  },
  {
    title: 'Learn the process',
    image2x: '/images/content/step-2@2x.png',
    image: '/images/content/step-2.png',
    content:
      'Traidify promotes a trend following approach to the markets, ' +
      'using mutli-time frame analysis and comparative analysis',
  },
  {
    title: 'Start trading',
    image2x: '/images/content/step-3@2x.png',
    image: '/images/content/step-3.png',
    content: 'Use our trade setups and start trading today.',
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Steps = ({ scrollToRef }) => {
  const styles2 = useStyles();
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          nextArrow: <SlickArrow>{/* <Icon name="arrow-next" size="18" /> */}</SlickArrow>,
          prevArrow: <SlickArrow>{/* <Icon name="arrow-prev" size="18" /> */}</SlickArrow>,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          infinite: false,
          nextArrow: <SlickArrow>{/* <Icon name="arrow-next" size="18" /> */}</SlickArrow>,
          prevArrow: <SlickArrow>{/* <Icon name="arrow-prev" size="18" /> */}</SlickArrow>,
        },
      },
    ],
  };
  return (
    <Container className={cn('container', styles2.container)}>
      <Box className={styles2.head}>
        <Typography className={styles2.title} variant="h2">
          How it works
        </Typography>
        <Typography className={styles2.info} variant="body1">
          First only, plug and play system for your daiy, weekly and monthly trading needs
        </Typography>
      </Box>
      <Box className={styles2.list} justifyContent="center">
        {items.map((x, index) => (
          <Box className={styles2.item} key={index}>
            <Box className={styles2.preview}>
              <img srcSet={`${x.image2x} 2x`} src={x.image} alt={`Step ${index + 1}`} />
            </Box>
            <Typography className={styles2.number}>Step {index + 1}</Typography>
            <Box mt={2}>
              <Typography className={styles2.subtitle}>{x.title}</Typography>
            </Box>
            <Box mt={2}>
              <Typography className={styles2.content}>{x.content}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default Steps;
