import React, { useEffect, useState } from 'react';

import { Box, Button, IconButton, InputAdornment, Typography } from '@mui/material';
import qs from 'qs';
import { Link, useLocation } from 'react-router-dom';

import { Contained } from '../../components/Button';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from '../../components/TextInput';
import { ERROR, INFO, SUCCESS } from '../../constants';
import { openSnackbarAction } from '../../redux/actions/app.action';
import { createNewPasswordAction } from '../../redux/actions/auth.action';
import useStyle from './index.style';
import SuccessModal from './ResetPasswordSuccessModal';

const CreateNewPassword = ({ handleValidate, ...props }) => {
  const dispatch = useDispatch();
  const auth = useSelector((store) => store?.auth);
  const notify = !!auth && auth.get('notify');
  const message = !!auth && auth.get('message');
  const classes = useStyle();
  const { search } = useLocation();
  const params = qs.parse(search.substr(1)); // remove ? at the beginning
  const code = params.code;

  const [success_modal, setSuccessModal] = useState(false);
  const [showPassword, setShowPassword] = useState([false, false]);
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [password_errors, setPasswordErrors] = useState([]);
  const [confirm_password_errors, setConfirmPasswordErrors] = useState([]);

  useEffect(() => {
    if (!!notify && notify == SUCCESS) {
      setSuccessModal(true);
    } else if (!!notify && notify == ERROR) {
      dispatch(openSnackbarAction(ERROR, message));
    }
  }, [notify, message]);

  const handleClick = () => {
    // TODO: handle click here
    dispatch(createNewPasswordAction({ code, password, confirm_password }));
  };

  const handleTogglePasswordVisibility = (index) => {
    const newShowPassword = [...showPassword];
    newShowPassword[index] = !newShowPassword[index];
    setShowPassword(newShowPassword);
  };

  return (
    <Box>
      <SuccessModal open={success_modal} handleClose={() => setSuccessModal(false)} />
      <Box className={classes.desc1}>
        <Typography variant="h4">Create New Password</Typography>
      </Box>
      <Box paddingTop="16px" className={classes.desc2}>
        <Typography variant="h6">
          Your new password must be different from recently used passwords.
        </Typography>
      </Box>
      <Box paddingTop="40px" maxWidth="400px">
        <form noValidate autoComplete="off">
          <Box>
            <TextInput
              id="password"
              value={password}
              onChange={(e) => {
                setPasswordErrors([]);
                setPassword(e.target.value);
              }}
              variant="outlined"
              size="small"
              label="Password"
              placeholder="Password"
              className={classes.textInput}
              type={showPassword[0] ? 'text' : 'password'}
              onBlur={(e) => handleValidate(e, setPasswordErrors)}
              conditions={password_errors}
              inputState={password_errors.length ? ERROR : INFO}
              InputProps={{
                endAdornment: (
                  <InputAdornment className={classes.inputAdornment} position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleTogglePasswordVisibility(0)}
                      tabIndex={-1}
                    >
                      {showPassword[0] ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box paddingTop="2rem">
            <TextInput
              id="confirm_password"
              value={confirm_password}
              onChange={(e) => {
                setConfirmPasswordErrors([]);
                setConfirmPassword(e.target.value);
              }}
              variant="outlined"
              size="small"
              label="Password"
              placeholder="Password"
              className={classes.textInput}
              type={showPassword[1] ? 'text' : 'password'}
              onBlur={(e) => handleValidate(e, setConfirmPasswordErrors, password)}
              conditions={confirm_password_errors}
              inputState={confirm_password_errors.length ? ERROR : INFO}
              InputProps={{
                endAdornment: (
                  <InputAdornment className={classes.inputAdornment} position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleTogglePasswordVisibility(1)}
                      tabIndex={-1}
                    >
                      {showPassword[1] ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box paddingTop="30px">
            <Contained
              onClick={handleClick}
              className={classes.textInput}
              miniRounded
              disabled={
                !password ||
                !confirm_password ||
                !!password_errors.length ||
                !!confirm_password_errors.length
              }
            >
              Reset Password
            </Contained>
          </Box>
          <Box paddingTop="20px" className={classes.noAccount}>
            <span>Don’t have an account?</span>
            <Button component={Link} disableRipple to="/sign-up">
              Sign Up
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default CreateNewPassword;
