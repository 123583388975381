const POLICY = {
  title: 'Membership Agreement',
  description: `Welcome to your new Traidify account!`,
  items: [
    {
      title: `Before you get started, please read our Membership Agreement, then use the buttons at the bottom to continue to your account.`,
      description: `‍Traidify.com, Inc., a Deleware corporation (referred to hereafter as "Traidify.com"),offers an online membership service which is an expanded suite of online tools andservices beyond what is currently offered under the Free User status. By completing theregistration process, paying all required membership fees, and using your membership, youare indicating that you agree to be bound by all of the terms in this MembershipAgreement. Please print and keep a copy of this Agreement. Traidify.com may change theterms in this Membership Agreement. When the terms are changed, Traidify.com will notifyyou by e-mail or online postings. The changes also will appear in this document, which youcan access at any time using the "Terms of Service" link at the bottom of any page on thesite. If you do not agree to be bound by the changes, you should not use your accountagain and you should cancel your membership. If you use your account after Traidify.comhas notified members of a change in the Membership Agreement, you are agreeing now to bebound by all of the changes.`,
    },
    {
      title: `PRIVACY OF INFORMATION`,
      description: `‍Please review our Privacy Statement, which also governs your use of our service, tounderstand how we collect and use information about you.`,
    },
    {
      title: `FEES AND PAYMENTS`,
      description: `‍Month-to-month members will be automatically renewed each month. You may disable this automatic renewal feature at any time.Long-term members will receive an email notification when their account is about the expire. Members can renew their account at any time using our online payment system.If we do not receive notice of your renewal by the expiration date, your account will revert back to the Free User status. Users who paid for their membership by personal check will not receive a renewal notice and are responsible for renewing their account before the expiration date.All fees are due in advance and are non-refundable.If you believe someone has accessed your account using your user name and password without your authorization, you must contact Traidify.com at support@stockcharts.com.If you are not satisfied with our service within 10 days from when you placed your initial order, you may cancel your account and you will not be charged. Individuals who subscribe via check are not eligible for refunds. If you cancel your membership, you will not be able to re-subscribe for three months from the date of cancellation.`,
    },
    {
      title: `COPYRIGHT AND LIMITATIONS ON USE`,
      description: `‍Only one individual may access your account at the same time, unless agreed otherwise by Traidify.com. The content available through your account is the property of Traidify.com or its licensors and is protected by copyright, trademark and other intellectual property laws. Content received through your account may be displayed, reformatted, and printed for your personal, non-commercial use only. You agree not to reproduce, retransmit, distribute, display, disseminate, sell, publish, broadcast or circulate the content to anyone, including but not limited to others in the same company or organization, without the express prior written consent of Traidify.com, with this one exception:You may, on an occasional and irregular basis, disseminate an insubstantial portion of our content for noncommercial purposes, without charge, to a limited number of individuals, provided you include all copyright and other proprietary rights notices with such portion of the content in the same form in which the notices appear on Traidify.com, original source attribution, and the phrase "Used with permission from Traidify.com, Inc." To request consent for other matters, please contact Traidify.com at support@traidify.com.`,
    },
    {
      title: `Were You Recommended?`,
      description: `If another Traidify Member recommended that you sign up, enter their User ID (i.e. email address) below and they'll receive a free month of service!‍`,
    },
  ],
};

export default POLICY;
