import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const getBarItemBorderRadius = (bartype, isvertical) => {
  if (bartype === 'negative') return isvertical === 'true' ? '0 0 1rem 1rem' : '1rem 0 0 1rem';
  if (bartype === 'positive') return isvertical === 'true' ? '1rem 1rem 0 0' : '0 1rem 1rem 0';
};

export const StyledContainer = styled(Box)(({ isvertical, labelPosition }) => ({
  display: 'flex',
  padding: '0px !important',

  '& :last-child': {
    marginBottom: '0 !important',
  },
  ...(isvertical === 'true'
    ? {
        flexDirection: 'column',
        marginRight: '12px',
      }
    : {
        flexDirection: labelPosition === 'top' ? 'column' : 'row',
      }),
}));

export const StyledEmptyBarValue = styled(Box)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const StyledBarValue = styled(Box)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const BarContainer = styled(Box)(({ isvertical }) => ({
  display: 'flex',
  ...(isvertical === 'true' && { flexDirection: 'column-reverse' }),
}));

export const StyledBar = styled(Box)(({ isvertical, bartype, theme, value, isrounded }) => ({
  display: 'flex',
  flex: 1,
  type: bartype,
  overflow: 'visible',
  ...(isvertical === 'true' && { flexDirection: 'column-reverse' }),
  ...(bartype === 'negative'
    ? {
        background: theme.palette.empty_bar_item,
        borderRadius:
          isrounded === 'true' ? '1rem' : getBarItemBorderRadius('negative', isvertical),

        '& .emptyBarValue': {
          display: 'flex',
          flex: `${Number(100 - value)}`,
        },

        '& .barValue': {
          display: 'flex',
          flex: value,
          background: theme.palette.gradients.gradient_negative,
          borderRadius:
            isrounded === 'true' ? '1rem' : getBarItemBorderRadius('negative', isvertical),
        },
      }
    : {
        background: theme.palette.empty_bar_item,
        borderRadius:
          isrounded === 'true' ? '1rem' : getBarItemBorderRadius('positive', isvertical),

        '& .emptyBarValue': {
          display: 'flex',
          flex: `${Number(100 - Number(value))}`,
        },

        '& .barValue': {
          display: 'flex',
          flex: value,
          background: theme.palette.gradients.gradient_positive,
          borderRadius:
            isrounded === 'true' ? '1rem' : getBarItemBorderRadius('positive', isvertical),
        },
      }),
}));

export const StyledGridItem = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  height: 'inherit',

  '& > div': {
    whiteSpace: 'nowrap',
    overflow: 'hidden ',
    textOverflow: 'ellipsis',
  },
});

export const StyledResultValue = styled(Typography)(({ resultvalue, resultcolor, theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...(resultcolor && { color: resultcolor }),
  ...(resultvalue &&
    typeof resultvalue === 'number' &&
    resultvalue < 0 && { color: theme.palette.red4 }),
}));

export const StyledPercentageValue = styled(Box)(({ isvertical }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 3px',
  minWidth: '75px',

  ...(isvertical === 'true' && {
    writingMode: 'vertical-rl',
    '-webkit-transform': 'rotate(90deg)',
    '-ms-transform': 'rotate(-150deg)',
    transform: 'rotate(180deg)',
  }),
}));

export const StyledLabel = styled(Typography)(({ isvertical, height }) => ({
  cursor: 'pointer',
  height: 'inherit',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...(isvertical === 'true' && {
    writingMode: 'vertical-rl',
    transform: 'rotate(-150deg)',
    textAlign: 'center',
    height: height,
    marginRight: '20px',
    fontSize: '13px',
  }),
}));
