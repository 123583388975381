import BackupIcon from '@mui/icons-material/Backup';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';

const MobileSteppers = ({ quizLength, activeStep, handleGoNext, handleGoBack, handleSubmit }) => {
  return (
    <MobileStepper
      variant="progress"
      steps={quizLength}
      position="static"
      activeStep={activeStep}
      sx={{ maxWidth: 400, flexGrow: 1, background: 'inherit' }}
      nextButton={
        activeStep !== quizLength - 1 ? (
          <Button size="small" onClick={handleGoNext}>
            Next <KeyboardArrowRight />
          </Button>
        ) : (
          <Button size="small" onClick={handleSubmit}>
            Submit
            <Box ml={0.5}>
              <BackupIcon />
            </Box>
          </Button>
        )
      }
      backButton={
        <Button size="small" onClick={handleGoBack} disabled={activeStep === 0}>
          <KeyboardArrowLeft /> Back
        </Button>
      }
    />
  );
};

export default MobileSteppers;
