import clsx from 'clsx';

import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Card, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { PieChart } from '../../../components/Graph';
import useStyle from '../index.style';

const GeneratePieChart = ({ values, labels, index, colors, setValue, TYPE, ...props }) => {
  const styles = useStyle();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedIndex, setSelectedIndex] = useState(null);

  const { userPortfolio } = useSelector((store) => {
    const profile_store = store.profile;

    if (profile_store) return profile_store.toJS();

    return {};
  });

  const riskTypes = [
    'Very Conservative',
    'Conservative',
    'Moderate',
    'Aggressive',
    'Very Aggressive',
  ];

  const isSelected = (index) => {
    const type = userPortfolio?.data[0]?.attributes?.name;
    // setValue(index);
    return riskTypes.indexOf(type) === index;
  };

  const handleClick = (index) => {
    if (setValue) {
      setValue(riskTypes[index]);
    }
    setSelectedIndex(index);
  };

  const textType = () => {
    return isSelected(index) ? styles.boldWhiteText : styles.whiteText;
  };

  const getContent = () => {
    return TYPE === 1 ? (
      <Box
        className={clsx(styles.chartContainer, {
          [styles.selectedItem]: isSelected(index),
          [styles.hide]: !isSelected(index) && small,
        })}
        onClick={() => handleClick(index)}
        mt={2}
      >
        <Typography className={styles.textFocus}>{riskTypes[index]}</Typography>
        <Card className={styles.cardItem}>
          <Box p={1}>
            <PieChart
              labels={labels}
              values={values}
              size={isSelected(index) ? 16 : 12}
              showLegend={false}
              bgColor={colors}
            />
          </Box>
        </Card>
      </Box>
    ) : (
      <Box>
        <Box mb={isSelected(index) ? 2 : 3} mt={2}>
          <Typography
            className={clsx(textType(), {
              [styles.hide]: !isSelected(index) && small,
            })}
            variant="h6"
          >
            {riskTypes[index]}
          </Typography>
        </Box>
        <Box
          className={clsx(styles.chartContainer2, {
            [styles.selectedItem2]: isSelected(index),
            [styles.hide]: !isSelected(index) && small,
          })}
          onClick={() => handleClick(index)}
          mb={7}
        >
          <Box p={1}>
            <PieChart
              labels={labels}
              values={values}
              size={isSelected(index) ? 16 : 12}
              showLegend={false}
              bgColor={colors}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return <>{getContent()}</>;
};

export default GeneratePieChart;
