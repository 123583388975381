import { Card, CardContent, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useMemo } from 'react';
import GaugeChart from 'react-gauge-chart';
import { useDispatch, useSelector } from 'react-redux';
import PositiveNegativeProgressBarChart from '../../../components/Charts/PositiveNegativeProgressBarChart';
import { riskIndicatorAction } from '../../../redux/actions/stock.action';
import { macroeconomicsSelector } from '../../../redux/reselect/macroeconomicsSelector';
import { stocksSelector } from '../../../redux/reselect/stocksSelector';
import useStyles from './styles';

const RiskIndicator = () => {
  //Styles
  const classes = useStyles();
  const dispatch = useDispatch();

  const { timeFrame } = useSelector(macroeconomicsSelector);
  const { risk_indicator } = useSelector(stocksSelector);
  const risk_basket = risk_indicator.data?.risk_basket;
  const risky_total = risk_indicator.data?.risky_total.toFixed(2);
  const safety_total = risk_indicator.data?.safety_total.toFixed(2);
  const indicator = risk_indicator.data?.risk_indicator;
  const statusLabel =
    indicator === 'OFF' ? 'Safe Assets Leading, Risk Off' : 'Risk Assets Leading, Risk On';

  const gaugeValue = indicator === 'OFF' ? 0.3 : 0.8;

  useEffect(() => {
    if (typeof timeFrame === 'undefined') return;

    dispatch(riskIndicatorAction(timeFrame));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFrame]);

  const getPercentage = (value = 0, total = 0) => {
    const val = (value / total) * 100;

    if (isNaN(val)) return 0;
    return Number(val);
  };

  const getAssets = (data = []) => {
    const performanceIndexList = data.map((d) => Math.abs(d.performance_index));
    const largestAbsoluteValue = Math.max(...performanceIndexList);

    const chartData = data.map((d, i) => {
      const performanceIndex = d.performance_index;
      const value = Math.abs(getPercentage(performanceIndex, largestAbsoluteValue));
      const positiveValue = d.performance_index >= 0 ? value : 0;
      const negativeValue = d.performance_index < 0 ? value : 0;

      return {
        id: i,
        label: `${d.ticker} (${d.fund_name})`,
        positiveValue: Math.abs(positiveValue),
        negativeValue: Math.abs(negativeValue),
        resultValue: Number(d.performance_index.toFixed(2)),
      };
    });
    return chartData;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const riskyData = useMemo(() => getAssets(risk_basket?.risky), [risk_basket?.risky]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const safetyData = useMemo(() => getAssets(risk_basket?.safety), [risk_basket?.safety]);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid
          container
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          width="100%"
          height="100%"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            display="flex"
            width="100%"
            height="50%"
            maxHeight="300px"
            justifyContent="center"
            alignItems="center"
          >
            <Box maxWidth="50%">
              <Typography variant="h6" fontWeight="bold" textAlign="center">
                Risk Indicator
              </Typography>
              <GaugeChart id="gauge-chart1" nrOfLevels={30} percent={gaugeValue} hideText />
              <Box display="flex" justifyContent="center">
                <Typography fontSize="1.2em" fontWeight="bold">
                  {statusLabel}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* <Grid
            item
            xs={1}
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="caption" fontSize="0.5em" textAlign="center">
              Market Analysis by <span style={{ color: 'rgb(51, 123, 207)' }}>Traidify</span>{' '}
              Powered by <span style={{ color: 'rgb(51, 123, 207)' }}>Traidify</span>
            </Typography>
            <Box
              style={{ height: '100%', width: '1px', border: '1px solid rgba(0, 0, 0, 0.12)' }}
            />
          </Grid> */}
          <Grid item xs={12} display="flex" height="50%">
            <Grid container display="flex" width="100%" height="100%">
              <Grid item xs={6} pr={1} width="100%" height="100%">
                <Typography variant="h6" fontWeight="bold" textAlign="center">
                  Risky Assets
                </Typography>
                <Typography variant="h6" fontWeight="bold" textAlign="center">
                  {risky_total}%
                </Typography>
                <PositiveNegativeProgressBarChart
                  barItemHeight="14px"
                  barItemWidth="100%"
                  chartHeight="100%"
                  chartWidth="100%"
                  direction="horizontal"
                  labelPosition="top"
                  rowHeight={20}
                  data={riskyData}
                />
              </Grid>
              <Grid item xs={6} width="100%" height="100%">
                <Typography variant="h6" fontWeight="bold" textAlign="center">
                  Safety Assets
                </Typography>
                <Typography variant="h6" fontWeight="bold" textAlign="center">
                  {safety_total}%
                </Typography>
                <PositiveNegativeProgressBarChart
                  barItemHeight="14px"
                  barItemWidth="100%"
                  chartHeight="100%"
                  chartWidth="100%"
                  direction="horizontal"
                  labelPosition="top"
                  rowHeight={20}
                  data={safetyData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RiskIndicator;
