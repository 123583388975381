import { makeStyles } from '@mui/styles';
import colors from '../../../styles/colors';
const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: '1280px',
    margin: '0 auto',
    padding: '0 40px',

    [theme.breakpoints.only('lg')]: {
      padding: '0 20px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 18px',
    },
  },
  section: {
    '@media(minWidth: 767px)': {
      position: 'relative',
      paddingBottom: '80px',
    },
  },
  head: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '80px',
    '@media(minWidth: 1179px)': {
      marginBottom: '48px',
    },
    '@media(minWidth: 767px)': {
      display: 'block',
    },
    '& button': {
      flexShrink: 0,
      '@media(minWidth: 767px)': {
        position: 'absolute',
        left: '32px',
        right: '32px',
        bottom: 0,
        width: 'calc(100% - 64px)',
        margin: 0,
      },
    },
  },
  wrap: {
    flexGrow: 1,
  },
  title: {
    marginBottom: '40px',
    marginTop: '96px',
    fontWeight: 'bold',
    color: '#3F8ED7',
    [theme.breakpoints.only('md')]: {
      marginBottom: '24px',
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
      textAlign: 'center',
    },
  },
  nav: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      // margin: '0 -32px',
      justifyContent: 'center',
    },
  },
  view: {
    marginTop: '60px',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  link: {
    display: 'flex',
    padding: '6px 12px',
    borderRadius: '14px',
    background: 'none',
    fontSize: '14px',
    lineHeight: 'calc(16 / 14) * 1px',
    fontWeight: 700,
    color: colors.n4,
    transition: 'all .2s',
    '@media(minWidth: 767px)': {
      flexShrink: 0,
    },
    '&:hover': {
      color: colors.n3,
    },
    ':root :global(.dark-mode)': {
      color: colors.n6,
    },
    '&:not(:last-child)': {
      marginRight: '24px',
      '@media(minWidth: 767px)': {
        marginRight: '20px',
      },
    },
  },
  active: {
    background: colors.n3,
    color: colors.n8,
    ':root :global(.dark-mode)': {
      background: colors.n3,
      color: colors.n8,
    },
  },
  wrapper: {
    margin: '-32px -18px 0',
    '@media(minWidth: 1179px)': {
      margin: '0 -16px',
    },
    '&:after': {
      content: ' ',
      display: 'table',
      clear: 'both',
    },
    '@media(minWidth: 1179px)': {
      display: 'none',
    },
  },
  item: {
    width: 'calc(50% - 36px)',
    margin: '32px 18px 0',
    float: 'right',
    color: colors.n2,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      float: 'none',
      margin: '2rem 0',
    },
    '&:hover': {
      '& $preview': {
        '& img': {
          transform: 'scale(1.1)',
        },
      },
      '& $subtitle': {
        color: colors.p1,
      },
    },
    '&:first-child': {
      marginBottom: '32px',
      float: 'left',
      [theme.breakpoints.up('lg')]: {
        float: 'none',
      },
      '& $preview': {
        marginBottom: '40px',
        borderRadius: '20px',
        [theme.breakpoints.up('lg')]: {
          marginBottom: '24px',
          borderRadius: '16px',
        },
      },
      '& $subtitle': {
        marginTop: '48px !important',
        marginBottom: '16px',
        fontSize: '32px',
        lineHeight: '40px',
        fontWeight: 700,
        letterSpacing: '-.01em',
        color: 'black',
        [theme.breakpoints.up('lg')]: {
          fontWeight: 600,
          lineHeight: '32px',
          letterSpacing: 0,
        },
        [theme.breakpoints.down('md')]: {
          marginBottom: '8px',
          fontSize: '16px',
        },
      },
      '& $content': {
        fontSize: '16px',
        lineHeight: 1.5,
        [theme.breakpoints.up('lg')]: {
          fontSize: '14px',
        },
      },
    },
    '&:not(:first-child)': {
      display: 'flex',
      flexDirection: 'row-reverse',
      '& $preview': {
        flex: '0 0 45.65%',
        borderRadius: '16px',
        marginLeft: '16px',
      },
      '& $subtitle': {
        marginBottom: '16px',
        fontSize: '16px',
        lineHeight: 'calc(24 / 16) * 1px',
        [theme.breakpoints.up('lg')]: {
          marginBottom: 0,
          fontSize: '24px',
          lineHeight: '32px',
        },
        [theme.breakpoints.down('md')]: {
          marginBottom: '8px',
          fontSize: '16px',
        },
      },
      '& $content': {
        marginBottom: 'auto',
      },
    },
  },
  preview: {
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      '&:before': {
        content: '',
        display: 'block',
        paddingBottom: '77%',
      },
    },
    '& img': {
      width: '100%',
      height: '80%',
      borderRadius: '20px',
      transition: 'transform 1s',
      [theme.breakpoints.up('lg')]: {
        '&:before': {
          content: '',
          display: 'block',
          paddingBottom: '77%',
        },
      },
    },
  },
  previewSkeleton: {
    '& span': {
      width: '100%',
      height: '80%',
      borderRadius: '20px',
    },
  },
  line: {
    display: 'flex',
    button: {
      flexShrink: 0,
      marginLeft: '32px',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  subtitle: {
    transition: 'color .2s',
    color: '#7FB1EE',
    fontWeight: 'bold',
  },
  content: {
    color: colors.n4,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingRight: '16px',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      padding: 0,
    },
  },
  date: {
    color: colors.n4,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
