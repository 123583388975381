import {
  EDIT_FORM_ACTION,
  SET_ERROR_ACTION,
  PAYMENTS_ACTION,
  MODAL_CLOSE_ACTION,
  RESET_ACTION,
  VERIFY_EMAIL_ACTION,
  TOGGLE_PAYMENT_DETAILS_MODAL,
  FOCUS_ACTION,
  TOGGLE_STRIPE_MODAL,
} from '../constants/payments.constant';

export const paymentsAction = (params) => ({ type: PAYMENTS_ACTION, params });
export const editFormAction = (key, value) => ({ type: EDIT_FORM_ACTION, key, value });
export const setErrorAction = (key, errors = []) => ({ type: SET_ERROR_ACTION, key, errors });
export const modalCloseAction = () => ({ type: MODAL_CLOSE_ACTION });
export const resetAction = () => ({ type: RESET_ACTION });
export const verifyEmailAction = (params) => ({ type: VERIFY_EMAIL_ACTION, params });
export const togglePaymentDetailsModal = (value) => ({ type: TOGGLE_PAYMENT_DETAILS_MODAL, value });
export const toggleStripeModal = (value) => ({ type: TOGGLE_STRIPE_MODAL, value });
export const focusAction = (value) => ({ type: FOCUS_ACTION, value });
