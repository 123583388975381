import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, Card, CardContent, Container, Grid, IconButton, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Slider from '@mui/material/Slider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { LoadingIndicator } from '../../components/ContainerIndicator';
import { AreaChart, Doughnut } from '../../components/Graph';
import { RadioGroupButton } from '../../components/RadioGroup';
import { TextInput2 } from '../../components/TextInput';

import useStyle from './index.style';
import Types from './Types';

import { format } from 'date-fns';
import { getUserPortfolio } from '../../redux/actions/profile.action';

const RiskAwareProfile = (props) => {
  const styles = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const [title, setTitle] = useState('Profile');
  const [details, setDetails] = useState('overview');
  const [chartLabels, setChartLabels] = useState([]);
  const [chartValues, setChartValues] = useState([]);
  const [isFlipped, setIsFlipped] = useState(false);
  const [breakdownData, setBreakdownData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [xAxisLabels, setXAxisLabels] = useState([]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [CAGR, setCAGR] = useState('');

  const { currentPortfolioData, userPortfolio, loading, isCustom, dates } = useSelector((store) => {
    const profile_store = store.profile;

    if (profile_store) return profile_store.toJS();

    return {};
  });

  const [startDate, setStartDate] = useState(format(new Date(dates.startDate), 'PPP'));
  const [endDate, setEndDate] = useState(format(new Date(dates.endDate), 'PPP'));
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);

  const allocations = [
    { label: 'Custom', value: 'CP' },
    { label: 'Very Conservative', value: 'VC' },
    { label: 'Conservative', value: 'C' },
    { label: 'Moderate', value: 'M' },
    { label: 'Aggressive', value: 'A' },
    { label: 'Very Aggressive', value: 'VA' },
  ];

  const breakDowns = [
    [43, 29, 28],
    [36, 29, 35],
    [38, 31, 31],
  ];

  // const handleTab = (currentTab) => {
  //   setTab(currentTab);
  //   allocations.forEach((allocation, index) => {
  //     if (allocation.label === userPortfolio?.data[0]?.attributes?.name) {
  //       dispatch(getPortfolioData(allocation.value, currentTab));
  //     }
  //   });
  // };

  const handleDetails = (event, value) => {
    if (value.length) {
      setDetails(value);
    }
  };

  const handleClick = () => {
    history.push('/app/risk-aware-profile/create-profile');
  };

  const handleFlip = () => {
    setIsFlipped((prevFlip) => !prevFlip);
  };

  const handleChartIndex = (labels, date) => {
    return labels.map((label) => label).indexOf(date);
  };

  const loadCustomData = () => {
    let labels = [];
    let values = [];
    try {
      currentPortfolioData?.analysis?.aggregate.forEach((data, dataIndex) => {
        data.assets.forEach((asset, assetIndex) => {
          // sum += asset.allocation;
          labels.push(asset.assets);
          values.push(asset.allocation.toFixed(2));
        });
      });

      let performance = [];
      for (const [key, value] of Object.entries(currentPortfolioData.performance)) {
        performance.push({ label: key, value: value });
      }
      setBreakdownData(performance);

      setChartLabels(labels);
      setChartValues(values);
      setTitle(currentPortfolioData?.portfolio);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!isCustom) {
      dispatch(getUserPortfolio());
    } else {
      loadCustomData();
    }
  }, []);

  // useEffect(() => {
  //   console.log('USE EFFECT IS', allocations);
  //   allocations.forEach((allocation, index) => {
  //     if (allocation.label === userPortfolio?.data[0]?.attributes?.name) {
  //       dispatch(getPortfolioData(allocation.value, tab));
  //     }
  //   });
  // }, [userPortfolio?.data[0]?.attributes?.name]);

  useEffect(() => {
    if (isCustom) {
      loadCustomData();
    }
  }, [currentPortfolioData?.analysis?.aggregate[0]?.allocation]);

  useEffect(() => {
    //loadCustomData();
    const newData = [];
    const allLabels = [];
    const allValues = [];
    if (currentPortfolioData?.analysis?.aggregate[0]?.allocation) {
      for (const [key, value] of Object.entries(currentPortfolioData?.returns_value)) {
        newData.push({ name: key, value: value });
        allValues.push(value);
        allLabels.push(key);
      }
      setCAGR((currentPortfolioData?.performance['CAGR'] * 100).toFixed(2) + '%');
    }

    setXAxisLabels(allLabels);
    setMinValue(Math.min(...allValues));
    setMaxValue(Math.max(...allValues));
    setChartData(newData);
  }, [currentPortfolioData?.analysis?.aggregate[0]?.allocation]);

  const content = (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Tooltip title={<Typography>Go Back</Typography>}>
            <IconButton
              size="large"
              onClick={() => history.push({ pathname: `/app/risk-aware-profile/create-profile` })}
            >
              <ArrowBackIcon fontSize="large" color="primary" />
            </IconButton>
          </Tooltip>
          <Typography className={styles.title} variant="h5">
            {title} Investor
          </Typography>
        </Box>
        {/* <Box>
          <Box display="flex" justifyContent="center" mt={0.3}>
            <ButtonGroup
              buttons={['traditional', 'blockchain', 'hedge']}
              currentTab={tab}
              getTab={handleTab}
            ></ButtonGroup>
          </Box>
        </Box> */}
      </Box>
      <Grid container>
        <Grid xs={12} sm={12} md={12} lg={5} xl={5}>
          <Card className={styles.graphContainer}>
            <CardContent>
              <Box className={styles.centerContent} mt={2} mb={6}>
                <ToggleButtonGroup color="info" value={details} exclusive onChange={handleDetails}>
                  <ToggleButton value="overview">Overview</ToggleButton>
                  <ToggleButton value="details">Details</ToggleButton>
                </ToggleButtonGroup>
              </Box>
              {details === 'overview' ? (
                <Box className={styles.centerContent} flexDirection="column">
                  <Doughnut labels={chartLabels} values={chartValues} breakDowns={breakDowns} />
                </Box>
              ) : (
                <Container>
                  <Box display="flex" alignItems="center">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box display="flex" justifyContent="space-around" textAlign="center">
                          <Typography className={styles.detailsFocus}>
                            Metrics
                            <Typography variant="subtitle1">
                              {startDate + ' - ' + endDate}
                            </Typography>
                          </Typography>
                        </Box>
                        {/* <TimePeriod
                          parentSD={startDate}
                          setStartDate={setStartDate}
                          parentED={endDate}
                          setEndDate={setEndDate}
                        /> */}
                      </Grid>
                    </Grid>
                  </Box>
                  <List>
                    {['Annualized Mean', 'Annualized Std'].map((value) => {
                      return (
                        <ListItem>
                          <Grid container display="flex" justifyContent="space-between">
                            <Grid inputMode="">
                              <Typography variant="h6">{value}</Typography>
                            </Grid>
                            <Grid item>
                              <Typography className={styles.allocationTitle} variant="h6">
                                {(currentPortfolioData.performance[value] * 100).toFixed(2)}%
                              </Typography>
                            </Grid>
                          </Grid>
                        </ListItem>
                      );
                    })}
                  </List>
                  <List>
                    {['Maximum Drawdown', 'Max Drawdown Duration'].map((value) => {
                      return (
                        <ListItem>
                          <Grid container display="flex" justifyContent="space-between">
                            <Grid inputMode="">
                              <Typography variant="h6">{value}</Typography>
                            </Grid>
                            <Grid item>
                              <Typography className={styles.allocationTitle} variant="h6">
                                {value === 'Maximum Drawdown'
                                  ? (currentPortfolioData.performance[value] * 100).toFixed(2) + '%'
                                  : currentPortfolioData.performance[value]}
                              </Typography>
                            </Grid>
                          </Grid>
                        </ListItem>
                      );
                    })}
                  </List>
                  <InfoOutlinedIcon id="basic-button" color="primary" sx={{ marginLeft: '15px' }} />
                  <List>
                    {['Calmar Ratio', 'Sharpe Ratio', 'Sortino Ratio', 'Kelly Criterion'].map(
                      (value) => {
                        return (
                          <ListItem>
                            <Grid container display="flex" justifyContent="space-between">
                              <Grid inputMode="">
                                <Typography variant="h6">{value}</Typography>
                              </Grid>
                              <Grid item>
                                <Typography className={styles.allocationTitle} variant="h6">
                                  {currentPortfolioData.performance[value].toFixed(2)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </ListItem>
                        );
                      }
                    )}
                  </List>
                </Container>
              )}
            </CardContent>
          </Card>
          <Box className={styles.cardContainer}>
            <Box className={styles.centerContent}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="h6">
                    A typical portfolio like this would target an annual return of{' '}
                    <span className={styles.title} variant="h6">
                      {CAGR}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={7} xl={7}>
          <Box mt={2}>
            {details === 'overview' ? (
              <Box>
                <Card className={styles.cardContainer}>
                  <CardContent>
                    <Typography className={styles.title2} variant="h5">
                      Risk Adjusted Return Graph
                    </Typography>

                    <AreaChart
                      data={chartData}
                      minValue={minValue}
                      maxValue={maxValue}
                      xLabels={xAxisLabels}
                    />
                    <Grid container>
                      <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Grid container>
                          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextInput2 value="10000" />
                          </Grid>
                          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Slider
                              size="small"
                              defaultValue={70}
                              aria-label="Small"
                              valueLabelDisplay="auto"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Box className={styles.centerContent} ml={6}>
                          <RadioGroupButton
                            title="How your assets will grow over 30 year?"
                            values={[
                              {
                                label:
                                  '50% confidence your portfolio will be worth between $82,297 - $166,124',
                                value: 0,
                              },
                              { label: 'Median expected return $117,548', value: 1 },
                            ]}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            ) : (
              <Container>
                <Types portfolio={currentPortfolioData?.short_name} title={title} />
              </Container>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
  return <Suspense fallback={<LoadingIndicator />}>{content}</Suspense>;
};

export default RiskAwareProfile;
