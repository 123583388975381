import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { retakeQuiz } from '../../redux/actions/quiz.action';

import { Outlined } from '../Button';
import ReviewQuizModal from './ReviewQuizModal';

const QuizResult = ({ setShowResult }) => {
  const history = useHistory();
  const styles = useStyles();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleGoto = () => {
    history.push({ pathname: `/app/risk-aware-profile` });
    setShowResult(false);
  };

  const handleRetakeQuiz = () => {
    dispatch(retakeQuiz());
  };

  return (
    <Box className={styles.resultContainer}>
      <Box mb={4}>
        <CheckCircleIcon color="success" />
      </Box>
      <Typography className={styles.title} variant="h3">
        Thank you for taking the Risk Tolerance Quiz!
      </Typography>
      <Typography className={styles.subtitle} variant="subtitle1">
        All your answers have been saved.
      </Typography>
      <Box className={styles.buttons}>
        <Button variant="contained" size="medium" color="warning" onClick={() => handleOpenModal()}>
          Review Quiz
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="success"
          onClick={() => handleRetakeQuiz()}
        >
          Retake Quiz
        </Button>
      </Box>
      <Box mt={2}>
        <Outlined onClick={() => handleGoto()} rounded small>
          Go to Risk Profile
        </Outlined>
      </Box>
      <ReviewQuizModal open={openModal} handleClose={handleCloseModal} />
    </Box>
  );
};

export default QuizResult;

const useStyles = makeStyles((theme) => ({
  resultContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 'auto 0',
    fontFamily: 'Poppins',
    minHeight: '500px',

    '& svg': {
      fontSize: '90px',
    },

    '& .MuiButton-root': {
      borderRadius: '20px',
      padding: '6px 30px',
      minWidth: '162.77px',
      maxWidth: '162.77px',
      maxHeight: '33px',

      [theme.breakpoints.down('sm')]: {},
    },

    [theme.breakpoints.down('sm')]: {
      minHeight: '300px',
      marginTop: '3rem',
      '& svg': {
        fontSize: '70px',
      },
    },
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    color: '#3F8ED7',
    fontWeight: 'bold !important',
    fontSize: '2rem !important',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem !important',
    },
  },

  subtitle: {
    fontSize: '1.5rem !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem !important',
    },
  },

  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
    minWidth: '340px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& .MuiButton-root': {
        marginTop: '2rem',
      },
    },
  },
}));
