import React from 'react';
import RISK_DISCLOSURE_AGREEMENT from '../../constants/risk_disclosure_agreement';
import CommonPolicyContent from './CommonPolicyContent';

const RiskDisclosureAgreement = (props) => {
  return (
    <CommonPolicyContent
      title={RISK_DISCLOSURE_AGREEMENT.title}
      description={RISK_DISCLOSURE_AGREEMENT.description}
      items={RISK_DISCLOSURE_AGREEMENT.items}
    />
  );
};

export default RiskDisclosureAgreement;
