import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '12px',
    width: '100%',
    height: '100%',
    '& > div': {
      height: '100%',
    },
  },
}));

export default useStyles;
