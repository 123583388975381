import { Box, Grid, Typography } from '@mui/material';
import { lazy, Suspense } from 'react';
import { HighChart } from '../../components/Charts';
import Chart1 from '../../components/Charts/Chart1';
import StockSummary from '../../components/StockSummary';
import TrendingStrategy from '../../components/TrendingStrategy';
import WeeklyAvgCard from '../../components/WeeklyAvgCard';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AutoComplete from '../../components/AutoComplete';
import { ButtonGroup } from '../../components/Button';
import { LoadingIndicator } from '../../components/ContainerIndicator';
import { capitalizeFirstLetter } from '../../helpers/utils';
import { editADXParams } from '../../redux/actions/technical_analysis.action';
import ADXBarChart from './ADXBarChart';
import MarketBreadth from './components/MarketBreadth';
import { TIMEFRAMES, TREND_DIP, TREND_STR, TYPE_BUY, TYPE_SELL } from './constant';
import LineChart from './LineChart';
import LineChartLarge from './LineChartLarge';
import { MeterGauge } from './MeterGauge';
import { MeterGaugeLarge } from './MeterGaugeLarge';
import SentimentCard from './SentimentCard';
import useStyles from './styles.js';
import TieredChart from './TieredChart';
const CandleStick = lazy(() => import('../../components/Charts/Candlestick.js'));

const Mobile = ({
  adx,
  adx_params,
  ticker,
  interval,
  search_ticker,
  ticker_results,
  show_ticker_suggestions,
  trend_labels,
  index_performance_data,
  comparative_performance_data,
  selected_performance_data,
  performance,

  candlestick_data,
  // assets_snr_frequency_stock_data,
  // untouched_high,
  // untouched_low,
  // loading_candlestick,
  // volatility_gauge,
  // volatility_atr,

  computeADXValueToPercentage,
  handleTickerSearchItemOnClick,
  handleSearchOnChange,
  handleTickerSearchOnFocus,
  handleTickerSearchOnBlur,
  getTimeframePropertiesByType,
  handleItemSelect,

  ...props
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [is_small_view, setIsSmallView] = useState(true);
  const [show_monthly_timeframe, toggleMonthlyTimeFrame] = useState(true);
  const [show_weekly_timeframe, toggleWeeklyTimeFrame] = useState(true);
  const adx_data = adx?.data || {};
  const time_frame_label = capitalizeFirstLetter(adx_params?.type || '');
  const item_props_by_type = getTimeframePropertiesByType();
  const adx_type = adx_params.type || 'daily';
  const adx_type_diff = (adx_data.diff || {})[adx_type];

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  });

  const valueToPercent = (val, total) => (100 / total) * val;

  const handleResize = () => {
    setIsSmallView(window.innerWidth < 900);
  };

  const getColorValues = (percent, total = 100) => {
    const color_values = ['#ED37D6'];
    const x = total / 4; // interval

    if (percent >= x) color_values.push('#C732EC');
    if (percent >= x * 2) color_values.push('#10B8FD');
    if (percent >= x * 3) color_values.push('#41E7B0');

    return color_values;
  };

  const handleTimeframeClick = (button) => {
    // set timeframe here
    dispatch(editADXParams('type', button.value));
  };

  const _renderSmallMeterGauge = () => {
    return (
      is_small_view && (
        <Box pt="3rem" overflow="scroll" display="flex" className={classes.MeterGaugeContainer}>
          <MeterGauge
            percentage={formatADXValues(adx_type_diff)}
            value={`${formatADXValues(adx_type_diff)}%`}
            adjust_label
            gradient_id="Direction"
            flow={`${time_frame_label} Direction`}
            label={['Falling', 'Rising']}
            desc={props.getTrendLabel(adx_type_diff)}
            negative_value_color
            ticks_colors={getColorValues(formatADXValues(adx_type_diff))}
          />
          <MeterGauge
            percentage={computeADXValueToPercentage(+adx_data.adx)}
            value={`${formatADXValues(adx_data.adx)}%`}
            gradient_id="Momentum"
            flow={`${time_frame_label} Momentum`}
            label={['0%', '100%']}
            desc="based on likes"
            ticks_colors={getColorValues(adx_data.adx)}
          />
        </Box>
      )
    );
  };

  const formatADXValues = (val) => {
    return Math.abs(Math.round(val || 0));
  };

  const _renderLargeMeterGauge = () => {
    return (
      !is_small_view && (
        <Box pt="3rem" display="flex" className={classes.MeterGaugeContainerLarge}>
          <MeterGaugeLarge
            percentage={formatADXValues(adx_type_diff)}
            value={`${formatADXValues(adx_type_diff)}%`}
            adjust_label
            gradient_id="Direction"
            flow={`${time_frame_label} Direction`}
            label={['Falling', 'Rising']}
            desc={props.getTrendLabel(adx_type_diff)}
            ticks_colors={getColorValues(formatADXValues(adx_type_diff))}
          />
          <MeterGaugeLarge
            percentage={computeADXValueToPercentage(formatADXValues(adx_data.adx))}
            value={`${formatADXValues(adx_data.adx)}%`}
            gradient_id="Momentum"
            flow={`${time_frame_label} Momentum`}
            label={['0%', '100%']}
            desc="based on likes"
            ticks_colors={getColorValues(formatADXValues(formatADXValues(adx_data.adx)))}
          />
        </Box>
      )
    );
  };

  const _renderSmallLineChart = () => {
    const items = item_props_by_type.map(
      (k, i) =>
        !!adx_data?.prices && (
          <Box display="flex" flex="1" justifyContent="center" px="1rem">
            <Chart1
              value={`${formatADXValues((adx_data.diff || {})[trend_labels[i]])}%`}
              title={props.getTrendLabel((adx_data.diff || {})[trend_labels[i]])}
              upTrend={(adx_data.diff || 0) >= 0}
            >
              <LineChart
                name={k}
                trend={adx_data.trend[adx_type]}
                data={adx_data?.prices[k] || []}
              />
            </Chart1>
          </Box>
        )
    );
    return (
      is_small_view && (
        <Box pt="3rem" display="flex" justifyContent="space-around" width="100%">
          {items}
        </Box>
      )
    );
  };

  const _renderLargeLineChart = () => {
    const items = item_props_by_type.map(
      (k, i) =>
        !!adx_data?.prices && (
          <Box display="flex" flex="1" justifyContent="center" px="1rem">
            <Chart1
              value={`${formatADXValues((adx_data.diff || {})[trend_labels[i]])}%`}
              title={capitalizeFirstLetter(trend_labels[i])}
              trend={props.getTrendLabel((adx_data.diff || {})[trend_labels[i]])}
              upTrend={(adx_data.diff || 0) >= 0}
            >
              <LineChartLarge
                name={k}
                trend={adx_data.trend[adx_type]}
                data={adx_data?.prices[k] || []}
              />
            </Chart1>
          </Box>
        )
    );

    return (
      !is_small_view && (
        <Box pt="3rem" display="flex" justifyContent="space-around" width="100%">
          {items}
        </Box>
      )
    );
  };

  const _renderBuyDipStrength = () => {
    return (
      <Box width="100%" pt="1rem">
        <Grid container spacing={2}>
          <Grid item className={classes.buyDipContainer} xs={12} md={6}>
            <SentimentCard type={TYPE_BUY} trend={TREND_STR} />
          </Grid>
          <Grid item className={classes.buyDipContainer} xs={12} md={6}>
            <SentimentCard type={TYPE_BUY} trend={TREND_STR} md={6} />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const _renderSellDipStrength = () => {
    return (
      <Box width="100%" pt="1rem">
        <Grid container spacing={2}>
          <Grid item className={classes.buyDipContainer} xs={12} md={6}>
            <SentimentCard type={TYPE_SELL} trend={TREND_DIP} />
          </Grid>
          <Grid item className={classes.buyDipContainer} xs={12} md={6}>
            <SentimentCard type={TYPE_SELL} trend={TREND_DIP} md={6} />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const _renderAutoComplete = () => {
    return (
      <Box pt="1rem" display="flex" justifyContent="center">
        <AutoComplete
          onChange={handleSearchOnChange}
          placeholder="What is your ticker?"
          value={search_ticker}
          items={(show_ticker_suggestions && ticker_results) || []}
          renderItem={(item) => {
            return (
              <li onClick={(e) => handleTickerSearchItemOnClick(e, item)}>
                <Typography variant="body2">{item.attributes.name}</Typography>
              </li>
            );
          }}
          onBlur={handleTickerSearchOnBlur}
          onFocus={handleTickerSearchOnFocus}
        />
      </Box>
    );
  };

  const _renderTimeframeOptions = () => {
    return (
      <Box pt="1rem">
        <ButtonGroup
          buttons={TIMEFRAMES}
          currentTab={adx_params.type}
          onClick={handleTimeframeClick}
        />
      </Box>
    );
  };

  const _renderDailyPerformance = () => {
    // <>
    // test
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Box mt="2rem" display="flex" justifyContent="center">
        <MarketBreadth
          timeframe={adx_params.type}
          data={performance}
          onSelectItem={handleItemSelect}
        />
      </Box>
    </Grid>;

    {
      /* <Grid item xs={12} sm={12} md={12}>
        <Box mt="2rem" display="flex" justifyContent="center">
          <NumberOfGainersDecliners data={selected_performance_data} />
        </Box>
      </Grid>
    </>; */
    }
  };

  const _renderCandleStickChart = () => {
    return (
      <Grid item xs={12} sm={12} md={12}>
        <Box mt="2rem" display="flex" flexDirection="column" justifyContent="center">
          <Suspense fallback={<LoadingIndicator hide_tips />}>
            <CandleStick
              // data={candlestick_data?.analysis || []}
              // assets_snr_frequency_stock_data={assets_snr_frequency_stock_data}
              // untouched_high={untouched_high}
              // untouched_low={untouched_low}
              // loading={loading_candlestick}
              // volatility_gauge={volatility_gauge}
              // volatility_atr={volatility_atr}
              {...candlestick_data}
              ticker={ticker}
              interval={interval}
              show_volume={false}
              show_monthly_snr={show_monthly_timeframe}
              show_weekly_snr={show_weekly_timeframe}
              toggleMonthlyTimeFrame={() => toggleMonthlyTimeFrame(!show_monthly_timeframe)}
              toggleWeeklyTimeFrame={() => toggleWeeklyTimeFrame(!show_weekly_timeframe)}
            />
          </Suspense>
        </Box>
      </Grid>
    );
  };

  const _renderLineCharts = () => {
    return (
      <Grid item xs={12} sm={12} md={12}>
        {_renderSmallLineChart()}
        {_renderLargeLineChart()}
        {_renderSmallMeterGauge()}
        {_renderLargeMeterGauge()}
      </Grid>
    );
  };

  const _renderStockSummary = () => {
    return (
      <Grid item xs={12} sm={12} md={4}>
        <Box pt="1rem" display="flex" justifyContent="center">
          <StockSummary />
        </Box>
      </Grid>
    );
  };

  const _renderTrendingStrategy = () => {
    return (
      <Grid item xs={12} sm={12} md={4}>
        <Box pt="1rem" display="flex" justifyContent="center">
          <TrendingStrategy card />
        </Box>
      </Grid>
    );
  };

  const _renderTieredChart = () => {
    return (
      <Grid item xs={12} sm={12} md={4}>
        <Box pt="1rem" display="flex" justifyContent="center">
          <TieredChart data={props.tiered_chart_data} />
        </Box>
      </Grid>
    );
  };

  const _renderADXBarChart = () => {
    return (
      <Grid item xs={12} sm={12} md={12} className={classes.trendingContainer}>
        <ADXBarChart
          percent="09.54"
          data={[
            {
              value: 20,
            },
            {
              value: 20,
            },
            {
              value: 70,
            },
            {
              value: 40,
            },
            {
              value: 100,
            },
            {
              value: 15,
            },
            {
              value: 100,
            },
            {
              value: 20,
            },
            {
              value: 20,
            },
            {
              value: 70,
            },
            {
              value: 40,
            },
            {
              value: 100,
            },
            {
              value: 15,
            },
            {
              value: 100,
            },
            {
              value: 100,
            },
          ]}
        />
      </Grid>
    );
  };

  const _renderWeeklyAvgCard = (title) => {
    return (
      <Grid item xs={12} sm={12} md={6}>
        <Box display="flex" justifyContent="center" pt="1rem">
          <WeeklyAvgCard
            title={title}
            values={[
              {
                rating: 20,
              },
              {
                rating: 20,
              },
              {
                rating: 70,
              },
              {
                rating: 40,
              },
              {
                rating: 100,
              },
              {
                rating: 15,
              },
              {
                rating: 100,
              },
            ]}
          />
        </Box>
      </Grid>
    );
  };

  const _renderCandleStickHightChart = (config) => {
    return (
      <Grid item xs={12} sm={12} md={12}>
        <Box mt="2rem" display="flex" justifyContent="center">
          <HighChart width="100%" config={config} />
        </Box>
      </Grid>
    );
  };

  // memoized components

  return (
    <>
      {_renderTimeframeOptions()}
      <Grid container spacing={0}>
        {_renderDailyPerformance()}
        {_renderBuyDipStrength()}
        {_renderSellDipStrength()}
        {_renderCandleStickChart()}
        {_renderLineCharts()}
        {_renderStockSummary()}
        {/* {_renderTrendingStrategy()} */}
        {_renderTieredChart()}
        {_renderADXBarChart()}
        {_renderWeeklyAvgCard('Sector Rank')}
        {_renderWeeklyAvgCard('Stock Rank in Sector')}
        {/* {_renderCandleStickHightChart(props.pivotConfig)}
        {_renderCandleStickHightChart(props.allTechnicalConfig)}
        {_renderCandleStickHightChart(props.volumeConfig)} */}
      </Grid>
    </>
  );
};

export default Mobile;
