// notificationMiddleware.js
import { SAVE_USER_DETAILS_OK } from '../redux/constants/auth.constant';

const notificationMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case SAVE_USER_DETAILS_OK:
        if (action.enqueueSnackbar) {
          action.enqueueSnackbar('User details updated successfully!');
        }
        break;
      default:
        break;
    }

    return result;
  };

export default notificationMiddleware;
