import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginBottom: '3rem',
    maxWidth: '1400px',

    '& input:-webkit-autofill': {
      '-webkit-text-fill-color': '#2c81ff !important',
    },

    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-h2': {
        fontSize: '30px',
      },

      '& #dropdown-title': {
        textAlign: 'center',
      },
    },
  },

  fields: {
    '& .MuiInputBase-root > .MuiInputBase-input': {
      height: '1.1876em !important',
    },
  },

  gridContainer: {
    maxWidth: '615px',
  },
  title: {
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    textAlign: 'left',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      '& svg': {
        width: '1.5rem',
        height: 'auto',
      },
    },
  },
  title2: {
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  textFocus: {
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    fontSize: '1.8rem',
    display: 'inline',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  errorTextFocus: {
    background: '#F06360',
    fontWeight: 'bold',
    fontSize: '1.8rem',
    display: 'inline',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  label: {
    textAlign: 'left',
    float: 'right',
  },
  value: {
    textAlign: 'left',
    float: 'right',
  },
  listContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: '30px 15px',

      '& > div': {
        margin: '15px',
      },
    },
  },

  cardContainer: {
    margin: '1em',
    padding: '1em',
    borderRadius: '1.5em',
    '& .MuiInputBase-root > .MuiInputBase-input': {
      height: '2.1876em',
    },
  },

  riskIndexContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(138.19deg, #FFCA1B 2.08%, #F77455 50.52%, #FE8F1A 94.79%)',
    color: '#fff',
    borderRadius: '1em',
    padding: '1em',
    width: '150px',
    margin: '1.5em auto',
  },

  riskNumber: {
    fontWeight: 'bold',
  },

  gainText: {},

  lossText: {
    background: 'linear-gradient(138.19deg, #F60638 2.08%, #F40469 50.52%, #F20094 94.79%)',
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  bottomArea: {
    borderTop: '1px solid #B9B9B9',
    padding: '1.5em',
  },

  centerContent: {
    alignItems: 'center', //Centered vertically
    flex: 1,
  },

  columnList: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column wrap',
    gap: '10px',
    height: 300,
  },

  icon: {
    paddingBottom: '5px',
    color: theme.palette.gradients.blue_linear,
  },

  styledPopover: {
    '& .MuiPaper-root': {
      borderRadius: '20px',
      marginTop: theme.spacing(1),
      minWidth: 200,
    },
  },

  underlineText: {
    textDecoration: 'underline',
    color: '#789CC8',
  },
  dropdownContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  columnRev: {
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
}));

export default useStyles;
