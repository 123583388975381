import {
  APPEND_ANSWER,
  SET_QUESTION_INDEX,
  SET_QUESTION,
  SET_PORTFOLIO_DATA,
  FETCH_PORTFOLIO_DATA,
  SET_CUSTOM_PORTFOLIO,
  POST_CUSTOM_PORTFOLIO,
  GET_USER_PORTFOLIO,
  SET_IS_CUSTOM,
  SAVE_TICKERS,
  GET_USER_SETTINGS,
} from '../constants/profile.constant';

export const appendAnswerAction = (params) => ({ type: APPEND_ANSWER, params });
export const setIndexAction = (index) => ({ type: SET_QUESTION_INDEX, index });
export const setQuestionAction = (currentQuestion) => ({ type: SET_QUESTION, currentQuestion });
export const getPortfolioData = (data) => ({ type: FETCH_PORTFOLIO_DATA, data });
export const setPortfolioData = (value, portfolio) => ({
  type: SET_PORTFOLIO_DATA,
  value,
  portfolio,
});
export const postCustomPortfolio = (value, data) => ({
  type: POST_CUSTOM_PORTFOLIO,
  value,
  data,
});
export const setCustomPortfolio = (value) => ({ type: SET_CUSTOM_PORTFOLIO, value });
export const getUserPortfolio = () => ({ type: GET_USER_PORTFOLIO });
export const setIsCustom = (value) => ({ type: SET_IS_CUSTOM, value });
export const saveTickers = (tickers) => ({ type: SAVE_TICKERS, tickers });
export const getUserSetting = (id) => ({ type: GET_USER_SETTINGS, id });
