import axios from 'axios';
import qs from 'qs';
import { API_BASE_URL } from '../config';
import { WARNING } from '../constants';
import { UNAUTHORIZED } from '../constants/api';
import { TOKEN_KEY } from '../constants/auth';
import { getLocationComponents } from '../helpers/utils';

import { dispatch } from '../index';
import { openSnackbarAction } from '../redux/actions/app.action';
import { logoutAction } from '../redux/actions/auth.action';

class API {
  constructor() {
    this.baseUrl = API_BASE_URL;
    this.defaultConfig = {
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }

  handleFulfilled = (result) => {
    return {
      status: result.status || 200,
      data: result?.data || {},
    };
  };

  handleRejected = (result) => {
    const data = result?.response?.data || { errors: { message: 'An Error Has Occurred.' } };
    const status_code = result?.response?.status;

    console.log('rejected', { ...result });
    if (status_code >= 400) {
      this._handle400StatusCode(status_code);
    }

    return {
      status: result.status || 400,
      data,
    };
  };

  _handle400StatusCode = (status_code) => {
    // TODO: Check if unauthorized then logout
    const location = window.location;
    const location_components = getLocationComponents(location);

    switch (status_code) {
      case UNAUTHORIZED:
        dispatch(logoutAction(location_components.pathname_sq));
        break;
    }
  };

  async get(url, params, headers = {}) {
    const config = { params, baseURL: this.baseUrl, headers };
    const response = await axios.get(url, config).then(this.handleFulfilled, this.handleRejected);

    return response;
  }

  async post(url, data = {}, config = {}) {
    const allConfigs = {
      ...this.defaultConfig,
      headers: {
        ...this.defaultConfig.headers,
        ...config.headers,
      },
      method: 'post',
      url,
      data,
    };

    if (allConfigs.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      // if application/x-www-form-urlencoded is the content type, then we stingify the data
      allConfigs.data = qs.stringify(data);
    }

    const response = await axios(allConfigs).then(this.handleFulfilled, this.handleRejected);
    return response;
  }

  async put(url, data = {}, config = {}) {
    const allConfigs = {
      ...this.defaultConfig,
      headers: {
        ...this.defaultConfig.headers,
        ...config.headers,
      },
      method: 'put',
      url,
      data,
    };

    if (allConfigs.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      // if application/x-www-form-urlencoded is the content type, then we stingify the data
      allConfigs.data = qs.stringify(data);
    }

    const response = await axios.put(allConfigs).then(this.handleFulfilled, this.handleRejected);

    return response;
  }

  async patch(url, data = {}, config = {}) {
    const allConfigs = {
      ...this.defaultConfig,
      headers: {
        ...this.defaultConfig.headers,
        ...config.headers,
      },
      method: 'patch',
      url,
      data,
    };

    if (allConfigs.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      // if application/x-www-form-urlencoded is the content type, then we stingify the data
      allConfigs.data = qs.stringify(data);
    }

    const response = await axios(allConfigs).then(this.handleFulfilled, this.handleRejected);

    return response;
  }

  async delete(url, params) {
    const config = { params, baseURL: this.baseUrl };
    const response = await axios
      .delete(url, config)
      .then(this.handleFulfilled, this.handleRejected);

    return response;
  }
}

export default API;
