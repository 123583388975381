import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { CommonThemeProvider } from 'traidify-common-components';
import SplashScreen from './components/SplashScreen';
import SnackbarProvider from './components/snackbar/snackbar-provider';
import { THEME_LIGHT_MODE } from './constants';
import UnfinishedModal from './containers/RiskToleranceQuiz/UnfinishedModal';
import { history } from './redux/store';
import MainRouter from './routes';
import './styles/app.sass';
import { dark, light } from './styles/theme';
const App = (props) => {
  const show = useSelector((store) => store.app.get('showSplashScreen'));
  const app = useSelector((store) => store.app.toJS());
  const theme = app.theme;
  const themePreference = theme === THEME_LIGHT_MODE ? light : dark;

  const { showUnfinishedModal } = useSelector((store) => {
    const quiz_store = store.quiz;

    if (quiz_store) return quiz_store.toJS();

    return {};
  });

  return (
    <StyledEngineProvider>
      <CssBaseline />
      <ThemeProvider theme={themePreference}>
        <SnackbarProvider>
          <CommonThemeProvider>
            <UnfinishedModal history={history} open={showUnfinishedModal} />
            {show && <SplashScreen />}
            <MainRouter history={history} {...props} />
          </CommonThemeProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Sentry.withProfiler(App);
