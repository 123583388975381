import { createSelector } from 'reselect';

const selectMacroeconomics = (store) => {
  const m = store?.macroeconomics;
  return m;
};

export const macroeconomicsSelector = createSelector(
  [selectMacroeconomics],
  (m) => m?.toJS() || {}
);
