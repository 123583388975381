import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import global_macro from '../../../assets/images/header/global_macro.png';
import portfolio_management from '../../../assets/images/header/portfolio_management.png';
import technical_analysis from '../../../assets/images/header/technical_analysis.png';
import globe from '../../../assets/images/home/globe.png';
import ItemLink from './ItemLink';

const HomeNavigation = (props) => {
  const classes = useStyles();

  const items = [
    {
      label: 'Stock Analysis',
      slug: 'technical-analysis',
      class: classes.linkTL,
      icon: technical_analysis,
    },
    {
      label: 'Portfolio Manangement',
      slug: 'risk-aware-profile',
      class: classes.linkTR,
      icon: portfolio_management,
    },
    { label: 'Global Macro', slug: 'macroeconomics', class: classes.linkC, icon: global_macro },
    // { label: 'Sentiment', slug: 'sentiment', class: classes.linkBR, icon: stock_sentiment },
  ];

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      {/* <Box display="flex" justifyContent="space-around">        
        <Box display="flex" justifyContent="center">
          {items.map((item, index) => (
            <ItemLink
              // className={item.class}
              selected={index == 0}
              label={item.label}
              slug={item.slug}
            />
          ))}
        </Box>
      </Box> */}
      <Box className={classes.outerCircle}>
        {items.map((item, index) => (
          <ItemLink
            key={item.label}
            className={item.class}
            selected={index === 0}
            label={item.label}
            slug={item.slug}
            icon={item.icon}
          />
        ))}
        {/* <ItemLink className={classes.linkTL} selected label="Stock Analysis" />
        <ItemLink className={classes.linkTR} label="Portfolio Management" />
        <ItemLink className={classes.linkBL} label="Global Macro" />
        <ItemLink className={classes.linkBR} label="Sentiment" /> */}
        {/* <CircleVideo /> */}
        <img src={globe} alt="Global" className={classes.imgGlobe} />
      </Box>
      {/* <Box>
        <Education />
      </Box> */}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  outerCircle: {
    width: '500px',
    height: '500px',
    borderRadius: '250px',
    position: 'relative',
    left: '-4%',
    [theme.breakpoints.down('sm')]: {
      width: '350px',
      height: 'auto',
      left: '4%',
    },
  },
  linkTL: {
    position: 'absolute',
    top: '25%',
    left: '-6%',
    [theme.breakpoints.down('sm')]: {
      top: '30%',
    },
  },
  linkTR: {
    position: 'absolute',
    top: '25%',
    right: '-19%',
    [theme.breakpoints.down('sm')]: {
      top: '30%',
      right: '4%',
    },
  },
  linkBL: {
    position: 'absolute',
    bottom: '25%',
    left: '-6%',
    [theme.breakpoints.down('sm')]: {
      bottom: '23%',
    },
  },
  linkBR: {
    position: 'absolute',
    bottom: '25%',
    right: '-15%',
    [theme.breakpoints.down('sm')]: {
      right: '8%',
      bottom: '23%',
    },
  },
  linkC: {
    position: 'absolute',
    bottom: '5%',
    left: '32%',
  },
  imgGlobe: {
    width: '600px',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
  },
}));

export default HomeNavigation;
