import { Box, Button, Container, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import lock from '../../assets/images/lock.svg';
import { Contained } from '../../components/Button';
import { stripeSelector } from '../../redux/reselect/stripeSelector';
const NotAllowed = (props) => {
  const history = useHistory();
  const styles = useStyles();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const { subscriptions } = useSelector(stripeSelector);

  const handleClick = () => {
    history.push({ pathname: `/app/offers` });
  };

  const handleGoBack = () => {
    history.push({ pathname: `/app` });
  };

  const message = () => {
    if (subscriptions?.length !== 0) {
      return 'If you want to access this page, please subscribe to one of our plans.';
    } else {
      return 'Your current plan does not include access to Global Macro.';
    }
  };

  const btnLabel = () => {
    return subscriptions?.length !== 0 ? 'Subscribe' : 'Upgrade Now';
  };
  return (
    <Container className="NotAllowed">
      <Box className={styles.container}>
        <img alt="Lock" src={lock} />
        <Typography variant="h5">Upgrade to access this page</Typography>
        <Box maxWidth={300}>
          <Typography>{message()}</Typography>
        </Box>
        <Contained small={small} rounded onClick={() => handleClick()}>
          {btnLabel()}
        </Contained>
        <Button variant="text" onClick={() => handleGoBack()}>
          Go back
        </Button>
      </Box>
    </Container>
  );
};

export default NotAllowed;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    margin: '5rem 0',
    '& img': {
      height: '95px',
      margin: '0 0 1rem 0',
    },
    '& .MuiTypography-root': {
      fontWeight: 'bold !important',
      margin: '0.5rem 0 1rem 0',
    },
    '& .MuiTypography-h5': {
      margin: 0,
      background: theme.palette.gradients.blue_linear,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },
    '& .MuiButton-text': {
      margin: '1rem 0',
    },

    [theme.breakpoints.down('sm')]: {
      margin: '3rem 0 1rem 0',
      '& img': {
        height: '60px',
        margin: '1rem 0',
      },
      '& .MuiTypography-h5': {
        fontSize: '1.5rem',
      },
      '& .MuiTypography-body1': {
        fontSize: '1rem',
      },
    },
  },
}));
