import CircleIcon from '@mui/icons-material/Circle';
import { Box, Typography } from '@mui/material';
import useStyles from '../index.style';

const Categories = (props) => {
  const { aggregates, colors, align, width } = props;

  const styles = useStyles();
  return (
    <Box className={styles.categoriesContainer}>
      {aggregates?.map((aggregate, index) => {
        return (
          <Box p={1}>
            <Box>
              <Typography id="title">
                <CircleIcon style={{ color: colors[index] }} />{' '}
                {aggregate?.class.replace(/[\W_]+/g, ' ')}: {aggregate?.allocation.toFixed(2)}%
              </Typography>
              <Box ml="1.8rem" display="flex">
                {aggregate?.assets?.map((item) => {
                  if (!item || item.allocation === 0) return <></>;
                  return (
                    <Typography style={{ marginRight: '1rem' }}>
                      <span style={{ fontWeight: 'bold' }}>{item?.assets}: </span>
                      {item?.allocation}%
                    </Typography>
                  );
                })}
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default Categories;
