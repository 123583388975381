import Skeleton from '@mui/lab/Skeleton';
import { Box, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Rating from './Rating.js';
import GradientSlider from './Slider.js';

import clsx from 'clsx';
import { MeterGauge } from './MeterGauge';

const SentimentCard = ({
  loading,
  subjectivity,
  sentiment,
  percentage,
  label,
  desc,
  icon,
  decimelToPercent,
  ...props
}) => {
  const classes = useStyles();

  if (!!loading) {
    return (
      <Card className={clsx(classes.card, classes.cardPlaceholder)}>
        <CardContent>
          <Skeleton className={classes.titlePlaceholder} />
          <Skeleton className={classes.contentPlaceholder} />
        </CardContent>
        <CardActions className={classes.actionsPlaceholderContainer}>
          <Skeleton className={classes.actionsPlaceholder} />
        </CardActions>
      </Card>
    );
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        {/* <Box display="flex" flexDirection="column" pt="2rem" px="1rem">
          <MeterGauge percentage={decimelToPercent(props.compound)} sentiment="positive" />
          <Box display="flex" flex="1" alignItems="center">
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <GradientSlider 
                label="Sentiment" 
                percent={-15} 
                style={classes.title3} 
                variant="6"
              />
            </Grid>
          </Box>
        </Box>
        <Box display="flex" alignItems="flex-end" pt="1rem" px="1rem" mb={2}>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <Rating 
              label="Subjectivity" 
              value={56}
              style={classes.title3} 
              variant="6"
            />
          </Grid>
        </Box>
        <Grid container>
          <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
            <MeterGauge percentage={decimelToPercent(props.compound)} sentiment="positive" />
          </Grid>
          <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
            <Box                       
              display="flex"                      
              textAlign="left"
              flexDirection="column"              
              mt={4}
            >
              <Box>
                <Typography className={classes.title3} variant="h6">Total Score</Typography>
                <Typography className={classes.title3} variant="h5">6.8 out of 10</Typography>
              </Box>                      
            </Box>
          </Grid>
        </Grid> */}
        <Box display="flex" flexDirection="column" pt="2rem" px="1rem">
          <Box display="flex" flex="1" alignItems="center">
            <Box width="-webkit-fill-available">
              <GradientSlider label="Sentiment" percent={-15} style={classes.title3} variant="6" />
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="flex-end" pt="1rem" px="1rem" mb={2}>
          <Box width="-webkit-fill-available">
            <Rating label="Subjectivity" value={56} style={classes.title3} variant="6" />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-evenly">
          <Box>
            <MeterGauge percentage={decimelToPercent(props.compound)} sentiment="positive" />
          </Box>
          <Box>
            <Box display="flex" textAlign="left" flexDirection="column" mt={7.5} ml={1}>
              <Box>
                <Typography variant="h6">Total Score</Typography>
                <Typography variant="h5">6.8 out of 10</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SentimentCard;

const useStyles = makeStyles((theme) => ({
  card: {
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    borderRadius: '14.77px',
    minWidth: '340px',
    width: '100%',
    maxHeight: '340px',
    marginTop: '1rem',

    '& > div:first-child': {
      padding: '1rem',
      paddingTop: '.5rem',
    },

    '& .MuiTypography-root': {
      fontWeight: 800,
    },

    '& .MuiTypography-h5': {
      fontSize: '19px',
    },

    '& .MuiTypography-h6': {
      fontSize: '14px',
    },

    '& .MuiTypography-body1': {
      fontSize: '12px',
    },

    [theme.breakpoints.down('sm')]: {
      borderRadius: '10px',

      '& .MuiTypography-h4': {
        fontSize: '19.53px',
      },

      '& .MuiTypography-h5': {
        fontSize: '14px',
      },
    },
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '1rem',

    '& h5': {
      display: 'flex',
      alignItems: 'center',
      background: theme.palette.gradients.blue_linear,
      fontWeight: 'bold',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },
  },
  cardImage: {
    width: '90px',
    height: '90px',
    objectFit: 'contain',
  },
  actions: {
    justifyContent: 'center',
    padding: '10px 16px',
    background: theme.palette.gradients.main_linear_1,
  },
  totalScore: {
    color: '#FFF',

    '& h5': {
      fontWeight: 'bold',
    },
  },
  cardPlaceholder: {
    minWidth: '284px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '328px',
      display: 'flex',
      flexDirection: 'column',

      '& > div:first-child': {
        flex: 1,
      },
    },
  },
  titlePlaceholder: {
    width: '75%',
    height: '2rem',
    transform: 'none',
  },
  contentPlaceholder: {
    height: '12rem',
    marginTop: '2rem',
    transform: 'none',
  },
  actionsPlaceholderContainer: {
    padding: 0,
  },
  actionsPlaceholder: {
    width: '100%',
    height: '64px',
    transform: 'none',
    borderRadius: 0,
  },
}));
