import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ResponsiveContainer } from 'recharts';

const BarGraph = (props) => {
  const { labels, colors, size = 200 } = props;
  const data = {
    labels: labels,
    datasets: [
      {
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: colors,
      },
    ],
  };

  const options = {
    showLine: false,
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        align: 'center',
      },
      datalabels: {
        display: true,
        color: 'white',
        formatter: function (value, context) {
          return value + '%';
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderWidth: 0,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
          borderWidth: 0,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  return (
    <ResponsiveContainer width={size} height={size}>
      <Bar options={options} data={data} plugins={[ChartDataLabels]} />
    </ResponsiveContainer>
  );
};

export default BarGraph;
