import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import cn from 'classnames';
import React, { useState } from 'react';
import { Contained } from '../../../components/Button';
import colors from '../../../styles/colors';
import Item from './Item';

const items = [
  {
    title: 'General',
    items: [
      {
        title: 'How does it work?',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
      {
        title: 'I forgot my username/password and would like access to Traidify',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
      {
        title: 'Why might my US stock data look incorrect?',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
      {
        title: 'I want access to all stock tickers',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
      {
        title: 'How do I use the Trade Builder',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
    ],
  },
  {
    title: 'Support',
    items: [
      {
        title: 'How does it work?',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
      {
        title: 'How can i set up a new website?',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
      {
        title: 'How does it work?',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
      {
        title: 'How does it work?',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
    ],
  },
  {
    title: 'Data',
    items: [
      {
        title: 'How can i set up a new website?',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
      {
        title: 'How does it work?',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
      {
        title: 'How does it work?',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
    ],
  },
  {
    title: 'Membership',
    items: [
      {
        title: 'How does it work?',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
      {
        title: 'How can i set up a new website?',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
      {
        title: 'How does it work?',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
      {
        title: 'How does it work?',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
      {
        title: 'How does it work?',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
      {
        title: 'How can i set up a new website?',
        content:
          'The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit,  Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode.',
      },
    ],
  },
];

const Faq = () => {
  const options = [];
  items.map((x) => options.push(x.title));

  const [category, setCategory] = useState(options[0]);
  const styles = useStyles();

  return (
    <Box className={styles.section} mt={6}>
      <Box className={styles.container}>
        <Box className={styles.top}>
          <Typography className={styles.title} variant="h3">
            <Box fontWeight="bold" mb={4}>
              Frequently asked questions
            </Box>
          </Typography>
          <Box className={styles.nav} mt={2} sx={{ justifyContent: 'space-evenly' }}>
            {items.map((x, index) => (
              <Contained
                className={cn(styles.link, {
                  [styles.active]: x.title === category,
                })}
                onClick={() => setCategory(x.title)}
                key={index}
                small
                rounded
              >
                {x.title}
              </Contained>
            ))}
          </Box>
        </Box>
        <Box className={styles.list}>
          {items
            .find((x) => x.title === category)
            .items.map((x, index) => (
              <Item className={styles.item} item={x} index={index} key={index} />
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Faq;

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: '136px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '112px',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '64px',
    },
  },
  container: {
    width: '100%',
    maxWidth: '1280px',
    margin: '0 auto',
    padding: '0 40px',

    [theme.breakpoints.only('lg')]: {
      padding: '0 20px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 16px 0 0',
    },
  },
  title: {
    marginBottom: '48px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: '40px',
    },
  },

  nav: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '-12px -6px 40px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  btn: {
    margin: '12px 6px 0',
    padding: '6px 12px',
    borderRadius: '14px',
    background: 'none',
    fontSize: '14px',
    lineHeight: 'calc(16 / 14) * 1px',
    fontWeight: 700,
    color: colors.n4,
    transition: 'all .2s',
    '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
    '&:hover': {
      color: colors.n3,
    },
  },
  active: {
    background: `${colors.n3} !important`,
    color: `${colors.n8} !important`,
  },

  dropdown: {
    display: 'none',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: '24px',
    },
  },
  list: {
    maxWidth: '546px',
    margin: '0 auto',
  },
  link: {
    display: 'flex',
    padding: '6px 12px',
    borderRadius: '14px',
    background: 'none',
    fontSize: '14px',
    lineHeight: 'calc(16 / 14) * 1px',
    fontWeight: 700,
    color: colors.n4,
    transition: 'all .2s',
    [theme.breakpoints.down('md')]: {
      flexShrink: 0,
    },
    '&:hover': {
      color: `${colors.n8} !important`,
    },
    '&:not(:last-child)': {
      marginRight: '24px',
      [theme.breakpoints.down('md')]: {
        marginRight: '20px',
      },
    },
  },
}));
