import { Box, Container, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorIndicator, LoadingIndicator } from '../../components/ContainerIndicator';
import { fetchQuizCategory } from '../../redux/actions/quiz.action';
import CategoriesContainer from './CategoriesContainer';

import useStyles from './index.style';

const TestYourKnowledge = () => {
  const dispatch = useDispatch();
  const styles = useStyles();

  useEffect(() => {
    dispatch(fetchQuizCategory());
  }, []);

  const { quizCategories, questionList, isLoading, isError } = useSelector((store) => {
    const quiz_store = store.quiz;

    if (quiz_store) return quiz_store.toJS();

    return {};
  });

  const getContent = isError ? (
    <ErrorIndicator
      title={'Oops! Something went wrong!'}
      description={`It's on us, we moved the content to a different page.
    The search below should help!
    `}
    />
  ) : (
    <Container className={styles.container}>
      <Box paddingTop="1.5em" paddingBottom="3em">
        <Grid container>
          <Grid xs={6} sm={9} md={9} lg={10} xl={10}>
            <Typography className={styles.title} variant="h3">
              Test Your Knowledge
            </Typography>
          </Grid>
        </Grid>
        <CategoriesContainer categories={quizCategories[1]?.attributes?.quiz_set} />
      </Box>
    </Container>
  );

  return (
    <Container className={styles.container}>
      {isLoading ? <LoadingIndicator /> : getContent}
    </Container>
  );
};

export default TestYourKnowledge;
