const POLICY = {
  title: 'Refund Policy',
  description: `You'll love autotrading as much as we do. Just in case you don't, we offer a generous and clear refund policy for our traders.`,
  items: [
    {
      title: '',
      description: `We are very proud of our platform and the team that supports you here at Traidify and we believe you deserve to work with a company that is transparent and straightforward with all subscription billing and refunds.

      First, to show our commitment to you, we always offer a 100% free 30-day trial. There is no credit card or payment method required to start the free trial. Plus, the free trial places no limits on bot features and includes 10 bots with a $10,000 capital allocation per live bot. We believe this generous free trial gives you the ability to explore all of the bots’ features and capabilities before you choose to upgrade and continue with Traidify.
      
      *To start your free 30-day trial, please request an invitation. We'll send you an invite as soon as it's your turn.
      
      When you eventually do upgrade to a monthly or annual plan (view pricing), rest easy knowing there are no contracts or commitments, and you can cancel at any time. In fact, we make it easy to cancel your subscription with one-click inside your billing profile. In the unlikely event that you choose to cancel, your subscription will still remain active for the remainder of the billing period you paid for. When the billing period ends, and your cancellation becomes effective, your account will be deactivated and you will no longer have access to the platform.
      
      If you are on a monthly plan and were charged for your next month of platform access, but you meant to cancel, we'd be happy to refund the charge so long as you contact us within three days of auto-renewal.
      
      If you paid for an annual plan and decide autotrading is not for you, you may cancel within 7 days of your initial payment for a full refund. Annual subscriptions cancelled after 7 days are charged at the regular full monthly price for the period of time the subscription was active.
      
      Please Note: Because payment methods may vary, refunds are not automated, and you need to contact our support team to request a refund. Our team will be very sad to hear you're choosing to leave us, but happy to help you because we want every experience with Traidify, even refunds, to be simple and easy.`,
    },
    {
      title: 'Who can I contact if I have questions?',
      description: `You may email our trader support team at team@traidify.com and we'd be happy to help you.`,
    },
    {
      title: 'How long do I have to request a full refund?',
      description: `You may request a full refund on your monthly subscription within three days of auto-renewal or within 7 days of the initial payment for an annual plan.`,
    },
    {
      title: 'When will I receive my refund?',
      description: `Depending on the payment method you used to upgrade, refunds typically take 3-5 business days.`,
    },
    {
      title: 'Anything not included during the trial?',
      description: `Our backtester is not included in the free trial and is only available to subscribers at the Pro and Elite level.`,
    },
    {
      title: 'What happens to my bots if I cancel my account?',
      description: `Once the cancelation is effective, all bots will automatically turn off. Any active positions will then need to be managed in your broker account. Should you wish to reactivate your account, you will have 30 days to restore your bot templates, automations library, and trading history before all your hard work is deleted forever.`,
    },
  ],
};

export default POLICY;
