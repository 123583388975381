import { Box, Container, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import useStyle from './index.style';

const Types = ({ portfolio, title, ...props }) => {
  const styles = useStyle();

  const veryConservativeDetails = () => {
    return (
      <Container className={styles.typesContainer}>
        <Typography className={styles.boldText} variant="h4" gutterBottom={true}>
          Typically, a {title} investor is:
        </Typography>
        <List>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              <Typography variant="h4" className={styles.detailsFocus}>
                Cautious
              </Typography>{' '}
              or a first-time investor
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              Primarily focused on{' '}
              <Typography variant="h4" className={styles.detailsFocus}>
                portfolio stability{' '}
              </Typography>
              <Typography variant="h4" className={styles.detailsFocus}>
                and preservation of capital
              </Typography>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              Will need the money from their investments in{' '}
              <Typography variant="h4" className={styles.detailsFocus}>
                five years or less
              </Typography>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              Has a{' '}
              <Typography variant="h4" className={styles.detailsFocus}>
                medium investment time horizon{' '}
              </Typography>
              and{' '}
              <Typography variant="h4" className={styles.detailsFocus}>
                {' '}
                seeks a growth potential{' '}
              </Typography>{' '}
              that can compete with inflation concerns
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h5" paragraph={true}>
              Someone with a portfolio that primarily consists of investments in{' '}
              <Typography variant="h4" className={styles.detailsFocus}>
                cash and bonds{' '}
              </Typography>
            </Typography>
          </ListItem>
        </List>
        <Typography className={styles.boldText} variant="h5">
          At year 10, 0.0% of portfolios are losing money.
        </Typography>
        <Typography variant="h5">
          <Box sx={{ fontStyle: 'italic' }}>
            SmartAsset does not make recommendations on securities
          </Box>
        </Typography>
      </Container>
    );
  };

  const conservativeDetails = () => {
    return (
      <Container className={styles.typesContainer}>
        <Typography className={styles.boldText} variant="h4" gutterBottom={true}>
          Typically, a {title} investor is:
        </Typography>
        <List>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              Willing and able to accept some{' '}
              <Typography variant="h4" className={styles.detailsFocus}>
                {' '}
                risk or volatility
              </Typography>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              Primarily focused on pursuing a{' '}
              <Typography variant="h4" className={styles.detailsFocus}>
                modest level of portfolio appreciation{' '}
              </Typography>
              with minimal principal loss and volatility
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              Someone with a portfolio that primarily includes{' '}
              <Typography variant="h4" className={styles.detailsFocus}>
                {' '}
                investments in cash
              </Typography>{' '}
              and
              <Typography variant="h4" className={styles.detailsFocus}>
                {' '}
                bonds with some allocation in equities
              </Typography>
            </Typography>
          </ListItem>
        </List>
        <Typography className={styles.boldText} variant="h5">
          At year 10, 0.0% of portfolios are losing money.
        </Typography>
        <Typography variant="h5">
          <Box sx={{ fontStyle: 'italic' }}>
            SmartAsset does not make recommendations on securities.
          </Box>
        </Typography>
      </Container>
    );
  };

  const moderateDetails = () => {
    return (
      <Container className={styles.typesContainer}>
        <Typography className={styles.boldText} variant="h4" gutterBottom={true}>
          Typically, a {title} investor is:
        </Typography>
        <List>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              Looking for a balance between{' '}
              <Typography variant="h4" className={styles.detailsFocus}>
                {' '}
                portfolio stability
              </Typography>{' '}
              and
              <Typography variant="h4" className={styles.detailsFocus}>
                {' '}
                portfolio appreciation
              </Typography>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              Willing and able to accept a moderate level of{' '}
              <Typography variant="h4" className={styles.detailsFocus}>
                {' '}
                risk and return
              </Typography>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              An investor focused on growth but looking for{' '}
              <Typography variant="h4" className={styles.detailsFocus}>
                greater diversification
              </Typography>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              Someone with a portfolio that primarily includes a
              <Typography variant="h4" className={styles.detailsFocus}>
                {' '}
                balance of investments in bonds
              </Typography>{' '}
              and
              <Typography variant="h4" className={styles.detailsFocus}>
                {' '}
                equities
              </Typography>
            </Typography>
          </ListItem>
        </List>
        <Typography className={styles.boldText} variant="h5">
          At year 10, 0.5% of portfolios are losing money.
        </Typography>
        <Typography variant="h5">
          <Box sx={{ fontStyle: 'italic' }}>
            SmartAsset does not make recommendations on securities.
          </Box>
        </Typography>
      </Container>
    );
  };

  const aggressiveDetails = () => {
    return (
      <Container className={styles.typesContainer}>
        <Typography className={styles.boldText} variant="h4" gutterBottom={true}>
          Typically, an {title} investor is:
        </Typography>
        <List>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              Primarily focused on{' '}
              <Typography variant="h4" className={styles.detailsFocus}>
                pursuing portfolio appreciation over time
              </Typography>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              Usually an{' '}
              <Typography variant="h4" className={styles.detailsFocus}>
                experienced equity investor
              </Typography>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              Can tolerate{' '}
              <Typography variant="h4" className={styles.detailsFocus}>
                market downturns{' '}
              </Typography>
              and volatility for the possibility of{' '}
              <Typography variant="h5" className={styles.detailsFocus}>
                {' '}
                achieving greater long-term gains
              </Typography>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              Someone who won’t need the money from their investments for{' '}
              <Typography variant="h5" className={styles.detailsFocus}>
                {' '}
                10 years or more{' '}
              </Typography>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h5" paragraph={true}>
              Someone with a portfolio that has exposure to various asset classes but primarily
              invested in
              <Typography variant="h5" className={styles.detailsFocus}>
                {' '}
                equities{' '}
              </Typography>
            </Typography>
          </ListItem>
        </List>
        <Typography className={styles.boldText} variant="h5">
          At year 10, 1.7% of portfolios are losing money.
        </Typography>
        <Typography variant="h5">
          <Box sx={{ fontStyle: 'italic' }}>
            SmartAsset does not make recommendations on securities.
          </Box>
        </Typography>
      </Container>
    );
  };

  const veryAggressiveDetails = () => {
    return (
      <Container className={styles.typesContainer}>
        <Typography className={styles.boldText} variant="h4" gutterBottom={true}>
          Typically, a {title} investor is:
        </Typography>
        <List>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              Primarily focused on pursuing
              <Typography variant="h5" className={styles.detailsFocus}>
                {' '}
                above-average portfolio appreciation over time.
              </Typography>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              Someone who can tolerate higher
              <Typography variant="h5" className={styles.detailsFocus}>
                {' '}
                degrees of fluctuation{' '}
              </Typography>
              in the value of his investments.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              Someone with{' '}
              <Typography variant="h5" className={styles.detailsFocus}>
                high return expectations
              </Typography>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h5" gutterBottom={true}>
              Someone who won’t need the money from their investments for
              <Typography variant="h5" className={styles.detailsFocus}>
                {' '}
                15 years or more.
              </Typography>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h5" paragraph={true}>
              Someone with a portfolio that has exposure to various
              <Typography variant="h5" className={styles.detailsFocus}>
                {' '}
                asset classes{' '}
              </Typography>
              but will be heavily invested in{' '}
              <Typography variant="h5" className={styles.detailsFocus}>
                equities
              </Typography>
            </Typography>
          </ListItem>
        </List>
        <Typography className={styles.boldText} variant="h5">
          At year 10, 3.7% of portfolios are losing money.
        </Typography>
        <Typography variant="h5">
          <Box sx={{ fontStyle: 'italic' }}>
            SmartAsset does not make recommendations on securities.
          </Box>
        </Typography>
      </Container>
    );
  };

  const investorDetails = (portfolio) => {
    if (portfolio == 'VC') {
      return veryConservativeDetails;
    } else if (portfolio == 'C') {
      return conservativeDetails;
    } else if (portfolio == 'M') {
      return moderateDetails;
    } else if (portfolio == 'A') {
      return aggressiveDetails;
    } else {
      return veryAggressiveDetails;
    }
  };

  return <Box mb={5}>{investorDetails(portfolio)}</Box>;
};

export default Types;
