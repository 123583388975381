import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  head: {
    display: 'flex',
    "@media only screen and (max-width: '767px')": {
      display: 'block',
    },
  },
  details: {
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '1rem',
    },
  },

  bold: {
    fontWeight: 600,
  },

  email: {
    fontWeight: 500,
    color: '#777E90',
  },
  level: {
    display: 'inline-block',
    maxWidth: '135px',
    marginTop: '20px',
    padding: '8px 16px',
    borderRadius: '16px',
    boxShadow: 'inset 0 0 0 2px #E6E8EC',
    fontSize: '14px',
    lineHeight: 16 / 14,
    fontWeight: 700,
    fontFamily: ('DM Sans', 'sans-serif'),
    color: '#58BD7D',

    "@media only screen and (max-width: '767px')": {
      marginTop: '12px',
    },
  },

  dropdown: {
    flexShrink: 0,
    width: '200px',
    margin: '12px 0 0 36px',

    "@media only screen and (max-width: '767px')": {
      width: '100%',
      margin: '40px 0 0',
    },
  },

  dropdownHead: {
    background: '#F4F5F6',
    boxShadow: `inset 0 0 0 2px "#F4F5F6"`,
    borderRadius: '24px',
  },

  settings: {
    '&:not(:first-child)': {
      marginTop: '48px',

      "@media only screen and (max-width: '767px')": {
        marginTop: '24px',
      },
    },
  },

  title: {
    marginBottom: '40px',
    fontSize: '24px',
    lineHeight: 32 / 24,
    fontWeight: 600,
    letterSpacing: '-.01em',
  },

  box: {
    '&:not(:last-child)': {
      marginBottom: '40px',
    },
  },

  subtitle: {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #E6E8EC',
    fontSize: '12px',
    lineHeight: 1,
    fontWeight: 700,
    textTransform: 'uppercase',
    color: '#B1B5C3',
  },

  item: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    '&:not(:last-child)': {
      marginBottom: '24px',
    },
  },

  category: {
    marginRight: 'auto',
  },

  content: {
    marginLeft: '16px',
    textAlign: 'right',
    color: '#777E90',
  },

  btns: {
    marginTop: '48px',
    textAlign: 'right',
    "@media only screen and (max-width: '767px')": {
      marginTop: '24px',
      textAlign: 'left',
    },
  },
}));

export default useStyles;
