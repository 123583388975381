import { Box, Grid, Typography } from '@mui/material';
import useStyle from '../index.style';
const Description = (props) => {
  const { title } = props;
  const styles = useStyle();

  const checkVowel = (word) => {
    if (title) {
      const vowels = 'aeiouAEIOU';
      return vowels.indexOf(word[0]) !== -1 ? 'an' : 'a';
    }
  };

  return (
    <Box mt={2}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box textAlign="center" mb={4}>
            <Typography variant="h6">You are {checkVowel(title)}</Typography>
            <Typography className={styles.textFocus} variant="h3">
              {title || 'Moderate'}
            </Typography>
            <Typography variant="h6">Investor</Typography>
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Box display="flex" alignItems="center" mb={2}>
            <Typography variant="h6">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </Typography>
          </Box>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default Description;
