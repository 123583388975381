import Mobile from './Mobile';

const Birthday = (props) => {
  return (
    <>
      <Mobile />
    </>
  );
};

export default Birthday;
