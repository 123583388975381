import { fork, put, call, takeLatest } from 'redux-saga/effects';
import stripe from '../../services/stripe';

import {
  FETCH_END,
  FETCH_ERROR,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_OK,
  SUBSCRIBE_CUSTOMER,
  SUBSCRIBE_CUSTOMER_OK,
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_OK,
  CANCEL_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_OK,
} from '../constants/stripe.constant';

export function* getProducts(action) {
  try {
    const xhr = yield call(stripe.fetchProducts);

    if (xhr.status >= 400) {
      throw xhr;
    }
    yield put({ type: FETCH_PRODUCTS_OK, ...xhr.data });
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;

    yield put({ type: FETCH_ERROR, ...e });
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* getProductsSaga() {
  yield takeLatest(FETCH_PRODUCTS, getProducts);
}

export function* getSubcriptions(action) {
  try {
    const xhr = yield call(stripe.fetchSubscriptions);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: FETCH_SUBSCRIPTIONS_OK, ...xhr.data });
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;

    yield put({ type: FETCH_ERROR, ...e });
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* getSubcriptionsSaga() {
  yield takeLatest(FETCH_SUBSCRIPTIONS, getSubcriptions);
}

export function* cancelSubcription(action) {
  try {
    const xhr = yield call(stripe.cancelSubscription, action.id);

    if (xhr.status >= 400) {
      throw xhr;
    }
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;

    yield put({ type: FETCH_ERROR, ...e });
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* cancelSubcriptionSaga() {
  yield takeLatest(CANCEL_SUBSCRIPTION, cancelSubcription);
}

export function* updateSubcription(action) {
  try {
    const xhr = yield call(stripe.updateSubscription, action.data);

    if (xhr.status >= 400) {
      throw xhr;
    }
    yield put({ type: UPDATE_SUBSCRIPTION_OK, ...xhr });
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;

    yield put({ type: FETCH_ERROR, ...e });
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* updateSubcriptionSaga() {
  yield takeLatest(UPDATE_SUBSCRIPTION, updateSubcription);
}

export function* subscribeCustomer(action) {
  try {
    const xhr = yield call(stripe.subscribeCustomer, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }
    yield put({ type: SUBSCRIBE_CUSTOMER_OK, ...xhr });
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;

    yield put({ type: FETCH_ERROR, ...e });
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* subscribeCustomerSaga() {
  yield takeLatest(SUBSCRIBE_CUSTOMER, subscribeCustomer);
}

const stripe_sagas = [
  fork(getProductsSaga),
  fork(subscribeCustomerSaga),
  fork(getSubcriptionsSaga),
  fork(cancelSubcriptionSaga),
  fork(updateSubcriptionSaga),
];
export default stripe_sagas;
