import { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { Box, Grid, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import { format } from 'date-fns';

const TimePeriod = ({ parentSD, setStartDate, parentED, setEndDate, setValue, ...props }) => {
  const styles = useStyle();
  const [period, setPeriod] = useState(0);
  const [endPeriod, setEndPeriod] = useState('');

  const periodLength = [
    { label: '1 Year', value: 1 },
    { label: '3 Years', value: 3 },
    { label: '5 Years', value: 5 },
    { label: '10 Years', value: 10 },
  ];

  const endPeriodValues = [
    { label: 'MTD', value: 'M' },
    { label: 'QTD', value: 'Q' },
    { label: 'YTD', value: 'Y' },
  ];

  const formatDate = (date) => {
    return format(date, 'yyyy-MM-dd');
  };

  const handlePeriod = (event) => {
    const value = parseInt(event.target.value);
    setPeriod(value);
    handleStartDate(value);
  };

  const handleStartDate = (value) => {
    const current = new Date();
    const getDate = new Date().setFullYear(current.getFullYear() - value);
    setStartDate(formatDate(getDate));
  };

  const handleEndPeriod = (event) => {
    setEndPeriod(event.target.value);
    handleEndPeriodDate(event.target.value);
  };

  const handleEndPeriodDate = (value) => {
    const currentDate = new Date();
    if (value === 'M') {
      const monthly = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      setEndDate(formatDate(monthly));
    } else if (value === 'Q') {
      const quarter = Math.floor((currentDate.getMonth() + 1) / 3);
      const quarterly = new Date(currentDate.getFullYear(), quarter * 3, 0);
      setEndDate(formatDate(quarterly));
    } else {
      const yearly = new Date(currentDate.getFullYear() - 1, 12, 0);
      setEndDate(formatDate(yearly));
    }
  };

  useEffect(() => {
    // setValue(formatDate);
  }, []);

  useEffect(() => {
    if (period !== 0) {
      // setValue(formatDate);
    }
  }, [period]);

  useEffect(() => {
    // setValue(formatDate);
  }, [endPeriod]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
      <Box width="100%">
        <Grid container className={styles.container}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl>
              <FormLabel>
                <Typography variant="h6">Period Length</Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={period}
                onChange={handlePeriod}
              >
                <Grid container>
                  {periodLength.map((data) => {
                    return (
                      <Grid item>
                        <FormControlLabel
                          value={data.value}
                          control={<Radio />}
                          label={data.label}
                          labelPlacement="end"
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl>
              <FormLabel>
                <Typography variant="h6">End Date</Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={endPeriod}
                onChange={handleEndPeriod}
              >
                <Grid container>
                  {endPeriodValues.map((data) => {
                    return (
                      <Grid item>
                        <FormControlLabel
                          value={data.value}
                          control={<Radio />}
                          label={data.label}
                          labelPlacement="end"
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </RadioGroup>
            </FormControl>
            <Box display="flex" flexDirection="row-reverse">
              <FormGroup>
                <FormControlLabel control={<Switch defaultChecked />} label="Default" />
              </FormGroup>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TimePeriod;

export { TimePeriod };

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'inline-grid',
    justifyContent: 'center',
    direction: 'row',
  },
}));
