import { Box, Card, Container, Grid, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import cn from 'classnames';
import { Contained } from '../../components/Button';
const SuccessPayment = () => {
  const styles = useStyles();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container className={styles.container}>
      <Card className={styles.card}>
        <Box display="flex" justifyContent="center" mb={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            maxWidth={300}
          >
            <Typography id="title" variant="h5" className={cn(styles.boldText, styles.textFocus)}>
              Thank you for your subscription to Traidify
            </Typography>
          </Box>
        </Box>
        <Typography variant="h6" className={cn(styles.boldText, styles.alignRight)}>
          Hello Sonia
        </Typography>
        <Typography>Bellow is the subscription details:</Typography>
        <Box className={styles.details}>
          <Grid container>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              <Box display="flex" flexDirection="column" p={2} borderRight="2px white solid">
                <Typography variant="subtitle1">Product</Typography>
                <Typography variant="h5" className={cn(styles.boldText, styles.textFocus)}>
                  Basic Plan <br /> <span className={styles.subtitle}>5 Tickers</span>
                </Typography>
              </Box>
            </Grid>
            {/* <Divider variant="inset" orientation="vertical"  flexItem /> */}
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display="flex" flexDirection="column" p={2}>
                <Typography variant="subtitle1">Amount</Typography>
                <Typography variant="h5" className={cn(styles.boldText, styles.textFocus)}>
                  $4.95
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" justifyContent="center" margin="2rem 0 1rem 0">
          <Contained rounded small={small}>
            Get Your Trade Plan Today
          </Contained>
        </Box>
        <Box textAlign="center">
          <Typography>
            If the link above doesn’t work, please copy and paste this URL into a browser <br />
            <a>www.verifylink.com</a>
          </Typography>
        </Box>
      </Card>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '3rem',
    maxWidth: '900px',
    '& .MuiTypography-subtitle1': {
      fontSize: '1.5rem',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.6)',
    },
    [theme.breakpoints.down('sm')]: {
      '& #title': {
        fontSize: '1.5rem',
      },
      '& .MuiTypography-body1': {
        fontSize: '0.825rem',
      },
      '& .MuiTypography-h6': {
        fontSize: '0.95rem',
      },
      '& .MuiTypography-subtitle1': {
        fontSize: '0.86rem',
      },
    },
  },
  card: {
    flexDirection: 'column',
    padding: '4rem',
    boxShadow: '0px 2.7908px 7.44214px rgba(0, 0, 0, 0.15)',
    borderRadius: '26.0475px',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem',
    },
  },
  textFocus: {
    color: '#4DABCC',
  },
  boldText: {
    fontWeight: 'bold',
  },
  details: {
    background: '#F8F8F8',
    borderRadius: '25px',
    maxWidth: '566px',
    margin: '1rem auto',
    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-h5': {
        fontSize: '0.79rem',
      },
      '& .MuiTypography-subtitle1': {
        fontSize: '0.76rem',
      },
    },
  },
  alignRight: {
    textAlign: 'left',
  },
  subtitle: {
    fontSize: '1.5',
  },
}));

export default SuccessPayment;
