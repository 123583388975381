import API from './API';

const text_sentiment = {
  adx: (data) => {
    return new API().get(`/technical-analysis/adx-mi/${data.market}/`, data.params);
  },
  adxDaily: (data) => {
    return new API().get(
      `/technical-analysis/adx-mi/${data.type}/?ticker=${data.ticker}&period=14&type=daily`
    );
  },
  adxMi: (data) => {
    return new API().get(`/technical-analysis/adx-mi/${data.ticker_type}/`, data.params);
  },
  tieredChartData: (data) => {
    return new API().get(`/technical-analysis/metric-matrix/${data.ticker_type}/`, data.params);
  },
  volatilityGauge: (data) => {
    return new API().get(
      `/technical-analysis/volatility-gauge/${data.ticker_type}/${data.timeFrame}/`,
      data.params
    );
  },
  volatilityATR: (data) => {
    return new API().get(`/technical-analysis/atrange/${data.ticker_type}/`, data.params);
  },
};

export default text_sentiment;
