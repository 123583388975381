import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: '100%',
    paddingBottom: '2rem',
  },
  title: {
    flex: 1,
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  commonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '50px',
  },
  categoryHeaderContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '0.5rem',
    background: theme.palette.gradients.gradient_title,
    borderRadius: '41px',
  },
  categoryHeader: {
    width: '100%',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    background: theme.palette.gradients.gradient_title,
    marginBottom: '2rem',
    padding: '0.5rem',
  },
  header: {
    fontWeight: '600',
    color: '#FFFFFF',
    textAlign: 'center',
    width: '100%',
  },
  globeChartContainer: {
    borderColor: 'red',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '300px',
  },
  globeChart: {
    width: '100%',
    '& > svg': {
      // width: '70%',
    },
  },
}));

export default useStyles;
