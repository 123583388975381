import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import './index.css';

const Employee = () => {
  return (
    <Grid container>
      <Box className="container" mt={6}>
        <Box className="starter-template">
          <Box className="head">
            <Typography variant="h4">
              <Box fontWeight="bold">Meet the team</Box>
            </Typography>
            <Box className="title-underline center">
              <span></span>
            </Box>
          </Box>
          <Box>
            <ul className="list-unstyled user-cards container container-l has-margin-v margin-xl">
              <li className="user-card-designer">
                <a href="designer">
                  <Box className="user-card-intro">
                    <Typography variant="h3">Designers</Typography>
                    <Typography>Drag and drop is the new black</Typography>
                    <Box className="card-link">
                      <span>Explore</span>
                    </Box>
                  </Box>
                  <Box className="user-svg-wrapper"></Box>{' '}
                  <span className="user-card-circle"></span>{' '}
                </a>
              </li>
              <li className="user-card-team">
                <a href="team">
                  <Box className="user-card-intro">
                    <Typography variant="h3">Teams</Typography>
                    <Typography>Share icons and collaborate on projects</Typography>
                    <Box className="card-link">
                      <span>Explore</span>
                    </Box>
                  </Box>
                  <Box className="user-svg-wrapper"></Box>{' '}
                  <span className="user-card-circle"></span>{' '}
                </a>
              </li>
              <li className="user-card-developer">
                <a href="developer">
                  <Box className="user-card-intro">
                    <Typography variant="h3">Developers</Typography>
                    <Typography>Unleash the power of SVG icons</Typography>
                    <Box className="card-link">
                      <span>Explore</span>
                    </Box>
                  </Box>
                  <Box className="user-svg-wrapper"></Box>{' '}
                  <span className="user-card-circle"></span>{' '}
                </a>
              </li>
            </ul>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default Employee;
