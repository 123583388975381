import React, { useState } from 'react';
// import styles from './Trend.module.sass';
import { Box, Container } from '@mui/material';
import { EconomicCalendar, MarketData } from 'react-tradingview-embed';
import useStyles from './index.style';

const Learn = () => {
  const navigation = ['All', 'Stock', 'Cryptos'];
  const [activeIndex, setActiveIndex] = useState(0);
  const [sorting, setSorting] = useState(navigation[0]);
  const [tab, setTab] = useState('All');
  const styles = useStyles();

  const items = [
    {
      title: 'Bitcoin',
      currency: 'BTC',
      price: '$36,201.34',
      positive: '+1.71%',
      image: '/images/content/currency/bitcoin.svg',
      url: '/exchange',
    },
    {
      title: 'Ethereum',
      currency: 'ETH',
      price: '$2,605.95',
      positive: '+2.04%',
      image: '/images/content/currency/ethereum.svg',
      url: '/exchange',
    },
    {
      title: 'Bitcoin Cash',
      currency: 'BCH',
      price: '$939.20',
      negative: '-0.74%',
      image: '/images/content/currency/bitcoin-cash.svg',
      url: '/exchange',
    },
    {
      title: 'Ripple',
      currency: 'XRP',
      price: '$1.02',
      positive: '+1.20%',
      image: '/images/content/currency/ripple.svg',
      url: '/exchange',
    },
    {
      title: 'Chainlink',
      currency: 'LINK',
      price: '$30.56',
      negative: '-3.84%',
      image: '/images/content/currency/chainlink.svg',
      url: '/exchange',
    },
  ];

  const data = [
    {
      name: '1',
      price: 1000,
    },
    {
      name: '2',
      price: 2300,
    },
    {
      name: '3',
      price: 2000,
    },
    {
      name: '4',
      price: 2780,
    },
    {
      name: '5',
      price: 1890,
    },
    {
      name: '6',
      price: 2390,
    },
    {
      name: '7',
      price: 2490,
    },
    {
      name: '8',
      price: 3000,
    },
    {
      name: '9',
      price: 2500,
    },
    {
      name: '10',
      price: 2000,
    },
    {
      name: '11',
      price: 2780,
    },
    {
      name: '12',
      price: 1890,
    },
    {
      name: '13',
      price: 2390,
    },
    {
      name: '14',
      price: 1490,
    },
  ];

  const handleTab = (selected) => {
    setTab(selected);
  };

  return (
    <Container className={styles.container}>
      <Box className={styles.line} mt={12}>
        <h2 className={styles.title}>Market trend</h2>
        {/* <Box>
          <Contained
            white={false}
            variant="contained"
            color="primary"
            rounded
            small={window.innerWidth <= 540 ? true : false}
          >
            View more
          </Contained>
        </Box> */}
      </Box>
      <Box
        height="50vh"
        mt={8}
        className="tradingview-widget-container"
        sx={{ '& > div': { height: '100%' } }}
      >
        <MarketData
          widgetProps={{
            width: '100%',
            height: '100%',
            symbolsGroups: [
              {
                name: 'Indices',
                originalName: 'Indices',
                symbols: [
                  {
                    name: 'FOREXCOM:SPXUSD',
                    displayName: 'S&P 500',
                  },
                  {
                    name: 'FOREXCOM:NSXUSD',
                    displayName: 'US 100',
                  },
                  {
                    name: 'FOREXCOM:DJI',
                    displayName: 'Dow 30',
                  },
                  {
                    name: 'INDEX:NKY',
                    displayName: 'Nikkei 225',
                  },
                  {
                    name: 'INDEX:DEU40',
                    displayName: 'DAX Index',
                  },
                  {
                    name: 'FOREXCOM:UKXGBP',
                    displayName: 'UK 100',
                  },
                ],
              },
              {
                name: 'Futures',
                originalName: 'Futures',
                symbols: [
                  {
                    name: 'CME_MINI:ES1!',
                    displayName: 'S&P 500',
                  },
                  {
                    name: 'CME:6E1!',
                    displayName: 'Euro',
                  },
                  {
                    name: 'COMEX:GC1!',
                    displayName: 'Gold',
                  },
                  {
                    name: 'NYMEX:CL1!',
                    displayName: 'Crude Oil',
                  },
                  {
                    name: 'NYMEX:NG1!',
                    displayName: 'Natural Gas',
                  },
                  {
                    name: 'CBOT:ZC1!',
                    displayName: 'Corn',
                  },
                ],
              },
              {
                name: 'Bonds',
                originalName: 'Bonds',
                symbols: [
                  {
                    name: 'CME:GE1!',
                    displayName: 'Eurodollar',
                  },
                  {
                    name: 'CBOT:ZB1!',
                    displayName: 'T-Bond',
                  },
                  {
                    name: 'CBOT:UB1!',
                    displayName: 'Ultra T-Bond',
                  },
                  {
                    name: 'EUREX:FGBL1!',
                    displayName: 'Euro Bund',
                  },
                  {
                    name: 'EUREX:FBTP1!',
                    displayName: 'Euro BTP',
                  },
                  {
                    name: 'EUREX:FGBM1!',
                    displayName: 'Euro BOBL',
                  },
                ],
              },
              {
                name: 'Forex',
                originalName: 'Forex',
                symbols: [
                  {
                    name: 'FX:EURUSD',
                    displayName: 'EUR/USD',
                  },
                  {
                    name: 'FX:GBPUSD',
                    displayName: 'GBP/USD',
                  },
                  {
                    name: 'FX:USDJPY',
                    displayName: 'USD/JPY',
                  },
                  {
                    name: 'FX:USDCHF',
                    displayName: 'USD/CHF',
                  },
                  {
                    name: 'FX:AUDUSD',
                    displayName: 'AUD/USD',
                  },
                  {
                    name: 'FX:USDCAD',
                    displayName: 'USD/CAD',
                  },
                ],
              },
            ],
            showSymbolLogo: true,
            colorTheme: 'light',
            isTransparent: false,
            locale: 'en',
          }}
        />
      </Box>

      <Box
        height="50vh"
        mt={8}
        className="tradingview-widget-container"
        sx={{ '& > div': { height: '100%' } }}
      >
        <EconomicCalendar
          widgetProps={{
            colorTheme: 'light',
            isTransparent: false,
            width: '100%',
            height: '100%',
            locale: 'en',
            importanceFilter: '-1,0,1',
            currencyFilter: 'USD,EUR,ITL,NZD,CHF,AUD,FRF,JPY,ZAR,TRL,CAD,DEM,MXN,ESP,GBP',
          }}
        />
      </Box>
      {/* <Box className={styles.nav}>
        <ButtonGroup buttons={navigation} currentTab={tab} getTab={handleTab}></ButtonGroup>
      </Box>
      <Box className={styles.dropdown}></Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" className={styles.table}>
          <TableHead>
            <TableRow className={styles.row}>
              <TableCell className={styles.col}>#</TableCell>
              <TableCell className={styles.col}>Name</TableCell>
              <TableCell className={styles.col}>Price</TableCell>
              <TableCell className={styles.col}>24th Change</TableCell>
              <TableCell className={styles.col}>Chart</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((x, index) => (
              <TableRow key={index} className={styles.row}>
                <TableCell className={styles.col}>{index + 1}</TableCell>
                <TableCell className={styles.col}>
                  <Box className={styles.item}>
                    <Box className={styles.icon}>
                      <img src={x.image} alt="Currency" />
                    </Box>
                    <Box className={styles.details}>
                      <Typography className={styles.subtitle}>{x.title}</Typography>
                      <Typography className={styles.currency}>{x.currency}</Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell className={styles.col}>
                  <Typography>{x.price}</Typography>
                </TableCell>
                <TableCell className={styles.col}>
                  {x.positive ? (
                    <Typography className={styles.positive}>{x.positive}</Typography>
                  ) : (
                    <Typography className={styles.negative}>{x.negative}</Typography>
                  )}
                </TableCell>
                <TableCell className={styles.col}>
                  <Box className={styles.chart} justifyContent="center">
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        width={500}
                        height={400}
                        data={data}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <defs>
                          <linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#45B36B" stopOpacity={0.6} />
                            <stop offset="95%" stopColor="#45B36B" stopOpacity={0} />
                          </linearGradient>
                        </defs>
                        <Area
                          type="monotone"
                          dataKey="price"
                          stroke="#58BD7D"
                          fillOpacity={1}
                          fill="url(#colorPrice)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
    </Container>
  );
};

export default Learn;
