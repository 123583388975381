import { Box, Typography } from '@mui/material';
import clsx from 'clsx';

import { HORIZONTAL } from './constants';

import useStyles from './styles';

const PositiveNegativeProgressBar = ({
  positive,
  negative,
  direction,
  styles = {},
  show_value = false,
  hide_zero = true,
  positive_label = '',
  negative_label = '',
  inherit_container,
}) => {
  const classes = useStyles({ positive, negative, direction, color: 'red', inherit_container });

  const getLabel = (text, value) => {
    if (!!hide_zero && value == 0) return ''; // eslint-disable-line eqeqeq

    return text;
  };

  if (direction === HORIZONTAL) {
    return (
      <Box className={classes.container} style={styles.container}>
        <Box className={clsx(classes.bar, classes.positiveHorizontal)}>
          <Box />
          <Box>
            {show_value && (
              <Typography variant="caption">{getLabel(positive_label, positive)}</Typography>
            )}
          </Box>
        </Box>
        <Box className={clsx(classes.bar, classes.negativeHorizontal)}>
          <Box />
          <Box>
            {show_value && (
              <Typography variant="caption">{getLabel(negative_label, negative)}</Typography>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={clsx(classes.container, styles.container)}>
      <Box className={clsx(classes.bar, classes.negative)}>
        <Box />
        <Box>
          {show_value && (
            <Typography variant="caption">{getLabel(negative_label, negative)}</Typography>
          )}
        </Box>
      </Box>
      <Box className={clsx(classes.bar, classes.positive)}>
        <Box />
        <Box>
          {show_value && (
            <Typography variant="caption">{getLabel(positive_label, positive)}</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PositiveNegativeProgressBar;
