import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.base,
    display: 'flex',
    flexDirection: 'column',
  },

  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    margin: '0 auto',
    paddingTop: '100px',
    //background: `url(${login_bg})`,
  },
  preview: {
    position: 'relative',
    overflow: 'hidden',
    '@media(minWidth: 1179px)': {
      '&:before': {
        content: '',
        display: 'block',
        paddingBottom: '77%',
      },
    },
    '& img': {
      width: '100%',
      height: '80%',
      borderRadius: '20px',
      transition: 'transform 1s',
      '@media(minWidth: 1179px)': {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        objectFit: 'cover',
      },
    },
  },
}));

export default useStyles;
