import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const style = {
  chart: {
    width: '500px',
  },
};

const DoughnutChart = (props) => {
  const { labels, values, breakDowns } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleHover = (canvas) => {
    setAnchorEl(canvas);
  };
  //unused might use in future
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  let index = 0;
  const setIndex = (canvas, arcIndex) => {
    index = arcIndex;
    handleHover(canvas);
  };
  const open = Boolean(anchorEl);
  const data = {
    labels: labels,
    breakDowns: breakDowns,
    datasets: [
      {
        label: 'Breakdown: ',
        data: values || [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132)',
          'rgba(54, 162, 235)',
          'rgba(255, 206, 86)',
          'rgba(75, 192, 192)',
          'rgba(153, 102, 255)',
          'rgba(255, 159, 64)',
          'rgb(134, 229, 146)',
          'rgb(255, 240, 131)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgb(134, 229, 146)',
          'rgb(255, 240, 131)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'center',
      },
      datalabels: {
        display: true,
        color: 'white',
        formatter: function (value, context) {
          return value + '%';
        },
        listeners: {
          enter: function (context) {
            setIndex(context.chart.canvas, context.dataIndex);
          },
          leave: function (context) {
            context.hovered = false;
            return true;
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      responsive: true,
    },
  };

  return (
    <div style={style.chart}>
      {/* <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        disableAutoFocusItem="false"
      >
        <MenuItem onClick={() => handleClose}>
          <PieChart labels={labels} values={data.breakDowns[index]} />
        </MenuItem>
      </Menu> */}
      <Doughnut
        data={data}
        plugins={[ChartDataLabels]}
        options={options}
        width={200}
        height={200}
      />
    </div>
  );
};

export default DoughnutChart;
