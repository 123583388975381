import { Hidden } from '@mui/material';
import { useHistory } from 'react-router-dom';
// import { HEADER_HEIGHT_NUMBER } from '../../constants/reponsive';
import { TYPE_1 } from './constants';
import Desktop from './Desktop';

const Header = ({ type = TYPE_1, disableHysteresis = true, props }) => {
  const history = useHistory();
  // const trigger = useScrollTrigger({
  //   threshold: HEADER_HEIGHT_NUMBER,
  //   disableHysteresis: disableHysteresis,
  // });

  return (
    <Hidden smDown>
      {/* <Slide appear={false} direction="down" in={!trigger}> */}
      <Desktop type={type} history={history} />
      {/* </Slide> */}
    </Hidden>
  );
};

export default Header;
