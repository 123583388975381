import { Box, Typography } from '@mui/material';
import React, { Component } from 'react';

import { withStyles } from '@mui/styles';

import clsx from 'clsx';
import Topics from './Topics';
import styles from './index.style';
// Products
// Trade Setup
// Stock Quadrants for Momentum and Strength
// ETF Performance Screener

// Company
// About
// Pricing
// Terms of use
// Disclaimer
// Privacy Policy
// Cookies Policy

// Education
// Trend
// Support & Resistance
// Candlesticks
// Multi-timeframe Analysis
// Comparative Performance
// Portfolio Allocation
const PRODUCTS_ITEMS = [
  {
    label: 'Trade Setup',
    to: '#',
  },
  {
    label: 'Stock Quadrants for Momentum and Strength',
    to: '#',
  },
  {
    label: 'ETF Performance Screener',
    to: '#',
  },
];
const COMPANY_ITEMS = [
  // {
  //   label: 'About',
  //   to: '#',
  // },
  // {
  //   label: 'Pricing',
  //   to: '#',
  // },
  {
    label: 'Terms Of Use',
    to: '/app/terms-and-conditions',
  },
  {
    label: 'Disclaimer',
    to: '#',
  },
  {
    label: 'Privacy Policy',
    to: '/app/privacy-policy',
  },
  {
    label: 'Cookies Policy',
    to: '/app/cookie-policy',
  },
];

const EDUCATION_ITEMS = [
  {
    label: 'Trend',
    to: '#',
  },
  {
    label: 'Support & Resistance',
    to: '#',
  },
  {
    label: 'Candlestick',
    to: '#',
  },
  {
    label: 'Multi-timeframe Analysis',
    to: '#',
  },
  {
    label: 'Comparative Performance',
    to: '#',
  },
  {
    label: 'Portfolio Allocation',
    to: '#',
  },
];

class Footer extends Component {
  render() {
    const { shift_drawer, classes } = this.props;

    return (
      <footer>
        <Box className={clsx(classes.footer, { [classes.shiftDrawer]: shift_drawer })}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            alignSelf="center"
            flex={3}
            pr="2rem"
          >
            {/* <Box display="flex" justifyContent="center">
              <Box px="10px">
                <img src={twitter} alt="twitter" />
              </Box>
              <Box px="10px">
                <img src={insta} alt="instagram" />
              </Box>
              <Box px="10px">
                <img src={pinterest} alt="pinterest" />
              </Box>
            </Box>
            <Box pt="1rem" display="flex" justifyContent="center">
              <Box px="10px">
                <ButtonBase className={classes.mobile_store}>
                  <img src={app_store} alt="appstore" />
                </ButtonBase>
              </Box>
              <Box px="10px">
                <ButtonBase className={classes.mobile_store}>
                  <img src={play_store} alt="playstore" />
                </ButtonBase>
              </Box>
            </Box> */}
            <Box className={classes.copyright} paddingTop="2rem">
              <Typography variant="caption">
                Copyright © {new Date().getFullYear()} Traidify. All rights reserved.
              </Typography>
            </Box>
          </Box>
          <Topics title="Products" items={PRODUCTS_ITEMS} />
          <Topics title="Company" items={COMPANY_ITEMS} />
          <Topics title="Education" items={EDUCATION_ITEMS} />
        </Box>
      </footer>
    );
  }
}

// export default Footer;
export default withStyles(styles, { withTheme: true })(Footer);
