import { Box, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const Options = ({ choices = [], submitted = false, handleScore, ...props }) => {
  const style = useStyles();
  const [answer, setAnswer] = useState(null);
  const [correct, setCorrect] = useState();
  const [correctIndex, setCorrectIndex] = useState(null);

  const { currentQuestion = {}, answerList = [] } = useSelector((store) => {
    const quiz_store = store.quiz;

    if (quiz_store) return quiz_store.toJS();

    return {};
  });

  useEffect(() => {
    let selected = {};
    choices?.forEach((choice, index) => {
      if (choice.is_correct === true) {
        selected = choice;
        setCorrectIndex(index);
      }
    });
    setCorrect(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let score = 0;
    answerList.forEach((answer, index) => {
      if (answer.question !== currentQuestion.id && answer.is_correct) {
        score += 1;
      } else if (answer.question === currentQuestion.id) {
        setAnswer(answer);
      }
    });
    handleScore(score);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (answer?.label === correct?.label && submitted) {
      handleScore(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted]);

  const passOption = (option) => {
    const { onAnswerClick } = props;
    onAnswerClick(option);
    setAnswer(option);
  };

  const isChecked = (choice) => {
    const { answerList } = props;
    return answerList.find((item) => item.label === choice.label);
  };

  const isSelected = (choice) => {
    return isChecked(choice) ? style.isSelected : style.optionContainer;
  };

  const renderChoices = (index) => {
    const alphabet = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];
    return alphabet[index];
  };

  return (
    <Box>
      <FormControl className={style.optionRoot} component="fieldset">
        <RadioGroup className={style.optionParent} name="radio-buttons-group">
          {choices.length
            ? choices.map((choice, index) => {
                const selectedChoice = renderChoices(index);
                return (
                  <FormControlLabel
                    className={isSelected(choice)}
                    onClick={() => {
                      passOption(choice);
                    }}
                    key={`option-${props.id}-${choice.id}`}
                    value={<p className={style.boldText}>{choice.label}</p>}
                    control={
                      <Radio
                        icon={<p className={style.choice}>{selectedChoice}</p>}
                        checkedIcon={<p className={style.choice}>{selectedChoice}</p>}
                        checked={isChecked(choice)}
                        // classes={{ root: style.radio, checked: style.checked }}
                      />
                    }
                    label={choice.label}
                  />
                );
              })
            : ''}
          {!submitted ? (
            ''
          ) : (
            <Box display="flex" alignItems="center" justifyContent="flex-start">
              <Box className={style.errorContainer}>
                {answer?.is_correct ? (
                  <Typography className={style.correctText} variant="h5">
                    Correct
                  </Typography>
                ) : (
                  <Typography className={style.errorText} variant="h5">
                    Incorrect
                  </Typography>
                )}
                <Typography className={style.resultContent}>
                  <Box display="flex" flexDirection="row">
                    <Typography className={style.italicText}>
                      Option {renderChoices(correctIndex)}
                    </Typography>
                    <span> &nbsp;</span>
                    <Typography> is the answer</Typography>
                  </Box>
                </Typography>
              </Box>
            </Box>
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  isSelected: {
    border: '3px solid #7FB1EE',
    // background: theme.palette.primary.mainGradient,
    padding: '0.8em 1em',
    borderRadius: '20px',
    width: '100%',
    marginBottom: '0.75em',
  },

  radio: {
    '&$checked': {
      color: `${theme.palette.common.white} !important`,
    },
  },
  checked: {
    color: 'red',
  },

  chartContainer: {
    display: 'flex',
  },

  optionRoot: {
    width: '100%',
  },

  optionParent: {
    maxWidth: '800px',
    minWidth: '600px',
    margin: '0 auto',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      maxWidth: 'initial',
      minWidth: 'initial',
    },
  },

  optionContainer: {
    border: '1px solid rgba(0,0,0,0.2)',
    padding: '0.5em 1em',
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    margin: '0 0 0.5rem 0',
    width: '100%',
    color: theme.palette.common.black,
  },
  choice: {
    display: 'flex',
    alignItems: 'center',
    color: theme.text[4],
    fontWeight: 'bold',
    fontSize: 25,
    fontFamily: 'Arial Black',
    textAlign: 'center',
    background: '#3F8ED7',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  boldText: {
    fontWeight: 'bold',
  },

  correctText: {
    color: '#3F8ED7',
    fontWeight: 'bold !important',
  },

  errorContainer: {
    display: 'flex',
    marginTop: '2rem',
    flexDirection: 'column',
  },

  errorText: {
    color: '#DA3737',
    fontWeight: 'bold !important',
  },

  italicText: {
    textDecoration: 'underline',
    fontStyle: 'italic',
    fontWeight: 'bold !important',
    color: '#3F8ED7',
  },

  resultContent: {
    color: '#3F8ED7',
    whiteSpace: 'pre-line',
    '& .MuiTypography-root': {
      fontSize: '1.2rem',
    },
  },
}));

export default Options;
