import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { quizSelector } from '../../redux/reselect/quizSelector';
import Modal from '../Modal';

const ReviewQuizModal = ({ open, handleClose }) => {
  const styles = useStyles();
  const quiz = useSelector(quizSelector);

  const generateContent = () => {
    return (
      <Box>
        {quiz?.questionList?.map((question, index) => {
          return (
            <Box>
              <Typography className={styles.questionTitle}>{question?.label}</Typography>
              <Box className={styles.answerBox}>{getQuestionAnswer(question?.id)}</Box>
            </Box>
          );
        })}
      </Box>
    );
  };

  const getQuestionAnswer = (question_id) => {
    let getAnswer = {};
    getAnswer = quiz?.answerList?.filter((answer, index) => {
      return answer?.question === question_id;
    });

    return getAnswer?.length > 0 ? getAnswer[0]?.label : 'No Answer';
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <Box className={styles.summaryContainer}>{generateContent()}</Box>
    </Modal>
  );
};

export default ReviewQuizModal;

const useStyles = makeStyles((theme) => ({
  summaryContainer: {
    margin: '2rem 0',
    [theme.breakpoints.down('sm')]: {
      margin: '1rem 0',
    },
  },

  questionTitle: {
    fontWeight: 'bold !important',
  },

  answerBox: {
    margin: '0.5rem',
    padding: '0.5rem',
    borderRadius: '20px',
    border: '2px #3F8ED7 solid',
  },
}));
