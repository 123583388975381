import { fromJS } from 'immutable';

import {
  APPEND_ANSWER,
  SET_QUESTION_INDEX,
  SET_QUESTION,
  FETCH_ERROR,
  FETCH_END,
  FETCH_PORTFOLIO_OK,
  POST_CUSTOM_OK,
  SET_PORTFOLIO_DATA,
  FETCH_PORTFOLIO_DATA,
  POST_CUSTOM_PORTFOLIO,
  SET_CUSTOM_PORTFOLIO,
  GET_USER_PORTFOLIO,
  ASSIGN_USER_PORTFOLIO,
  SET_IS_CUSTOM,
  SAVE_TICKERS,
  GET_USER_SETTINGS,
  GET_USER_SETTINGS_OK,
} from '../constants/profile.constant';

const quizData = [
  {
    id: 1,
    question: 'In general, how would your best friend describe you as a risk taker?',
    choices: [
      {
        choiceName: 'A real gambler',
        score: 4,
      },
      {
        choiceName: 'Willing to take risks after completing adequate research',
        score: 3,
      },
      {
        choiceName: 'Cautious',
        score: 2,
      },
      {
        choiceName: 'A real risk avoider',
        score: 1,
      },
    ],
  },

  {
    id: 2,
    question:
      'You are on a TV game show and can choose one of the following.  Which would you take?',
    choices: [
      {
        choiceName: '$1,000 in cash',
        score: 1,
      },
      {
        choiceName: 'A 50% chance at winning $5,000',
        score: 2,
      },
      {
        choiceName: 'A 25% chance at winning $10,000',
        score: 3,
      },
      {
        choiceName: 'A 5% chance at winning $100,000',
        score: 4,
      },
    ],
  },

  {
    id: 3,
    question: `You have just finished saving for a “once-in-a-lifetime” vacation.  Three weeks before you plan to 
      leave, you lose your job. You would:`,
    choices: [
      {
        choiceName: 'Cancel the vacation',
        score: 1,
      },
      {
        choiceName: 'Take a much more modest vacation ',
        score: 2,
      },
      {
        choiceName: 'Go as scheduled, reasoning that you need the time to prepare for a job search',
        score: 3,
      },
      {
        choiceName:
          'Extend your vacation, because this might be your last chance to go first-class',
        score: 4,
      },
    ],
  },

  {
    id: 4,
    question: 'If you unexpectedly received $20,000 to invest, what would you do?',
    choices: [
      {
        choiceName: 'Deposit it in a bank account, money market account, or an insured CD',
        score: 1,
      },
      {
        choiceName: 'Invest it in safe high quality bonds or bond mutual funds',
        score: 2,
      },
      {
        choiceName: 'Invest it in stocks or stock mutual funds ',
        score: 3,
      },
    ],
  },

  {
    id: 5,
    question:
      'In terms of experience, how comfortable are you investing in stocks or stock mutual funds?',
    choices: [
      {
        choiceName: 'Not at all comfortable',
        score: 1,
      },
      {
        choiceName: 'Somewhat comfortable',
        score: 2,
      },
      {
        choiceName: 'Very comfortable',
        score: 3,
      },
    ],
  },

  {
    id: 6,
    question: 'When you think of the word “risk” which of the following words comes to mind first?',
    choices: [
      {
        choiceName: 'Loss',
        score: 1,
      },
      {
        choiceName: 'Uncertainty',
        score: 2,
      },
      {
        choiceName: 'Opportunity',
        score: 3,
      },
      {
        choiceName: 'Thrill',
        score: 4,
      },
    ],
  },

  {
    id: 7,
    question: `Some experts are predicting prices of assets such as gold, jewels, collectibles, and real estate (hard 
      assets) to increase in value; bond prices may fall, however, experts tend to agree that government 
      bonds are relatively safe.  Most of your investment assets are now in high interest government bonds.  
      What would you do?`,
    choices: [
      {
        choiceName: 'Hold the bonds',
        score: 1,
      },
      {
        choiceName:
          'Sell the bonds, put half the proceeds into money market accounts, and the other half into hard assets',
        score: 2,
      },
      {
        choiceName: 'Sell the bonds and put the total proceeds into hard assets',
        score: 3,
      },
      {
        choiceName:
          'Sell the bonds, put all the money into hard assets, and borrow additional money to buy more',
        score: 4,
      },
    ],
  },

  {
    id: 8,
    question:
      'Given the best and worst case returns of the four investment choices below, which would you prefer?',
    choices: [
      {
        choiceName: '$200 gain best case; $0 gain/loss worst case',
        score: 1,
      },
      {
        choiceName: '$800 gain best case; $200 loss worst case',
        score: 2,
      },
      {
        choiceName: '$2,600 gain best case; $800 loss worst case',
        score: 3,
      },
      {
        choiceName: '$4,800 gain best case; $2,400 loss worst case',
        score: 4,
      },
    ],
  },

  {
    id: 9,
    question:
      'In addition to whatever you own, you have been given $1,000.  You are now asked to choose between:',
    choices: [
      {
        choiceName: 'A sure gain of $500',
        score: 1,
      },
      {
        choiceName: 'A 50% chance to gain $1,000 and a 50% chance to gain nothing',
        score: 3,
      },
    ],
  },

  {
    id: 10,
    question:
      'In addition to whatever you own, you have been given $2,000.  You are now asked to choose between:',
    choices: [
      {
        choiceName: 'A sure loss of $500',
        score: 1,
      },
      {
        choiceName: 'A 50% chance to lose $1,000 and a 50% chance to lose nothing',
        score: 3,
      },
    ],
  },

  {
    id: 11,
    question:
      'Suppose a relative left you an inheritance of $100,000, stipulating in the will that you invest ALL the money in ONE of the following choices.  Which one would you select? ',
    choices: [
      {
        choiceName: 'A savings account or money market mutual fund',
        score: 1,
      },
      {
        choiceName: 'A mutual fund that owns stocks and bonds',
        score: 2,
      },
      {
        choiceName: 'A portfolio of 15 common stocks',
        score: 3,
      },
      {
        choiceName: 'Commodities like gold, silver, and oil',
        score: 4,
      },
    ],
  },

  {
    id: 12,
    question:
      'If you had to invest $20,000, which of the following investment choices would you find most appealing?',
    choices: [
      {
        choiceName:
          '60% in low-risk investments 30% in medium-risk investments 10% in high-risk investments',
        score: 1,
      },
      {
        choiceName:
          '30% in low-risk investments 40% in medium-risk investments 30% in high-risk investments',
        score: 2,
      },
      {
        choiceName:
          '10% in low-risk investments 40% in medium-risk investments 50% in high-risk investments',
        score: 3,
      },
    ],
  },

  {
    id: 13,
    question: `Your trusted friend and neighbor, an experienced geologist, is putting together a group of investors to 
      fund an exploratory gold mining venture. The venture could pay back 50 to 100 times the investment 
      if successful.  If the mine is a bust, the entire investment is worthless.  Your friend estimates the 
      chance of success is only 20%.  If you had the money, how much would you invest?`,
    choices: [
      {
        choiceName: 'Nothing',
        score: 1,
      },
      {
        choiceName: 'One month’s salary',
        score: 2,
      },
      {
        choiceName: 'Three month’s salary ',
        score: 3,
      },
      {
        choiceName: 'Six month’s salary',
        score: 4,
      },
    ],
  },
];

const initial_state = fromJS({
  currentQuestion: quizData[0],
  questionNumber: 0,
  answerList: [],
  quizLength: quizData.length,
  quizData: quizData,
  showModal: true,
  currentPortfolioData: [],
  customData: {},
  loading: false,
  error: {},
  isError: false,
  userPortfolio: null,
  isCustom: false,
  dates: {},
  tickers: [],
  userDetails: {},
  equities: {
    title: 'Equities',
    fields: [
      { label: 'S&P 500 Index', value: 0, type: 'SPY' },
      { label: 'Dow Jones Industrial Average Index', value: 0, type: 'DIA' },
      { label: 'Nasdaq Index', value: 0, type: 'QQQ' },
      { label: 'Small Cap Growth', value: 0, type: 'IWM' },
      { label: 'Small Cap Value', value: 0, type: 'IJS' },
      { label: 'Utilities', value: 0, type: 'XLU' },
      { label: 'Consumer Staples', value: 0, type: 'XLP' },
      { label: 'Real Estate', value: 0, type: 'XLRE' },
      { label: 'Health Care', value: 0, type: 'XLV' },
      { label: 'Energy', value: 0, type: 'XLE' },
      { label: 'Financial Services', value: 0, type: 'XLF' },
      { label: 'Communication Services', value: 0, type: 'XLC' },
      { label: 'Industrials', value: 0, type: 'XLI' },
      { label: 'Materials', value: 0, type: 'XLB' },
      { label: 'Consumer Discretionary', value: 0, type: 'XLY' },
      { label: 'Technology', value: 0, type: 'XLK' },
      { label: 'Large Cap Value', value: 0, type: 'IWD' },
      { label: 'Large Cap Growth', value: 0, type: 'IWF' },
      { label: 'International Developed', value: 0, type: 'EFA' },
      { label: 'Emerging Markets', value: 0, type: 'EEM' },
    ],
  },
  equitiesAlternative: {
    title: 'Equity Alternatives',
    fields: [
      { label: 'Equity Market Neutral (IQ Hedge)', value: 0, type: 'QMN' },
      { label: 'Equity Market Neutral (AGFiQ)', value: 0, type: 'BTAL' },
      { label: 'Equity Market Neutral (AQR)', value: 0, type: 'QMNIX' },
      { label: 'Equity Market Neutral (Vanguard)', value: 0, type: 'VMNIX' },
      { label: 'Multialternative (Grant Park)', value: 0, type: 'GPAIX' },
      { label: 'Options Trading', value: 0, type: 'CWXIX' },
      { label: 'Options Trading - S&P 500 Covered Call', value: 0, type: 'GATEX' },
      { label: 'Options Trading - BuyWrite', value: 0, type: 'FTHI' },
      { label: 'Long-Short Equity (AQR)', value: 0, type: 'QLEIX' },
      { label: 'Long-Short Equity (Boston Partners)', value: 0, type: 'BGLSX' },
      { label: 'Long-Short Equity (Marketfield)', value: 0, type: 'MFLDX' },
      { label: 'Tactical Allocation (Arrow DWA)', value: 0, type: 'DWTNX' },
      { label: 'Managed Futures (AlphaSimplex)', value: 0, type: 'ASFYX' },
      { label: 'Managed Futures (KFA)', value: 0, type: 'KMLM' },
    ],
  },
  bonds: {
    title: 'Bonds',
    fields: [
      { label: 'U.S. Total Bond Market.', value: 0, type: 'AGG' },
      { label: 'Inflation-Protected Government Bonds', value: 0, type: 'STIP' },
      { label: '20-Year U.S. Government Bonds', value: 0, type: 'TLT' },
      { label: 'U.S. Municipal Bonds', value: 0, type: 'MUB' },
      { label: 'Bank Loans', value: 0, type: 'BLKN' },
      { label: 'Short-term Corporate Bonds', value: 0, type: 'VCSH' },
      { label: 'Investment Grade Corporate Bonds', value: 0, type: 'LQD' },
      { label: 'High Yield Corporate Bonds', value: 0, type: 'HYG' },
      { label: 'Emerging Markets Government Bonds', value: 0, type: 'PCY' },
    ],
  },
  bondsAlternative: {
    title: 'Bonds Alternative',
    fields: [
      { label: 'Chevron', value: 0, type: 'CVX' },
      { label: 'Google', value: 0, type: 'GOOG' },
      { label: 'Microsoft', value: 0, type: 'MSFT' },
      { label: 'Mortgage Backed Securities', value: 0, type: 'MBB' },
      { label: 'Preferred Stock/Convertible Debt', value: 0, type: 'PFF' },
      { label: 'Nontraditional Bond', value: 0, type: 'RPIEX' },
      { label: 'Interest Rate Hedge ETF', value: 0, type: 'PFIX' },
      { label: 'Floating Rate Treasuries', value: 0, type: 'USFR' },
      { label: 'Long-Short Credit', value: 0, type: 'LBAY' },
    ],
  },
  diversifiers: {
    title: 'Diversifiers',
    fields: [
      { label: 'REIT', value: 0, type: 'VNQ' },
      { label: 'Gold', value: 0, type: 'GLD' },
      { label: 'Silver', value: 0, type: 'SLV' },
      { label: 'Natural Gas', value: 0, type: 'UNG' },
      { label: 'Solar', value: 0, type: 'TAN' },
      { label: 'Commodities', value: 0, type: 'DBC' },
      { label: 'Bitcoin', value: 0, type: 'BTC-USD' },
      { label: 'CTA', value: 0, type: 'WTMF' },
      { label: 'Cash', value: 0, type: 'VGSH' },
    ],
  },
  cashData: {
    title: 'Currency',
    fields: [
      { label: 'US Dollar', value: 0, type: 'USDU' },
      { label: 'Euro', value: 0, type: 'FXE' },
      { label: 'Swiss Franc', value: 0, type: 'FXF' },
      { label: 'Bitcoin/USD', value: 0, type: 'BTCUSD' },
      { label: 'Ethureum/USD', value: 0, type: 'ETHUSD' },
      { label: 'Binance Coin/USD', value: 0, type: 'BNBUSD' },
      { label: 'XRP/USD', value: 0, type: 'XRPUSD' },
      { label: 'Solana/USD', value: 0, type: 'SOLUSD' },
      { label: 'Cardano/USD', value: 0, type: 'ADAUSD' },
    ],
  },
  managedFuture: {
    title: 'Managed Future',
    fields: [
      { label: 'CTA', value: 0, type: 'WTMF' },
      { label: 'Gold', value: 0, type: 'AMFAX' },
    ],
  },
  commodities: {
    title: 'Commodities',
    fields: [
      { label: 'Bloomberg Diversified Commodity Index', value: 0, type: 'DJP' },
      { label: 'Bloomberg Agriculture Index', value: 0, type: 'JJA' },
      { label: 'Oil', value: 0, type: 'DBO' },
      { label: 'Industrial Metals', value: 0, type: 'DBB' },
      { label: 'Gold', value: 0, type: 'GLD' },
      { label: 'Silver', value: 0, type: 'SLV' },
    ],
  },
  blockchain: {
    title: 'Blockchain',
    fields: [
      { label: 'Bitcoin', value: 0, type: 'BTC' },
      { label: 'Ethereum', value: 0, type: 'ETH' },
      { label: 'Dogecoin', value: 0, type: 'DOGE' },
      { label: 'Ripple', value: 0, type: 'XRP' },
      { label: 'Litecoin', value: 0, type: 'LTC' },
      { label: 'Bitcoin Cash', value: 0, type: 'BCH' },
      { label: 'Binance Coin', value: 0, type: 'BNB' },
      { label: 'ApeCoin', value: 0, type: 'APE' },
      { label: 'Solana', value: 0, type: 'SOL' },
      { label: 'Cardano', value: 0, type: 'ADA' },
    ],
  },
});

const profileReducer = (state = initial_state, action) => {
  switch (action.type) {
    case APPEND_ANSWER:
      return state.set('answerList', action.params);

    case SET_QUESTION_INDEX:
      return state
        .set('questionNumber', action.index)
        .set('currentQuestion', quizData[action.index]);

    case SET_PORTFOLIO_DATA:
      if (action.portfolio === 'traditional') {
        return state
          .set('equitiesData', action.value[0])
          .set('bondsData', action.value[1])
          .set('diversifiersData', action.value[2]);
      } else {
        return state
          .set('cashData', action.value[0])
          .set('managedFutureData', action.value[1])
          .set('metalsData', action.value[2])
          .set('blockchainData', action.value[3]);
      }

    case SET_QUESTION:
      return state.set('currentQuestion', action.currentQuestion);

    case FETCH_PORTFOLIO_OK:
      return state.set('currentPortfolioData', action.data);

    case SET_IS_CUSTOM:
      return state.set('isCustom', action.value);
    case FETCH_PORTFOLIO_DATA:
      return state.set('loading', true);

    case POST_CUSTOM_PORTFOLIO:
      return state.set('loading', true).set('dates', action.data).set('currentPortfolioData', []);

    case POST_CUSTOM_OK:
      return state.set('isCustom', true).set('currentPortfolioData', action);

    case GET_USER_PORTFOLIO:
      return state.set('loading', true);

    case GET_USER_SETTINGS:
      return state.set('loading', true);

    case GET_USER_SETTINGS_OK:
      return state.set('userDetails', action);

    case ASSIGN_USER_PORTFOLIO:
      return state.set('isCustom', false).set('userPortfolio', action);

    case SAVE_TICKERS:
      return state.set('tickers', action.tickers);
    case FETCH_ERROR:
      return state.set('isError', true).set('error', action?.data?.error);

    case FETCH_END:
      return state.set('loading', false);

    default:
      return state;
  }
};

export default profileReducer;
