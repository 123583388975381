import PercentIcon from '@mui/icons-material/Percent';
import { Box, Card, Container, Grid, Typography } from '@mui/material';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { differenceInDays } from 'date-fns';
import { Contained } from '../../components/Button';
import { TextInput } from '../../components/TextInput';
import { postCustomPortfolio, saveTickers } from '../../redux/actions/profile.action';
import { profileSelector } from '../../redux/reselect/profileSelector';
import useStyle from './index.style';

const Securities = ({
  securities,
  portfolio,
  providerRef,
  startDate,
  endDate,
  investmentCash,
  ...props
}) => {
  const styles = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const [cash, setCash] = useState(0);
  const [total, setTotal] = useState(0);
  const profile = useSelector(profileSelector);

  const handleTotal = (value, field) => {
    let securitiesCopy = securities;
    securitiesCopy.forEach((security, index) => {
      if (security.label === field) {
        security.value = parseInt(value);
      }
    });

    if (!isTotalValid(0)) {
      const sum = getTotal(0) + cash;
      setTotal(sum);
    } else {
      setTotal('Invalid');
      providerRef.current.enqueueSnackbar('The value you entered is not valid.', {
        variant: 'warning',
      });
    }
  };

  const dateDistance = (start, end) => {
    const result = differenceInDays(new Date(end), new Date(start));

    return result >= 15 ? true : false;
  };

  const isDatesValid = (start, end) => {
    return Date.parse(new Date(start)) <= Date.parse(new Date(end));
  };

  const getTotal = (initial) => {
    let sum = securities.reduce((previousValue, currentValue) => {
      return previousValue + parseFloat(currentValue.value);
    }, initial);
    return sum;
  };

  const isTotalValid = (initial) => {
    return isNaN(parseInt(getTotal(initial)));
  };

  const totalStyles = () => {
    return total !== 100 ? styles.errorTextFocus : styles.textFocus;
  };

  const handleCash = (value) => {
    const convertedValue = parseInt(value);
    if (!isTotalValid(convertedValue)) {
      setTotal(getTotal(convertedValue));
      setCash(parseInt(convertedValue));
    } else {
      setTotal('Invalid');
      providerRef.current.enqueueSnackbar('The value you entered is not valid.', {
        variant: 'warning',
      });
    }
  };

  const handleAnalyze = () => {
    if (isDatesValid(startDate, endDate)) {
      if (dateDistance(startDate, endDate)) {
        if (!(total >= 95 && total <= 100)) {
          providerRef.current.enqueueSnackbar(
            'Total must be greater than or equal to 95% and less than or equal to 100%',
            {
              variant: 'warning',
            }
          );
        } else {
          let payload = {
            allocation: [],
          };
          let data = {};

          portfolio.forEach((element) => {
            data = {
              class: element.title,
              assets: securities
                .filter((security, index) => {
                  const isTrue = element.fields.some((field) => {
                    return field.type === security.type;
                  });
                  return isTrue;
                })
                .map((item) => {
                  return { symbol: item.type, weights: parseInt(item.value) };
                }),
            };
            if (data.assets.length > 0) {
              payload.allocation.push(data);
            }
          });
          data = {
            class: 'Cash',
            assets: [{ symbol: 'VTIP', weights: cash.toFixed(2) }],
          };
          payload.allocation.push(data);
          dispatch(
            postCustomPortfolio(payload, {
              startDate: startDate,
              endDate: endDate,
              amount: investmentCash,
            })
          );
          // dispatch(saveTickers([]));
          history.push({ pathname: `/app/risk-profile` });
        }
      } else {
        providerRef.current.enqueueSnackbar(
          'The distance between Start and End date must be 15 days or more',
          { variant: 'warning' }
        );
      }
    } else {
      providerRef.current.enqueueSnackbar('End date must be greater than or equal to Start date.', {
        variant: 'warning',
      });
    }
  };

  const loadSecurities = () => {
    return (
      <Container className={styles.fields}>
        <Typography className={styles.textFocus}>Portfolio</Typography>
        {securities.map((data, index) => (
          <Grid container>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              <Box className={styles.centerContent} mt={1}>
                <Typography variant="body1">{data.label}</Typography>
              </Box>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} ml={2}>
              <Box display="flex" justifyContent="center" className={styles.fields}>
                <TextInput
                  value={data.value}
                  setValue={handleTotal}
                  field={data.label}
                  inputMode="numeric"
                  icon={<PercentIcon />}
                />
              </Box>
            </Grid>
          </Grid>
        ))}
      </Container>
    );
  };

  useEffect(() => {
    setTotal(getTotal(0));
  }, []);

  useEffect(() => {
    // const isValid = securities.some((security) => {
    //   return parseInt(security.value) > 0;
    // });
    setTotal(getTotal(cash));
    dispatch(saveTickers(securities));
  }, [securities]);

  return (
    <Card className={styles.cardContainer}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ml={12}>
        {loadSecurities()}
        <Container>
          <Typography className={styles.textFocus}>Cash</Typography>
          <Grid container>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              <Box className={styles.centerContent} mt={1}>
                <Typography variant="body1">Cash</Typography>
              </Box>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} ml={2}>
              <Box className={styles.fields} display="flex" justifyContent="center">
                <TextInput
                  defaultValue={0}
                  setValue={handleCash}
                  inputMode="numeric"
                  icon={<PercentIcon />}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Grid container display="flex" justifyContent="space-around">
            <Grid item>
              <Box className={styles.centerContent} mt={1}>
                <Typography className={styles.textFocus}>Total</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box
                className={styles.centerContent}
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={1}
              >
                <Typography className={totalStyles()}>
                  {total} {!isTotalValid(0) ? '%' : ''}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Box display="flex" justifyContent="center" mt={2}>
        <Contained onClick={handleAnalyze} rounded>
          Analyze Portfolio
        </Contained>
      </Box>
    </Card>
  );
};

export default Securities;
