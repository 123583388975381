import {
  DAILY_STOCK_PRICES_ACTION,
  EDIT_DATA_ACTION,
  RISK_INDICATOR_ACTION,
  SEARCH_STOCK_ACTION,
  STOCK_PRICES_ACTION,
} from '../constants/stock.constant';

export const getDailyStockPrices = (params) => ({ type: DAILY_STOCK_PRICES_ACTION, params });
export const getStockPricesAction = (market, timeFrame, params) => ({
  type: STOCK_PRICES_ACTION,
  market,
  timeFrame,
  params,
});
export const searchStocksAction = (params, from) => ({ type: SEARCH_STOCK_ACTION, params, from });
export const editDataAction = (key, value) => ({ type: EDIT_DATA_ACTION, key, value });
export const riskIndicatorAction = (timeFrame) => ({ type: RISK_INDICATOR_ACTION, timeFrame });
