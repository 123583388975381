// const THRESHOLD = {
//   low: 30,
//   medium: 70,
//   high: 100,
// };

export const CONFIG_SMALL = {
  HEIGHT: 200,
  WIDTH: 150,
  OUTER_RADIUS: 60,
  INNER_RADIUS: 52,
  NEEDLE_LENGTH: 30,
  LINE_Y1: 55,
  LINE_Y2: 50,
};

export const CONFIG_LARGE = {
  HEIGHT: 200,
  WIDTH: 300,
  OUTER_RADIUS: 100,
  INNER_RADIUS: 90,
  NEEDLE_LENGTH: 75,
  LINE_Y1: 10,
  LINE_Y2: 15,
};

export const TYPE_SELL = 1;
export const TYPE_BUY = 2;

export const TREND_STR = 1;
export const TREND_DIP = 2;
export const SCALP = 'scalp';
export const ACTIVE_INTRADAY = 'activeIntraday';
export const INTRADAY = 'intraday';
export const INTRA_SWING = 'intraSwing';
export const ACTIVE_SWING = 'activeSwing';
export const SWING = 'swing';
export const INVESTOR = 'investor';
export const HOURLY = 'hourly';
export const DAILY = 'daily';
export const WEEKLY = 'weekly';
export const MONTHLY = 'monthly';
export const TIMEFRAMES = [
  {
    label: 'Scalp',
    value: SCALP,
  },
  {
    label: 'Active Intraday',
    value: ACTIVE_INTRADAY,
  },
  {
    label: 'Intraday',
    value: INTRADAY,
  },
  {
    label: 'Intra-Swing',
    value: INTRA_SWING,
  },
  {
    label: 'Active Swing',
    value: ACTIVE_SWING,
  },
  {
    label: 'Swing',
    value: SWING,
  },
  {
    label: 'Investor',
    value: INVESTOR,
  },
];
