import { makeStyles } from '@mui/styles';
import colors from '../../../styles/colors';
import { HORIZONTAL, VERTICAL } from './constants';
const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: (props) => props.direction,
    flex: 1,
    display: 'flex',
    minHeight: (props) =>
      props.height ||
      (props.direction === HORIZONTAL ? (props.inherit_container ? 'inherit' : '100px') : '14px'),
    minWidth: (props) =>
      props.width ||
      (props.direction === VERTICAL ? (props.inherit_container ? 'inherit' : '100px') : '14px'),
  },
  bar: {
    display: 'flex',
    position: 'relative',
    flex: 1,
    height: (props) =>
      props.height ||
      (props.direction === HORIZONTAL ? (props.inherit_container ? 'inherit' : '150px') : '14px'),
    width: (props) =>
      props.width ||
      (props.direction === VERTICAL ? (props.inherit_container ? 'inherit' : '150px') : '14px'),
    flexDirection: (props) => props.direction,

    '& span': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    '&:first-child': {
      backgroundColor: colors.gray4,
      borderTopRightRadius: (props) => (props.direction === HORIZONTAL ? '1rem' : 0),
      borderTopLeftRadius: '1rem',
      borderBottomLeftRadius: (props) => (props.direction === VERTICAL ? '1rem' : 0),
    },

    '&:last-child': {
      backgroundColor: colors.gray4,
      borderBottomRightRadius: '1rem',
      borderBottomLeftRadius: (props) => (props.direction === HORIZONTAL ? '1rem' : 0),
      borderTopRightRadius: (props) => (props.direction === VERTICAL ? '1rem' : 0),
    },
  },
  negative: {
    '& div:first-child': {
      flex: (props) => 100 - props.negative,
      display: 'flex',
    },

    '& div:last-child': {
      backgroundColor: colors.red3,
      flex: (props) => props.negative,
      display: 'flex',
      borderRight: (props) => props.direction === VERTICAL && 'solid white .1px',
      borderBottom: (props) => props.direction === HORIZONTAL && 'solid white .1px',
      borderRadius: '1rem',
      transition: 'all 300ms ease-out',
    },
  },
  positive: {
    '& div:first-child': {
      backgroundColor: colors.green_1,
      flex: (props) => props.positive,
      display: 'flex',
      borderLeft: (props) => props.direction === VERTICAL && 'solid white .1px',
      borderTop: (props) => props.direction === HORIZONTAL && 'solid white .1px',
      borderRadius: '1rem',
      transition: 'all 300ms ease-out',
    },

    '& div:last-child': {
      flex: (props) => 100 - props.positive,
      display: 'flex',
    },
  },

  negativeHorizontal: {
    '& div:last-child': {
      flex: (props) => 100 - props.negative,
      display: 'flex',
    },

    '& div:first-child': {
      backgroundColor: colors.red3,
      flex: (props) => props.negative,
      display: 'flex',
      borderRight: 'solid white .1px',
      borderBottom: 'solid white .1px',
      borderRadius: '1rem',
    },
  },
  positiveHorizontal: {
    '& div:last-child': {
      backgroundColor: colors.green_1,
      flex: (props) => props.positive,
      display: 'flex',
      borderTop: 'solid white .1px',
      borderRadius: '1rem',
    },

    '& div:first-child': {
      flex: (props) => 100 - props.positive,
      display: 'flex',
    },
  },
}));

export default useStyles;
