import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

const DatePicker = ({ value, setValue, ...props }) => {
  const [date, setDate] = useState(new Date());

  const handleChange = (newDate) => {
    setValue(format(newDate, 'yyyy-MM-dd'));
  };

  useEffect(() => {
    setDate(value);
  }, [value]);

  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          maxDate={new Date()}
          closeOnSelect
          label={props.label}
          inputFormat="MMM dd, yyyy"
          value={date}
          onChange={handleChange}
          onYearChange={handleChange}
          onClose={() => setDate(value)}
          renderInput={(params) => <TextField {...params} classes={{ root: classes.root }} />}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DatePicker;

export { DatePicker };

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '63px',
      display: 'flex',
      alignItems: 'center',
      background: 'linear-gradient(90.85deg, #5D5FEF -48.5%, #41E7B0 116.69%)',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },
  },
}));
