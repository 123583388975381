import { makeStyles } from '@mui/styles';
import colors from '../../../styles/colors';
const useStyles = makeStyles((theme) => ({
  releases: {
    borderRadius: '20px',
    padding: '120px 0',
    background: colors.n7,
    marginTop: '100px',
    marginBottom: 0,
    [theme.breakpoints.up('lg')]: {
      padding: '112px 0',
      marginBottom: 0,
    },
    [theme.breakpoints.down('md')]: {
      padding: '64px 0',
      margin: '6.25rem 0 0 2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 !important',
      padding: '0 !important',
    },
  },

  container: {
    width: '100%',
    maxWidth: '1280px',
    margin: '0 auto',
    padding: '0 40px',
    [theme.breakpoints.up('lg')]: {
      padding: '20px',
      marginBottom: 0,
    },
    [theme.breakpoints.down('md')]: {
      padding: '16px',
    },
  },

  head: {
    display: 'flex',
    marginBottom: '80px',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
      marginBottom: '48px',
    },
  },

  stage: {
    width: '352px',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      marginBottom: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  },

  wrap: {
    flexShrink: 0,
    width: '640px',
    marginLeft: 'auto',
    [theme.breakpoints.only('xl')]: {
      width: '500px',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },

  title: {
    marginBottom: '20px',
    fontSize: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },

  info: {
    maxWidth: '450px',
    marginBottom: '64px',
    fontSize: '16px',
    lineHeight: 'calc(24 / 16) * 1px',
    color: colors.n3,
    [theme.breakpoints.down('lg')]: {
      marginBottom: '48px',
    },
  },

  nav: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  link: {
    display: 'flex',
    padding: '6px 12px',
    borderRadius: '14px',
    background: 'none',
    fontSize: '14px',
    lineHeight: 'calc(16 / 14) * 1px',
    fontWeight: 700,
    color: colors.n4,
    transition: 'all .2s',
    '&:hover': {
      background: colors.n3,
      color: colors.n8,
    },
    '&.active': {
      background: colors.n3,
      color: colors.n8,
    },
    '&:not(:last-child)': {
      marginRight: '12px',
    },
  },

  active: {
    background: colors.n3,
    color: colors.n8,
    ':root :global(.dark-mode)': {
      background: colors.n3,
      color: colors.n8,
    },
  },

  item: {
    '&:not(:last-child)': {
      marginBottom: '80px',
    },
  },
}));

export default useStyles;
