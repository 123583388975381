import API from './API';
import qs from 'qs';

const auth = {
  register: (params) => {
    return new API().post('/user/registration/', params);
  },

  forgotPassword: (params) => {
    const data = qs.stringify({
      email: 'leo@traidify.com',
    });
    return new API().get('/user/forgot-password/', params);
  },

  createNewPassword: (params) => {
    return new API().post('/user/forgot-password/', params);
  },

  login: (credentials) => {
    return new API().post('/user/oauth/token/', credentials, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  verifyEmail: (params) => {
    return new API().get(`/user/email-verification/`, params);
  },

  resendVerifyEmail: (params) => {
    return new API().post(`/user/email-verification/`, params);
  },

  userDetails: () => {
    return new API().get(`/users/get_auth_user/`);
  },
  patch: (id, params) => {
    return new API().patch(`/users/${id}/`, params, {
      headers: { 'Content-Type': 'application/vnd.api+json' },
    });
  },
  saveUserSettings: (id, params) => {
    return new API().post(`/user/settings/?user_id=${id}`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  getUserSettings: (id) => {
    return new API().get(`/user/settings/?user_id=${id}`);
  },

  getUserPermission: () => {
    return new API().get(`/groups/`);
  },
};

export default auth;
