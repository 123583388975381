// # possible values -> d(daily/real time), w(weekly), m(monthly), y(yearly), ytd(yeartodate), qtd(quartertodate)
export const TIMEFRAME = {
  REAL_TIME: 'realtime',
  WTD: 'weektodate',
  MTD: 'monthtodate',
  QTD: 'quartertodate',
  YTD: 'yeartodate',
  OYTD: 'oneyeartodate',
};

export const TIMEFRAME_LIST = [
  {
    label: 'Real-Time',
    title: 'Real-Time',
    value: TIMEFRAME.REAL_TIME,
    format: 'MMM-DD H:mm',
  },
  {
    label: 'WTD',
    title: 'Week to Date',
    value: TIMEFRAME.WTD,
    format: 'MMM-DD H:mm',
  },
  {
    label: 'MTD',
    title: 'Month to Date',
    value: TIMEFRAME.MTD,
    format: 'MMM-DD',
  },
  {
    label: 'QTD',
    title: 'Quarter to Date',
    value: TIMEFRAME.QTD,
    format: 'MMM-DD',
  },
  {
    label: 'YTD',
    title: 'Year to Date',
    value: TIMEFRAME.YTD,
    format: 'MMM-YY',
  },
  {
    label: '1 Yr',
    title: 'One Year to Date',
    value: TIMEFRAME.OYTD,
    format: 'MMM-YY',
  },
];
