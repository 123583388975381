import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useState } from 'react';
const Select = ({
  id,
  labelId,
  value,
  placeholder,
  options,
  setValue,
  className,
  fullWidth = false,
  multiple = true,
  group,
  isChip = true,
  ...props
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelected(typeof value === 'string' ? value.split(',') : value);
  };

  const handleClick = (value, type) => {
    setValue(value, type);
  };

  return (
    <Box display="flex" justifyContent="center">
      <FormControl
        variant="outlined"
        classes={{
          root: clsx(classes.formControl, {
            [classes.fullWidth]: fullWidth === true,
          }),
        }}
      >
        <InputLabel labelId={labelId || `${id}Label`}>{placeholder}</InputLabel>
        <MuiSelect
          id="grouped-select"
          multiple={multiple}
          autoWidth
          value={selected}
          onChange={handleChange}
          label={placeholder}
          variant="standard"
          renderValue={(values) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
              {values.map((value) =>
                isChip ? (
                  <Chip key={value} label={value} />
                ) : (
                  <Typography className={classes.coloredText}>{value}</Typography>
                )
              )}
            </Box>
          )}
          {...props}
        >
          {/* <MenuItem value="" disabled>
            <Typography variant="subtitle1">{placeholder}</Typography>
          </MenuItem> */}
          {options.map(({ label, value, type }) => (
            <MenuItem key={value} value={label} onClick={() => handleClick(label, type)}>
              <Checkbox checked={selected.indexOf(label) > -1} />
              {label}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </Box>
  );
};

export default Select;

export { Select };

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: '3px',
    paddingLeft: '8px',
    marginBottom: '5px',
    '@media only screen and (min-width: 1200px)': {
      minWidth: '200px',
    },
    // maxWidth: '200px',
    [theme.breakpoints.only('xl')]: {
      minWidth: '200px',
      // maxWidth: '200px',
    },
    [theme.breakpoints.only('lg')]: {
      minWidth: '210px',
      // maxWidth: '200px',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '280px',
      // maxWidth: '200px',
    },
  },

  coloredText: {
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '1.2rem',
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(90.85deg, #5D5FEF -48.5%, #41E7B0 116.69%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  fullWidth: {
    width: '100%',
  },
}));
