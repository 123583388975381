import { Hidden } from '@mui/material';
import Desktop from './Desktop';

const Footer = (props) => {
  return (
    <Hidden smDown>
      <Desktop />
    </Hidden>
  );
};

export default Footer;
