import React from 'react';
import Faq from './Faq';
import Question from './Question';

import { Container } from '@mui/material';
import Header from '../../components/Header';
import useStyle from './index.style';

const Home = () => {
  const classes = useStyle();

  return (
    <Container className={classes.container}>
      <Header />
      {/* <Main /> */}
      {/* <Education /> */}
      <Faq />
      <Question />
      {/* <Footer /> */}
    </Container>
  );
};

export default Home;
