import { makeStyles } from '@mui/styles';
import bg from '../../assets/images/portfolio_management/bg.png';
const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    width: '100%',
    maxWidth: '1700px',
    position: 'relative',
  },

  content: {
    background: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    paddingTop: '50px',
    marginTop: '3rem',
    borderRadius: '6px',
    width: '100%',
  },

  card: {
    padding: '2em 0 4em 0',
    '&:first-child': {
      padding: '40px',
    },
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px',
    color: theme.text[4],
    fontWeight: 'bold',
    textAlign: 'center',
    background: theme.palette.gradients.blue_linear,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  titleContainer: {
    fontWeight: 'bold',
    margin: '1em 0',
  },
  cardContainer: {
    padding: '0 8px',
    marginBottom: '1em',
    cursor: 'pointer',
    display: 'flex',
    '& .MuiCardActions-root': {
      // marginTop: "20px",
      justifyContent: 'center',
    },
    '&:first-child': {},
  },

  cardActions: {
    justifyContent: 'center',
  },

  videoThumbnail: {
    position: 'relative',
    height: '250px',

    '& .playBtn': {},
  },

  playBtn: {
    display: 'none',

    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },

  title: {
    fontWeight: 800,
  },
}));

export default useStyles;
