import { takeEvery, fork, put, call } from 'redux-saga/effects';
import {
  RETRIEVE_TICKER_ACTION,
  RETRIEVE_TICKER_OK,
  RETRIEVE_TICKER_ERROR,
  RETRIEVE_TICKER_END,
} from '../constants/save_tickers.constant';

import save_tickers from '../../services/save_tickers';

export function* retrieveTicker(action) {
  try {
    const xhr = yield call(save_tickers.searchTicker, action.ticker);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: RETRIEVE_TICKER_OK, ...xhr.data });
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;

    yield put({ type: RETRIEVE_TICKER_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_TICKER_END });
  }
}

export function* retrieveTickerSaga() {
  yield takeEvery(RETRIEVE_TICKER_ACTION, retrieveTicker);
}

const save_tickers_sagas = [fork(retrieveTickerSaga)];
export default save_tickers_sagas;
