import { Box, Container } from '@mui/material';
import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Card.module.sass';

const Card = ({ className, item }) => {
  return (
    <Container className={cn(className, styles.card)}>
      <Box className={styles.preview}>
        <img srcSet={`${item.image2x} 2x`} src={item.image} alt="Card" />
      </Box>
      <Box className={styles.title}>{item.title}</Box>
      <Box className={styles.content}>{item.content}</Box>
      <Link className={cn('button-stroke button-small', styles.button)} to={item.url}>
        {item.button}
      </Link>
    </Container>
  );
};

export default Card;
