import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, Container, Grid, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { BubbleChart } from '../../Graph';
import useStyle from './index.style';

const BubbleChartDetails = (props) => {
  const styles = useStyle();

  return (
    <Container>
      <Grid container alignItems="center" justifyContent="center">
        <Typography className={styles.title} variant="h5">
          $1,549,845
        </Typography>
        <Chip icon={<ArrowUpwardIcon />} label="45" color="success" variant="outlined" />
      </Grid>
      <Box marginTop="3em" display="flex" alignItems="center" justifyContent="center">
        <BubbleChart />
      </Box>
      <Grid container spacing={2}>
        {/* <Grid item xs={4}>
          Total Users
        </Grid>
        <Grid item xs={4}>
          Active Users
        </Grid>
        <Grid item xs={4}>
          Dynamics
        </Grid> */}
      </Grid>
    </Container>
  );
};
export default BubbleChartDetails;
