import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1em',
    margin: 0,
    width: '100%',
    maxWidth: '100%',
    position: 'relative',
  },

  videoDetails: {
    padding: '1em 0.5em',
    width: '95%',
    background: theme.palette.gradients.blue_linear,
    transform: 'translateY(-10px)',
    color: theme.palette.common.white,
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    color: theme.text[4],
    fontWeight: 'bold',
    textAlign: 'center',
    background: theme.palette.gradients.blue_linear,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  selectedTopic: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.text[4],
    fontWeight: 'bold',
    background: theme.palette.gradients.blue_linear,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  notSelectedTopic: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.text[4],
    fontWeight: 400,
    background: theme.palette.gradients.blue_linear,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  paragraph: {
    marginTop: '1em',
  },

  cardContainer: {
    marginRight: '1em',
  },

  videoThumbnail: {
    position: 'relative',
    height: '250px',

    '& .playBtn': {
      '&:hover': {
        border: '2px solid red',
      },
    },
  },

  playBtn: {
    display: 'none',

    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },

  boldText: {
    fontWeight: 'bold',
  },

  video: {
    width: '95%',
    height: '550px',
    borderRadius: '20px',
  },

  videoContainer: {
    margin: '1em',
  },

  topicsContainer: {
    borderRadius: '10px',
  },

  contentContainer: {
    margin: '16px',
  },

  styledTab: {
    '& .MuiTab-wrapper': {
      display: 'contents',
      width: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },

  brainIcon: {
    marginRight: '5px',
  },

  quizText: {
    display: 'flex',
    alignItems: 'center',
    color: theme.text[4],
    fontWeight: 'bold',
    textAlign: 'center',
    background: '#21C2FD',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  italicText: {
    fontStyle: 'italic',
  },

  gradientBoldText: {
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  avatar: {
    height: '56px',
    width: '56px',
  },
  imageContainer: {
    '& img': {
      width: '90px',
      height: '80px',
      margin: '5px',
      borderRadius: '10px',
    },
  },

  iconContainer: {
    marginLeft: '4px',
  },

  quizContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '28rem',
    maxHeight: '9.5rem',
    borderRadius: '10px',

    '& .MuiTypography-root': {
      fontSize: '0.9rem',
    },
  },

  quizContent: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 10px 10px 10px',
  },

  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2 /* number of lines to show */,
    '-webkit-box-orient': 'vertical',
  },
}));

export default useStyles;
