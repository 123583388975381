import {
  EDIT_FORM_ACTION,
  SET_ERROR_ACTION,
  LOGIN_ACTION,
  LOGIN_FETCH,
  MODAL_CLOSE_ACTION,
  RESET_ACTION,
  UPDATE_METADATA_ACTION,
} from '../constants/login.constant';

export const loginAction = (params) => ({ type: LOGIN_ACTION, params });
export const loginFetch = () => ({ type: LOGIN_FETCH });
export const editFormAction = (key, value) => ({ type: EDIT_FORM_ACTION, key, value });
export const setErrorAction = (key, errors = []) => ({ type: SET_ERROR_ACTION, key, errors });
export const modalCloseAction = () => ({ type: MODAL_CLOSE_ACTION });
export const resetAction = () => ({ type: RESET_ACTION });
export const updateMetadataAction = (id, params) => ({ type: UPDATE_METADATA_ACTION, id, params });
