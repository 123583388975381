export const labels = ['Ballast', 'Growth'];

export const values = [
  [80, 20],
  [55, 45],
  [35, 65],
  [20, 80],
  [10, 90],
];

export const disclaimers = [
  [
    'Inflation-Protected Bonds (VTIP)',
    'Equity: SPY',
    'Gold: GLD',
    'Long-Short Credit:',
    'Managed Futures (ASFYX, AMFAX)',
    'Gold (GLD)',
  ],
  [
    'Corporate Bonds: VCIT',
    'Gov’t Bond: AGG',
    'US Equities: SPY',
    'International Equities: IEFA',
    'Emerging Market Equities: VWO',
  ],
  [
    'Inflation-Protected Bonds (VTIP)',
    'Managed Futures (ASFYX, AMFAX)',
    'Gold (GLD)',
    'Bitcoin (BTC-USD)',
  ],
];

export const shadesOfBlue = [
  '#d1dbe4',
  '#88a2c4',
  '#a3b7ca',
  '#7593af',
  '#476f95',
  '#194a7a',
  '#03045e',
  '#262d79',
  '#475492',
  '#677bab',
];

export const CATEGORY_TITLES_TRADITIONAL = [
  'Equity',
  'Inflation Protected Bond',
  'Alternative Investments',
  'Managed Futures',
  'Equity Market Neutral',
];

export const CATEGORY_TITLES_BITCOIN = ['Cash', 'Managed Futures', 'Precious Metals', 'Blockchain'];

export const PERFORMANCE_RISK_RETURN = [
  'Annualized Return',
  'Annualized Std',
  'Maximum Drawdown',
  'Maximum Drawdown Duration',
];

export const PERFORMANCE_RATIOS = [
  'Sharpe Ratio',
  'Calmar Ratio',
  'Sortino Ratio',
  'Kelly Criterion',
];

export const CHART_COLORS = [
  '#00deff',
  '#ff0000',
  '#0f3bfb',
  '#30ff4f',
  '#9e00e9',
  '#43d200',
  '#0058ff',
  '#a8fd54',
  '#561bb5',
  '#94e943',
  '#000086',
  '#fbd900',
  '#1c0d8c',
  '#ffd800',
  '#0048ce',
  '#00ff99',
  '#001485',
  '#b3fe80',
  '#0037b1',
  '#009600',
  '#e272ff',
  '#009c31',
  '#fa0088',
  '#007e00',
  '#ab2e9e',
  '#86a700',
  '#008eff',
  '#ff0000',
  '#00ffff',
  '#ff3900',
  '#00ffff',
  '#fa0072',
  '#2ad89a',
  '#f80070',
  '#00ffff',
  '#9f0000',
  '#00ffff',
  '#890000',
  '#00ffff',
  '#ff8a00',
  '#004fa5',
  '#ff9c00',
  '#0072c6',
  '#ffbd37',
  '#00205c',
  '#fff595',
  '#1d0027',
  '#c5ffd1',
  '#920060',
  '#007600',
  '#ab76cb',
  '#006700',
  '#a55091',
  '#007e2b',
  '#eeb1e9',
  '#003f00',
  '#13afff',
  '#d45c00',
  '#00b3ff',
  '#bf7400',
  '#007ed0',
  '#b08d00',
  '#0062ad',
  '#8f970e',
  '#003768',
  '#cb8827',
  '#00cbff',
  '#7c0800',
  '#00f3ff',
  '#460000',
  '#87e2a3',
  '#0b0f1f',
  '#ffe2ff',
  '#003b00',
  '#ffe2ff',
  '#003600',
  '#fae2ff',
  '#002e00',
  '#d7c5e6',
  '#003000',
  '#8bcbfc',
  '#6b8400',
  '#9ee6fd',
  '#2b0c00',
  '#00cbdc',
  '#472101',
  '#7ad3ac',
  '#d27583',
  '#00a766',
  '#6b5600',
  '#009eac',
  '#002610',
  '#9cafa2',
  '#003e14',
  '#00976a',
  '#345059',
  '#009159',
  '#005c62',
  '#006224',
  '#006d4b',
];
