import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',

    [theme.breakpoints.down('sm')]: {
      fontSize: '26.47px',
    },
  },
  title2: {
    color: '#5d5fef',
    fontWeight: 700,
    lineHeight: '63px',
  },

  title3: {
    color: '#3D94EC',
    fontWeight: 800,
  },

  title4: {
    color: theme.palette.white,
    fontWeight: 'bold',
    marginTop: '32px',
    marginLeft: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      marginTop: '16px',
      marginLeft: '16px',
    },
  },

  listContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: '30px 15px',

      '& > div': {
        margin: '15px',
      },
    },
  },
  gradientContainer: {
    width: '100%',
    // maxWidth: "700px",
    height: '350px',
    left: '100px',
    top: '415px',

    /* blue&pink */
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(103.48deg, #5D5FEF 0.56%, #EF5DA8 35.81%)',
    boxShadow: '7px 10px 13px -0.971651px rgba(0, 0, 0, 0.2)',
    borderRadius: '40px',
    margin: '3rem 0',

    [theme.breakpoints.only('sm')]: {
      // flexDirection: 'column',
      borderRadius: '20px',
      margin: '3rem',
      height: 'auto',
      width: 'auto',
    },
    [theme.breakpoints.only('xs')]: {
      height: 'auto',
      borderRadius: '10px',
    },
  },

  gradientContainer2: {
    width: '100%',
    minHeight: '350px',
    left: '100px',
    top: '415px',

    /* blue&pink */
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(96.48deg, #5D5FEF -9.74%, #41E7B0 103.84%)',
    boxShadow: '7px 10px 13px -0.971651px rgba(0, 0, 0, 0.2)',
    borderRadius: '40px',
    margin: '3rem 0',
  },

  socialContainer: {
    display: 'flex',
    margin: '15px 0',
    flex: '1 1 auto',
    justifyContent: 'center',

    '& :nth-child(2)': {
      // background: "linear-gradient(96.48deg, #5D5FEF -9.74%, #41E7B0 103.84%)",
    },
  },

  MSFTCard: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '16.5432px',
    margin: '0 5rem',
    padding: '1rem 1.5rem',

    '& img': {
      padding: '0.2rem',
      margin: '1rem',
      borderRadius: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: '5.85772px',
      padding: '1.1rem',
      margin: '1.5rem',
      '& .MuiTypography-root': {
        fontSize: '16.3px',
        fontWeight: 700,
        lineHeight: '22.42px',
      },
      '& img': {
        width: '71.67px',
        height: 'auto',
        margin: '0.2rem 1rem',
      },
    },
    [theme.breakpoints.only('sm')]: {
      padding: '2rem',
      margin: '2rem',
      '& .MuiTypography-root': {
        fontSize: '2rem',
        lineHeight: '43px',
      },
      '& img': {
        width: '150px',
        height: 'auto',
        margin: '0.2rem 1rem',
      },
    },
    [theme.breakpoints.only('md')]: {
      padding: '0.7rem 2.5rem',
      '& .MuiTypography-root': {
        fontSize: '2rem',
        lineHeight: '53px',
      },
      '& img': {
        width: '150px',
        height: 'auto',
        margin: '0.5rem 1rem',
      },
    },
    [theme.breakpoints.up('lg')]: {
      padding: '1rem 3rem',
      '& .MuiTypography-root': {
        fontSize: '2.2rem',
        lineHeight: '43px',
      },
      '& img': {
        width: '200px',
        height: 'auto',
        margin: '0.2rem 1rem',
      },
    },
  },

  MSFTDetails: {
    // position: "absolute",
    width: '100%',
    flexGrow: 1,
    height: '100%',
    padding: '2rem 3rem',
    borderRadius: '40px',
    boxShadow:
      '-3.25956px 0px 3.25956px rgba(0, 0, 0, 0.15), 1.90141px 2.7163px 3.53118px -0.263929px rgba(0, 0, 0, 0.2)',

    [theme.breakpoints.only('sm')]: {
      width: 'auto',
      height: 'auto',
      padding: '4rem',
      borderRadius: '20px',
      '& .MuiTypography-h6': {
        fontSize: '15.85px',
      },
      '& .MuiTypography-h4': {
        fontSize: '24.53px',
      },
      '& #total': {
        paddingBottom: '1rem',
        '& #total-value': {
          marginTop: '12px',
        },
      },
    },

    [theme.breakpoints.only('xs')]: {
      width: 'auto',
      height: 'auto',
      padding: '1rem',
      borderRadius: '10px',
      '& .MuiTypography-h6': {
        fontSize: '10.85px',
      },
      '& .MuiTypography-h4': {
        fontSize: '19.53px',
      },
      '& #total': {
        paddingBottom: '1rem',
        '& #total-value': {
          marginTop: '12px',
        },
      },
    },
  },

  autoSearch: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiInputBase-input': {
        height: '0.3876em !important',
        fontSize: '15.48px',
      },
    },
  },
}));

export default useStyles;
