import { fromJS } from 'immutable';
import { ERROR, SUCCESS } from '../../constants';
import {
  EDIT_FORM_ACTION,
  SET_ERROR_ACTION,
  PAYMENTS_ACTION,
  PAYMENTS_OK,
  PAYMENTS_ERROR,
  PAYMENTS_END,
  RESET_ACTION,
  TOGGLE_PAYMENT_DETAILS_MODAL,
  TOGGLE_STRIPE_MODAL,
  FOCUS_ACTION,
} from '../constants/payments.constant';

const initial_state = fromJS({
  form: {
    email: '',
    number: '',
    name: '',
    expiry: '',
    cvc: '',
    focused: '',
  },
  errors: {
    email: [],
    number: [],
    name: [],
    expiry: [],
    cvc: [],
  },
  loading: false,
  notify: '',
  message: '',
  showPaymentDetailsModal: false,
  stripeModal: false,
});

const paymentsReducer = (state = initial_state, action) => {
  let levels = undefined;
  switch (action.type) {
    case EDIT_FORM_ACTION:
      levels = action.key.split('.');
      return state.setIn(['form', ...levels], action.value).setIn(['errors', ...levels], []);

    case SET_ERROR_ACTION:
      levels = action.key.split('.');
      return state.setIn(['errors', ...levels], action.errors);

    // payments start
    case PAYMENTS_ACTION:
      return state.set('loading', true);
    case PAYMENTS_OK:
      return state.set('notify', SUCCESS).set('successModal', true);
    case PAYMENTS_ERROR:
      return state.set('errors', action.data).set('notify', ERROR);
    case PAYMENTS_END:
      return state.set('notify', '').set('loading', false);
    // payments end

    case RESET_ACTION:
      return initial_state;
    // payments end

    case FOCUS_ACTION:
      return state.set('focused', action.value);

    case TOGGLE_PAYMENT_DETAILS_MODAL:
      return state.set('showPaymentDetailsModal', action.value);

    case TOGGLE_STRIPE_MODAL:
      return state.set('stripeModal', action.value);

    default:
      return state;
  }
};

export default paymentsReducer;
