import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { stripeSelector } from '../../redux/reselect/stripeSelector';
import { Contained, Outlined } from '../Button';
import SaveTickers from '../SaveTickers';

import { toggleSaveTickerModal } from '../../redux/actions/stripe.action';

const SubscriptionStatus = ({ isWhite, isBold, justify, align }) => {
  const { saveTickerModal, subscriptions } = useSelector(stripeSelector);
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = () => {
    history.push({ pathname: `/app/offers` });
  };

  const message = () => {
    return subscriptions[0]?.plan?.product?.name === 'Best'
      ? null
      : 'Do you want to upgrade your plan?';
  };

  const buttonLabel = () => {
    const plan = subscriptions[0]?.plan?.product?.name;
    const anotherPlan = plan === 'Best' ? 'Change Plan' : 'Upgrade';
    return subscriptions.length === 0 ? 'Subscribe' : anotherPlan;
  };

  const subscriptionLabel = () => {
    const plan = subscriptions[0]?.plan?.product?.name;
    if (plan === 'Better') {
      return 'Pro plan';
    } else if (plan === 'Best') {
      return 'Premium plan';
    } else {
      return plan;
    }
  };

  const openModal = () => {
    dispatch(toggleSaveTickerModal(true));
  };
  const closeModal = () => {
    dispatch(toggleSaveTickerModal(false));
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={justify}
      alignItems={align}
      className={cn({ [styles.white]: isWhite })}
    >
      {subscriptions.length === 0 ? (
        <Box mt={1}>
          <Typography variant="body1">You are currently not subscribed to any plans.</Typography>
        </Box>
      ) : (
        <>
          <Typography>
            You are on{' '}
            <span className={cn(styles.title, { [styles.white]: isWhite })}>
              {subscriptionLabel()}
            </span>
            .
          </Typography>
          <Typography>{message()}</Typography>
        </>
      )}
      <Box display="flex" justifyContent={align} mt={1} width={300}>
        <Contained small rounded onClick={() => handleClick()}>
          {buttonLabel()}
        </Contained>
        {(subscriptions[0]?.plan?.product?.name === '5 Ticker Plan' ||
          subscriptions[0]?.plan?.product?.name === '10 Ticker Plan') && (
          <Box ml={4}>
            <Outlined small rounded onClick={() => openModal()}>
              Edit Tickers
            </Outlined>
          </Box>
        )}
      </Box>
      <SaveTickers open={saveTickerModal} handleClose={closeModal} />
    </Box>
  );
};

export default SubscriptionStatus;

const useStyles = makeStyles((theme) => ({
  container: {
    '&:not(:first-child)': {
      marginTop: '48px',

      "@media only screen and (max-width: '767px')": {
        marginTop: '24px',
      },
    },
  },
  title: {
    flex: 1,
    background: 'linear-gradient(350deg, rgba(33, 82, 255, 1), rgba(33, 212, 253, 1))',
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  white: {
    color: '#FFFFFF',
    background: 'inherit',
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': '#FFFFFF',
  },
}));
