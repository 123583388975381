import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from '@mui/styles';

const Options = ({ choices = [], ...props }) => {
  const style = useStyles();

  const passOption = (option) => {
    const { onAnswerClick } = props;
    onAnswerClick(option);
  };

  const isChecked = (choice) => {
    const { answerList } = props;
    return answerList.find((item) => item.label === choice.label);
  };

  const isSelected = (choice) => {
    return isChecked(choice) ? style.isSelected : style.optionContainer;
  };

  const renderChoices = (index) => {
    const alphabet = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];
    return alphabet[index];
  };

  return (
    <FormControl className={style.optionRoot} component="fieldset">
      <RadioGroup className={style.optionParent} name="radio-buttons-group">
        {choices.length
          ? choices.map((choice, index) => {
              const selectedChoice = renderChoices(index);
              return (
                <FormControlLabel
                  className={isSelected(choice)}
                  onClick={() => {
                    passOption(choice);
                  }}
                  key={`option-${props.id}-${choice.id}`}
                  value={choice.label}
                  control={
                    <Radio
                      icon={<p className={style.choice}>{selectedChoice}</p>}
                      checkedIcon={<p className={style.choice}>{selectedChoice}</p>}
                      checked={isChecked(choice)}
                      classes={{ root: style.radio, checked: style.checked }}
                    />
                  }
                  label={choice.label}
                />
              );
            })
          : ''}
      </RadioGroup>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  isSelected: {
    border: '3px solid #7FB1EE',
    // background: theme.palette.primary.mainGradient,
    padding: '0.8em 1em',
    borderRadius: '20px',
    width: '100%',
    marginBottom: '0.75em',
    marginLeft: '0 !important',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0 !important',
    },
  },

  radio: {
    '&$checked': {
      color: `${theme.palette.common.white} !important`,
    },
  },
  checked: {
    color: 'red',
  },

  chartContainer: {
    display: 'flex',
  },

  optionRoot: {
    width: '100%',
  },

  optionParent: {
    maxWidth: '800px',
    margin: '0 auto',
  },

  optionContainer: {
    border: '1px solid rgba(0,0,0,0.2)',
    padding: '0.5em 1em',
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    marginLeft: '0 !important',
    marginBottom: '0.5em',
    minWidth: '320px',
    color: theme.palette.common.black,
  },
  choice: {
    display: 'flex',
    alignItems: 'center',
    color: theme.text[4],
    fontWeight: 'bold',
    fontSize: 25,
    fontFamily: 'Arial Black',
    textAlign: 'center',
    background: '#3F8ED7',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  boldText: {
    fontWeight: 'bold',
  },

  correctText: {
    color: '#3F8ED7',
    fontWeight: 'bold !important',
  },

  errorContainer: {
    display: 'flex',
    marginTop: '2rem',
    flexDirection: 'column',
  },

  errorText: {
    color: '#DA3737',
    fontWeight: 'bold !important',
  },

  italicText: {
    textDecoration: 'underline',
    fontStyle: 'italic',
    fontWeight: 'bold !important',
    color: '#3F8ED7',
  },

  resultContent: {
    color: '#3F8ED7',
    whiteSpace: 'pre-line',
    '& .MuiTypography-root': {
      fontSize: '1.2rem',
    },
  },
}));

export default Options;
