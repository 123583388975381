import cn from 'classnames';
import React, { useState } from 'react';
// import styles from './Item.module.sass';
import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Icon from '../../../../components/Icon';
import colors from '../../../../styles/colors';
const Item = ({ className, item, index }) => {
  const [visible, setVisible] = useState(false);
  const styles = useStyles();
  return (
    <Container className={cn(className, styles.item)}>
      <Box
        className={cn(styles.head, { [styles.active]: visible })}
        onClick={() => setVisible(!visible)}
      >
        <Typography className={styles.number}>
          {index < 9 && '0'}
          {index + 1}
        </Typography>
        <Typography className={styles.title}>{item.title}</Typography>
        <div className={styles.arrow}>
          <Icon name="arrow-down" size="24" />
        </div>
      </Box>
      <div className={cn(styles.body, { [styles.visible]: visible })}>{item.content}</div>
    </Container>
  );
};

export default Item;

const useStyles = makeStyles((theme) => ({
  arrow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: '24px',
    marginLeft: '16px',
    '& svg': {
      fill: colors.n4,
      transition: 'transform .2s',
    },
  },

  head: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '24px 0',
    fontSize: '16px',
    lineHeight: '1.5rem',
    fontWeight: 500,
    cursor: 'pointer',
    transition: 'color .2s',
    '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
  },
  active: {
    '& .MuiTypography-root': {
      color: colors.p1,
    },
    '& svg': {
      transform: 'rotate(180deg) !important',
    },
  },

  number: {
    flexShrink: 0,
    width: '40px',
    color: colors.n4,
    transition: 'color .2s',
  },
  item: {
    '&:hover': {
      '& .head': {
        color: colors.p1,
      },
      '& .number': {
        color: colors.p1,
      },
      '& .MuiTypography-root': {
        color: colors.p1,
      },
    },
    '&:not(:last-child)': {
      '& .head': {
        borderBottom: `1px solid ${colors.n6}`,
      },
    },
  },

  title: {
    flexGrow: 1,
  },
  visible: {
    display: 'block !important',
  },
  body: {
    display: 'none',
    padding: '24px 0 0 40px',
    color: colors.n4,
  },
}));
