import { Box, Container, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrainingVideos } from '../../../redux/actions/training.action';
import VideoItem from '../../PortfolioManagement1/HomeNavigation/VideoItem';
import useStyle from '../index.style';

const RelatedVideos = (props) => {
  const dispatch = useDispatch();
  const styles = useStyle();
  const { trainingVideos } = useSelector((store) => {
    const training_store = store.training;

    if (training_store) return training_store.toJS();

    return {};
  });

  useEffect(() => {
    dispatch(fetchTrainingVideos());
  }, []);
  return (
    <Container>
      {trainingVideos?.length > 0 && (
        <Box>
          <Typography className={styles.boldWhiteText} variant="h5">
            Related Videos
          </Typography>
          <Box
            display="flex"
            flexWrap="wrap"
            flexDirection="row"
            justifyContent="space-around"
            mt={2}
          >
            <Grid container width="100%" height="100%" display="flex" justifyContent="space-around">
              {trainingVideos.slice(0, 2).map((video, index) => {
                return (
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <VideoItem video={video} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default RelatedVideos;
