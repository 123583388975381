//RELATIVE PRICE INDEX DATA
export const RETRIEVE_RELATIVE_PRICE_INDEX_ACTION =
  'macroeconomics/RETRIEVE_RELATIVE_PRICE_INDEX_ACTION';
export const RETRIEVE_RELATIVE_PRICE_INDEX_OK = 'macroeconomics/RETRIEVE_RELATIVE_PRICE_INDEX_OK';
export const RETRIEVE_RELATIVE_PRICE_INDEX_ERROR =
  'macroeconomics/RETRIEVE_RELATIVE_PRICE_INDEX_ERROR';
export const RETRIEVE_RELATIVE_PRICE_INDEX_END = 'macroeconomics/RETRIEVE_RELATIVE_PRICE_INDEX_END';
export const SET_RELATIVE_PRICE_INDEX_PARAMS_ACTION =
  'macroeconomics/SET_RELATIVE_PRICE_INDEX_PARAMS_ACTION';

//RELATIVE SP INDEX DATA
export const RETRIEVE_RELATIVE_SP_INDEX_ACTION = 'macroeconomics/RETRIEVE_RELATIVE_SP_INDEX_ACTION';
export const RETRIEVE_RELATIVE_SP_INDEX_OK = 'macroeconomics/RETRIEVE_RELATIVE_SP_INDEX_OK';
export const RETRIEVE_RELATIVE_SP_INDEX_ERROR = 'macroeconomics/RETRIEVE_RELATIVE_SP_INDEX_ERROR';
export const RETRIEVE_RELATIVE_SP_INDEX_END = 'macroeconomics/RETRIEVE_RELATIVE_SP_INDEX_END';

//RELATIVE BONDS INDEX DATA
export const RETRIEVE_RELATIVE_BONDS_INDEX_ACTION =
  'macroeconomics/RETRIEVE_RELATIVE_BONDS_INDEX_ACTION';
export const RETRIEVE_RELATIVE_BONDS_INDEX_OK = 'macroeconomics/RETRIEVE_RELATIVE_BONDS_INDEX_OK';
export const RETRIEVE_RELATIVE_BONDS_INDEX_ERROR =
  'macroeconomics/RETRIEVE_RELATIVE_BONDS_INDEX_ERROR';
export const RETRIEVE_RELATIVE_BONDS_INDEX_END = 'macroeconomics/RETRIEVE_RELATIVE_BONDS_INDEX_END';

//GLOBAL MACROECONOMICS DATA
export const RETRIEVE_GLOBAL_MACROECONOMICS_ACTION =
  'macroeconomics/RETRIEVE_GLOBAL_MACROECONOMICS_ACTION';
export const RETRIEVE_GLOBAL_MACROECONOMICS_OK = 'macroeconomics/RETRIEVE_GLOBAL_MACROECONOMICS_OK';
export const RETRIEVE_GLOBAL_MACROECONOMICS_ERROR =
  'macroeconomics/RETRIEVE_GLOBAL_MACROECONOMICS_ERROR';
export const RETRIEVE_GLOBAL_MACROECONOMICS_END =
  'macroeconomics/RETRIEVE_GLOBAL_MACROECONOMICS_END';

//GLOBAL MACROECONOMICS COUNTRIES DATA
export const RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_ACTION =
  'macroeconomics/RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_ACTION';
export const RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_OK =
  'macroeconomics/RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_OK';
export const RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_ERROR =
  'macroeconomics/RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_ERROR';
export const RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_END =
  'macroeconomics/RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_END';

//GLOBAL MACROECONOMICS UPCOMING EARNINGS
export const RETRIEVE_UPCOMING_EARNINGS_ACTION = 'macroeconomics/RETRIEVE_UPCOMING_EARNINGS_ACTION';
export const RETRIEVE_UPCOMING_EARNINGS_OK = 'macroeconomics/RETRIEVE_UPCOMING_EARNINGS_OK';
export const RETRIEVE_UPCOMING_EARNINGS_ERROR = 'macroeconomics/RETRIEVE_UPCOMING_EARNINGS_ERROR';
export const RETRIEVE_UPCOMING_EARNINGS_END = 'macroeconomics/RETRIEVE_UPCOMING_EARNINGS_END';

export const SET_TIMEFRAME_ACTION = 'stock/SET_TIMEFRAME_ACTION';
