import {
  APPEND_ANSWER,
  SET_QUESTION_INDEX,
  TOGGLE_MODAL,
  FETCH_QUIZ,
  TOGGLE_UNFINISHED_MODAL,
  TOGGLE_CONTINUE_MODAL,
  FETCH_USER,
  SAVE_ANSWER,
  FINISH_QUIZ,
  TOGGLE_PORTFOLIO_MODAL,
  SET_CATEGORY,
  FETCH_QUIZ_CATEGORY,
  SUBMIT_CATEGORY_ANSWERS,
  FETCH_CATEGORY_ANSWERS,
  RETAKE_QUIZ,
} from '../constants/quiz.constant';

export const fetchQuizData = (params) => ({ type: FETCH_QUIZ, params });
export const appendAnswerAction = (params) => ({ type: APPEND_ANSWER, params });
export const setIndexAction = (index) => ({ type: SET_QUESTION_INDEX, index });
export const modalToggle = (value) => ({ type: TOGGLE_MODAL, value });
export const toggleUnfinishedModal = (value) => ({ type: TOGGLE_UNFINISHED_MODAL, value });
export const toggleContinueModal = (value) => ({ type: TOGGLE_CONTINUE_MODAL, value });
export const fetchUserDetails = (value) => ({ type: FETCH_USER, value });
export const saveUserAnswer = (data) => ({ type: SAVE_ANSWER, data });
export const uploadQuizData = (data) => ({ type: FINISH_QUIZ, data });
export const togglePortfolioModal = (value) => ({ type: TOGGLE_PORTFOLIO_MODAL, value });
export const setCategory = (value) => ({ type: SET_CATEGORY, value });
export const fetchQuizCategory = () => ({ type: FETCH_QUIZ_CATEGORY });
export const saveCategoryAnswers = (category, data) => ({
  type: SUBMIT_CATEGORY_ANSWERS,
  category,
  data,
});
export const fetchCategoryAnswers = (category) => ({ type: FETCH_CATEGORY_ANSWERS, category });
export const retakeQuiz = () => ({ type: RETAKE_QUIZ });
