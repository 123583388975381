import { Box, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { LineChart } from 'traidify-common-components';
import { ReactComponent as BullSVG } from '../../../../assets/icons/bull.svg';
import { TIMEFRAME_LIST } from '../constants';
import useStyles from '../styles';
import { capitalizeFirstLetter, getTickersX, getTickersY } from '../utils';

const LineChartList = ({ data, lineChartData, timeFrame, tickerLabel, type }) => {
  //Styles
  const classes = useStyles();

  return (
    <Stack spacing={4} sx={{ width: '100%', marginBottom: '32px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Box
          className={clsx(
            classes.bull,
            type === 'Overperforming' ? classes.bullLeft : classes.bullRight
          )}
        >
          <BullSVG width="25px" />
        </Box>
        <Box
          className={clsx(
            classes.title,
            type === 'Overperforming' ? classes.titleLeft : classes.titleRight
          )}
        >{`${type} ${tickerLabel}`}</Box>
      </Box>
      {React.Children.toArray(
        lineChartData.map((d, i) => {
          const header = d.id.split(':');
          const tickValuesX = getTickersX(d);
          const tickValuesY = getTickersY(d);
          const fund = data.find((_data) => _data.relticker === d.id);
          return (
            <>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: '#7FB1EE' }}>
                {capitalizeFirstLetter(fund.etf_group + ': ' + fund.fund_name)}
              </Typography>
              <Box key={`chart-${type}-${i}`} className={classes.card}>
                <LineChart
                  data={[d]}
                  header1={header[0]}
                  header2={header[1]}
                  isOverPerforming={type === 'Overperforming'}
                  enableCrosshair={true}
                  useMesh={true}
                  crosshairType="bottom-right"
                  margin={{ top: 30, right: 50, bottom: 60, left: 45 }}
                  yFormat=" >-.4f"
                  axisRight={{
                    format: (value) => `${value.toFixed(2)}%`,
                    tickValues: tickValuesY,
                    orient: 'right',
                    tickSize: 0,
                    tickPadding: 10,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: 0,
                  }}
                  axisBottom={{
                    format: (value) =>
                      moment(new Date(value)).format(
                        TIMEFRAME_LIST.find((tf) => tf.value === timeFrame).format
                      ),
                    tickValues: tickValuesX,
                    orient: 'bottom',
                    tickSize: 0,
                    tickPadding: 10,
                    tickRotation: -40,
                    legend: '',
                    legendOffset: 0,
                  }}
                  tooltip={(input) => {
                    return (
                      <Box className={classes.tooltip}>
                        <Box style={{ fontWeight: 'bold' }}>
                          {moment(new Date(input.point.data.xFormatted)).format('MM/DD/YY')}
                        </Box>
                        <Box>{`${Number(input.point.data.yFormatted).toFixed(2)}%`}</Box>
                      </Box>
                    );
                  }}
                />
              </Box>
            </>
          );
        })
      )}
    </Stack>
  );
};

export default LineChartList;
