import React from 'react';

import { Box, Container, Typography } from '@mui/material';

import ProgressCircular from '../../components/ProgressCircular';

import useStyles from './index.styles';

const ErrorIndicator = ({ hide_tips = false, props }) => {
  const classes = useStyles();

  const tipsList = [
    'Buy Low, Hold on Average, Sell High.',
    'Review your Needs and Goals.',
    'Consider how long you want to Invest.',
    'Avoid High-Risk Investments if you dont Understand them!',
    "Review Periodically, but don't STOCK WATCH.",
    "Only Invest what you're willing to loss.",
  ];

  const getRandomTip = () => {
    const random = Math.floor(Math.random() * tipsList.length);

    return tipsList[random];
  };

  return (
    <Container className="loading-indicator">
      <Box
        // height="85vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box className={classes.imageContainer}>
          <ProgressCircular loadingText={'Loading your Content, Please Wait...'} />

          {!hide_tips && (
            <Typography variant="h4" className={classes.desc2}>
              Tip: {getRandomTip()}
            </Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default ErrorIndicator;
