import * as d3 from 'd3';
import { createSelector } from 'reselect';

const selectTA = (store) => {
  const technical_analysis = store?.technical_analysis;
  return technical_analysis;
};

export const candlestickDataSelector = createSelector([selectTA], (ta) => {
  const ts_js = ta.toJS();
  const {
    loading_candlestick,
    candlestick_data,
    assets_snr_frequency_stock_data,
    untouched_high,
    untouched_low,
    volatility_gauge,
    volatility_atr,
  } = ts_js;
  const data = candlestick_data?.analysis || [];

  const quantile = volatility_gauge?.quantile_array || [];
  const quantile_ticks = quantile.map((q) => q.value);
  const dates_array = [-1];
  const volatility_dates = [];
  const y_high = d3.map(data, (d) => d.high);
  const y_low = d3.map(data, (d) => d.low);
  const y_volume = d3.map(data, (d) => d.volume);
  const y_min = d3.min(y_low);
  const y_max = d3.max(y_high);
  const y_axis_buffer = Math.floor((y_max - y_min) * 0.1);
  const x_dates = d3.map(data, (d) => {
    const date = new Date(d.datetime);
    dates_array.push(d.datetime);
    volatility_dates.push({
      atr: volatility_atr[`${d.datetime.replace('T', ' ')}`],
      datetime: date,
    });
    return date;
  });

  return {
    loading_candlestick,
    data,
    assets_snr_frequency_stock_data,
    untouched_high,
    untouched_low,
    volatility_gauge,
    volatility_atr,

    quantile,
    quantile_ticks,
    dates_array,
    volatility_dates,
    y_high,
    y_low,
    y_volume,
    y_min,
    y_max,
    y_axis_buffer,
    x_dates,
  };
});
