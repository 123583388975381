import { Container } from '@mui/material';
import useStyles from './index.style';

import { useSelector } from 'react-redux';
import HomeNavigation from './HomeNavigation';

export const PortfolioManagement1 = (props) => {
  const styles = useStyles();
  const appStore = useSelector((store) => store.app.toJS());

  return (
    <Container className={styles.container}>
      <HomeNavigation />
    </Container>
  );
};

export default PortfolioManagement1;
