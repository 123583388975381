import colors from './colors';

const typography = {
  fontFamily: ['Poppins', 'Roboto', 'sans-serif'].join(','),
  htmlFontSize: 14,
  fontSize: 14,
  // h1: {
  //   fontSize: '4rem',
  //   lineHeight: 1.4,
  // },
  // h2: {
  //   fontSize: '3.5rem',
  //   lineHeight: 1.4,
  // },
  // h3: {
  //   fontSize: '2.313rem',
  //   lineHeight: 1.4,
  // },
  // h4: {
  //   fontSize: '1.25rem',
  //   lineHeight: 1.4,
  // },

  subtitle1: {
    // fontSize: '1.5rem',
    // lineHeight: 1.4,
    color: colors.gray1,
  },
  // body1: {
  //   fontSize: '1.375rem',
  //   lineHeight: 1.4,
  // },
  // overline: {
  //   fontSize: '1.125rem',
  //   lineHeight: 1.4,
  // },
  // caption: {
  //   fontSize: '0.875rem',
  //   lineHeight: 1.4,
  // },
  button: {
    textTransform: 'none',
  },

  bodyBold1: {
    fontSize: '24px',
    lineHeight: 'calc(32 / 24) * 1px',
    fontWeight: '600',
    letterSpacing: '-.01em',
  },

  body2: {
    fontSize: '16px',
    lineHeight: 'calc(24 / 16) * 1px',
  },

  bodyBold2: {
    fontSize: '16px',
    lineHeight: 'calc(24 / 16) * 1px',
    fontWeight: '500',
  },

  caption1: {
    fontSize: '14px',
    lineHeight: 'calc(24 / 14) * 1px',
  },

  captionBold1: {
    fontSize: '14px',
    lineHeight: 'calc(24 / 14) * 1px',
    fontWeight: '500',
  },

  caption2: {
    fontSize: '12px',
    lineHeight: 'calc(20 / 12) * 1px',
  },

  captionBold2: {
    fontSize: '12px',
    lineHeight: 'calc(20 / 12) * 1px',
    fontWeight: '600',
  },

  caption3: {
    fontSize: '10px',
    lineHeight: 'calc(16/ 10) * 1px',
  },

  hairline1: {
    fontSize: '16px',
    lineHeight: 1,
    fontWeight: 700,
    textTransform: 'uppercase',
  },

  hairline2: {
    fontSize: '12px',
    lineHeight: 1,
    fontWeight: 700,
    textTransform: 'uppercase',
  },

  button1: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 1,
  },

  button2: {
    fontSize: '14px',
    lineHeight: 'calc(16 / 14) * 1px',
    fontWeight: 700,
  },
};

export default typography;
