import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Contained as ContainedButton } from '../../components/Button';
import Profile from '../../components/Profile';
import { togglePaymentDetailsModal } from '../../redux/actions/payments.action';
import useStyle from './index.style';

import PaymentDetailsModal from '../../components/PaymentDetailsModal';
import BasicTabs from './Tabs';

const Payments = (props) => {
  const styles = useStyle();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(togglePaymentDetailsModal(true));
  };

  const { showPaymentDetailsModal } = useSelector((store) => {
    const payments_store = store.payments;

    if (payments_store) return payments_store.toJS();

    return {};
  });

  /*const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };*/

  return (
    <Profile title="Sessions & login history">
      <Box sx={{ width: '100%' }}>
        <Grid container>
          <Grid xs={6} sm={6} md={9} lg={10} xl={10}>
            <Typography className={styles.title} variant="h5">
              Payment History
            </Typography>
          </Grid>
          <Grid xs={6} sm={6} md={3} lg={2} xl={2}>
            <ContainedButton miniRounded onClick={handleClick}>
              + Create Payment
            </ContainedButton>
          </Grid>
        </Grid>

        <Box>
          <BasicTabs />
        </Box>

        <PaymentDetailsModal open={showPaymentDetailsModal} />
      </Box>
    </Profile>
  );
};

export default Payments;
