import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 1rem',
  },
  cardContainer: {
    backgroundColor: '#FFF',
    width: '100%',
    maxWidth: '350px',
    borderRadius: '16px',
    padding: '1.5rem 2rem',
    alignItems: 'center',
  },
  title: {
    color: '#000',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  cardTitle: {
    background: theme.palette.gradients.gradient_1,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    padding: '.5rem 0',
  },
  name: {
    fontWeight: 'bold',
  },
  desc: {
    color: theme.palette.gray1,
  },
}));

export default useStyles;
