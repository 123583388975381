import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, Grid, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import useStyles from './index.style';

import { Outlined } from '../../components/Button';
import ProgressCircular from '../../components/ProgressCircular';

import Chip from '@mui/material/Chip';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

import { toggleStripeModal } from '../../redux/actions/payments.action';
import { setPlan, toggleSaveTickerModal } from '../../redux/actions/stripe.action';

import { stripeSelector } from '../../redux/reselect/stripeSelector';

const Plans = ({ setSelectedPlan, selectedPlan, products, ...props }) => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { saveTickerModal, subscriptions } = useSelector(stripeSelector);

  const getProduct = (name) => {
    const prod = products?.filter((product) => {
      return product?.attributes?.product?.name?.includes(name);
    });
    return prod[0]?.attributes;
  };
  const planList = [
    {
      planName: 'Basic - Ticker Bundle Plan',
      subName: 'Basic',
      description:
        'This a la carte pricing model allows users to pick either a ' +
        'bundle of five or ten stock or blockchain tickers for trade setups.',
      price: [
        {
          id: getProduct('5')?.djstripe_id,
          label: '5 Ticker Plan',
          value: getProduct('5')?.unit_amount / 100,
        },
        {
          id: getProduct('10')?.djstripe_id,
          label: '10 Ticker Plan',
          value: getProduct('10')?.unit_amount / 100,
        },
      ],
      benefits: {
        header: '5 or 10 Tickers of User Choice for all the below:',
        items: [
          { label: 'Support and Resistance Levels at Multiple Timeframes', included: true },
          { label: 'Monthly and Weekly Power Levels', included: true },
          { label: 'Multi-timeframe Trend Analysis', included: true },
          {
            label: 'Daytrader, Swing Trader, and Long-Term Investor Trading Styles',
            included: true,
          },
          { label: 'Ticker Specific Trade Plan', nickname: 'Better', included: true },
        ],
      },
    },
    {
      planName: 'Pro',
      subName: 'Better',
      description:
        'unlimited Access to trade setups plus volatilty analysis, stock screens, ' +
        'and relative asset class performance.',
      price: [
        {
          id: getProduct('Better')?.djstripe_id,
          label: 'Unlimited Tickers',
          value: getProduct('Better')?.unit_amount / 100,
        },
      ],
      benefits: {
        header: 'Unlimited Tickers of User Choice for all the below:',
        items: [
          { label: 'Support and Resistance Levels at Multiple Timeframes', included: true },
          { label: 'Monthly and Weekly Power Levels', included: true },
          { label: 'Multi-timeframe Trend Analysis', included: true },
          {
            label: 'Daytrader, Swing Trader, and Long-Term Investor Trading Styles',
            included: true,
          },
          { label: 'Ticker Specific Trade Plan', included: true },
          { label: 'Stock Screener', included: true },
          { label: 'ETF Performance Rank', included: true },
          { label: 'Stock Summary', included: true },
          { label: 'Volatility Trend Analysis', included: true },
          { label: 'Global Macro Relative Performance of Asset Classes', included: true },
        ],
      },
    },
    {
      planName: 'Premium',
      subName: 'Best',
      description:
        'All access pass to trade setups for multiple time periods, stock screens, ' +
        'global macro relative analysis, sentiment analysis, and strategic investor ' +
        'portfolio management, as well as education.',
      price: [
        {
          id: getProduct('Best')?.djstripe_id,
          label: 'Unlimited Tickers',
          value: getProduct('Best')?.unit_amount / 100,
        },
      ],
      benefits: {
        header: 'Unlimited Tickers of User Choice for all the below:',
        items: [
          { label: 'Support and Resistance Levels at Multiple Timeframes', included: true },
          { label: 'Monthly and Weekly Power Levels', included: true },
          { label: 'Multi-timeframe Trend Analysis', included: true },
          {
            label: 'Daytrader, Swing Trader, and Long-Term Investor Trading Styles',
            included: true,
          },
          { label: 'Ticker Specific Trade Plan', included: true },
          { label: 'Stock Screener', included: true },
          { label: 'ETF Performance Rank', included: true },
          { label: 'Stock Summary', included: true },
          { label: 'Volatility Trend Analysis', included: true },
          { label: 'Global Macro Relative Performance of Asset Classes', included: true },
          { label: 'Sentiment Text Analysis', included: true },
          { label: 'Portfolio Management - Risk Adjusted Traditional Portfolio', included: true },
          { label: 'Portfolio Management - Risk Adjusted Alternatives Portfolio', included: true },
          { label: 'Portfolio Management - Risk Adjusted Blockchain Portfolio', included: true },
          { label: 'Portfolio Backtester - Backtest your own portfiolio', included: true },
          { label: 'Educational Classroom with Quizes to reinforce learning', included: true },
        ],
      },
    },
  ];

  const handleClick = (title, id, priceLabel) => {
    if (title !== 'Basic - Ticker Bundle Plan') {
      setSelectedPlan(title);
      dispatch(setPlan({ price: id, label: title }));
      dispatch(toggleStripeModal(true));
    } else {
      setSelectedPlan(priceLabel);
      dispatch(setPlan({ price: id, label: priceLabel }));
      dispatch(toggleSaveTickerModal(true));
    }
  };

  const loadBenefits = (benefit) => {
    return (
      <ListItem>
        <Box mr={2}>{benefit.included ? <CheckIcon color="primary" /> : <CloseIcon />}</Box>
        <Typography className={benefit.included === false ? styles.excluded : ''}>
          {benefit.label}
        </Typography>
      </ListItem>
    );
  };

  const isSubscribed = (planName, priceLabel) => {
    const plan = subscriptions[0]?.plan?.product?.name;
    return plan?.includes(planName) || plan?.includes(priceLabel);
  };

  return (
    <Grid container alignItems="stretch" justifyContent="space-evenly">
      {planList.map((plan, index) => {
        return (
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={styles.planContainer}>
            <Box>
              <Typography className={styles.coloredText}>{plan.planName}</Typography>
            </Box>
            {/* <Box mt={2}>
                <Typography>{plan.description}</Typography>
              </Box> */}
            <Grid container justifyContent="space-around">
              {plan.price.map((price) => {
                return (
                  <Grid item>
                    <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
                      <Typography className={styles.priceLabel}>{price.label}</Typography>
                      <Box className={styles.price}>
                        <Box mt={1}>
                          <Typography variant="h5">$</Typography>
                        </Box>
                        <Typography variant="h4">{price.value || <ProgressCircular />}</Typography>
                        <Box mt={1.5}>
                          <Typography variant="subtitle1"> /mo</Typography>
                        </Box>
                      </Box>
                      {isSubscribed(plan.subName, price.label) ? (
                        <Chip
                          label="Subscribed"
                          color="success"
                          deleteIcon={<DoneIcon />}
                          className={styles.successChip}
                        />
                      ) : (
                        <Outlined
                          white={false}
                          variant="contained"
                          rounded
                          small
                          onClick={() => handleClick(plan.planName, price.id, price.label)}
                        >
                          {subscriptions.length > 0 ? 'Change plan' : 'Choose Plan'}
                        </Outlined>
                      )}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            <Box className={styles.benefits}>
              <List>
                <Typography variant="body1">{plan.benefits.header}</Typography>
                {plan.benefits.items.map((benefit, index) => {
                  return loadBenefits(benefit);
                })}
              </List>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Plans;
