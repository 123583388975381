import { createSelector } from 'reselect';

const selectTechnicalAnalysis = (store) => {
  const ta = store?.technical_analysis;
  return ta;
};

// Selector to get technical analysis data
export const technicalAnalysisSelector = createSelector([selectTechnicalAnalysis], (ta) => {
  // Convert to plain JS object or use empty object if undefined
  const obj = ta?.toJS() || {};

  // Check if adjusted data is present
  const adjusted = obj.trade_builder_setup?.[obj?.adx_params?.type]?.adjusted;
  if (!adjusted) return obj;

  // Create a new object with calculated set to adjusted
  return {
    ...obj,
    trade_builder_setup: {
      ...obj.trade_builder_setup,
      [obj?.adx_params?.type]: {
        ...obj.trade_builder_setup?.[obj?.adx_params?.type],
        calculated: adjusted,
      },
    },
  };
});
