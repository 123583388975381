import { Box, Container, Typography } from '@mui/material';
import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Contained } from '../Button';
import Card from './Card';
import useStyles from './index.style';

const items = [
  {
    title: 'Screen Stocks',
    content:
      'See what stocks are ranking against one another for trend and relative strength, and historical performance.',
    button: 'Sign up for free',
    image: '/images/content/card-pic-1.png',
    image2x: '/images/content/card-pic-1@2x.png',
    url: '/sign-up',
  },
  {
    title: 'Manage your Risk',
    content:
      'Understand the basics of risk management and how stop losses protect against significant moves against your position.  Our Trade Setup provides you with the entry price, target price, and stop-loss price, the three required values to keep with good trade management.',
    button: 'Learn now',
    image: '/images/content/card-pic-2.png',
    image2x: '/images/content/card-pic-2@2x.png',
    url: '/exchange',
  },
  {
    title: 'Learn Stock Analysis',
    content:
      'Trend, Support & Resistance Levels, and multi-timeframe analysis are the keys to day trading.  Understand how to apply all three of these concepts so you can have awinning edge.',
    button: 'Learn now',
    image: '/images/content/card-pic-3.png',
    image2x: '/images/content/card-pic-3@2x.png',
    url: '/learn-crypto',
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Popular = ({ classSection }) => {
  const styles = useStyles();
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          nextArrow: <SlickArrow>{/* <Icon name="arrow-next" size="18" /> */}</SlickArrow>,
          prevArrow: <SlickArrow>{/* <Icon name="arrow-prev" size="18" /> */}</SlickArrow>,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          infinite: false,
          nextArrow: <SlickArrow>{/* <Icon name="arrow-next" size="18" /> */}</SlickArrow>,
          prevArrow: <SlickArrow>{/* <Icon name="arrow-prev" size="18" /> */}</SlickArrow>,
        },
      },
    ],
  };

  return (
    <Container className={cn(classSection, styles.section)}>
      <Box className={cn('container', styles.container)}>
        <Box className={styles.head}>
          <Typography className={styles.title}>
            <Box fontWeight="bold">Become a trader now</Box>
          </Typography>
          <Typography className={styles.info} variant="body2">
            We've got everything you need to start trading.
          </Typography>
        </Box>
        <Box className={styles.wrapper}>
          <Slider className={cn('popular-slider', styles.slider)} {...settings}>
            {items.map((x, index) => (
              <Card className={styles.card} item={x} key={index} />
            ))}
          </Slider>
        </Box>
        <Box className={styles.btns}>
          <Link to="/contact">
            <Contained className={styles.button} rounded>
              Contact Us
            </Contained>
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default Popular;
