import { fromJS } from 'immutable';
import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_OK,
  FETCH_POST_DETAILS,
  FETCH_POST_DETAILS_OK,
  FETCH_END,
  FETCH_ERROR,
} from '../constants/landing_home.constant';

const initial_state = fromJS({
  categories: [],
  postDetails: {},
  loading: false,
  error: null,
});

const landingHomeReducer = (state = initial_state, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES:
      return state.set('loading', true);

    case FETCH_CATEGORIES_OK:
      return state.set('categories', action.data);

    case FETCH_END:
      return state.set('loading', false);

    case FETCH_ERROR:
      return state.set('error', action);

    case FETCH_POST_DETAILS:
      return state.set('loading', true);

    case FETCH_POST_DETAILS_OK:
      return state.set('postDetails', action.data);

    default:
      return state;
  }
};

export default landingHomeReducer;
