import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Contained } from '../../components/Button';
import { LoadingIndicator } from '../../components/ContainerIndicator';
import { DatePicker } from '../../components/DatePicker';
import { Select } from '../../components/Select';
import { TextInput } from '../../components/TextInput';
import { TimePeriod } from '../../components/TimePeriod';

import Dropdowns from './Dropdowns';
import useStyle from './index.style';
import Securities from './Securities';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { Box, Card, Container, Grid, Hidden, Typography } from '@mui/material';
import { SnackbarProvider } from 'notistack';

import { format } from 'date-fns';

const RiskAwareCreatePortfolio = (props) => {
  const styles = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const providerRef = useRef();
  const {
    loading,
    equities,
    equitiesAlternative,
    bonds,
    bondsAlternative,
    commodities,
    blockchain,
    tickers,
  } = useSelector((store) => {
    const profile_store = store.profile;

    if (profile_store) return profile_store.toJS();

    return {};
  });

  const allocations = [
    { label: 'Custom', value: 'CP' },
    { label: 'Very Conservative', value: 'VC' },
    { label: 'Conservative', value: 'C' },
    { label: 'Moderate', value: 'M' },
    { label: 'Aggressive', value: 'A' },
    { label: 'Very Aggressive', value: 'VA' },
  ];

  const [amount, setAmount] = useState(10000);
  const [selected, setSelected] = useState('CP');
  const [securities, setSecurities] = useState(tickers);
  const [disabled, setDisabled] = useState(false);
  const [tab, setTab] = useState('traditional');
  const [startDate, setStartDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));

  const traditional = [
    bondsAlternative,
    bonds,
    equitiesAlternative,
    equities,
    commodities,
    blockchain,
  ];
  const [portfolio, setPortfolio] = useState(traditional);

  // useEffect(() => {
  //   if (selected !== 'CP') {
  //     dispatch(getPortfolioData(selected, tab));
  //   }
  // }, [selected]);

  useEffect(() => {
    if (tab == 'traditional') {
      setPortfolio(traditional);
    }
  }, [tab]);

  const handleSelect = (value) => {
    setSelected(value);
  };

  const handleAmount = (amount) => {
    setAmount(amount);
  };

  const handleSecurities = (label, type) => {
    const newValue = [{ label: label, value: 0, type: type }];
    let securitiesCopy = [];
    if (securities.length > 0) {
      const isTrue = securities.some((security, index) => {
        return security.type === type;
      });

      if (isTrue) {
        securitiesCopy = securities.filter((security, index) => {
          return security.type !== type;
        });
        setSecurities(securitiesCopy);
      } else {
        setSecurities([...securities, ...newValue]);
      }
    } else {
      setSecurities([...securities, ...newValue]);
    }
  };

  const getContent = (
    <SnackbarProvider
      maxSnack={3}
      ref={providerRef}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Container className={styles.container}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
            <Box display="flex" justifyContent="left" mt={2} mb={2}>
              <Typography className={styles.title} variant="h3">
                <Box display="flex" alignItems="center">
                  <Tooltip title={<Typography>Go Back</Typography>}>
                    <IconButton
                      size="large"
                      onClick={() => history.push({ pathname: `/app/risk-aware-profile/` })}
                    >
                      <ArrowBackIcon fontSize="large" color="primary" />
                    </IconButton>
                  </Tooltip>
                  Create Your Portfolio
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container className={styles.columnRev}>
          <Grid item xs={12} sm={12} md={12} lg={7} xl={9}>
            <Grid container>
              {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card className={styles.cardContainer}>
                  <AreaChart />
                </Card>
              </Grid> */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Dropdowns portfolio={portfolio} handleSecurities={handleSecurities} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5} xl={3}>
            <Card className={styles.cardContainer}>
              <TimePeriod
                parentSD={startDate}
                setStartDate={setStartDate}
                parentED={endDate}
                setEndDate={setEndDate}
              />
              <Grid container justifyContent="space-evenly">
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                  <Box display="flex" justifyContent="center" mb={2}>
                    <DatePicker value={startDate} setValue={setStartDate} label="Start Date" />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                  <Box display="flex" justifyContent="center" mb={2}>
                    <DatePicker value={endDate} setValue={setEndDate} label="End Date" />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                  <Box display="flex" justifyContent="center" mb={2}>
                    <TextInput
                      placeholder="Initial Amount"
                      value={amount}
                      setValue={handleAmount}
                      disabled={disabled}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                  <Select
                    options={allocations}
                    value={selected}
                    placeholder="Allocation List"
                    setValue={handleSelect}
                    savedItems={securities.map((ticker) => ticker.label)}
                    multiple={false}
                    isChip={false}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Card>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Hidden smDown>
                <Securities
                  securities={securities}
                  portfolio={portfolio}
                  providerRef={providerRef}
                  startDate={startDate}
                  endDate={endDate}
                  investmentCash={amount}
                />

                <Card className={styles.cardContainer}>
                  <Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ml={12}>
                      <Typography className={styles.textFocus}>
                        Need help choosing the right stocks for you?
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ml={12}>
                      <Box display="flex" flexDirection="row-reverse" mt={2}>
                        <Contained rounded>Read More</Contained>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
        <Hidden mdUp>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <Securities
              securities={securities}
              portfolio={portfolio}
              providerRef={providerRef}
              startDate={startDate}
              endDate={endDate}
              investmentCash={amount}
            />

            <Card className={styles.cardContainer}>
              <Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ml={12}>
                  <Typography className={styles.textFocus}>
                    Need help choosing the right stocks for you?
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ml={12}>
                  <Box display="flex" flexDirection="row-reverse" mt={2}>
                    <Contained rounded>Read More</Contained>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Hidden>
      </Container>
    </SnackbarProvider>
  );

  return (
    <Container className={styles.container}>
      {loading ? <LoadingIndicator /> : getContent}
    </Container>
  );
};

export default RiskAwareCreatePortfolio;
