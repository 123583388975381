import cn from 'classnames';
import React, { useState } from 'react';

import { Box, Typography } from '@mui/material';
import { Contained } from '../../../components/Button';
import Item from './Item';
import useStyles from './index.style';
const navigationList = ['Stocks', 'Blockchain', 'Tutorials'];

const items = [
  {
    title: "A Beginner's Guide to Traidify",
    currency: 'Ethereum',
    content:
      'A walk through on how to access the markets to look at supply and demand levels for trend trading approach.',
    category: 'red',
    categoryText: 'new',
    image: '/images/content/releases-pic-1.jpg',
    image2x: '/images/content/releases-pic-1@2x.jpg',
    url: '/learn-crypto-details',
  },
  {
    title: 'What Is Crypto Market Sentiment?',
    currency: 'Ethereum',
    content: 'Learn how to use sentiment analysis on cryptocurrencies',
    category: 'green',
    categoryText: 'beginner',
    image: '/images/content/releases-pic-2.jpg',
    image2x: '/images/content/releases-pic-2@2x.jpg',
    url: '/learn-crypto-details',
  },
  {
    title: 'The bonds market - is it safe for your long-term portfolio',
    currency: 'Ethereum',
    content:
      'A look at the bond market and how interest rate pressures may want you looking elsewhere for the ballast of your portfolio.',
    category: 'red',
    categoryText: 'new',
    image: '/images/content/releases-pic-3.jpg',
    image2x: '/images/content/releases-pic-3@2x.jpg',
    url: '/learn-crypto-details',
  },
];

const Releases = ({ scrollToRef }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  // const [navigation, setNavigation] = useState(navigationList[0]);
  const styles = useStyles();

  return (
    <Box className={styles.releases} ref={scrollToRef} mt={6}>
      <Box className={styles.container}>
        <Box className={styles.head}>
          <Typography className={styles.stage} variant="h2">
            <Box fontWeight="bold">Our Blog</Box>
          </Typography>
          <Box className={styles.wrap}>
            <Typography className={styles.title} variant="h3">
              <Box fontWeight="bold">Latest Releases</Box>
            </Typography>
            <Box>
              <Typography className={styles.info} variant="body2">
                Traidify is your trading resource to the market.
              </Typography>
            </Box>
            <Box className={styles.nav}>
              {navigationList.map((x, index) => (
                <Contained
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                  small
                  rounded
                >
                  {x}
                </Contained>
              ))}
            </Box>
          </Box>
        </Box>
        <Box className={styles.list}>
          {items.map((x, index) => (
            <Item className={styles.item} item={x} key={index} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Releases;
