import {
  EDIT_ADX_PARAMS,
  RETRIEVE_ADX_DAILY,
  RETRIEVE_ADX_MI_STOCKS_ACTION,
  RETRIEVE_ADX_STOCKS_ACTION,
  RETRIEVE_ADX_VIX_STOCKS_ACTION,
  RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_FREQUENCY_ACTION,
  RETRIEVE_CANDLESTICK_ANALYSIS_ACTION,
  RETRIEVE_COMPARATIVE_PERFORMANCE_ACTION,
  RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_ACTION,
  RETRIEVE_FUND_SCREENING_ACTION,
  RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_ACTION,
  RETRIEVE_INDEX_PERFORMANCE_ACTION,
  RETRIEVE_TIERED_CHART_DATA_ACTION,
  RETRIEVE_TRADE_BUILDER_SETUP_ACTION,
  RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_ACTION,
  RETRIEVE_TRADE_SETUP_ACTION,
  RETRIEVE_UNTOUCHED_SNR_ACTION,
  RETRIEVE_VOLATILITY_ATR_ACTION,
  RETRIEVE_VOLATILITY_GAUGE_ACTION,
  SELECT_PERFORMANCE_DATA_ACTION,
  SET_CANDLESTICK_ADJUSTMENTS,
  SET_ETF_COMPARATIVE_PERFORMANCE_PARAMS_ACTION,
  SET_FUND_SCREENING_PARAMS_ACTION,
  SET_HOLDING_COMPARATIVE_PERFORMANCE_PARAMS_ACTION,
  SET_SEARCH_TICKER,
  TOGGLE_MAX_AMOUNT_MODAL,
} from '../constants/technical_analysis.constant';

export const retrieveADXStocksAction = (market, params) => ({
  type: RETRIEVE_ADX_STOCKS_ACTION,
  market,
  params,
});
export const retrieveADXVixStocksAction = (market, params) => ({
  type: RETRIEVE_ADX_VIX_STOCKS_ACTION,
  market,
  params,
});

export const retrieveADXDaily = (params) => ({
  type: RETRIEVE_ADX_DAILY,
  params,
});

export const editADXParams = (key, value) => ({
  type: EDIT_ADX_PARAMS,
  key,
  value,
});

export const retrieveTieredChartDataAction = (params, ticker_type) => ({
  type: RETRIEVE_TIERED_CHART_DATA_ACTION,
  params,
  ticker_type,
});

export const retrieveCandleStickAnalysisAction = (params) => ({
  type: RETRIEVE_CANDLESTICK_ANALYSIS_ACTION,
  params,
});

export const retrieveAssetsSNRFrequencyStockAction = (params) => ({
  type: RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_FREQUENCY_ACTION,
  params,
});

export const retrieveTradeSetupAction = (params) => ({
  type: RETRIEVE_TRADE_SETUP_ACTION,
  params,
});

export const retrieveIndexPerformanceAction = (params) => ({
  type: RETRIEVE_INDEX_PERFORMANCE_ACTION,
  params,
});

export const retrieveComparativePerformanceAction = (params, ticker, selected = false) => ({
  type: RETRIEVE_COMPARATIVE_PERFORMANCE_ACTION,
  params,
  ticker,
  selected,
});

export const selectPerformanceDataAction = (data) => ({
  type: SELECT_PERFORMANCE_DATA_ACTION,
  data,
});

export const retrieveUntouchedSNRAction = (params) => ({
  type: RETRIEVE_UNTOUCHED_SNR_ACTION,
  params,
});

export const retrieveVolatilityGaugeAction = (params, ticker_type, timeFrame) => ({
  type: RETRIEVE_VOLATILITY_GAUGE_ACTION,
  params,
  ticker_type,
  timeFrame,
});

export const retrieveVolatilityATRAction = (params, ticker_type) => ({
  type: RETRIEVE_VOLATILITY_ATR_ACTION,
  params,
  ticker_type,
});

export const toggleMaxAmountModal = (value) => ({
  type: TOGGLE_MAX_AMOUNT_MODAL,
  value,
});

export const retrieveETFComparativePerformanceAction = (params) => ({
  type: RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_ACTION,
  params,
});

export const setETFComparativePerformanceParamsAction = (params) => ({
  type: SET_ETF_COMPARATIVE_PERFORMANCE_PARAMS_ACTION,
  params,
});

export const retrieveHoldingComparativePerformanceAction = (params) => ({
  type: RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_ACTION,
  params,
});

export const setHoldingComparativePerformanceParamsAction = (params) => ({
  type: SET_HOLDING_COMPARATIVE_PERFORMANCE_PARAMS_ACTION,
  params,
});

export const retrieveFundScreeningAction = (params) => ({
  type: RETRIEVE_FUND_SCREENING_ACTION,
  params,
});

export const setFundScreeningParamsAction = (params) => ({
  type: SET_FUND_SCREENING_PARAMS_ACTION,
  params,
});

//ADX MI STOCKS
export const retrieveAdxMiStocksAction = (params) => ({
  type: RETRIEVE_ADX_MI_STOCKS_ACTION,
  params,
});

//TRADE SETUP BUILDER | UNTOUCHED_HIGH_LOW | SNR_FREQUENCY
export const retrieveTradeSetupUntouchedSNRAction = (params, enqueueSnackbar) => ({
  type: RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_ACTION,
  params,
  enqueueSnackbar,
});

//TRADE SETUP BUILDER
export const retrieveTradeBuilderSetupAction = (params, enqueueSnackbar) => ({
  type: RETRIEVE_TRADE_BUILDER_SETUP_ACTION,
  params,
  enqueueSnackbar,
});

export const setSearchTicker = (params) => ({
  type: SET_SEARCH_TICKER,
  params,
});

export const setCandlestickAdjustments = (params) => ({
  type: SET_CANDLESTICK_ADJUSTMENTS,
  params,
});
