import { Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PositiveNegativeProgressBar } from 'traidify-common-components';
import { macroeconomicsSelector } from '../../../redux/reselect/macroeconomicsSelector';

const LocationStats = () => {
  //Redux stores
  const { global_macroeconomics_countries } = useSelector(macroeconomicsSelector);
  const { data } = global_macroeconomics_countries;
  const daltaList = data.map((d) => Math.abs(d?.delta || 0));
  const largestAbsoluteValue = Math.max(...daltaList);

  const scaledDeltaNumber = useMemo(() => {
    if (!data) return 0;

    const deltaValues = data.map((_data) => Math.abs(_data.delta));
    const maxDelta = Math.max(...deltaValues);

    return maxDelta > 5 ? Math.ceil(maxDelta / 10) * 10 : 5;
  }, [data]);

  const getPercentage = (value = 0, total = 0) => {
    const val = (value / total) * 100;

    if (isNaN(val)) return 0;
    return Number(val);
  };

  return (
    <Grid container spacing={2}>
      {data.map((_data) => (
        <Grid item xs={12} sm={6}>
          <Typography variant="body2">
            {_data?.etf_subgroup}{' '}
            <Typography variant="caption">
              {_data?.delta > 0 ? '+' : ''}
              {_data?.delta?.toFixed(2) ?? 0}%
            </Typography>
          </Typography>
          <PositiveNegativeProgressBar
            direction="horizontal"
            negativeValue={
              _data?.delta < 0
                ? Math.abs(
                    getPercentage(Number(+_data?.delta ?? 0), largestAbsoluteValue).toFixed(2)
                  )
                : 0
            }
            positiveValue={
              _data?.delta > 0
                ? Math.abs(
                    getPercentage(Number(+_data?.delta ?? 0), largestAbsoluteValue).toFixed(2)
                  )
                : 0
            }
            width="100%"
            onClick={() => {}}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default LocationStats;
