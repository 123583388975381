import { makeStyles } from '@mui/styles';

import { HEADER_HEIGHT } from '../../../constants/reponsive';

const useStyles = makeStyles((theme) => ({
  container: {
    // '@media screen and (orientation: landscape) and (max-device-width: 767px)': {
    //   display: 'none',
    // },
  },
  appBar: {
    height: '70px',
    backgroundColor: '#FFFFFF',
    zIndex: theme.zIndex.drawer + 1,
    borderBottomWidth: 2,
    borderColor: theme.palette.common.white,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logoContainer: {
    height: 'inherit',
  },
  logo: {
    width: 'calc(100% - 199px)',
    height: '100%',
  },
  bottomAppBar: {
    height: HEADER_HEIGHT,
    background: theme.palette.header.bg,
    zIndex: theme.zIndex.drawer + 1,
    borderTopWidth: 2,
    borderColor: theme.palette.common.white,
    top: 'auto',
    bottom: 0,

    '& > div': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  toolbar: {
    flex: 1,
    alignItems: 'center',
    height: HEADER_HEIGHT,
    // paddingLeft: '16px',
    // paddingRight: '16px',

    [theme.breakpoints.up('lg')]: {
      // paddingLeft: '55px',
      // paddingRight: '50px',
    },
  },
  searchBarWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingLeft: '40px',
    paddingRight: '40px',

    [theme.breakpoints.down('md')]: {
      paddingLeft: '40px',
      paddingRight: '10px',
    },
  },
  linkItems: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  // Duplicate style so this one not using but might need in future
  // linkItem: {
  //   height: '44px',
  //   width: '44px',
  //   paddingLeft: '35px',
  //   paddingRight: '35px',
  //   alignItems: 'center',
  //   justifyContent: 'center',

  //   [theme.breakpoints.down('md')]: {
  //     paddingLeft: '32px',
  //     paddingRight: '32px',
  //   },
  // },
  bottomLinkItem: {
    flex: 1,
  },
  menuContainer: {
    '& div': {
      width: '120px',
    },
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '350px',
    height: '40px',
    background: 'white',
    border: `solid ${theme.palette.peter_river} 3px`,
    borderRadius: '15px',
    position: 'relative',
    boxShadow: '0.93198px 0.93198px 7.45584px -0.983119px rgba(0, 0, 0, 0.15)',
    [theme.breakpoints.down('md')]: {
      width: '410px',
    },
  },

  linkItem: {
    width: '180px',
    alignItems: 'center',
    justifyContent: 'center',

    '& > div': {
      minWidth: 'unset',
      color: theme.palette.dark1,
      alignItems: 'center',
      justifyContent: 'center',

      '&:first-child': {
        paddingRight: '6px',
      },
    },
  },
  menuDrawer: {
    zIndex: '1202',
    '&.MuiPopover-root': {
      borderRadius: '12px',
      boxShadow: '-1px 1px 31px 0px rgb(0 0 0 / 75%)',
      '-webkit-box-shadow': '-1px 1px 31px 0px rgb(0 0 0 / 75%)',
      '-moz-box-shadow': '-1px 1px 31px 0px rgba(0,0,0,0.75)',
    },
  },
  list: {
    width: '285px',
    paddingBottom: '12px',
  },
  listItem: {
    padding: '0px',
  },
  listItemText: {
    fontSize: '1.3rem',
    borderBottom: '1px solid #000000',
    paddingTop: '8px',
    paddingBottom: '8px',
    marginTop: '0px',
    marginBottom: '0px',
  },
  listItemButton: {
    padding: '0px 12px !important',
  },
  userProfile: {
    position: 'absolute',
    right: 24,
    top: 16,
  },
}));

export default useStyles;
