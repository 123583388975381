import React, { useEffect, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Contained } from '../../components/Button';

import TextInput from '../../components/TextInput';
import EmailSentModal from './EmailSentModal';
import useStyle from './index.style';

import { ERROR, INFO, SUCCESS } from '../../constants';
import { openSnackbarAction } from '../../redux/actions/app.action';
import { forgotPasswordAction } from '../../redux/actions/auth.action';

const EmailVerify = ({ handleValidate, props }) => {
  const auth = useSelector((store) => store?.auth);
  const notify = !!auth && auth.get('notify');
  const message = !!auth && auth.get('message');

  const classes = useStyle();
  const dispatch = useDispatch();
  const [success_modal, setSuccessModal] = useState(false);
  const [email, setEmail] = useState('');
  const [email_errors, setEmailErrors] = useState([]);

  const handleSubmit = () => {
    dispatch(forgotPasswordAction({ email }));
  };

  useEffect(() => {
    if (!!notify && notify == SUCCESS) {
      setSuccessModal(true);
    } else if (!!notify && notify == ERROR) {
      dispatch(openSnackbarAction(ERROR, message));
    }
  }, [notify, message]);

  return (
    <Box>
      <EmailSentModal handleClose={() => setSuccessModal(false)} open={success_modal} />
      <Box className={classes.desc1}>
        <Typography variant="h4">Reset Password</Typography>
      </Box>
      <Box paddingTop="16px" className={classes.desc2}>
        <Typography variant="h6">
          Enter the email associated with your account and we’ll send an email with instructions to
          reset your password.
        </Typography>
      </Box>
      <Box paddingTop="40px" maxWidth="400px">
        <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
          <TextInput
            id="email"
            label="Email"
            placeholder="Email"
            inputMode="email"
            value={email}
            onChange={(e) => {
              setEmailErrors([]);
              setEmail(e.target.value);
            }}
            inputState={!!email_errors.length ? ERROR : INFO}
            conditions={email_errors}
            onBlur={(e) => handleValidate(e, setEmailErrors)}
          />
          <Box paddingTop="30px">
            <Contained
              onClick={handleSubmit}
              className={classes.textInput}
              miniRounded
              disabled={!email || !!email_errors.length}
            >
              Verify email
            </Contained>
          </Box>
          <Box paddingTop="20px" className={classes.noAccount}>
            <span>Don’t have an account?</span>
            <Button component={Link} disableRipple to="/sign-up">
              Sign Up
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default EmailVerify;
