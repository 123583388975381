import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  main: {
    position: 'relative',
    paddingTop: '160px',
    [theme.breakpoints.only('lg')]: {
      paddingTop: '80px',
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: '92px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '50px',
      paddingRight: '14px',
    },
  },
  container: {
    maxWidth: '1440px',
    padding: 0,
  },
  wrap: {},
  wrapAlignment: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  centerItems: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      marginBottom: '4rem',
    },
  },
  title: {
    marginBottom: '20px',
    color: '#3F8ED7',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  },
  text: {
    '& .MuiTypography-root': {
      marginBottom: '16px',
      fontSize: '32px',
    },
    [theme.breakpoints.only('md')]: {
      maxWidth: '300px',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-root': {
        fontSize: '1.5rem',
      },
    },
  },
  button: {
    marginBottom: '80px',
    '@media(minWidth: 1179px)': {
      marginBottom: 0,
    },
  },
  scroll: {
    '@media(minWidth: 1179px)': {
      marginBottom: 0,
    },
  },
  bg: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '300px',
    },
    '& img': {
      height: 'auto',
      width: '100%',
    },
  },
}));

export default useStyles;
