import {
  FETCH_PRODUCTS,
  SUBSCRIBE_CUSTOMER,
  TOGGLE_SUCCESS_MODAL,
  FETCH_SUBSCRIPTIONS,
  CANCEL_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  TOGGLE_SAVE_TICKER_MODAL,
  SET_SELECTED_PLAN,
} from '../constants/stripe.constant';

export const fetchProducts = () => ({ type: FETCH_PRODUCTS });
export const subscribeCustomer = (params) => ({ type: SUBSCRIBE_CUSTOMER, params });
export const toggleSuccessModal = (value) => ({ type: TOGGLE_SUCCESS_MODAL, value });
export const fetchSubscriptions = () => ({ type: FETCH_SUBSCRIPTIONS });
export const cancelSubscription = (id) => ({ type: CANCEL_SUBSCRIPTION, id });
export const updateSubscription = (data) => ({ type: UPDATE_SUBSCRIPTION, data });
export const setPlan = (data) => ({ type: SET_SELECTED_PLAN, data });
export const toggleSaveTickerModal = (value) => ({ type: TOGGLE_SAVE_TICKER_MODAL, value });
