import React, { useEffect, useState } from 'react';
import useStyle from './index.style';

import { Box, Container, Grid, Typography } from '@mui/material';
import { ButtonGroup } from '../../components/Button';
import { ErrorIndicator, LoadingIndicator } from '../../components/ContainerIndicator';
import TrainingModal from './TrainingModal';
import VideoContainer from './VideoContainer';

import { useDispatch, useSelector } from 'react-redux';

import { fetchTrainingVideos } from '../../redux/actions/training.action';

const Challenge = (props) => {
  const styles = useStyle();
  const dispatch = useDispatch();
  const [tab, setTab] = useState('Do');

  const handleTab = (tab) => {
    setTab(tab);
  };
  const {
    trainingVideos = [],
    showModal,
    isError,
    loading,
  } = useSelector((store) => {
    const training_store = store.training;

    if (training_store) return training_store.toJS();

    return {};
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchTrainingVideos());
  }, []);

  const getContent = isError ? (
    <ErrorIndicator
      title={'Oops! Something went wrong!'}
      description={`It's on us, we moved the content to a different page.
The search below should help!
`}
    />
  ) : (
    <Container className={styles.container}>
      <Box paddingTop="1.5em" paddingBottom="3em">
        <Grid container display="flex" justifyContent="space-between">
          <Grid>
            <Box ml={3}>
              <Typography className={styles.title} variant="h3">
                Courses
              </Typography>
            </Box>
          </Grid>
          <Grid>
            <Box mr={5} mt={2}>
              <ButtonGroup
                buttons={['Do', 'Done']}
                currentTab={tab}
                getTab={handleTab}
              ></ButtonGroup>
            </Box>
          </Grid>
        </Grid>

        {/* Video List */}
        <VideoContainer trainingVideos={trainingVideos} />
      </Box>
    </Container>
  );

  return (
    <Container className={styles.container}>
      {loading ? <LoadingIndicator /> : getContent}
      <TrainingModal open={showModal} />
    </Container>
  );
};

export default Challenge;
