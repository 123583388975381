import { Checkbox } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';

function createData(amount, description, customer, date) {
  return { amount, description, customer, date };
}

const rows = [
  createData('US$500', 'abcde-fghij-klmnop', 'user@test.com', 'January 1, 2022'),
  createData('US$500', 'abcde-fghij-klmnop', 'user@test.com', 'January 1, 2022'),
  createData('US$500', 'abcde-fghij-klmnop', 'user@test.com', 'January 1, 2022'),
  createData('US$500', 'abcde-fghij-klmnop', 'user@test.com', 'January 1, 2022'),
  createData('US$500', 'abcde-fghij-klmnop', 'user@test.com', 'January 1, 2022'),
  createData('US$500', 'abcde-fghij-klmnop', 'user@test.com', 'January 1, 2022'),
  createData('US$500', 'abcde-fghij-klmnop', 'user@test.com', 'January 1, 2022'),
  createData('US$500', 'abcde-fghij-klmnop', 'user@test.com', 'January 1, 2022'),
  createData('US$500', 'abcde-fghij-klmnop', 'user@test.com', 'January 1, 2022'),
  createData('US$500', 'abcde-fghij-klmnop', 'user@test.com', 'January 1, 2022'),
  createData('US$500', 'abcde-fghij-klmnop', 'user@test.com', 'January 1, 2022'),
];

export default function BasicTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox color="primary" />
            </TableCell>
            <TableCell>AMOUNT</TableCell>
            <TableCell>DESCRIPTION</TableCell>
            <TableCell>CUSTOMER</TableCell>
            <TableCell>DATE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.transaction_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Checkbox color="primary" />
              </TableCell>
              <TableCell>{row.amount}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.customer}</TableCell>
              <TableCell>{row.date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
