import React from 'react';

import { Box, Grid, Hidden, Typography } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

import left_arrow from '../../assets/images/icons/left_arrow_blue.svg';
import login_bg from '../../assets/images/signin/login_bg_1.png';

import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '../../components/SnackBar';
import {
  validateConfirmPassword,
  validateEmail,
  validatePassword,
} from '../../helpers/validations';
import { closeSnackbarAction } from '../../redux/actions/app.action';
import CreateNewPassword from './CreateNewPassword';
import EmailVerify from './EmailVerify';
import useStyle from './index.style';

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pathname = props.location.pathname;
  const classes = useStyle();
  const app = useSelector((store) => store.app.toJS());
  const { message, open, notify } = app.snackbar;
  const { auth } = props;
  const loading = auth && auth.get('loading');

  const handleVerifyEmailClick = () => {
    history.push('/set-password');
  };

  const handleValidate = (e, setError, password = '') => {
    const { id, value } = e.target;
    let errors = [];

    switch (id) {
      case 'email':
        errors = validateEmail(value);
        break;

      case 'password':
        errors = validatePassword(value);
        break;

      case 'confirm_password':
        errors = validateConfirmPassword(value, password);
        break;

      default:
        break;
    }

    if (!!errors.length) {
      setError(errors);

      return false;
    }

    return true;
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(closeSnackbarAction());
  };

  return (
    <Box className={classes.container}>
      <Header />
      <Snackbar message={message} severity={notify} open={open} onClose={handleClose} />
      <Box className={classes.content} paddingTop="150px">
        <Hidden smDown>
          <Box className={classes.bgContainer} zIndex={1}>
            <Grid container spacing={0}>
              <Grid item xs={false} sm={false} md={6} lg={6} xl={6} />
              <Grid item xs={false} sm={false} md={6} lg={6} xl={6}>
                <Box className={classes.imgBg}>
                  <img src={login_bg} alt="background" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Hidden>
        <Grid className={classes.gridContainer} item container spacing={0}>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <Box className={classes.mainContent}>
              <Box>
                <Box>
                  <Link className={classes.goBackButton} to="/login">
                    <Box marginTop="-2px" display="flex">
                      <img src={left_arrow} alt="back" />
                    </Box>
                    <Typography className={classes.goBack} component="span" variant="body2">
                      Go Back
                    </Typography>
                  </Link>
                </Box>
                {pathname === '/set-password/' ? (
                  <CreateNewPassword handleValidate={handleValidate} />
                ) : (
                  <EmailVerify
                    handleValidate={handleValidate}
                    handleClick={handleVerifyEmailClick}
                  />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};

export default ResetPassword;
