import qs from 'qs';

export const setItem = (key, value) => {
  const data = JSON.stringify(value);
  window.localStorage.setItem(key, data);
};

export const getItem = (key) => {
  const value = window.localStorage.getItem(key);

  return value;
};

export const removeItem = (key) => {
  window.localStorage.removeItem(key);
};

export const removeAllItems = () => {
  window.localStorage.clear();
};

export const capitalizeFirstLetter = (letter = '') => {
  return `${letter.charAt(0).toUpperCase()}${letter.substr(1)}`;
};

export const getLocationComponents = (location) => {
  const { pathname, search } = location;
  const params = qs.parse(search.substr(1));
  const pathname_sq = `${pathname}${search}`;

  return { pathname, search, params, pathname_sq };
};

export const round = (value, precision) => {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};
