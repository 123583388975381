import React from 'react';
// import styles from './Main.module.sass';
import { Box, Container, Grid, Typography } from '@mui/material';
import { Contained, Outlined } from '../../../components/Button';
import Play from '../../../components/Play';
import useStyles from './index.styles';

const Main = ({ scrollToRef, scrollToRefCatalog }) => {
  const styles = useStyles();
  return (
    <Container className={styles.main}>
      <Box className={styles.container}>
        <Box className={styles.head}>
          <Typography className={styles.title} variant="h2">
            Stocks & Crypto
          </Typography>
          <Box className={styles.info}>A Beginner’s Guide to Traidify</Box>
          <Box className={styles.btns}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box mb={2}>
                  <Contained
                    className={styles.button}
                    onClick={() => scrollToRef.current.scrollIntoView({ behavior: 'smooth' })}
                    rounded
                  >
                    Learn now
                  </Contained>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Outlined
                  className={styles.button}
                  onClick={() =>
                    scrollToRefCatalog.current.scrollIntoView({
                      behavior: 'smooth',
                    })
                  }
                  rounded
                >
                  Video tutorial
                </Outlined>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={styles.video}>
          <img src="/images/content/video-bg.jpg" alt="Preview" />
          <Play />
        </Box>
      </Box>
    </Container>
  );
};

export default Main;
