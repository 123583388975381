import { push } from 'react-router-redux';
import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { CLIENT_ID } from '../../config';
import auth from '../../services/auth';
import { fetchUserDetails } from '../actions/quiz.action';

import {
  LOGIN_ACTION,
  LOGIN_OK,
  LOGIN_ERROR,
  LOGIN_END,
  LOGIN_FETCH,
  LOGIN_SET_DETAILS,
  UPDATE_METADATA_ACTION,
  UPDATE_METADATA_OK,
  UPDATE_METADATA_ERROR,
  UPDATE_METADATA_END,
} from '../constants/login.constant';

import {
  FORGOT_PASSWORD_ACTION,
  FORGOT_PASSWORD_OK,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_END,
  CREATE_NEW_PASSWORD_ACTION,
  CREATE_NEW_PASSWORD_OK,
  CREATE_NEW_PASSWORD_ERROR,
  CREATE_NEW_PASSWORD_END,
} from '../constants/auth.constant';

import { FETCH_USER } from '../constants/quiz.constant';

export function* login(action) {
  try {
    const params = {
      ...action.params,
      grant_type: 'password',
      client_id: CLIENT_ID,
    };

    const xhr = yield call(auth.login, params);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: LOGIN_OK, ...xhr.data });
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;
    const errors = data.errors[0] || data.errors;
    let general_error = false;
    let unverified_email = false;
    if (errors?.code == 'unverified_email') {
      unverified_email = true;
    } else {
      general_error = true;
    }

    yield put({ type: LOGIN_ERROR, general_error, unverified_email, ...errors });
  } finally {
    yield put({ type: LOGIN_END });
  }
}

export function* loginSaga() {
  yield takeEvery(LOGIN_ACTION, login);
}

export function* fetchUserData(action) {
  try {
    const xhr = yield call(auth.userDetails);

    if (xhr.status >= 400) {
      throw xhr;
    }
    if (!xhr.data.data?.attributes?.quiz_completed) {
      yield put({ type: FETCH_USER });
    }
    yield put({ type: LOGIN_SET_DETAILS, ...xhr.data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: LOGIN_ERROR, ...e });
  } finally {
    yield put({ type: LOGIN_END });
  }
}

export function* forgotPassword(action) {
  try {
    const xhr = yield call(auth.forgotPassword, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: FORGOT_PASSWORD_OK, ...xhr.data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: FORGOT_PASSWORD_ERROR, ...e });
  } finally {
    yield put({ type: FORGOT_PASSWORD_END });
  }
}

export function* createNewPassword(action) {
  try {
    const xhr = yield call(auth.createNewPassword, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: CREATE_NEW_PASSWORD_OK, ...xhr.data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: CREATE_NEW_PASSWORD_ERROR, ...e });
  } finally {
    yield put({ type: CREATE_NEW_PASSWORD_END });
  }
}

export function* updateMetadata(action) {
  try {
    const xhr = yield call(auth.patch, action.id, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }
    const data = xhr.data.attributes.metadata;
    yield put({ type: UPDATE_METADATA_OK, data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: UPDATE_METADATA_ERROR, ...e });
  } finally {
    yield put({ type: UPDATE_METADATA_END });
  }
}

export function* fetchUser() {
  yield takeEvery(LOGIN_FETCH, fetchUserData);
}

export function* forgotPasswordSaga() {
  yield takeEvery(FORGOT_PASSWORD_ACTION, forgotPassword);
}

export function* createNewPasswordSaga() {
  yield takeEvery(CREATE_NEW_PASSWORD_ACTION, createNewPassword);
}

export function* updateMetadataSaga() {
  yield takeEvery(UPDATE_METADATA_ACTION, updateMetadata);
}

const login_sagas = [
  fork(loginSaga),
  fork(fetchUser),
  fork(forgotPasswordSaga),
  fork(createNewPasswordSaga),
  fork(updateMetadataSaga),
];
export default login_sagas;
