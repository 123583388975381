import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Employee from './Employee';
import Lessons from './Lessons';
import Main from './Main';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

const AboutUs = () => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Header />
      <Main />
      {/* <Details /> */}
      <Lessons classSection="section" />
      <Employee />
      <Footer />
    </Container>
  );
};

export default AboutUs;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.base,
    display: 'flex',
    flexDirection: 'column',
  },

  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    margin: '0 auto',
    paddingTop: '100px',
    //background: `url(${login_bg})`,
  },
}));
