export const RESET_ACTION = 'payments/RESET_ACTION';

export const EDIT_FORM_ACTION = 'payments/EDIT_FORM_ACTION';

export const SET_ERROR_ACTION = 'payments/SET_ERROR_ACTION';

export const MODAL_CLOSE_ACTION = 'payments/MODAL_CLOSE_ACTION';

export const PAYMENTS_ACTION = 'payments/PAYMENTS_ACTION';
export const PAYMENTS_OK = 'payments/PAYMENTS_OK';
export const PAYMENTS_ERROR = 'payments/PAYMENTS_ERROR';
export const PAYMENTS_END = 'payments/PAYMENTS_END';

export const TOGGLE_STRIPE_MODAL = 'payments/TOGGLE_STRIPE_MODAL';

export const VERIFY_EMAIL_ACTION = 'payments/VERIFY_EMAIL_ACTION';
export const VERIFY_EMAIL_OK = 'payments/VERIFY_EMAIL_OK';
export const VERIFY_EMAIL_ERROR = 'payments/VERIFY_EMAIL_ERROR';
export const VERIFY_EMAIL_END = 'payments/VERIFY_EMAIL_END';
export const TOGGLE_PAYMENT_DETAILS_MODAL = 'payments/TOGGLE_PAYMENT_DETAILS_MODAL';

export const FOCUS_ACTION = 'payments/FOCUS_ACTION';
