import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { AppBar, Button, Grid, Popover, Toolbar } from '@mui/material';

import { ReactComponent as MenuSVG } from '../../../assets/icons/header/menu.svg';
import appbar_logo from '../../../assets/images/header/appbar_logo.png';
import desktop_logo from '../../../assets/images/header/desktop_logo.png';
import useStyles from './index.style';

import { useDispatch, useSelector } from 'react-redux';
// import { THEME_DARK_MODE } from '../../../constants';
// import { setDrawerState } from '../../../redux/actions/app.action';
import { loginFetch } from '../../../redux/actions/login.action';
import { authSelector } from '../../../redux/reselect/authSelector';
import Menu from './Menu';
import User from './User';

const Header = ({ mobileDrawerOpen, setMobileDrawerOpen, ...props }) => {
  const styles = useStyles();
  // const theme = useSelector((store) => store.app.get('theme'));
  // const appStore = useSelector((store) => store.app.toJS());
  const { token } = useSelector(authSelector);

  const dispatch = useDispatch();
  // const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'drawer-popper' : undefined;

  // const handleDrawer = (value) => {
  //   dispatch(setDrawerState(value));
  // };

  // const handleGoToPage = (link) => {
  //   history.push({ pathname: `/${link}` });
  // };

  useEffect(() => {
    dispatch(loginFetch());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const isDarkMode = theme === THEME_DARK_MODE;

  return (
    <>
      <AppBar className={styles.appBar} position="fixed">
        <Toolbar className={styles.toolbar} disableGutters>
          <Grid container spacing={0} alignItems="center" style={{ height: '100%' }}>
            {token && (
              <Button
                aria-describedby={id}
                type="button"
                onClick={handleClick}
                variant="text"
                startIcon={<MenuSVG width="25" height="25" />}
                style={{ margin: '0px 12px', minWidth: '0px' }}
                // disableGutters
              />
            )}
            <Link to="/app">
              <img src={desktop_logo} width={130} height={50} alt="Traidify Desktop Logo" />
            </Link>
            <img className={styles.logo} src={appbar_logo} height={50} alt="Traidify Appbar Logo" />
            <User className={styles.userProfile} />
          </Grid>
        </Toolbar>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={styles.menuDrawer}
      >
        <Menu onClose={handleClose} />
      </Popover>
    </>
  );
};

export default Header;
