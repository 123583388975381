import { Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import Play from '../Play';
import useStyles from './index.styles';

const Card = ({ className, item }) => {
  const styles2 = useStyles();
  return (
    <Link className={cn(className, styles2.card)} to={item.url}>
      <Box className={styles2.preview}>
        {item.attributes?.posts[0]?.thumbnail ? (
          <img src={item.attributes?.posts[0]?.thumbnail} alt="Card" />
        ) : (
          <img
            alt="Crypto"
            src="https://u3j7m9h7.rocketcdn.me/wp-content/uploads/2020/12/cryptocurrency-stocks.jpg"
          />
        )}
        {item.play && <Play className="play" small />}
      </Box>
      <Box className={styles2.body}>
        <Box className={styles2.avatar}></Box>
        <Box className={styles2.details}>
          <Box className={styles2.title}>
            {item.attributes?.posts[0]?.title || item?.title || 'Lorem Ipsum'}
          </Box>
          <Box className={styles2.author}>{item.author || 'Lorem Ipsum'}</Box>
        </Box>
        <Chip label="Lorem Ipsum" color="success" />
      </Box>
    </Link>
  );
};

export default Card;
