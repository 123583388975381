import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import cn from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLockModal } from '../../redux/actions/auth.action';
import { stripeSelector } from '../../redux/reselect/stripeSelector';
import { Contained } from '../Button';
const BlurredContent = ({ children, content, isBlurred, fullHeight, small, type, ...props }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const { subscriptions } = useSelector(stripeSelector);

  // unused might use in future
  // const handleClick = () => {
  //   history.push({ pathname: `/app/offers` });
  // };

  const openModal = () => {
    dispatch(toggleLockModal(true));
  };

  const blurComponent = () => {
    if (type === 1) {
      return subscriptions.length < 0;
    } else if (type === 2) {
      const plan = subscriptions[0]?.plan?.product?.name;
      return plan === '5 Ticker Plan' || plan === '10 Ticker Plan';
    }
  };
  return (
    <Box className={cn(styles.container, { [styles.fullHeight]: fullHeight })}>
      {blurComponent() && (
        <Contained id="disable-btn" rounded small={small} onClick={() => openModal()}>
          Unlock Full Access
        </Contained>
      )}
      <Box className={cn(styles.children, { [styles.blurredContainer]: blurComponent() })}>
        {children}
      </Box>
    </Box>
  );
};

export default BlurredContent;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    '& #disable-btn': {
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%)',
      margin: 'auto',
      zIndex: 1,
      width: 'max-content',
    },
  },

  fullHeight: {
    height: '100%',
  },

  children: {
    height: '100%',
  },

  blurredContainer: {
    pointerEvents: 'none',
    cursor: 'default',
    userSelect: 'none',
    '-webkit-filter': 'blur(6px)',
  },
}));
