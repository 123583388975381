import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  coloredText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: theme.typography.bodyBold1.fontWeight,
    fontSize: theme.typography.bodyBold1.fontSize,
    lineHeight: theme.typography.bodyBold1.lineHeight,
    color: '#027BFF',
  },
  container: {
    // width: '1200px',
  },

  planContainer: {
    display: 'flex',
    border: '2px solid #85B4ED',
    // justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '20px',
    margin: '3rem 0',
    padding: '1rem',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    maxWidth: '370px',
    flexWrap: 'wrap',

    // [theme.breakpoints.down('md')]: {
    //   margin: '3rem auto',
    // },
  },

  price: {
    color: '#5294D7',
    display: 'inline-flex',
    justifyContent: 'center',
    margin: '1rem 0',
    '& .MuiTypography-root': {
      fontWeight: '700',
    },
  },

  underlineText: {
    color: '#789CC8',
    cursor: 'pointer',
    textDecoration: 'underline',
  },

  benefits: {
    marginTop: '2 rem',
  },

  excluded: {
    textDecoration: 'line-through',
  },

  priceLabel: {
    fontWeight: 800,
    fontSize: '20px',
    lineHeight: '18px',
    color: '#1D2228',
    opacity: 0.5,
  },
  focus: {
    background: 'linear-gradient(350deg, rgba(33, 82, 255, 1), rgba(33, 212, 253, 1))',
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  successChip: {
    fontWeight: 'bold !important',
    fontSize: '1rem !important',
    backgroundColor: '#1DA571 !important',
    padding: '0.5rem !important',
    // "& .MuiChip-root":{
    //   fontSize: "1rem",
    //   backgroundColor: "#1DA571",
    //   padding:  "0.5rem",
    // }
  },
}));

export default useStyles;
