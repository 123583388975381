import { Slide, useScrollTrigger } from '@mui/material';
import Desktop from './Desktop';

const Footer1 = ({ shift_drawer, target, ...props }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: target || undefined,
  });

  return (
    <Slide appear={false} direction="up" in={!trigger}>
      <Desktop shift_drawer={shift_drawer} />
    </Slide>
  );
};

export default Footer1;
