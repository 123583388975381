import { Box, Card, Grid, Typography } from '@mui/material';
import Rating from './Rating.js';
import SentimentCard from './SentimentCard1.js';
import GradientSlider from './Slider.js';

import microsoft from '../../assets/images/sentiment/microsoft_logo.png';

import AutoComplete from '../../components/AutoComplete/index.js';
import useStyles from './styles.js';

const Mobile = ({
  search_ticker,
  show_ticker_suggestions,
  ticker_results,
  search_loading,
  handleTickerSearchOnBlur,
  handleTickerSearchOnFocus,
  handleTickerSearchItemOnClick,
  handleSearchOnChange,

  ...props
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Box pt="1rem" display="flex" justifyContent="center" alignItems="center">
        <Grid container>
          <Grid item xs={7} sm={7} md={8} lg={8} xl={8}>
            <Typography className={classes.title} variant="h2">
              Sentiment
            </Typography>
          </Grid>
          <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
            <Box sx={{ maxWidth: '500px' }}>
              <AutoComplete
                className={classes.autoSearch}
                onChange={handleSearchOnChange}
                placeholder="What is your ticker?"
                value={search_ticker}
                items={(show_ticker_suggestions && ticker_results) || []}
                loading={search_loading}
                renderItem={(item) => {
                  return (
                    <li onClick={(e) => handleTickerSearchItemOnClick(e, item)}>
                      <Typography variant="body2">{item.attributes.name}</Typography>
                    </li>
                  );
                }}
                onBlur={handleTickerSearchOnBlur}
                onFocus={handleTickerSearchOnFocus}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
        <Box display="flex" flexDirection="row">
          <Box className={classes.gradientContainer}>
            <Card className={classes.MSFTCard}>
              <Typography className={classes.title2} variant="h4">
                Microsoft
              </Typography>
              <img src={microsoft} width={130} height="auto" />
            </Card>
            <Card className={classes.MSFTDetails}>
              <Box display="flex" flexDirection="column" pt="1rem" sx={{ height: '100%' }}>
                <Grid xs={12} sm={12} md={12} lg={8} xl={8}>
                  <GradientSlider
                    label="Sentiment"
                    percent={-15}
                    style={classes.title3}
                    variant="5"
                  />
                </Grid>
                <Box id="total" display="flex" alignItems="flex-end" pb="2rem">
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Rating label="Subjectivity" value={56} style={classes.title3} variant="5" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        id="total-value"
                        display="flex"
                        textAlign="left"
                        flexDirection="column"
                        alignItems="flex-end"
                        mt={3}
                      >
                        <Box>
                          <Typography className={classes.title3} variant="h6">
                            Total Score
                          </Typography>
                          <Typography className={classes.title3} variant="h4">
                            6.8 out of 10
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Card>
          </Box>
        </Box>
      </Box>

      <Box display="flex" flexWrap="wrap" p="30px 0" className={classes.listContainer}>
        {(props.data || []).map((item, i) => (
          <Box className={classes.socialContainer}>
            <Box
              className={i % 2 === 0 ? classes.gradientContainer : classes.gradientContainer2}
              flexDirection="column"
              sx={{ justifyContent: 'flex-end !important', alignItems: 'flex-start !important' }}
              height="auto !important"
              borderRadius="14.78px !important"
            >
              <Box>
                <Typography className={classes.title4} variant="h4">
                  {item.label}
                </Typography>
              </Box>
              <SentimentCard decimelToPercent={props.decimelToPercent} {...item} />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Mobile;
