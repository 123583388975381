import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import TextInput2 from './TextInput2';

import { ERROR, INFO, SUCCESS, WARNING } from '../../constants';
import { INPUT_STATUS } from '../../constants/input';

const TextInput = ({
  conditions = [],
  label,
  magic_label,
  placeholder,
  className,
  size = 'small',
  variant = 'outlined',
  inputState = INFO,
  inputMode,
  fullWidth,
  disabled,
  setValue,
  value,
  field,
  icon,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box className={clsx({ [classes.fullWidth]: fullWidth })}>
      {label && (
        <Typography className={classes.label} variant="body2">
          {label}
        </Typography>
      )}
      <TextField
        variant={variant}
        size={size}
        label={magic_label}
        placeholder={placeholder}
        className={clsx(classes.textInput, className)}
        classes={{
          root: clsx(classes.root, {
            [classes.info]: inputState === INFO,
            [classes.success]: inputState === SUCCESS,
            [classes.error]: inputState === ERROR,
            [classes.warning]: inputState === WARNING,
          }),
        }}
        disabled={disabled}
        defaultValue={value}
        onChange={(event) => {
          if (!!setValue) setValue(event.target.value, field);
          if (!!props.onChange) props.onChange(event);
        }}
        InputProps={{
          endAdornment: icon && <InputAdornment position="end">{icon}</InputAdornment>,
        }}
        inputMode={inputMode}
        {...props}
      />
      {conditions.map((c, i) => {
        if (typeof c === 'string') {
          return (
            <Typography
              key={`${c}${i}`}
              variant="caption"
              className={clsx(classes.condition, {
                [classes.conditionError]: inputState === ERROR,
                [classes.conditionSuccess]: inputState === SUCCESS,
                [classes.conditionWarning]: inputState === WARNING,
              })}
            >
              {c}
            </Typography>
          );
        }

        return (
          <Typography
            key={`${c.label}${i}`}
            variant="caption"
            className={clsx(classes.condition, {
              [classes.conditionValid]: c.isValid === INPUT_STATUS.VALID,
              [classes.conditionError]: c.error === INPUT_STATUS.INVALID,
              [classes.conditionError]: inputState === ERROR,
              [classes.conditionWarning]: inputState === WARNING,
            })}
          >
            {c.label}
          </Typography>
        );
      })}
    </Box>
  );
};

export default TextInput;
export { TextInput, TextInput2 };

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '28px',
      display: 'flex',
      alignItems: 'center',
      background: 'linear-gradient(90.85deg, #5D5FEF -48.5%, #41E7B0 116.69%)',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': '#2c81ff !important',

      '& .MuiInputBase-input': {
        height: '2.1876em !important',
      },
    },

    '& input:-webkit-autofill': {
      '-webkit-text-fill-color': '#2c81ff !important',
    },
  },
  error: {
    '& fieldset': {
      borderColor: theme.palette.error.main,
    },
  },
  success: {
    '& fieldset': {
      borderColor: theme.palette.success.main,
    },
  },
  warning: {
    '& fieldset': {
      borderColor: theme.palette.warning.main,
    },
  },
  textInput: {
    marginTop: '3px',
    marginBottom: '5px',
    width: '100%',
  },
  label: {
    fontWeight: 500,
  },
  condition: {
    fontWeight: 500,
    color: theme.palette.grey.grey_3,
    opacity: 0.67,
    display: 'block',
  },
  conditionValid: {
    color: theme.palette.green_1,
  },
  conditionError: {
    color: theme.palette.error.main,
  },
  conditionSuccess: {
    color: theme.palette.success.main,
  },
  conditionWarning: {
    color: theme.palette.warning.main,
  },
  input: {
    color: theme.palette.gradients.blue_linear,
  },
  fullWidth: {
    width: '100%',
  },
}));
