import React from 'react';
import { useHistory } from 'react-router-dom';
// import styles from './Card.module.sass';
import { Box, Typography } from '@mui/material';
import { Contained } from '../../../components/Button';
import useStyles from './index.styles';

const Card = ({ className, item }) => {
  const history = useHistory();
  const styles = useStyles();
  console.log({ item });
  return (
    <Box className={styles.card}>
      <Box className={styles.preview}>
        <img srcSet={`${item.image2x} 2x`} src={item.image} alt="Card" />
      </Box>
      <Box className={styles.wrap}>
        <Typography className={styles.title} variant="h5">
          {item.title}
        </Typography>
        <Typography className={styles.content} variant="body1">
          {item.content}
        </Typography>
        <Box mt={6}>
          <Contained
            className={styles.button}
            rounded
            onClick={() => history.push({ pathname: `/sign-up` })}
          >
            Join now
          </Contained>
        </Box>
      </Box>
    </Box>
  );
};

export default Card;
