import { makeStyles } from '@mui/styles';
import colors from '../../../styles/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: '1280px',
    margin: '0 auto',
    padding: '0 50px',
    [theme.breakpoints.up('lg')]: {
      padding: '0 20px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 16px',
    },
  },

  main: {
    paddingTop: '80px',
    marginBottom: '136px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: '0 !important',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '64px',
      margin: '0 0 7rem 1.8rem',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: '112px',
    },
  },

  head: {
    maxWidth: '730px',
    margin: '0 auto 80px',
    textAlign: 'center',
  },

  title: {
    marginBottom: '16px',
    marginTop: '32px',
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
    },
  },

  info: {
    marginBottom: '32px',
    fontSize: '24px',
    lineHeight: 'calc(32 / 24) * 1px',
    letterSpacing: '-.01em',
    color: colors.n4,
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },

  btns: {
    display: 'flex',
    justifyContent: 'center',
  },

  button: {
    '&:not(:last-child)': {
      marginRight: '16px',
    },
  },

  video: {
    position: 'relative',
    borderRadius: '16px',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      '&:before': {
        content: '',
        display: 'block',
        paddingBottom: '122%',
      },
    },

    '& img': {
      width: '100%',
      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        objectFit: 'cover',
      },
    },
  },
}));

export default useStyles;
