import { fromJS } from 'immutable';

import {
  FETCH_END,
  FETCH_ERROR,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_OK,
  SUBSCRIBE_CUSTOMER,
  SUBSCRIBE_CUSTOMER_OK,
  TOGGLE_SUCCESS_MODAL,
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_OK,
  CANCEL_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_OK,
  TOGGLE_SAVE_TICKER_MODAL,
  SET_SELECTED_PLAN,
} from '../constants/stripe.constant';

const initial_state = fromJS({
  products: [],
  isError: false,
  error: [],
  isLoading: false,
  userDetails: {},
  success: false,
  subscriptions: [],
  saveTickerModal: false,
  selectedPlan: {},
});

const stripeReducer = (state = initial_state, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return state.set('isLoading', true);

    case FETCH_PRODUCTS_OK:
      return state.set('products', action.data);

    case FETCH_SUBSCRIPTIONS:
      return state.set('isLoading', true);

    case FETCH_SUBSCRIPTIONS_OK:
      const list = action.results
        .filter((subs) => {
          return subs.status !== 'canceled';
        })
        .sort((first, second) => new Date(second?.start_date) - new Date(first?.start_date));

      return state.set('subscriptions', [...list]);

    case SET_SELECTED_PLAN:
      return state.set('selectedPlan', action.data);

    case UPDATE_SUBSCRIPTION:
      return state.set('isLoading', true);

    case UPDATE_SUBSCRIPTION_OK:
      return state.set('subscriptions', [action.data]).set('isLoading', false);

    case CANCEL_SUBSCRIPTION:
      return state.set('isLoading', true);

    case SUBSCRIBE_CUSTOMER:
      return state.set('isLoading', true).set('success', false);

    case SUBSCRIBE_CUSTOMER_OK:
      return state.set('userDetails', action).set('success', true);

    case TOGGLE_SUCCESS_MODAL:
      return state.set('success', action.value);

    case TOGGLE_SAVE_TICKER_MODAL:
      return state.set('saveTickerModal', action.value);

    case FETCH_ERROR:
      return state.set('isError', true).set('error', action);

    case FETCH_END:
      return state.set('isLoading', false);

    default:
      return state;
  }
};

export default stripeReducer;
