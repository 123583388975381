import { Box, Card, Container, Grid, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';

import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import { SnackbarProvider } from 'notistack';
import AutoComplete from '../../components/AutoComplete';
import { Contained } from '../../components/Button';
import { retrieveTicker, saveTickers } from '../../redux/actions/save_tickers.action';
import { fetchSubscriptions, updateSubscription } from '../../redux/actions/stripe.action';
import { saveTickerSelector } from '../../redux/reselect/saveTickerSelector';
import { stripeSelector } from '../../redux/reselect/stripeSelector';
import DataTable from './DataTable';
import useStyles from './index.style';

const SaveTickers = ({ ...props }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const providerRef = useRef();
  const [selected, setSelected] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [cryptos, setCryptos] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [showSuggestion, setShowSuggestion] = useState(false);

  const searchTickerDebounce = useCallback(
    debounce((v) => searchStock(v), 1000),
    []
  );

  const { loading, ticker } = useSelector(saveTickerSelector);
  const { subscriptions, isLoading } = useSelector(stripeSelector);

  useEffect(() => {
    // dispatch(cancelSubscription(55));
    dispatch(fetchSubscriptions());
  }, []);

  useEffect(() => {
    if (subscriptions?.length > 0) {
      subscriptions.forEach((subscription) => {
        subscription.metadata.tickers.split(',').forEach((ticker) => {
          searchStock(ticker);
        });
      });
    }
  }, [subscriptions?.length]);

  useEffect(() => {
    if (subscriptions?.length > 0) {
      ticker?.forEach((item) => {
        subscriptions?.forEach((subscription) => {
          const list = subscription?.metadata?.tickers?.split(',');
          if (
            list?.find(
              (saved) =>
                saved === item?.attributes?.ticker &&
                !selected?.find((st) => st.ticker === item?.attributes?.ticker)
            )
          ) {
            handleSelected(item?.attributes);
          }
        });
      });
      setSearchString('');
    }
  }, [ticker[0]?.attributes?.ticker]);

  const topCryptos = [
    {
      id: 1,
      name: 'Bitcoin',
      ticker: 'X:BTCUSD',
      type: 'crypto',
      image: '/images/content/currency/bitcoin.svg',
    },
    {
      id: 2,
      name: 'Ethereum',
      ticker: 'X:ETHUSD',
      type: 'crypto',
      image: '/images/content/currency/ethereum.svg',
    },
    {
      id: 3,
      name: 'Dogecoin',
      ticker: 'X:DOGEUSD',
      type: 'crypto',
      image: '/images/content/currency/dogecoin.svg',
    },
    {
      id: 4,
      name: 'Ripple',
      ticker: 'X:XRPUSD',
      type: 'crypto',
      image: '/images/content/currency/ripple.svg',
    },
    {
      id: 5,
      name: 'Litecoin',
      ticker: 'X:LTCUSD',
      type: 'crypto',
      image: '/images/content/currency/litecoin.png',
    },
    {
      id: 6,
      name: 'Bitcoin Cash',
      ticker: 'X:BCHUSD',
      type: 'crypto',
      image: '/images/content/currency/bitcoin-cash.svg',
    },
    {
      id: 7,
      name: 'Binance Coin',
      ticker: 'BNB',
      type: 'crypto',
      image: '/images/content/currency/bnb.svg',
    },
    {
      id: 8,
      name: 'ApeCoin',
      ticker: 'X:APEUSD',
      type: 'crypto',
      image: '/images/content/currency/apecoin.png',
    },
    {
      id: 9,
      name: 'Solana',
      ticker: 'X:SOLUSD',
      type: 'crypto',
      image: '/images/content/currency/solana.png',
    },
    {
      id: 10,
      name: 'Cardano',
      ticker: 'X:ADAUSD',
      type: 'crypto',
      image: '/images/content/currency/cardano.svg',
    },
  ];

  const topStocks = [
    {
      id: 1,
      name: 'Microsoft',
      ticker: 'MSFT',
      type: 'stock',
      image: '/images/content/stocks/microsoft.png',
    },
    {
      id: 2,
      name: 'Apple Inc.',
      ticker: 'AAPL',
      type: 'stock',
      image: '/images/content/stocks/apple.png',
    },
    {
      id: 3,
      name: 'Google',
      ticker: 'GOOG',
      type: 'stock',
      image: '/images/content/stocks/google.png',
    },
    {
      id: 4,
      name: 'Meta Platforms Inc.',
      ticker: 'META',
      type: 'stock',
      image: '/images/content/stocks/meta.png',
    },
    {
      id: 5,
      name: 'Amazon.com Inc.',
      ticker: 'AMZN',
      type: 'stock',
      image: '/images/content/stocks/amazon.png',
    },
    {
      id: 6,
      name: 'Tesla Inc.',
      ticker: 'TSLA',
      type: 'stock',
      image: '/images/content/stocks/tesla.png',
    },
    {
      id: 7,
      name: 'Walmart Inc',
      ticker: 'WMT',
      type: 'stock',
      image: '/images/content/stocks/walmart.png',
    },
    {
      id: 8,
      name: 'Coca-Cola Company',
      ticker: 'KO',
      type: 'stock',
      image: '/images/content/stocks/coke.png',
    },
    {
      id: 9,
      name: 'Home Depot Inc.',
      ticker: 'HD',
      type: 'stock',
      image: '/images/content/stocks/home-depot.png',
    },
    {
      id: 10,
      name: 'Johnson & Johnson',
      ticker: 'JNJ',
      type: 'stock',
      image: '/images/content/stocks/jnj.jpg',
    },
  ];

  const handleSelected = (data) => {
    if (data) {
      let selectedCopy = [];
      if (!Array.isArray(data)) {
        const newValue = [{ name: data.name, value: 0, ticker: data.ticker, type: data.type }];
        if (selected.length > 0) {
          const isTrue = selected.some((security, index) => {
            return security.ticker === data.ticker;
          });

          if (isTrue) {
            selectedCopy = selected.filter((security, index) => {
              return security.ticker !== data.ticker;
            });
            setSelected(selectedCopy);
          } else {
            setSelected([...selected, ...newValue]);
          }
        } else {
          setSelected((selected) => [...selected, ...newValue]);
        }
      } else {
        if (selected.length > 0) {
          const isTrue = data.some((item, index) => {
            return selected.find((checked) => {
              return checked.ticker === item.ticker;
            });
          });
          if (isTrue) {
            selectedCopy = selected.filter((security, index) => {
              return !data.find((item) => {
                return security.ticker === item.ticker;
              });
            });
            setSelected(selectedCopy);
          } else {
            setSelected([...selected, ...data]);
          }
        } else {
          setSelected((selected) => [...selected, ...data]);
        }
      }
    } else {
      setSelected([]);
    }
  };

  const loadPopular = (items, type) => {
    return (
      <Box mt={3}>
        <Typography className={styles.coloredTitle} variant="h4">
          Popular {type}
        </Typography>
        <DataTable
          items={items}
          type={type}
          handleSelected={handleSelected}
          checkedItems={selected}
        />
      </Box>
    );
  };

  const getSpecificData = (type) => {
    return selected.filter((item, index) => item.type === type);
  };

  const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

  const loadData = (type) => {
    return (
      <>
        <Typography className={styles.gradientText}>{capitalize(type)}</Typography>
        {getSpecificData(type).length === 0 ? (
          <Typography variant="subtitle1">No items have been selected</Typography>
        ) : (
          getSpecificData(type).map((data, index) => (
            <Box mb={1}>
              <Grid container key={data.ticker}>
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                  <Box>
                    <Chip
                      clickable
                      key={data.ticker}
                      label={data.name}
                      onDelete={() => handleSelected(data)}
                    />
                    {/* <Typography variant="body1">
                      {data.name}
                      <IconButton onClick={handleSelected(data)}>
                        <ClearIcon size="small" />
                      </IconButton>
                    </Typography> */}
                  </Box>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Box display="flex" justifyContent="center" mt={0.5}>
                    <Typography variant="body1">{data.ticker}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))
        )}
      </>
    );
  };

  const handleSearchOnChange = (e) => {
    const { value } = e.target;
    searchTickerDebounce(value);
    setSearchString(value);
    setShowSuggestion(true);
  };

  const searchStock = (search_str) => {
    const clean_search_string = (search_str || '').trim();
    dispatch(retrieveTicker(clean_search_string));
  };

  const handleTickerSearchItemOnClick = (e, item) => {
    handleSelected(item.attributes);
    setShowSuggestion(false);
  };

  const handleTickerSearchOnBlur = (e) => {
    setShowSuggestion(false);
  };

  const handleSave = () => {
    const tickers = selected
      .map((item) => {
        return item.ticker;
      })
      .join();

    dispatch(saveTickers(tickers));

    history.push({ pathname: '/app/offers' });
  };

  const handleUpdate = () => {
    const payload = {
      id: subscriptions[0]?.djstripe_id,
      tickers: {
        metadata: {
          tickers: selected
            .map((item) => {
              return item.ticker;
            })
            .join(),
        },
      },
    };
    dispatch(updateSubscription(payload));
    if (!isLoading) {
      providerRef.current.enqueueSnackbar('Your selected tickers have been saved.', {
        variant: 'success',
      });
    }
  };

  const saveOrUpdate = () => {
    return subscriptions?.length > 0 ? handleUpdate() : handleSave();
  };

  return (
    <SnackbarProvider
      maxSnack={3}
      ref={providerRef}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Container>
        <Grid container justifyContent="space-evenly">
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Box className={styles.container}>
              {loadPopular(topStocks, 'Stocks')}
              {loadPopular(topCryptos, 'Cryptos')}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Box
              className={styles.container}
              display="flex"
              justifyContent="center"
              flexDirection="column"
              textAlign="center"
            >
              <Typography className={styles.coloredTitle} variant="h5">
                Or search for them here
              </Typography>
              {/* <AutoComplete 
                label="Select Ticker" 
                options={getFields()} 
                setValue={handleSelected} 
                checkedItems={selected}
              /> */}
              <AutoComplete
                onChange={handleSearchOnChange}
                placeholder="What is your ticker?"
                value={searchString}
                items={(showSuggestion && ticker) || []}
                renderItem={(item) => {
                  return (
                    <li onClick={(e) => handleTickerSearchItemOnClick(e, item)}>
                      <Typography variant="body2">
                        {item.attributes.name + ' - ' + item.attributes.ticker}
                      </Typography>
                    </li>
                  );
                }}
                loading={loading}
                onBlur={(e) => handleTickerSearchOnBlur(e)}
                onFocus={() => setShowSuggestion(true)}
                // onFocus={handleTickerSearchOnFocus}
              />
            </Box>
            <Box mt={4}>
              <Card className={styles.card}>
                {loadData('stock')}
                {loadData('crypto')}
                <Grid container>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Typography className={styles.gradientText}>Total</Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <Box display="flex" justifyContent="center">
                      <Typography className={styles.gradientText}>{selected.length}</Typography>
                    </Box>
                  </Grid>
                </Grid>
                {selected.length !== 0 && (
                  <Box display="flex" justifyContent="center" mt={2}>
                    <Contained rounded small onClick={() => saveOrUpdate()}>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={25} />
                      ) : subscriptions.length > 0 ? (
                        'Update'
                      ) : (
                        'Save'
                      )}
                    </Contained>
                  </Box>
                )}
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </SnackbarProvider>
  );
};

export default SaveTickers;
