import { createSelector } from 'reselect';

const getPaymentsErrors = (store) => {
  const payments = store?.payments?.toJS()?.errors;
  return payments;
};

export const isFormValid = createSelector([getPaymentsErrors], (errors) => {
  const { email, number, name, expiry, cvc } = errors || {};
  let hasErrors = false;

  hasErrors = name?.some((e) => !!e);

  if (!hasErrors) {
    hasErrors = number?.some((e) => !!e);
  }

  if (!hasErrors) {
    hasErrors = email?.some((e) => !!e);
  }

  if (!hasErrors) {
    hasErrors = expiry?.some((e) => !!e);
  }

  if (!hasErrors) {
    hasErrors = cvc?.some((e) => !!e);
  }

  return !hasErrors;
});
