import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Desktop from './Desktop';
import useStyles from './index.style';
import Mobile from './Mobile';

import { signUpSelector } from '../../redux/reselect/signUpSelector';

const EmailVerified = ({ location, ...props }) => {
  const classes = useStyles();
  const { email_verification } = useSelector(signUpSelector);
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    if (email_verification?.status === 200) {
      setExpired(false);
    } else {
      setExpired(true);
    }
  }, [email_verification]);

  return (
    <Box>
      <Desktop classes={classes} expired={expired} />
      <Mobile classes={classes} expired={expired} />
    </Box>
  );
};

export default EmailVerified;
