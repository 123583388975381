import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BlurredContent from '../../../components/BlurredContent';
import ProgressCircular from '../../../components/ProgressCircular';
import { retrieveGlobalMacroeconomicsAction } from '../../../redux/actions/macroeconomics.action';
import { macroeconomicsSelector } from '../../../redux/reselect/macroeconomicsSelector';
import useCommonStyles from '../styles';
import LineChartList from './blocks/LineChartList';
import {
  CATEGORY,
  CATEGORY_LIST,
  TICKERS,
  TICKERS_LIST,
  TIMEFRAME,
  TIMEFRAME_LIST,
} from './constants';
import useStyles from './styles';
import { capitalizeFirstLetter, generateLineChartData } from './utils';

const RelativeAnalysis = ({ isBlurred = false, ...props }) => {
  //Dispatch
  const dispatch = useDispatch();

  //Styles
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  //Redux Stores
  const { global_macroeconomics } = useSelector(macroeconomicsSelector);
  const { data, loading } = global_macroeconomics;

  //States
  const [timeFrame, setTimeFrame] = useState(TIMEFRAME.REAL_TIME);
  const [category, setCategory] = useState(CATEGORY.ASSET_CLASSES);
  const [ticker, setTicker] = useState(TICKERS.SPY);
  const tickerLabel = TICKERS_LIST.find((_ticker) => _ticker.value === ticker).label;

  const formattedDateRange = useMemo(() => {
    if (!data) return '';

    const relative_prices = data.find((_data) => _data.relticker === 'IWP:SPY')?.relative_prices;

    if (!relative_prices) return '';
    const from_time = relative_prices[0]?.datetime;
    const to_time = relative_prices[relative_prices.length - 1]?.datetime;

    if (timeFrame === TIMEFRAME.REAL_TIME) return moment(to_time).format('MM/DD/YYYY');

    return `${moment(from_time).format('MM/DD/YYYY')} - ${moment(to_time).format('MM/DD/YYYY')}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { overPerformingData, underPerformingData } = useMemo(() => {
    if (!data || data.length === 0) return { overPerformingData: [], underPerformingData: [] };

    return generateLineChartData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const callGlobalMacroApi = () =>
    dispatch(
      retrieveGlobalMacroeconomicsAction({
        timeFrame,
        params: {
          b: ticker,
          t: category,
        },
      })
    );

  useEffect(() => {
    window.addEventListener('orientationchange', () => callGlobalMacroApi());
    return () => {
      window.removeEventListener('orientationchange', () => callGlobalMacroApi());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    callGlobalMacroApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFrame, category, ticker]);

  /** Handlers */
  /**
   * Handles on click of each time frame
   * - sets time frame
   */
  const onClickTimeFrame = (val) => setTimeFrame(val);

  /**
   * Handles on click of each category header
   * - sets category header
   */
  const onClickCategoryHeader = (val) => setCategory(val);

  /**
   * Handles on click of each category header
   * - sets category header
   */
  const onClickTicker = (val) => setTicker(val);

  const renderHeader = () => (
    <Box className={commonClasses.headerContainer}>
      <Typography className={commonClasses.header} variant="h5">
        Relative Analysis
      </Typography>
    </Box>
  );

  const renderCategoryHeader = () => (
    <Box
      className={clsx(commonClasses.categoryHeaderContainer, classes.categoryHeaderContainer)}
      mb={4}
    >
      <Grid container>
        {React.Children.toArray(
          CATEGORY_LIST.map(({ value, label }, index) => (
            <Grid
              item
              xs={6}
              sm={4}
              md={2}
              style={{ display: 'flex', justifyContent: 'center' }}
              onClick={() => onClickCategoryHeader(value)}
            >
              <Box
                className={clsx(
                  commonClasses.categoryHeader,
                  category === value ? classes.activeCategoryHeader : ''
                )}
                sx={{
                  width: 'fit-content !important',
                  padding: '0.3rem 1rem',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontSize: '.95rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {label}
                </Typography>
              </Box>
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );

  const renderTimeFrames = () => (
    <Grid container className={classes.timeFrame} mt={6} mb={2}>
      {React.Children.toArray(
        TIMEFRAME_LIST.map(({ value, label }, index) => {
          return (
            <>
              <Grid
                item
                justifyContent="center"
                className={clsx(
                  classes.timeFrameLabel,
                  timeFrame === value ? classes.activeTimeFrameLabel : ''
                )}
                onClick={(e) => onClickTimeFrame(value)}
              >
                {label}
              </Grid>
              {index < TIMEFRAME_LIST.length - 1 && (
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  style={{ margin: '0px', borderColor: '#000000' }}
                />
              )}
            </>
          );
        })
      )}
    </Grid>
  );

  const renderDetails = () => (
    <>
      <Box mb={3}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: '#000000' }}>
          {`Overperforming S&P 500`}
          <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: '#7FB1EE' }}>
            {TIMEFRAME_LIST.find((tf) => tf.value === timeFrame).title}
          </Typography>
        </Typography>

        <Box className={clsx(classes.detailsBodyContainer, classes.detailsBodyContainerHigh)}>
          <Stack spacing={2} p={2}>
            {React.Children.toArray(
              overPerformingData.map((_overPerformingData, index) => {
                const tempData = data.find((_data) => _data.relticker === _overPerformingData.id);
                return (
                  <Box>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: 'bold' }}
                    >{`${capitalizeFirstLetter(tempData.etf_subgroup)}`}</Typography>
                    <Typography variant="subtitle2">{`${tempData.relticker}, ${tempData.fund_name}`}</Typography>
                  </Box>
                );
              })
            )}
          </Stack>
        </Box>
      </Box>
      <Box>
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 'bold', color: '#000000' }}
        >{`Underperforming S&P 500`}</Typography>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: '#7FB1EE' }}>
          {TIMEFRAME_LIST.find((tf) => tf.value === timeFrame).label}
        </Typography>
        <Box className={clsx(classes.detailsBodyContainer, classes.detailsBodyContainerLow)}>
          <Stack spacing={2} p={3}>
            {React.Children.toArray(
              underPerformingData.map((_overPerformingData, index) => {
                const tempData = data.find((_data) => _data.relticker === _overPerformingData.id);
                return (
                  <>
                    <Box key={`underperforming-${index}`}>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 'bold' }}
                      >{`${capitalizeFirstLetter(tempData.etf_subgroup)}`}</Typography>
                      <Typography variant="subtitle2">{`${tempData.relticker}, ${tempData.fund_name}`}</Typography>
                    </Box>
                  </>
                );
              })
            )}
          </Stack>
        </Box>
      </Box>
    </>
  );

  const renderBody = () => (
    <Grid container spacing={4}>
      <Grid container item xs={12} lg={1} alignItems="flex-start">
        <Box className={classes.compareAgainst}>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: '#7FB1EE' }}>
            Compare Against
          </Typography>
          <Box>
            {React.Children.toArray(
              TICKERS_LIST.map((ticker, index) => (
                <Button
                  variant="outlined"
                  className={classes.compareText}
                  style={{ marginBottom: '8px' }}
                  onClick={() => onClickTicker(ticker.value)}
                >
                  {ticker.label}
                </Button>
              ))
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} lg={9} justifyContent="center" style={{ paddingBottom: '2rem' }}>
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
          divider={<Divider orientation="vertical" style={{ width: '2px' }} />}
          spacing={4}
        >
          <LineChartList
            data={data}
            lineChartData={overPerformingData}
            timeFrame={timeFrame}
            tickerLabel={tickerLabel}
            type={'Overperforming'}
            width="100%"
          />
          <LineChartList
            data={data}
            lineChartData={underPerformingData}
            timeFrame={timeFrame}
            tickerLabel={tickerLabel}
            type={'Underperforming'}
          />
        </Stack>
      </Grid>
      <Grid
        container
        className={classes.relativeAnalysisDetails}
        xs={12}
        lg={2}
        justifyContent="center"
        alignItems="flex-start"
      >
        {renderDetails()}
      </Grid>
    </Grid>
  );

  const renderLoader = () => <ProgressCircular size={50} thickness={5} />;

  return (
    <Box mt={6}>
      {renderHeader()}
      <BlurredContent isBlurred={isBlurred}>
        {renderCategoryHeader()}
        {renderTimeFrames()}
        {!loading ? (
          <Box mt={2} mb={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="body2">{formattedDateRange}</Typography>
          </Box>
        ) : (
          <></>
        )}
        <Box mt={6}>{loading ? renderLoader() : renderBody()}</Box>
      </BlurredContent>
    </Box>
  );
};

export default RelativeAnalysis;
