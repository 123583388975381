import { Box, ListItem, ListItemIcon, Typography } from '@mui/material';
import React from 'react';

import ball from '../../assets/images/footer/ball.png';
import github from '../../assets/images/footer/github.png';
import insta from '../../assets/images/footer/insta.png';
import pinterest from '../../assets/images/footer/pinterest.png';
import twitter from '../../assets/images/footer/twitter.png';

import { Link } from 'react-router-dom';
import useStyles from './index.style';

const Footer = ({ ...props }) => {
  const styles = useStyles();
  return (
    <footer className={styles.footer}>
      <Box className={styles.navLinks} display="flex" flexDirection="row">
        <Box>
          <Link to="/" className={styles.link}>
            Company
          </Link>
        </Box>
        <Box>
          <Link to="/about-us" className={styles.link}>
            About Us
          </Link>
        </Box>
        <Box>
          <Link to="/" className={styles.link}>
            Team
          </Link>
        </Box>
        <Box>
          <Link to="/contact" className={styles.link}>
            Contact Us
          </Link>
        </Box>
      </Box>
      <Box className={styles.social}>
        <ListItem>
          <ListItemIcon>
            <Link to="/">
              <img src={ball} alt="dribble" />
            </Link>
          </ListItemIcon>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Link to="/">
              <img src={twitter} alt="twitter" />
            </Link>
          </ListItemIcon>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Link to="/">
              <img src={insta} alt="instagram" />
            </Link>
          </ListItemIcon>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Link to="/">
              <img src={pinterest} alt="pinterest" />
            </Link>
          </ListItemIcon>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Link to="/">
              <img src={github} alt="github" />
            </Link>
          </ListItemIcon>
        </ListItem>
      </Box>
      <Box className={styles.copyright} paddingTop="2rem">
        <Typography>
          Copyright © {new Date().getFullYear()} Traidify. All rights reserved.
        </Typography>
      </Box>
    </footer>
  );
};

export default Footer;
