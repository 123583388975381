import { fromJS } from 'immutable';
import {
  RETRIEVE_TICKER_ACTION,
  RETRIEVE_TICKER_OK,
  RETRIEVE_TICKER_ERROR,
  RETRIEVE_TICKER_END,
  SAVE_TICKER_ACTION,
} from '../constants/save_tickers.constant';

const initial_state = fromJS({
  ticker: [],
  tickerList: [],
  loading: false,
});

const sentimentReducer = (state = initial_state, action) => {
  switch (action.type) {
    case RETRIEVE_TICKER_ACTION:
      return state.set('loading', true);

    case RETRIEVE_TICKER_OK:
      return state.set('ticker', action.data);

    case RETRIEVE_TICKER_ERROR:
      return state;

    case RETRIEVE_TICKER_END:
      return state.set('loading', false);

    case SAVE_TICKER_ACTION:
      return state.set('tickerList', action.tickers);

    default:
      return state;
  }
};

export default sentimentReducer;
