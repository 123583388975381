import cn from 'classnames';
import React from 'react';
// import styles from './Card.module.sass';
import { Box, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import Play from '../../../../components/Play';
import colors from '../../../../styles/colors';
const Card = ({ className, item }) => {
  const styles = useStyles();
  return (
    <Link className={cn(className, styles.card)} to={item.url}>
      <Box className={styles.preview}>
        <img srcSet={`${item.image2x} 2x`} src={item.image} alt="Card" />
        {item.play && <Play className="play" small />}
      </Box>
      {item.categoryText && (
        <Chip
          label={item.categoryText}
          sx={{
            bgcolor:
              item.category === 'green' ? 'green' : item.category === 'purple' ? 'purple' : 'blue',
            color: 'white',
          }}
          className={cn(
            styles.category,
            { 'category-purple': item.category === 'purple' },
            { 'category-green': item.category === 'green' },
            { 'category-blue': item.category === 'blue' }
          )}
        />
      )}
      <Typography className={styles.title} variant="h6">
        {item.title}
      </Typography>
      <Box className={styles.line}>
        <Box className={styles.author}>
          <Box className={styles.avatar}></Box>
          <Typography className={styles.man} variant="body2">
            {item.author}
          </Typography>
        </Box>
        <Typography className={styles.date} variant="body2">
          {item.date}
        </Typography>
      </Box>
    </Link>
  );
};

export default Card;

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingBottom: '48px',
    borderBottom: `2px solid ${colors.n6}`,
    color: colors.n2,
    transition: 'color .2s',
    [theme.breakpoints.only('md')]: {
      paddingBottom: 0,
      border: 'none',
    },

    '&:hover': {
      color: colors.p1,
      '& .preview': {
        '& img': {
          transform: 'scale(1.1)',
        },
      },
    },
  },

  preview: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    marginBottom: '48px',
    borderRadius: '12px',
    [theme.breakpoints.only('md')]: {
      marginBottom: '32px',
    },

    '&:before': {
      content: '',
      display: 'block',
      paddingBottom: '57%',
      [theme.breakpoints.only('lg')]: {
        paddingBottom: '75%',
      },
    },

    '& img': {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transition: 'transform 1s',
    },
  },

  category: {
    marginBottom: '16px',
    fontSize: '1rem !important',
    fontWeight: 'bold',
  },

  title: {
    marginBottom: '48px',
    fontSize: '24px',
    lineHeight: 'calc(32 / 24) * 1px',
    fontWeight: 600,
    letterSpacing: '-.01em',
    [theme.breakpoints.only('md')]: {
      marginBottom: '32px',
    },
  },

  line: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 'auto',
    fontWeight: 500,
    color: colors.n4,
  },

  author: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
  },

  avatar: {
    flexShrink: 0,
    width: '24px',
    height: '24px',
    marginRight: '12px',
    borderRadius: '50%',
    overflow: 'hidden',
    background: colors.p4,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
}));
