import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import upgrade from '../../assets/images/lock.svg';
import { toggleLockModal } from '../../redux/actions/auth.action';
import { Contained } from '../Button';
import Modal from '../Modal';
const Upgrade = ({ open, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const styles = useStyles();

  const handleClick = () => {
    history.push({ pathname: `/app/offers` });
    closeModal();
  };

  const closeModal = () => {
    dispatch(toggleLockModal(false));
  };

  //unused might use in future
  // const openModal = () => {
  //   dispatch(toggleLockModal(true));
  // };
  return (
    <Modal open={open} handleClose={closeModal}>
      <Box className={styles.container}>
        <img alt="Upgrade" src={upgrade} />
        <Typography variant="h5">Upgrade to use this feature</Typography>
        <Box maxWidth={300}>
          <Typography>
            Sorry, you cannot use this feature becase it is not included in your current plan.
          </Typography>
        </Box>
        <Contained rounded onClick={() => handleClick()}>
          Upgrade Now
        </Contained>
      </Box>
    </Modal>
  );
};

export default Upgrade;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    padding: '2rem',
    '& .MuiTypography-root': {
      fontWeight: 'bold !important',
      margin: '0 0 1rem 0',
    },
    '& .MuiTypography-h5': {
      marginTop: '1rem',
      background: theme.palette.gradients.blue_linear,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },
    '& img': {
      height: '95px',
      width: 'auto',
      margin: '0 0 1rem 0',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-body1': {
        fontSize: '1rem',
      },
      '& .MuiTypography-h5': {
        fontSize: '1.2rem',
      },
      '& img': {
        height: '60px',
        margin: '1rem 0',
      },
    },
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '1rem',
    borderRadius: '1.5rem',
    flexGrow: 1,
  },
}));
