import { Box, Card, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import anchor from '../../../assets/images/portfolio_management/anchor.png';
import SubscriptionStatus from '../../../components/SubscriptionStatus';
import useStyle from '../index.style';

const FinishedQuiz = (props) => {
  const styles = useStyle();
  const { userPortfolio } = useSelector((store) => {
    const profile_store = store.profile;

    if (profile_store) return profile_store.toJS();

    return {};
  });

  const { user_details } = useSelector((store) => {
    const login_store = store.login;

    if (login_store) return login_store.toJS();

    return {};
  });

  const { user_data } = useSelector((store) => {
    const auth_store = store.auth;

    if (auth_store) return auth_store.toJS();

    return {};
  });

  const anchorPosition = () => {
    switch (userPortfolio?.data[0]?.attributes?.name) {
      case 'Very Conservative':
        return 1;
      case 'Conservative':
        return 5;
      case 'Moderate':
        return 7;
      case 'Aggressive':
        return 9;
      case 'Very Aggressive':
        return 12;
      default:
        return 0;
    }
  };

  return (
    <Card className={styles.cardContainer}>
      <Box className={styles.riskDetails}>
        <Typography className={styles.boldWhiteText} variant="h2">
          {user_details.first_name || user_details.last_name
            ? `${user_details.first_name} ${user_details.last_name}`
            : 'No name'}
        </Typography>
        <Box mt={2} mb={2}>
          <Typography className={styles.compressedText}>Risk Profile</Typography>
          <Typography className={styles.riskTitle} variant="h3">
            {userPortfolio?.data[0]?.attributes?.name || 'Moderate'}
          </Typography>
        </Box>
        <Box className={styles.divider} />
        <Box display="flex" alignItems="center" mt={4}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box className={styles.inlineDisplay}>
                <Box className={styles.rightText} flexDirection="column" mt={4} id="risk-trade">
                  <Typography className={styles.whiteText}>Max Risk</Typography>
                  <Typography className={styles.whiteText}>Per Trade</Typography>
                </Box>
                <Typography className={styles.boldWhiteText} variant="h1" id="risk-amount">
                  ${user_data?.data?.attributes?.max_loss_amount || 0}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} justifyContent="center">
              <Box className={styles.riskProfileContainer}>
                <Box className={styles.leftText} flexDirection="column">
                  <Typography className={styles.whiteText}>Very</Typography>
                  <Typography className={styles.whiteText}>Conservative</Typography>
                </Box>
                <Box className={styles.rightText} flexDirection="column">
                  <Typography className={styles.whiteText}>Very</Typography>
                  <Typography className={styles.whiteText}>Aggressive</Typography>
                </Box>
              </Box>
              <Grid
                xs={anchorPosition()}
                sm={anchorPosition()}
                md={anchorPosition()}
                lg={anchorPosition()}
                xl={anchorPosition()}
              >
                <Box className={styles.iconContainer}>
                  <img src={anchor} width={30} height={30} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <SubscriptionStatus isWhite justify="center" align="center" />
      </Box>
    </Card>
  );
};

export default FinishedQuiz;
