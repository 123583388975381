import { makeStyles } from '@mui/styles';
import colors from '../../../styles/colors';
const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxwidth: '1280px',
    margin: '50px auto',
    padding: '0 80px',
    [theme.breakpoints.up('lg')]: {
      padding: '0 40px',
    },
    [theme.breakpoints.down('md')]: {
      padding: ' 032px',
    },
  },
  head: {
    // maxWidth: '380px',
    margin: '0 auto 80px',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      marginBottom: '64px',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '40px',
    },
  },
  title: {
    marginBottom: '20px',
    fontWeight: 'bold',
    fontSize: '4.3rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
    },
  },
  info: {
    color: colors.n3,
    ':root :global(.dark-mode)': {
      color: colors.n6,
    },
  },
  list: {
    display: 'flex',
    margin: '0 -16px',
    '@media(minWidth: 1023px)': {
      margin: '0 -40px',
      overflow: 'auto',
      overflowX: 'auto',
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
      '-webkit-overflow-scrolling': 'touch',
      '&::-webkit-scrollbar': {
        display: 'none ',
      },
      '&:before': {},
      '&:after': {
        content: '',
        flexShrink: 0,
        width: '40px',
        height: '1px',
      },
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  item: {
    position: 'relative',
    flex: '0 0 calc(25% - 32px)',
    margin: '0 16px',
    textAlign: 'center',
    '@media(minWidth: 1023px)': {
      flex: '0 0 256px',
      width: '256px',
      margin: 0,
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '&::after': {
      content: '',
      position: 'absolute',
      top: '47px',
      right: '-84px',
      width: '130px',
      height: '2px',
      backgroundImage: `linear-gradient(90deg, ${colors.n5} 0, ${colors.n5} 6px, transparent 6px, transparent 14px)`,
      backgroundRepeat: 'repeat-x',
      backgroundSize: '12px auto',
      [theme.breakpoints.only('xl')]: {
        right: '-66px',
        width: '100px',
      },
      [theme.breakpoints.only('lg')]: {
        right: '-79px',
        width: '120px',
      },
      [theme.breakpoints.down('md')]: {
        right: 0,
      },
    },
    '&::first-child': {
      '& $preview': {
        '&::before': {
          display: 'none',
        },
      },
    },
    '&:last-child': {
      '&::after': {
        display: 'none',
      },
      '& $preview': {
        '&::after': {
          display: 'none',
        },
      },
    },
    '&:not(:last-child)': {
      [theme.breakpoints.up('lg')]: {
        marginRight: '32px',
      },
    },
  },
  preview: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '96px',
    height: '96px',
    margin: '0 auto 80px',
    borderRadius: '24px',
    [theme.breakpoints.down('md')]: {
      margin: '0 0 32px 0',
    },
    '&::before': {},
    '&::after': {
      content: '',
      position: 'absolute',
      top: '50%',
      width: '12px',
      height: '12px',
      transform: 'translateY(-50%)',
      borderRadius: '50%',
      border: `2px solid ${colors.n5}`,
    },
    '&::before': {
      left: '-24px',
    },
    '&::after': {
      right: '-24px',
    },
    '& img': {
      width: '100%',
    },
  },
  number: {
    marginBottom: '32px',
    fontSize: theme.typography.captionBold2.fontSize,
    lineHeight: theme.typography.captionBold2.lineHeight,
    fontWeight: theme.typography.captionBold2.fontWeight,
    color: colors.n4,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  subtitle: {
    marginBottom: '16px',
    fontSize: theme.typography.bodyBold2.fontWeight,
    lineHeight: theme.typography.bodyBold2.lineHeight,
    fontWeight: theme.typography.bodyBold2.fontWeight,
    [theme.breakpoints.down('md')]: {
      fontWeight: 600,
      textAlign: 'center',
    },
  },
  content: {
    color: colors.n3,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
}));

export default useStyles;
