import { makeStyles } from '@mui/styles';
import colors from '../../styles/colors';
const useStyles = makeStyles((theme) => ({
  card: {
    display: 'block',
    color: colors.n2,
    transition: 'color .2s',
    '&:hover': {
      color: colors.p1,
      '& .preview': {
        '& img': {
          transform: 'scale(1.1)',
        },
      },
    },
  },
  preview: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '16px',
    '& img': {
      width: '100%',
      transition: 'transform 1s',
    },
    '& .category': {
      position: 'absolute',
      top: '8px',
      left: '8px',
      zIndex: 2,
    },
  },

  body: {
    display: 'flex',
    padding: '20px 0',
  },

  avatar: {
    position: 'relative',
    top: '2px',
    flexShrink: 0,
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    overflow: 'hidden',
    background: colors.s2,

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },

  details: {
    flexGrow: 1,
    padding: '0 16px',
  },

  title: {
    marginBottom: '2px',
    fontSize: '16px',
    lineHeight: 24 / 16,
    fontWeight: 500,
  },

  author: {
    fontSize: '12px',
    lineHeight: 20 / 12,
    color: colors.n4,
  },

  status: {
    alignSelf: 'flex-start',
  },
}));

export default useStyles;
