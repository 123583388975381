import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import colors from '../../styles/colors';
const ADXBarChart = ({ percent, data, ...props }) => {
  const classes = useStyles();
  const items = [];

  for (let i = 0, l = 15; i < l; i++) {
    items.push(<BarItem key={i + '-' + data[i].value} classes={classes} value={data[i].value} />);
  }

  return (
    <Box className={classes.container}>
      <Box display="flex">
        <Box flex="1">
          <Typography className={classes.title} variant="h4">
            {percent || 0}%
            <ArrowDropDownSharpIcon fontSize="inherit" htmlColor={colors.red3} />
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        {items}
      </Box>
    </Box>
  );
};

const BarItem = ({ classes, day, value, ...props }) => {
  return (
    <Box className={classes.dayItem}>
      <Box>
        <Box className={classes.bar}>
          <Box display="flex" flex={100 - value} />
          <Box display="flex" flex={value} />
        </Box>
      </Box>
    </Box>
  );
};

export default ADXBarChart;

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFF',
    minHeight: '200px',
    flex: 1,
    borderRadius: '16px',
    padding: '.875rem 1rem',
  },
  title: {
    color: '#FFF',
    fontWeight: 'bold',
    color: colors.red3,
    textAlign: 'center',
  },
  value: {
    color: '#FFF',
    fontSize: '1.2rem',
    fontWeight: 'bold',

    '& span': {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      opacity: 0.5,
    },
  },

  dayItem: {},
  bar: {
    height: '150px',
    width: '1rem',
    backgroundColor: 'rgba(138, 140, 178, 0.3)',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column',

    '& > div': {
      transition: 'all ease 1s',
      display: 'flex',
    },

    '& > div:last-child': {
      width: 'inherit ',
      backgroundColor: colors.red3,
      borderRadius: '1rem',
    },
  },
}));
