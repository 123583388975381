export const SEARCH_STOCK_ACTION = 'stock/SEARCH_STOCK_ACTION';
export const SEARCH_STOCK_OK = 'stock/SEARCH_STOCK_OK';
export const SEARCH_STOCK_ERROR = 'stock/SEARCH_STOCK_ERROR';
export const SEARCH_STOCK_END = 'stock/SEARCH_STOCK_END';

export const DAILY_STOCK_PRICES_ACTION = 'stock/DAILY_STOCK_PRICES_ACTION';
export const DAILY_STOCK_PRICES_OK = 'stock/DAILY_STOCK_PRICES_OK';
export const DAILY_STOCK_PRICES_ERROR = 'stock/DAILY_STOCK_PRICES_ERROR';
export const DAILY_STOCK_PRICES_END = 'stock/DAILY_STOCK_PRICES_END';

//STOCK PRICES
export const STOCK_PRICES_ACTION = 'stock/STOCK_PRICES_ACTION';
export const STOCK_PRICES_OK = 'stock/STOCK_PRICES_OK';
export const STOCK_PRICES_ERROR = 'stock/STOCK_PRICES_ERROR';
export const STOCK_PRICES_END = 'stock/STOCK_PRICES_END';

export const EDIT_DATA_ACTION = 'stock/EDIT_DATA_ACTION';

export const RISK_INDICATOR_ACTION = 'stock/RISK_INDICATOR_ACTION';
export const RISK_INDICATOR_OK = 'stock/RISK_INDICATOR_OK';
export const RISK_INDICATOR_ERROR = 'stock/RISK_INDICATOR_ERROR';
export const RISK_INDICATOR_END = 'stock/RISK_INDICATOR_END';
