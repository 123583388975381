import { Box, Container, Typography } from '@mui/material';
import cn from 'classnames';
import React from 'react';
import Slider from 'react-slick';
import Card from './Card';
import useStyles from './index.style';

const items = [
  {
    title: 'Be Part of our Global Community',
    content:
      'Let’s stay in touch. Join our communities to keep up with the Traidify team and our traders from across the world.',
    stage: 'crypto and stock news',
    image: '/images/content/news-pic-1.png',
    image2x: '/images/content/news-pic-1@2x.png',
    url: '/learn-crypto-details',
  },
  {
    title: 'Be Part of our Global Community',
    content:
      'Let’s stay in touch. Join our communities to keep up with the Traidify team and our traders from across the world.',
    stage: 'Join now',
    image: '/images/content/news-pic-1.png',
    image2x: '/images/content/news-pic-1@2x.png',
    url: '/learn-crypto-details',
  },
  {
    title: 'Be Part of our Global Community',
    content:
      'Let’s stay in touch. Join our communities to keep up with the Traidify team and our traders from across the world.',
    stage: 'crypto news',
    image: '/images/content/news-pic-1.png',
    image2x: '/images/content/news-pic-1@2x.png',
    url: '/learn-crypto-details',
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const News = ({ classSection }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <SlickArrow>{/* <Icon name="arrow-next" size="18" /> */}</SlickArrow>,
    prevArrow: <SlickArrow>{/* <Icon name="arrow-prev" size="18" /> */}</SlickArrow>,
  };
  const styles = useStyles();

  return (
    <Container className={cn(classSection, styles.section)}>
      <Box className={cn('container', styles.container)}>
        <Box className={styles.head}>
          <Box className={styles.logo}></Box>
          <Typography className={styles.title} variant="h2" fontWeight={600}>
            Your out-of-the box hedge fund solution
          </Typography>
        </Box>
        <div className={styles.wrapper}>
          <Slider className="news-slider" {...settings}>
            {items.map((x, index) => (
              <Card className={styles.card} item={x} key={index} />
            ))}
          </Slider>
        </div>
      </Box>
    </Container>
  );
};

export default News;
