import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/Modal';
import CreditCard from './CreditCard';

import { togglePaymentDetailsModal } from '../../redux/actions/payments.action';
import { isFormValid } from '../../redux/reselect/payments';

import {
  validateCardHolder,
  validateCardNumber,
  validateCvc,
  validateEmail,
  validateExpiry,
} from '../../helpers/validations';

import {
  editFormAction,
  paymentsAction,
  resetAction,
  setErrorAction,
} from '../../redux/actions/payments.action';

const PaymentDetailsModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const validForm = useSelector(isFormValid);

  const { form, errors, loading } = useSelector((store) => {
    const payment_store = store.payments;

    if (payment_store) return payment_store.toJS();

    return {};
  });

  useEffect(() => {
    return () => {
      dispatch(resetAction());
    };
  }, []);

  const handleOnChange = (e) => {
    const { id: key, value } = e.target;
    dispatch(editFormAction(key, value));
  };

  const handleValidate = (e) => {
    const { id, value } = e.target;
    let errors = [];

    switch (id) {
      case 'name':
        errors = validateCardHolder(value);
        break;

      case 'email':
        errors = validateEmail(value);
        break;

      case 'number':
        errors = validateCardNumber(value);
        break;

      case 'expiry':
        errors = validateExpiry(value);
        break;

      case 'cvc':
        errors = validateCvc(value);
        break;

      default:
        break;
    }

    if (!!errors.length) {
      dispatch(setErrorAction(id, errors));

      return false;
    }

    return true;
  };

  const validateAll = () => {
    return (
      handleValidate({ target: { id: 'email', value: form.email } }) &&
      handleValidate({ target: { id: 'number', value: form.number } }) &&
      handleValidate({ target: { id: 'name', value: form.name } }) &&
      handleValidate({ target: { id: 'expiry', value: form.expiry } }) &&
      handleValidate({ target: { id: 'cvc', value: form.cvc } })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const allFieldsValid = validateAll();

    if (!allFieldsValid) {
      return false;
    }

    dispatch(paymentsAction(form));
  };

  const closeModal = () => {
    dispatch(togglePaymentDetailsModal(false));
  };

  return (
    <Modal open={open} handleClose={closeModal}>
      <Box>
        <Box>
          <Typography variant="h5">Payment Information</Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1">Credit Card</Typography>
        </Box>
        <Box className={classes.container}>
          <CreditCard
            validForm={validForm}
            form={form}
            errors={errors}
            loading={loading}
            handleSubmit={handleSubmit}
            handleOnChange={handleOnChange}
            handleValidate={handleValidate}
            validateAll={validateAll}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default PaymentDetailsModal;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '2rem 2rem',

    [theme.breakpoints.down('sm')]: {
      padding: '2rem 2rem',
    },
  },
}));
