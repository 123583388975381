import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Checkbox, Hidden, Typography } from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Modal from '../../components/Modal';
import { MEMBERSHIP_AGREEMENT_POPUP_KEY } from '../../constants/auth';
import { getPermission } from '../../redux/actions/auth.action';
import { updateMetadataAction } from '../../redux/actions/login.action';
import { getUserPortfolio } from '../../redux/actions/profile.action';
import { fetchUserDetails } from '../../redux/actions/quiz.action';
// import { fetchSubscriptions } from '../../redux/actions/stripe.action';
import { loginSelector } from '../../redux/reselect/loginSelector';
import UserMembershipAgreement from '../TermsOfService/UserMembershipAgreement';
import Header from './Header';
import useStyles from './index.style';

import Footer1 from '../../components/Footer1';
import { getItem } from '../../helpers/utils';

const App = (props) => {
  const styles = useStyles();
  const main_ref = useRef();
  useSelector((store) => {
    const quiz_store = store.quiz;

    if (quiz_store) return quiz_store.toJS();

    return {};
  });
  const login = useSelector(loginSelector);
  const { user_details } = login;
  const appStore = useSelector((store) => store.app.toJS());
  const dispatch = useDispatch();
  const { metadata } = user_details || {};
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [show_membership_agreement, setShowmembershipAgreement] = useState(false);
  const [isCheckedAgreement, setIsCheckedAgreement] = useState(false);

  useEffect(() => {
    dispatch(getUserPortfolio());
    dispatch(getPermission());
    // dispatch(fetchSubscriptions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkMembershipAgreement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata?.membership_agreement]);

  const checkMembershipAgreement = () => {
    console.log({ metadata }, !metadata?.membership_agreement);
    if (!!metadata && !metadata?.membership_agreement) {
      const next_popup_show = +getItem(MEMBERSHIP_AGREEMENT_POPUP_KEY);
      const show_next_date = new Date(next_popup_show);
      console.log({ next_popup_show, show_next_date }, show_next_date <= new Date());

      if (!next_popup_show) {
        setShowmembershipAgreement(true);
      } else if (!!next_popup_show && show_next_date <= new Date()) {
        setShowmembershipAgreement(true);
      }
    }
  };

  const handleAgreeMembershipButtonClick = () => {
    setShowmembershipAgreement(false);
    const params = {
      data: {
        type: 'users',
        id: user_details.id,
        attributes: { metadata: { ...metadata, membership_agreement: 1 } },
      },
    };
    dispatch(updateMetadataAction(user_details.id, params));
  };

  const handleAgreeMembershipCheckboxChange = (event) => {
    setIsCheckedAgreement(event.target.checked);
  };

  return (
    <Box component="div" style={{ height: '100vh', width: '100%' }}>
      <Header mobileDrawerOpen={mobileDrawerOpen} setMobileDrawerOpen={setMobileDrawerOpen} />
      {/* <Drawer
        mobileDrawerOpen={mobileDrawerOpen}
        setMobileDrawerOpen={setMobileDrawerOpen}
        user_quiz_completed={userQuizDetails.completed}
        dispatch={dispatch}
        location={location}
      /> */}
      <main
        ref={main_ref}
        className={clsx(styles.content, { [styles.contentShift]: appStore.open })}
      >
        <Modal open={show_membership_agreement}>
          <Box px={12} py={6}>
            <UserMembershipAgreement />
            <Box pt="1rem" mt={4} textAlign="center">
              <Box>
                <Typography variant="caption">
                  <span>
                    <Checkbox
                      aria-label="Terms Checkbox"
                      size="small"
                      checked={isCheckedAgreement}
                      onChange={handleAgreeMembershipCheckboxChange}
                    />
                  </span>
                  I agree to Traidify's{' '}
                  <Link href="/app/legal">Terms and Conditions and Privacy Policy</Link>.
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" mt={4}>
                <Button
                  variant="contained"
                  onClick={handleAgreeMembershipButtonClick}
                  disabled={!isCheckedAgreement}
                >
                  I agree
                </Button>
              </Box>
            </Box>
          </Box>
          {/* <UserMembershipAgreement
            handleAgreeMembershipAgree={handleAgreeMembershipAgree}
            handleAgreeMembershipDisagree={handleAgreeMembershipDisagree}
          /> */}
        </Modal>
        {React.Children.toArray(props.children)}
      </main>
      <Hidden smDown>
        <Footer1 shift_drawer={false} target={main_ref.current} />
      </Hidden>
    </Box>
  );
};

export default App;
