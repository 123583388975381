import { Box, ButtonBase, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
const ItemLink = ({ label, selected = false, className, slug, icon, ...props }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleItemClick = (value) => {
    history.push('/app/' + value);
  };
  return (
    <ButtonBase
      onClick={() => handleItemClick(slug)}
      className={clsx(classes.root, { [classes.notSelected]: !selected }, className)}
    >
      <Box className={classes.inner}>
        <img src={icon} className={clsx(classes.icon, { [classes.selectedIcon]: selected })} />
        <Typography variant="subtitle2"> {label}</Typography>
      </Box>
    </ButtonBase>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'linear-gradient(90.08deg, #5C62ED -48.14%, #41E7B0 108.38%)',
    borderRadius: '50px',
    display: 'inline-block',
    color: 'white',
    zIndex: 1,
    border: 'solid white 5px',
    minWidth: '220px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100px',
    },
  },
  inner: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: 'inherit',
    height: 'inherit',
    borderRadius: '50px',
    padding: '10px 20px',
    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-root': {
        fontSize: '0.7rem',
      },
      '& img': {
        display: 'none',
      },
    },
  },
  notSelected: {
    padding: '2px',

    '& > div': {
      backgroundColor: 'white',
      color: theme.palette.peter_river,
    },
  },
  icon: {
    height: '25px',
    width: 'auto',
  },
  selectedIcon: {
    filter: 'brightness(0) invert(1)',
  },
}));

export default ItemLink;
