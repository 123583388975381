import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../components/Modal';
import PaymentForm from './PaymentForm';

import { toggleStripeModal } from '../../redux/actions/payments.action';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  'pk_test_51J8E9LCzL4zIS5awjcZDrOHcJBgU2ck3HZ6lQWmzF8rL9IYBodxfQnjP86R75gfXR1GWf1nluKHFWiGp1IRyv9Od00Lk1rnuaX'
);

const CheckoutForm = ({ selectedPlan, ...props }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const { stripeModal } = useSelector((store) => {
    const payments_store = store.payments;

    if (payments_store) return payments_store.toJS();

    return {};
  });

  //unused might use in future
  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: 'pi_1DrarH2eZvKYlo2CwLNBzpA1_secret_gnpZejaVQsL2DmQ3LibqMI882',
  // };

  const closeModal = () => {
    dispatch(toggleStripeModal(false));
  };

  return (
    <Modal open={stripeModal} handleClose={closeModal}>
      <Container className={styles.container}>
        <Elements stripe={stripePromise}>
          <PaymentForm closeModal={closeModal} selectedPlan={selectedPlan} />
        </Elements>
      </Container>
    </Modal>
  );
};

export default CheckoutForm;

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: '500px',

    [theme.breakpoints.down('sm')]: {
      minWidth: '300px',
      padding: 0,
    },
  },
}));
