const COOKIE_POLICY = {
  title: 'Cookies Policy',
  description: `This Cookies Policy ("Cookies Policy") describes cookies and other technologies that Traidify, LLC. ("Traidify", "we", "us" or "our") uses on its website ("Site") and the choices that users have. This Cookies Policy is a part of Traidify’s Privacy Policy. When you first visit the Site, you will be asked to consent to the use of cookies in accordance with this Cookies Policy. Note that if you accept, we will store them on your computer.

  **What is a cookie?**
  A 'cookie' is a piece of information that is sent to your browser by a website you visit. The Site uses first party cookies (those set by a website that is being visited by the user at the time. For example, cookies via www.taidify.com) as well as third-party cookies (set by a different domain), as described below.
  
  Cookies can be stored on your computer for various periods of time. They can be in a form of either "session cookies" or "persistent cookies." A session cookie only lasts as long as the browser session and is automatically deleted when you close your browser. A persistent cookie lasts long after your browser is closed and will remain until it expires (as determined by the third party in charge of placing it) or until you delete the cookie. Persistent cookies are used to help sites recognize and identify your computer when you open your browser and surf the Internet again.
  
  The data collected through cookies may include information about the IP (Internet Protocol) address of your device, browser type, language, operating system, the state or country from which you have accessed the Site, the date and the time of your visit(s), the number of links you click on this Site, the functions you use, the searches you request, and the data you have saved while on this Site. Taidify may use the information collected for a variety of necessary, legitimate purposes, including user authentication, user interface customization, security, research and analysis to improve the functionality of our Site, and advertising (for more information on how we use your data, please read our Privacy Policy).
  
  Most browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies. If you reject the use of cookies, you will not be able to access the paid areas of StockCharts.com and you will no longer be able to customize your user interface preferences; however, you will still be able to use the free areas of the Site.
  
  You can find out more about cookies (including how to see or manage them) at All About Cookies or AboutCookies.org websites.
  
  **How do we use cookies?**
  The following sets out how we use different categories of cookies, as well as information on your options for managing your settings for the data collected by these technologies:
  
  -   Necessary cookies: These cookies are used to provide users with services available through a Site and to use some of its features, such as the ability to log-in and access secure areas, provide pop-up notices, and accept language from sign-up forms. These cookies are essential for using and navigating a Site. Without them, basic functions of our Site would not work. Because these cookies are strictly necessary to deliver our Site’s essential services, you cannot refuse them.    
  -   Performance/Analytics cookies: These cookies are used to recognize and count the number of Site visitors, gather statistics regarding how visitors move around the Site (including number of page views and the amount time spent on each page) and for conversion tracking and click hotspots. This helps us improve the way our Site works and general user experience. For example, these cookies allow us to ensure that users are able to find what they need easily. 
  -   Advertising cookies (ad cookies): These cookies are used to show advertising that is relevant to you.
  -   Cookies used to integrate third party services: These cookies are used to integrate third-party functions on the Site, such as videos, maps, or social network plug-ins.
  -   Other third party cookies: On some parts of the Site, including, but not limited, to news widgets, we use content provided by other websites(third parties). These third party sites are able to set their own cookies in some cases. Note that we have no control over these third-party cookies. You can turn them off by disabling them on your browser. You can find more information on the most popular web-browser help pages:
      -   Google Chrome 
      -   Mozilla Firefox   
      -   Microsoft Edge
      -   Safari (macOS)
      -   Safari (iOS)
  
  For other browsers, please consult the documentation that your browser manufacturer provides.
  
  **Web beacons**
  Web beacons, also called tracking pixels, are tiny graphics with unique identifiers that allow us to track usage patterns, count users who have visited a particular page, advertising impressions and clicks (ad reactions), etc. Web beacons can only collect limited information, including a cookie number, time and date of a page view, and a description of the page on which the web beacon resides. These beacons do not carry any personal data and are only used to track the effectiveness of this Site and to show advertisements based on user interests. We use web beacons in connection with Google Analytics and advertising services as well as our Snowplow Tracker. The information generated relating to our Site is used for various necessary and legitimate purposes, including the creation of reports on the use of this site as well as the development, testing, and improvement of services. Google will store information of users’ actions on the Traidify Site and it will be collected by Google services. For more information on Google’s use of data for marketing and analytics purposes, please see Advertising – Privacy & Terms and Google’s Privacy Policy.
  
  **Handling payments**
  Unless otherwise specified, this Website processes any payments by credit card, bank transfer or other means via external payment service providers. In general and unless where otherwise stated, Users are requested to provide their payment details and personal information directly to such payment service providers. This Website isn't involved in the collection and processing of such information: instead, it will only receive a notification by the relevant payment service provider as to whether payment has been successfully completed.
  
  **Changes**
  We may change the type of third party service providers that place cookies on our Site and amend this Cookies Policy at any time by posting the amended version on our Site. Unless additional notice or consent is required by applicable laws, this will serve as your official notification of these changes.
  
  **With Whom Traidify May Share User Information?**
  Though we make every effort to preserve User privacy, we may disclose your Personal Information to third parties when we believe, in good faith and in our sole discretion, that such disclosure is reasonably necessary to: (1) enforce or apply the terms and conditions of the Site, including investigation of potential violations thereof, (2) comply with legal or regulatory requirements or an enforceable governmental request, (3) protect the rights, property or safety of us, our Users, or other third parties, (4) prevent a crime or protect national security, or (5) detect, prevent or otherwise address fraud, security, or technical issues.
  
  We may disclose Personal Information and non-Personal Information to employees, companies, agents, contractors, service providers or others engaged to perform functions on our behalf. These companies do not retain, share, store, or use Personal Information for any secondary purpose.
  
  We may also disclose non-Personal Information (including de-identified, aggregated Personal Information) to our business partners, merchants, investors, potential buyers, and other third parties if we deem such disclosure, at our sole discretion, to have sound business reasons or justifications.
  
  We do NOT sell, rent, share or trade your Personal Information to third parties for their own marketing purposes.
  
  Please note also that if you choose to use our social networking function to post information to Facebook, Twitter or another social networking site, anyone who has access to your information through that other site will be able to see your information. Use of social networking functions or other information sharing functions on our Site is considered to be voluntary disclosure of Personal Information by you.
  
  
  **Business Transitions**
  Traidify.com reserves the right to sell or transfer information (including your Personal and non-Personal Information) to a third party in the event of a sale, merger, or transfer of all or substantially all of the assets of our company, or in the unlikely event of a bankruptcy, liquidation, or receivership of our business, and you hereby consent to any such sale or transfer. If any such transfer is required, we will make reasonable efforts to notify you in writing in advance by posting a notice on the Site and/or emailing you at your contact email address of record, and we will make reasonable efforts to ensure that the transferee will continue to use any Personal Information collected from you prior to the transfer in accordance with the terms of the then-current version of this Privacy Statemen`,
};

export default COOKIE_POLICY;
