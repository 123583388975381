import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Contained } from '../../components/Button';
import CheckoutForm from '../../components/CheckoutForm';
import PaymentDetailsModal from '../../components/PaymentDetailsModal';
import SaveTickers from '../../components/SaveTickers';
import SuccessModal from '../../components/SuccessModal';
import { toggleStripeModal } from '../../redux/actions/payments.action';
import {
  cancelSubscription,
  fetchProducts,
  fetchSubscriptions,
  setPlan,
  toggleSaveTickerModal,
  toggleSuccessModal,
} from '../../redux/actions/stripe.action';
import { stripeSelector } from '../../redux/reselect/stripeSelector';
import useStyles from './index.style';
import Plans from './Plans';

const Offers = (props) => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedPlan, setSelectedPlan] = useState('');
  const [monthlyProducts, setMonthlyProducts] = useState([]);

  const { success, saveTickerModal, subscriptions, products } = useSelector(stripeSelector);
  const { showPaymentDetailsModal } = useSelector((store) => {
    const payments_store = store.payments;

    if (payments_store) return payments_store.toJS();

    return {};
  });

  useEffect(() => {
    dispatch(fetchProducts());
  }, []);

  useEffect(() => {
    if (products) {
      const getProducts = products?.filter((product, index) => {
        if (product?.attributes?.recurring?.interval === 'month') {
          return product;
        }
      });
      setMonthlyProducts(getProducts);
    }
  }, [products]);

  useEffect(() => {
    if (success === true) {
      dispatch(cancelSubscription(subscriptions[0]?.djstripe_id));
      dispatch(toggleStripeModal(false));
      dispatch(fetchSubscriptions());
    }
  }, [success]);

  const closeSuccessModal = () => {
    dispatch(toggleSuccessModal(false));
    dispatch(setPlan({}));
  };

  const closeSaveTickerModal = () => {
    dispatch(toggleSaveTickerModal(false));
  };

  const canGoto = () => {
    if (selectedPlan === 'Pro' || 'Premium') {
      return (
        <Contained rounded onClick={() => handleGoto()}>
          Go to Technical Analysis
        </Contained>
      );
    }
  };

  const handleGoto = () => {
    history.push({ pathname: `/app/technical-analysis` });
    closeSuccessModal();
  };

  const accessPage = () => {
    if (selectedPlan === 'Pro') {
      return (
        <Typography>
          You can now access <span className={styles.focus}>Technical Analysis page.</span>
        </Typography>
      );
    } else if (selectedPlan === 'Premium') {
      return (
        <Typography>
          You can now access{' '}
          <span className={styles.focus}>Technical Analysis and Global Macro page.</span>
        </Typography>
      );
    } else {
      return (
        <Typography>
          You can now save & update{' '}
          <span className={styles.focus}>{selectedPlan.replace(' Plan', 's')}.</span>
        </Typography>
      );
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
        <Typography className={styles.coloredText}>
          Try any of our Pro plan, free for 30 days
        </Typography>
        <Plans setSelectedPlan={setSelectedPlan} products={monthlyProducts} />
      </Box>

      <CheckoutForm selectedPlan={selectedPlan} />
      <PaymentDetailsModal open={showPaymentDetailsModal} />
      <SuccessModal
        open={success}
        handleClose={closeSuccessModal}
        message={`Congratulation, you have successfully subscribed to ${selectedPlan.replace(
          'Plan',
          ''
        )} plan. `}
        subsMessage={accessPage()}
        gotoBtn={canGoto()}
      />
      <SaveTickers
        open={saveTickerModal}
        handleClose={closeSaveTickerModal}
        selectedBasic={selectedPlan}
      />
    </>
  );
};

export default Offers;
