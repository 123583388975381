import { makeStyles } from '@mui/styles';
import colors from '../../../../styles/colors';

const useStyles = makeStyles((theme) => ({
  item: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row-reverse',
    color: colors.n2,
    transition: 'color .2s',
    marginTop: '4rem',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
    '&:hover': {
      color: colors.p1,
      '&.arrow': {
        transform: 'translateX(10px)',
        '& svg': {
          fill: colors.p1,
        },
      },
    },
  },

  preview: {
    flexShrink: 0,
    width: '640px',
    marginLeft: 'auto',
    [theme.breakpoints.down('xl')]: {
      width: '500px',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      marginBottom: '32px',
    },
    '& img': {
      width: '100%',
      borderRadius: '16px',
      [theme.breakpoints.down('lg')]: {
        minHeight: '240px',
        objectFit: 'cover',
      },
    },
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '352px',
    [theme.breakpoints.down('xl')]: {
      width: 'auto',
      paddingRight: '32px',
    },
    [theme.breakpoints.down('lg')]: {
      paddingRight: 0,
    },
  },

  category: {
    marginBottom: 'auto',
  },

  title: {
    margin: '24px 0 8px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '16px ',
    },
  },

  currency: {
    marginBottom: '20px',
    fontSize: '16px',
    lineHeight: 'calc(24 / 16) * 1px',
    color: colors.n3,
  },

  content: {
    marginBottom: 'auto',
    color: colors.n4,
  },

  arrow: {
    position: 'absolute',
    top: 'calc(100% + 14px)',
    left: 0,
    width: '24px',
    height: '24px',
    transition: 'transform .2s',
    [theme.breakpoints.down('lg')]: {
      position: 'static',
      marginTop: '16px',
    },

    '& svg': {
      fill: colors.n4,
      transition: 'fill .2s',
    },
  },
}));

export default useStyles;
