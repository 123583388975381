import React from 'react';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const PieChart = (props) => {
  const { labels, values, size, showLegend, bgColor } = props;

  const style = {
    chart: {
      width: size * 12,
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: '# of Votes',
        data: values || [12, 19, 3, 5, 2, 3],
        backgroundColor: bgColor || [
          'rgba(54, 162, 235)',
          'rgba(255, 99, 132)',
          'rgba(255, 206, 86)',
          'rgba(75, 192, 192)',
          'rgba(153, 102, 255)',
          'rgba(255, 159, 64)',
        ],
        borderColor: bgColor || [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: showLegend,
        position: 'bottom',
        align: 'center',
      },
      datalabels: {
        display: true,
        color: 'white',
        anchor: 'center',
        // font: function(context) {
        //   var width = context.chart.width;
        //   var size = Math.round(width / 25);
        // 	return {
        //   	size: size,
        //     weight: 600
        //   };
        // },
        padding: {
          bottom: 4,
        },
        offset: 45,
        formatter: function (value, context) {
          return value + '%';
        },
      },
      responsive: true,
    },
  };

  return (
    <div style={style.chart}>
      <Pie data={data} plugins={[ChartDataLabels]} options={options} width={size} height={size} />
    </div>
  );
};

export default PieChart;
