import { AppBar, Box, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import desktop_logo from '../../assets/images/header/desktop_logo.png';
// import img_logo from '../../assets/images/header/mini_logo.png';
// import img_logo_white from '../../assets/images/header/mini_logo_white.png';
import icon_template from '../../assets/images/header/template.png';
import icon_template_white from '../../assets/images/header/template_white.png';
import { Contained } from '../Button';
import { TYPE_2 } from './constants';
import styles from './index.style';

const Header = ({ classes, type, history }) => {
  const isWhite = type === TYPE_2;
  // const logo = !isWhite ? img_logo : img_logo_white;
  const template = isWhite ? icon_template_white : icon_template;

  const tabs = [
    // { id: '', text: 'Home' },
    // { id: 'discover', text: 'Discover' },
    // { id: 'contact', text: 'Contact Us' },
    // { id: 'about-us', text: 'About Us' },
  ];

  const handleGoToPage = (link) => {
    history.push({ pathname: `/${link}` });
  };

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar className={clsx(classes.toolbar, { [classes.whiteToolbar]: isWhite })}>
        <Box display="flex" justifyContent="flex-start">
          <Link to="/landing-home" onClick={() => window.scrollTo(0, 0)}>
            <img src={desktop_logo} width={105} height={35} alt="Traidify" />
          </Link>
        </Box>
        <Box
          flex="1"
          flexDirection="row"
          display="flex"
          justifyContent="center"
          height="100%"
          className={classes.navContainer}
        >
          {tabs?.map((detail, index) => (
            <ListItemButton
              className={clsx(classes.linkItem, { [classes.whiteLinkItem]: isWhite })}
              title={detail.text}
              onClick={() => handleGoToPage(detail.id)}
            >
              <ListItemIcon>
                <img src={template} alt="navigation link company" />
              </ListItemIcon>
              <Box justifyContent="center" display="flex">
                <ListItemText primary={detail.text} />
              </Box>
            </ListItemButton>
          ))}
        </Box>
        <Box display="flex" justifyContent="center">
          <Contained
            white={isWhite}
            variant="contained"
            color="primary"
            rounded
            onClick={() => history.push({ pathname: `/sign-up` })}
          >
            SIGN UP TODAY
          </Contained>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default withStyles(styles, { withTheme: true })(Header);
