import React from 'react';
// import styles from './Lessons.module.sass';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import colors from '../../../styles/colors';
import Card from './Card';
const items = [
  {
    title: 'Learn about UI8 coin and earn an All-Access Pass',
    author: 'Floyd Buckridge',
    date: 'Feb 03, 2021',
    category: 'purple',
    categoryText: 'learn & earn',
    image: '/images/content/lessons-pic-1.jpg',
    image2x: '/images/content/lessons-pic-1@2x.jpg',
    play: true,
    url: '/learn-crypto',
  },
  {
    title: 'Submit your watchlist and win USDT',
    author: 'Floyd Buckridge',
    date: 'Feb 03, 2021',
    category: 'green',
    categoryText: 'weekly watchlist airdrop',
    image: '/images/content/lessons-pic-2.jpg',
    image2x: '/images/content/lessons-pic-2@2x.jpg',
    play: false,
    url: '/learn-crypto',
  },
  {
    title: 'Submit your watchlist and win USDT',
    author: 'Floyd Buckridge',
    date: 'Feb 03, 2021',
    category: 'blue',
    categoryText: 'featured',
    image: '/images/content/lessons-pic-3.jpg',
    image2x: '/images/content/lessons-pic-3@2x.jpg',
    play: true,
    url: '/learn-crypto',
  },
  {
    title: 'Learn about UI8 coin and earn an All-Access Pass',
    author: 'Floyd Buckridge',
    date: 'Feb 03, 2021',
    category: 'purple',
    categoryText: 'learn & earn',
    image: '/images/content/lessons-pic-1.jpg',
    image2x: '/images/content/lessons-pic-1@2x.jpg',
    play: true,
    url: '/learn-crypto',
  },
];

const Lessons = ({ classSection }) => {
  const styles = useStyles();
  return (
    <Box className={styles.section} mt={6}>
      <Box className={styles.container}>
        <Box className={styles.head}>
          <Typography className={styles.title} variant="h3">
            Learn and earn
          </Typography>
          <Typography className={styles.info} variant="body1">
            Stacks is a production-ready library of stackable content blocks built in React Native.
          </Typography>
        </Box>
        <Box className={styles.list}>
          {items.map((x, index) => (
            <Card className={styles.card} item={x} key={index} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Lessons;

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxwidth: '1500px',
    padding: '0 80px',
    [theme.breakpoints.up('lg')]: {
      padding: '40px',
    },
    [theme.breakpoints.only('md')]: {
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 32px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },

  section: {
    marginBottom: '136px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '112px',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '64px',
    },
  },

  head: {
    maxWidth: '455px',
    margin: '0 auto 64px',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '48px',
    },
  },

  title: {
    marginBottom: '20px',
  },

  info: {
    fontSize: '16px',
    lineHeight: 'calc(24 / 16) * 1px',
    color: colors.n3,
  },

  list: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-48px -16px 0',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      margin: 0,
    },
  },

  card: {
    flex: '0 0 calc(33.333% - 32px)',
    width: 'calc(33.333% - 32px)',
    margin: '48px 16px 0',
    [theme.breakpoints.down('lg')]: {
      flex: '0 0 calc(50% - 32px)',
      width: 'calc(50% - 32px)',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '32px 0 0',
    },
    '&:nth-child(n+4)': {
      display: 'none',
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
      },
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },
  btns: {
    marginTop: '64px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: '48px',
    },
  },

  button: {
    '& .loader': {
      margin: '0 16px 0 5px',
      transform: 'scale(.8)',
    },
  },
}));
