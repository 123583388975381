import cn from 'classnames';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Switch from '../../../../components/Switch';
import { THEME_DARK_MODE } from '../../../../constants';
import { toggleThemeAction } from '../../../../redux/actions/app.action';
import { logoutAction } from '../../../../redux/actions/auth.action';
import styles from './User.module.sass';

import { Icon, Typography } from '@mui/material';
import { ReactComponent as UserProfileSVG } from '../../../../assets//icons/header/user_profile.svg';

const User = ({ className }) => {
  const { user_details } = useSelector((store) => {
    const login_store = store.login;

    if (login_store) return login_store.toJS();

    return {};
  });
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const theme = useSelector((store) => store.app.toJS().theme);

  const items = [
    {
      title: 'Profile',
      icon: 'user',
      content:
        user_details.first_name || user_details.last_name
          ? `${user_details.first_name} ${user_details.last_name}`
          : 'User has no Name',
      url: '/app/profile',
    },
    {
      title: 'Payments',
      icon: 'lock',
      content: 'Setup 2FA for more security',
      url: '/app/payments',
    },
    {
      title: 'Settings',
      icon: 'cog',
      content: 'View additional settings',
      url: '/api-keys',
    },
    {
      title: 'Dark mode',
      icon: 'theme-dark',
      content: 'Switch dark/light mode',
    },
    {
      title: 'Log out',
      icon: 'exit',
      handleClick: () => dispatch(logoutAction()),
    },
  ];

  const toggleTheme = () => {
    dispatch(toggleThemeAction());
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(className, styles.user, { [styles.active]: visible })}>
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <UserProfileSVG width="40" height="40" />
        </button>
        <div className={styles.body}>
          <div className={styles.menu}>
            {items.map((x, index) =>
              x.url ? (
                <Link
                  className={styles.item}
                  to={x.url}
                  onClick={() => setVisible(!visible)}
                  key={index}
                >
                  <div className={styles.icon}>
                    <Icon name={x.icon} size="20" />
                  </div>
                  <div className={styles.details}>
                    <Typography variant="body2" className={styles.title}>
                      {x.title}
                    </Typography>
                    <Typography variant="caption" className={styles.content}>
                      {x.content}
                    </Typography>
                  </div>
                </Link>
              ) : (
                <div className={styles.item} key={index} onClick={() => x.handleClick()}>
                  <div className={styles.icon}>
                    <Icon name={x.icon} size="20" />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.line}>
                      <div variant="body2" className={styles.title}>
                        {x.title}
                      </div>
                      {x.title === 'Dark mode' ? (
                        <Switch
                          checked={theme === THEME_DARK_MODE}
                          onChange={toggleTheme}
                          name="theme-switch"
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <Typography variant="caption" className={styles.content}>
                      {x.content}
                    </Typography>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
