import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const MobileSteppers = ({ quizLength, activeStep, handleGoNext, handleGoBack }) => {
  return (
    <MobileStepper
      variant="progress"
      steps={quizLength}
      position="static"
      activeStep={activeStep}
      sx={{ maxWidth: 400, flexGrow: 1, background: 'inherit' }}
      nextButton={
        <Button size="small" onClick={handleGoNext} disabled={activeStep === 5}>
          Next <KeyboardArrowRight />
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleGoBack} disabled={activeStep === 0}>
          <KeyboardArrowLeft /> Back
        </Button>
      }
    />
  );
};

export default MobileSteppers;
