import API from './API';

const auth = {
  fetchPortfolioData: (data) => {
    return new API().get(
      `/portfolio/${data.type}/?start_date=${data.startDate}&end_date=${data.endDate}&only=${data.value}&investment_cash=10000`
    );
  },
  getUserPortfolio: () => {
    return new API().get(`/portfolio/user/`);
  },
  fetchCustomData: (payload) => {
    const { value, data } = payload;
    return new API().post(
      `/portfolio/custom/?start_date=${data.startDate}&end_date=${data.endDate}&investment_cash=${data.amount}`,
      value,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  },
};

export default auth;
