import SearchIcon from '@mui/icons-material/Search';
import { Box, Container } from '@mui/material';
import Fab from '@mui/material/Fab';
import cn from 'classnames';
import React, { useState } from 'react';
import { Contained, Outlined } from '../../../components/Button';
import Card from '../../../components/Card';
import Icon from '../../../components/Icon';
import styles from './Catalog.module.sass';
import useStyles from './index.style';

// const dateOptions = ['Recently added', 'Long added'];
const sortingOptions = ['Tutorial', 'Usecase', 'Trading', 'Wallet'];

const articles = [
  {
    title: 'Finding Supply and demand in any market',
    author: 'First name Last name',
    category: 'purple',
    categoryText: 'video tutorial',
    status: 'green',
    statusText: 'popular',
    image: '/images/content/article-pic-1.jpg',
    image2x: '/images/content/article-pic-1@2x.jpg',
    play: true,
    url: '/learn-crypto-details',
  },
  {
    title: 'Why trend matters',
    author: 'First name Last name',
    categoryText: 'popular',
    status: 'green',
    statusText: 'popular',
    image: '/images/content/article-pic-2.jpg',
    image2x: '/images/content/article-pic-2@2x.jpg',
    url: '/learn-crypto-details',
  },
  {
    title: 'Top 7 NFT usecase',
    author: 'First name Last name',
    category: 'green',
    categoryText: 'new',
    status: 'green',
    statusText: 'popular',
    image: '/images/content/article-pic-3.jpg',
    image2x: '/images/content/article-pic-3@2x.jpg',
    url: '/learn-crypto-details',
  },
  {
    title: 'What can monthly highs and lows tell us?',
    author: 'First name Last name',
    category: 'purple',
    categoryText: 'video tutorial',
    status: 'green',
    statusText: 'popular',
    image: '/images/content/article-pic-4.jpg',
    image2x: '/images/content/article-pic-4@2x.jpg',
    play: true,
    url: '/learn-crypto-details',
  },
  {
    title: 'Top 7 blockchain use cases',
    author: 'First name Last name',
    category: 'purple',
    categoryText: 'video tutorial',
    status: 'green',
    statusText: 'popular',
    image: '/images/content/article-pic-5.jpg',
    image2x: '/images/content/article-pic-5@2x.jpg',
    play: true,
    url: '/learn-crypto-details',
  },
  {
    title: 'How to identify trend',
    author: 'First name Last name',
    category: 'blue',
    categoryText: 'featured',
    status: 'green',
    statusText: 'popular',
    image: '/images/content/article-pic-6.jpg',
    image2x: '/images/content/article-pic-6@2x.jpg',
    url: '/learn-crypto-details',
  },
];

const Catalog = ({ scrollToRefCatalog }) => {
  const [email, setEmail] = useState('');
  // const [date, setDate] = useState(dateOptions[0]);
  // const [sorting, setSorting] = useState(sortingOptions[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const styles2 = useStyles();

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <Container className={styles2.section} ref={scrollToRefCatalog}>
      <Box className={styles2.container}>
        <form className={styles.form} action="" onSubmit={() => handleSubmit()}>
          <input
            className={styles2.input}
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="search"
            placeholder="Search anything about Trend Trading"
            required
          />
          <Box className={styles2.result}>
            <Fab color="primary">
              <SearchIcon />
            </Fab>
          </Box>
        </form>
        <Box className={styles2.sorting}>
          <Box className={styles2.dropdown}>
            {/* <Dropdown
              className={styles.dropdown}
              value={date}
              setValue={setDate}
              options={dateOptions}
            /> */}
          </Box>
          <Box className={styles2.nav}>
            {sortingOptions.map((x, index) => (
              <Contained
                className={cn(styles2.link, {
                  [styles2.active]: index === activeIndex,
                })}
                onClick={() => setActiveIndex(index)}
                key={index}
                small
                rounded
              >
                {x}
              </Contained>
            ))}
          </Box>
          <Box className={styles.dropdown}>
            {/* <Dropdown
              className={styles.dropdown}
              value={sorting}
              setValue={setSorting}
              options={sortingOptions}
            /> */}
          </Box>
        </Box>
        <Box className={styles2.list}>
          {articles.map((x, index) => (
            <Card className={styles2.card} item={x} key={index} />
          ))}
        </Box>
        <Box justifyContent="center" display="flex">
          <Outlined className={cn('button-stroke button-small', styles.button)} rounded>
            <span>Learn more</span>
            <Icon name="arrow-down" size="16" />
          </Outlined>
        </Box>
      </Box>
    </Container>
  );
};

export default Catalog;
