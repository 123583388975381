import { Box, Divider, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { SnackbarProvider } from 'notistack';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subscribeCustomer } from '../../redux/actions/stripe.action';
import { saveTickerSelector } from '../../redux/reselect/saveTickerSelector';
import { stripeSelector } from '../../redux/reselect/stripeSelector';
import { Contained } from '../Button';
const PaymentForm = ({ closeModal, ...props }) => {
  const styles = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const providerRef = useRef();

  const { tickerList } = useSelector(saveTickerSelector);
  const { isLoading, selectedPlan } = useSelector(stripeSelector);

  const appearance = {
    theme: 'none',
    rules: {
      '.Input': {
        border: '1px solid #E0E6EB',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
      },

      '.Tab:hover': {
        color: 'var(--colorText)',
      },

      '.Tab--selected': {
        borderColor: '#E0E6EB',
        boxShadow:
          '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
      },

      '.Input--invalid': {
        boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
      },

      // See all supported class names and selector syntax below
    },
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '20px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      // invalid: {
      //   color: '#fa755a',
      //   iconColor: '#fa755a',
      // },
    },
  };

  const getTickers = () => {
    return selectedPlan.label === 'Pro' || selectedPlan.label === 'Premium' ? tickerList : null;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    });
    if (error) {
      providerRef.current.enqueueSnackbar(error?.message, {
        variant: 'warning',
      });
    } else {
      const payload = {
        price: selectedPlan.price,
        payment_method: paymentMethod.id,
        metadata: {
          tickers: getTickers(),
        },
      };
      dispatch(subscribeCustomer(payload));
    }
  };

  return (
    <form>
      <SnackbarProvider
        maxSnack={3}
        ref={providerRef}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box mb={2}>
          <Typography variant="h5">Card Details</Typography>
        </Box>
        <Divider />
        <Box my={2}>
          <Typography variant="subtitle1">
            Card Number
            <Box py={2} px={2} className={styles.fieldContainer}>
              <CardNumberElement options={appearance} />
            </Box>
          </Typography>
        </Box>
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="subtitle1">
                Expiration Date
                <Box py={2} px={2} className={styles.fieldContainer}>
                  <CardExpiryElement options={appearance} />
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="subtitle1">
                CVC
                <Box py={2} px={2} className={styles.fieldContainer}>
                  <CardCvcElement options={appearance} />
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}></Box>
        <Box display="flex" justifyContent="center">
          <Contained disabled={isLoading} rounded onClick={handleSubmit}>
            {!isLoading ? 'Pay' : <CircularProgress size={25} />}
          </Contained>
          {/* <LoadingButton variant="contained" loading={loading} onClick={handleSubmit}>
            Pay
          </LoadingButton> */}
        </Box>
      </SnackbarProvider>
    </form>
  );
};

export default PaymentForm;

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    maxWidth: '450px',
    borderRadius: '10px',
    border: '1px solid',
    boxShadow: 'rgb(50 50 93 / 15%) 0px 1px 3px, rgb(0 0 0 / 2%) 0px 1px 0px',
    '&:hover': {
      border: '1px solid',
      borderColor: theme.palette.peter_river,
    },
    '&:focus': {
      border: 'px solid',
      borderColor: theme.palette.peter_river,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '300px',
    },
  },
}));
