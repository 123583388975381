import { createSelector } from 'reselect';

const selectSaveTicker = (store) => {
  const save_ticker = store?.save_tickers;
  return save_ticker;
};

export const saveTickerSelector = createSelector(
  [selectSaveTicker],
  (save_ticker) => save_ticker?.toJS() || {}
);
