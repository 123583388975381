import { createSelector } from 'reselect';

const selectTA = (store) => {
  const technical_analysis = store?.technical_analysis;
  return technical_analysis;
};

export const adxParams = createSelector([selectTA], (ta) => {
  const ts_js = ta.toJS();
  return ts_js?.adx_params || {};
});
