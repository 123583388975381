import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReactLoading from 'react-loading';
export default function ProgressCircular({ size = 100, thickness = 6, loadingText, ...props }) {
  const style = useStyles();

  return (
    <Box className={style.container}>
      <ReactLoading type={'spinningBubbles'} color={'#3F8ED7'} height={size} width={size} />
      {/* <Typography variant="h4" className={style.contentBlue}>
        {loadingText}
      </Typography> */}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      '& div': {
        display: 'contents',
        '& svg': {
          width: '70px',
          height: 'auto',
        },
      },
    },
  },
  contentBlue: {
    background: '#3F8ED7',
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    margin: '1em 0',
    textAlign: 'center',
  },
}));
