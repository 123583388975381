import { debounce } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editDataAction, searchStocksAction } from '../../redux/actions/stock.action';
import { editTickerParams, retrieveStocksAction } from '../../redux/actions/text_sentiment.action';
import Mobile from './Mobile';

export default (props) => {
  const dispatch = useDispatch();
  const { data, ticker_params } = useSelector((store) => store?.text_sentiment?.toJS()) || {};
  const stocks = useSelector((store) => store?.stocks?.toJS()) || {};
  const stocks_data = stocks?.data || [];
  const searchTickerDebounce = (value) => debounce(() => searchStock(value), 3000)();
  const [show_ticker_suggestions, setShowTickerSuggestions] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(
      retrieveStocksAction({
        symbol: stocks?.ticker || 'MSFT',
        type: 'stock',
        date_from: '2021-11-9',
        date_to: '2021-11-14',
      })
    );
  }, [stocks?.ticker?.ticker]);

  useEffect(() => {
    setLoading(false);
  }, [stocks_data]);

  const decimelToPercent = (value) => {
    return (Math.abs(value) * 100).toFixed(2);
  };

  const handleTickerSearchOnFocus = (e) => {
    e.preventDefault();
    setShowTickerSuggestions(true);
  };

  const handleTickerSearchOnBlur = (e) => {
    setShowTickerSuggestions(false);
  };

  function searchStock(search_string) {
    const clean_search_string = (search_string || '').trim();
    dispatch(searchStocksAction(clean_search_string));
    setLoading(true);
  }

  const handleSearchOnChange = (e) => {
    const { value } = e.target;
    dispatch(editTickerParams('symbol', value));
    searchTickerDebounce(value);
  };

  const handleTickerSearchItemOnClick = (e, item) => {
    e.preventDefault();

    setShowTickerSuggestions(false);

    dispatch(editTickerParams('symbol', `${item.attributes.ticker} - ${item.attributes.name}`));
    dispatch(editDataAction('ticker', item.attributes.ticker));
  };

  return (
    <>
      <Mobile
        data={data}
        search_loading={loading || false}
        decimelToPercent={decimelToPercent}
        search_ticker={ticker_params?.symbol || ''}
        ticker_results={stocks_data || []}
        handleSearchOnChange={handleSearchOnChange}
        show_ticker_suggestions={show_ticker_suggestions}
        handleTickerSearchItemOnClick={handleTickerSearchItemOnClick}
        handleTickerSearchOnFocus={handleTickerSearchOnFocus}
        handleTickerSearchOnBlur={handleTickerSearchOnBlur}
      />
    </>
  );
};
