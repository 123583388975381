export const APPEND_ANSWER = 'profile/APPEND_ANSWER';
export const SET_QUESTION_INDEX = 'profile/SET_QUESTION_INDEX';
export const SET_QUESTION = 'profile/SET_QUESTION';
export const FIELD_ERROR = 'profile/FIELD_ERROR';
export const FETCH_END = 'profile/FETCH_END';
export const FETCH_ERROR = 'profile/FETCH_ERROR';
export const FETCH_PORTFOLIO_OK = 'profile/FETCH_PORTFOLIO_OK';
export const POST_CUSTOM_OK = 'profile/FETCH_CUSTOM_OK';
export const FETCH_PORTFOLIO_DATA = 'profile/FETCH_PORTFOLIO_DATA';
export const SET_PORTFOLIO_DATA = 'profile/SET_PORTFOLIO_DATA';
export const SET_CUSTOM_PORTFOLIO = 'profile/SET_CUSTOM_PORTFOLIO';
export const POST_CUSTOM_PORTFOLIO = 'profile/POST_CUSTOM_PORTFOLIO';
export const GET_USER_PORTFOLIO = 'profile/GET_USER_PORTFOLIO';
export const ASSIGN_USER_PORTFOLIO = 'profile/ASSIGN_USER_PORTFOLIO';
export const SET_IS_CUSTOM = 'profile/IS_CUSTOM';
export const SAVE_TICKERS = 'profile/SAVE_TICKERS';
export const GET_USER_SETTINGS = 'profile/GET_USER_SETTINGS';
export const GET_USER_SETTINGS_OK = 'profile/GET_USER_SETTINGS_OK';
