import React from 'react';
import { Bubble } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const style = {
  chart: {
    width: '1000px',
    textAlign: 'center',
  },
};
let canvas = undefined;
let ctx = undefined;
let gradient = undefined;
let gradient2 = undefined;
const center = require('center-align');
const BubbleChart = (props) => {
  const data = {
    datasets: [
      {
        label: 'Income dataset',
        data: Array.from({ length: 15 }, () => ({
          x: Math.floor(Math.random() * 100),
          y: Math.floor(Math.random() * 100),
          r: Math.floor(Math.random() * 70),
        })),
        backgroundColor: gradient,
      },
      {
        label: 'Profit dataset',
        data: Array.from({ length: 15 }, () => ({
          x: Math.floor(Math.random() * 100),
          y: Math.floor(Math.random() * 100),
          r: Math.floor(Math.random() * 70),
        })),
        backgroundColor: gradient2,
      },
      {
        data: Array.from({ length: 15 }, () => ({
          x: Math.floor(Math.random() * 100),
          y: Math.floor(Math.random() * 100),
          r: Math.floor(Math.random() * 10),
        })),
        backgroundColor: 'rgba(44, 44, 44)',
      },
    ],
  };

  const options = {
    showLine: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.dataset.data[context.dataIndex];
            return 'Total: $' + value.r * 1000;
          },
        },
      },
      datalabels: {
        anchor: 'center',
        align: 'center',
        color: function (context) {
          const value = context.dataset.data[context.dataIndex];
          return value.r < 50 ? context.dataset.backgroundColor : 'white';
        },
        font: {
          weight: 'bold',
        },
        formatter: function (value, context) {
          if (value.r >= 65) {
            const bubbleLabel = `Alpha Design System${'\n'}$ ${value.r * 1000}${'\n'}${
              value.r * 2
            }  Sales`;
            return center(bubbleLabel);
          } else {
            return '';
          }
        },
        padding: 4,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderWidth: 0,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
          borderWidth: 0,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  window.onload = function () {
    canvas = document.getElementById('bubble');
    ctx = canvas.getContext('2d');
    gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, 'rgba(250, 34, 86, 0.1)');
    gradient.addColorStop(1, 'rgba(250, 34, 86, 1)');
    gradient2 = ctx.createLinearGradient(0, 0, 0, 400);
    gradient2.addColorStop(0, 'rgba(36, 108, 249, 0.1)');
    gradient2.addColorStop(1, 'rgba(36, 108, 249, 1)');
  };
  return (
    <div style={style.chart}>
      <Bubble id="bubble" data={data} plugins={[ChartDataLabels]} options={options} />
    </div>
  );
};

export default BubbleChart;
