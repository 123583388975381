const colors = {
  base: '#F9FFFE',
  peter_river: '#2C81FF',
  white: '#FFFFFF',
  headerNavBg: '#1D2228',

  disabled: '#E9ECEF',

  red_1: '#F60638',
  red2: '#F20094',
  red3: '#FF6978',

  dark1: '#252F40',
  dark2: '#181B21',
  dark3: '#223354',

  gray: '#E9ECEF',
  gray1: '#8392AB',
  gray2: '#686868',
  gray3: 'rgba(34, 51, 84, .5)',
  gray4: 'rgba(138, 140, 178, 0.3)',

  green_1: '#0EC533',
  light_greenish_blue: '#37FE87',

  yellow_1: '#FFCA1B',

  border: '#CCCEDD',

  // from guidelines - make sure to use this.
  brand: {
    primary: '#4289FD',
    secondary: '#83CFFE',
    tertiary: '#C6F4FE',
    base: '#F9FFFE',
  },

  black: {
    black_1: '#1E2229',
    black_2: '#1E1E1E',
    black_3: '#222730',
    black_4: '#1D2228',
  },

  grey: {
    grey_1: '#717171',
    grey_2: '#8F8F8F',
    grey_3: '#969696',
    grey_4: '#DFDFDF',
    grey_5: '#E5E5E5',
  },

  green: {
    green_1: '#0EC533',
    green_2: '#37FE87',
    green_3: '#11CABE',
  },

  purple: {
    purple_1: '#A276FF',
  },

  p1: '#3772FF',
  p2: '#9757D7',
  p3: '#FF6838',
  p4: '#58BD7D',
  s1: '#4BC9F0',
  s2: '#E4D7CF',
  s3: '#FFD166',
  s4: '#CDB4DB',

  n1: '#141416',
  n2: '#23262F',
  n3: '#353945',
  n4: '#777E90',
  n5: '#B1B5C3',
  n6: '#E6E8EC',
  n7: '#F4F5F6',
  n8: '#FCFCFD',

  // Gradients
  gradients: {
    main_linear_1: 'linear-gradient(180deg, #35D4C1 0%, #2596F3 55.19%, #8557FC 100%)',
    // main_linear_2: 'linear-gradient(138.19deg, #41E7B0 2.08%, #2297F3 50.52%, #8358FC 94.79%)',
    main_linear_2: 'linear-gradient(90.08deg, #5C62ED -48.14%, #41E7B0 108.38%)',
    quince_jelly: 'linear-gradient(138.19deg, #FFCA1B 2.08%, #F77455 50.52%, #FE8F1A 94.79%)',
    prunus_avium: 'linear-gradient(138.19deg, #F60638 2.08%, #F40469 50.52%, #F20094 94.79%)',
    light_greenish_blue: 'linear-gradient(94.54deg, #37FE87 17.11%, #26FE88 99.94%)',
    graph_color: 'linear-gradient(180deg, #1CD1A1 -71.94%, #FFFFFF 109.47%)',
    cpanel_green: 'linear-gradient(138.19deg, #41E7B0 2.08%, #0EC533 94.79%)',
    blue_linear: 'linear-gradient(350deg, rgba(33, 82, 255, 1), rgba(33, 212, 253, 1))',
    blue_linear_1: 'linear-gradient(138.19deg, #21D4FD 2%, #2152FF 95%)',
    stepper_linear: 'linear-gradient(138.19deg, #FFCA1B 2.08%, #F77455 50.52%, #FE8F1A 94.79%)',

    // updated from guideline
    gradient_1: 'linear-gradient(138.19deg, #41E7B0 2.08%, #2297F3 50.52%, #8358FC 94.79%)',
    gradient_2: 'linear-gradient(180deg, #FD749B 0%, #281AC8 100%)',
    gradient_3: 'linear-gradient(180deg, #FFCA1B 0%, #F77455 53.65%, #FE8F1A 100%)',
    gradient_4: 'linear-gradient(180deg, #00E0FF 0%, #0047FF 100%)',
    gradient_5: 'linear-gradient(180deg, #F60638 0%, #F40469 47.92%, #F20094 100%)',
    gradient_6: 'linear-gradient(90deg, rgba(255,221,90,1) 69%, rgba(255,182,113,1) 100%)',

    // chart
    gradient_title:
      'linear-gradient(96.48deg, rgba(93, 95, 239, 0.7) -9.74%, rgba(65, 231, 176, 0.7) 103.84%)',
    gradient_background: 'linear-gradient(96.48deg, #5D5FEF -9.74%, #41E7B0 103.84%)',
  },
};

export default colors;
