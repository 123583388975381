import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import immutableTransform from 'redux-persist-transform-immutable';

import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { Iterable } from 'immutable';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';

import rootReducer from './reducers/root.reducer';
import rootSaga from './sagas/root.saga';
import { ENV } from '../config';
import axios from 'axios';
import notificationMiddleware from '../middlewares/notificationMiddleware';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const history = createBrowserHistory();
const persistConfig = {
  transforms: [immutableTransform()],
  key: 'root',
  storage,
  // blacklist: [],
  whitelist: ['app', 'auth'],
  stateReconciler: autoMergeLevel1,
};

const createAxiosMiddleware = () => {
  return ({ getState }) =>
    (next) =>
    (action) => {
      const auth = getState().auth;
      const token = auth.toJS().token;

      axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : null;

      return next(action);
    };
};

const configureStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [
    sagaMiddleware,
    notificationMiddleware,
    routerMiddleware(history),
    thunk,
    createAxiosMiddleware(),
  ];
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  if (ENV === 'development') {
    // add logger for development only
    const logger = createLogger({
      collapsed: false,
      stateTransformer: (state) => {
        let newState = {};

        for (var i of Object.keys(state)) {
          if (Iterable.isIterable(state[i])) {
            newState[i] = state[i].toJS();
          } else {
            newState[i] = state[i];
          }
        }

        return newState;
      },
    });
    middleware.push(logger);
  }

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  let persistor = persistStore(store);

  // persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};

export default configureStore;

export { history };
