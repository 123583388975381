import { Box, Container, Grid, Typography } from '@mui/material';
import CardItem from './CardItem';
import useStyles from './index.style';

import { useSelector } from 'react-redux';
import analysis from '../../assets/images/portfolio_management/analysis.png';

export const PortfolioManagement = (props) => {
  const styles = useStyles();
  const { user_details } = useSelector((store) => {
    const login_store = store.login;

    if (login_store) return login_store.toJS();

    return {};
  });
  const card_items = [
    {
      title: 'Terms of Use',
      desc: `The overarching agreement you make with Traidify.`,
      image: analysis,
      path: '/terms-and-conditions',
    },
    {
      title: 'Risk Disclosure Agreement',
      desc: `Required agreement related to investment security risk and the platform.`,
      image: analysis,
      path: '/risk-disclosure-agreement',
    },
    {
      title: 'Cookie policy',
      desc: `How we use tracking technology to improve your experience with us.`,
      image: analysis,
      path: '/cookie-policy',
    },
    {
      title: 'Privacy Policy',
      desc: `Your privacy is very important to us. Here's how we use your information.`,
      image: analysis,
      path: '/privacy-policy',
    },
    {
      title: 'TD Ameritrade',
      desc: `Agreement for your brokerage connection to TD Ameritrade.`,
      image: analysis,
      path: '/challenge/',
    },
    {
      title: 'Tradier',
      desc: `Agreement for your brokerage connection to Tradier.`,
      image: analysis,
      path: '/risk-tolerance-quiz',
    },
    {
      title: 'Refund Policy',
      desc: `You'll love autotrading just as much as you do. Just in case you don't, we offer a generous and clear refund policy forr our traders.`,
      image: analysis,
      path: '/refund-policy',
    },
  ];
  return (
    <Container className={styles.container}>
      <Box paddingTop="2rem">
        <Grid container spacing={0}>
          <Grid className={styles.cardItemContainer} xs={12} sm={12} md={10} lg={8} xl={6}>
            <Box px="1rem" py="1rem">
              <Box>
                <Typography variant="h5">Terms of Service</Typography>
              </Box>
              <Box>
                <Typography>
                  We constantly work to keep our policies and disclosures updated to ensure the best
                  practices around privacy, security, usage, and compliance. You can find more
                  details regarding each policy below, collectively referred to as the 'Terms of
                  Service'.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box className={styles.content2}>
          <Grid container spacing={0}>
            {card_items.map((item, index) => (
              <Grid
                className={styles.cardItemContainer}
                key={index}
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={4}
              >
                <CardItem {...item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default PortfolioManagement;
