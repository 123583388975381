import { makeStyles } from '@mui/styles';
import colors from '../../styles/colors';
const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxwidth: '1500px',
    padding: '0 80px',
    [theme.breakpoints.up('lg')]: {
      padding: '0 40px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 32px',
    },
  },
  section: {
    borderRadius: '14px',
    margin: '50px auto',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      paddingBottom: '80px',
    },
  },
  head: {
    maxWidth: '455px',
    margin: '0 auto 64px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: '40px',
      textAlign: 'left',
    },
  },
  title: {
    marginBottom: '20px',
    fontSize: '2.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    },
  },
  info: {
    color: colors.n4,
    fontSize: theme.typography.bodyBold2.fontSize,
    lineHeight: theme.typography.bodyBold2.lineHeight,
    fontWeight: theme.typography.bodyBold2.fontWeight,
  },
  wrapper: {
    margin: '0 -12px',
    [theme.breakpoints.down('md')]: {
      margin: '0 -4px',
    },
  },
  btns: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '64px',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      marginTop: '40px',
    },
    '& button': {
      marginTop: '48px',
      color: '#FFF',
      '& svg': {
        fill: colors.n4,
      },
      '&:hover': {},
      '&.active': {
        background: colors.n2,
        boxShadow: `0 0 0 2px ${colors.n2} inset`,
        color: colors.n8,
        '& svg': {
          fill: colors.n8,
        },
      },

      [theme.breakpoints.down('sm')]: {
        '& .MuiButton-root': {
          height: '40px',
          width: 'min-content !important',
          borderRadius: '20px',
          padding: '0 16px',
          fontSize: '14px',
        },
      },
    },

    button: {},
  },
}));

export default useStyles;
