import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  MeterGaugeContainer: {
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  trendingContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  buyDipContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
