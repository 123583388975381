import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from '../../components/Button';
import useStyles from './index.style';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';

import { fetchCategoryAnswers, setCategory } from '../../redux/actions/quiz.action';

import FavoriteIcon from '@mui/icons-material/Favorite';

import { useHistory } from 'react-router-dom';

const Category = ({ category = {}, ...props }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { name, image, description } = category;

  const {
    userQuizDetails = {},
    currentQuestion,
    categoryQuestionList,
  } = useSelector((store) => {
    const quiz_store = store.quiz;

    if (quiz_store) return quiz_store.toJS();

    return {};
  });

  const handleClick = (e) => {
    e.stopPropagation();
    // history.push('/app/risk-tolerance-quiz');
    dispatch(setCategory(category));
    dispatch(fetchCategoryAnswers(category.slug));

    history.push({ pathname: `/app/test-your-knowledge/${name}` });
  };

  return (
    <Grid className={classes.cardContainer} xs={12} sm={6} md={6} lg={4} xl={4}>
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          className={classes.videoThumbnail}
          height="250"
          image={
            image === null
              ? 'https://deconova.eu/wp-content/uploads/2016/02/default-placeholder.png'
              : image
          }
        >
          <IconButton className={classes.playBtn}>
            <FavoriteIcon />
          </IconButton>
        </CardMedia>
        <CardContent>
          <Typography className={classes.title} variant="h5">
            <Box justifyContent="center" display="flex">
              {name}
            </Box>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description.substr(0, 123) + '...'}
          </Typography>
        </CardContent>
        <CardActions>
          <Box justifyContent="center" display="flex" alignItems="flex-end" mb={2}>
            <Button children="Take Quiz" onClick={handleClick} />
          </Box>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default withRouter(Category);
