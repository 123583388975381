import { fromJS } from 'immutable';
import {
  RETRIEVE_GLOBAL_MACROECONOMICS_ACTION,
  RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_ACTION,
  RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_END,
  RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_ERROR,
  RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_OK,
  RETRIEVE_GLOBAL_MACROECONOMICS_END,
  RETRIEVE_GLOBAL_MACROECONOMICS_ERROR,
  RETRIEVE_GLOBAL_MACROECONOMICS_OK,
  RETRIEVE_RELATIVE_BONDS_INDEX_ACTION,
  RETRIEVE_RELATIVE_BONDS_INDEX_END,
  RETRIEVE_RELATIVE_BONDS_INDEX_ERROR,
  RETRIEVE_RELATIVE_BONDS_INDEX_OK,
  RETRIEVE_RELATIVE_PRICE_INDEX_ACTION,
  RETRIEVE_RELATIVE_PRICE_INDEX_END,
  RETRIEVE_RELATIVE_PRICE_INDEX_ERROR,
  RETRIEVE_RELATIVE_PRICE_INDEX_OK,
  RETRIEVE_RELATIVE_SP_INDEX_ACTION,
  RETRIEVE_RELATIVE_SP_INDEX_END,
  RETRIEVE_RELATIVE_SP_INDEX_ERROR,
  RETRIEVE_RELATIVE_SP_INDEX_OK,
  RETRIEVE_UPCOMING_EARNINGS_ACTION,
  RETRIEVE_UPCOMING_EARNINGS_END,
  RETRIEVE_UPCOMING_EARNINGS_ERROR,
  RETRIEVE_UPCOMING_EARNINGS_OK,
  SET_RELATIVE_PRICE_INDEX_PARAMS_ACTION,
  SET_TIMEFRAME_ACTION,
} from '../constants/macroeconomics.constant';

const initial_state = fromJS({
  relative_price_index: {
    data: [],
    loading: false,
  },
  relative_sp_index: {
    data: [],
    loading: false,
  },
  relative_bonds_index: {
    data: [],
    loading: false,
  },
  global_macroeconomics: {
    data: [],
    loading: false,
  },
  global_macroeconomics_countries: {
    data: [],
    loading: false,
  },
  upcoming_earnings: {
    data: [],
    loading: false,
  },
  timeFrame: 'daily',
});

const macroeconomicsReducer = (state = initial_state, action) => {
  switch (action.type) {
    case RETRIEVE_RELATIVE_PRICE_INDEX_ACTION:
      return state.setIn(['relative_price_index', 'loading'], true);

    case RETRIEVE_RELATIVE_PRICE_INDEX_OK:
      return state.setIn(['relative_price_index', 'data'], action.data);

    case RETRIEVE_RELATIVE_PRICE_INDEX_ERROR:
    case RETRIEVE_RELATIVE_PRICE_INDEX_END:
      return state.setIn(['relative_price_index', 'loading'], false);

    case RETRIEVE_RELATIVE_SP_INDEX_ACTION:
      return state.setIn(['relative_sp_index', 'loading'], true);

    case RETRIEVE_RELATIVE_SP_INDEX_OK:
      return state.setIn(['relative_sp_index', 'data'], action.data);

    case RETRIEVE_RELATIVE_SP_INDEX_ERROR:
    case RETRIEVE_RELATIVE_SP_INDEX_END:
      return state.setIn(['relative_sp_index', 'loading'], false);

    case RETRIEVE_RELATIVE_BONDS_INDEX_ACTION:
      return state.setIn(['relative_bonds_index', 'loading'], true);

    case RETRIEVE_RELATIVE_BONDS_INDEX_OK:
      return state.setIn(['relative_bonds_index', 'data'], action.data);

    case RETRIEVE_RELATIVE_BONDS_INDEX_ERROR:
    case RETRIEVE_RELATIVE_BONDS_INDEX_END:
      return state.setIn(['relative_bonds_index', 'loading'], false);

    case SET_RELATIVE_PRICE_INDEX_PARAMS_ACTION:
      return state.setIn(['relative_bonds_index', 'params'], action.params);

    case RETRIEVE_GLOBAL_MACROECONOMICS_ACTION:
      return state.setIn(['global_macroeconomics', 'loading'], true);

    case RETRIEVE_GLOBAL_MACROECONOMICS_OK:
      return state.setIn(['global_macroeconomics', 'data'], action.data);

    case RETRIEVE_GLOBAL_MACROECONOMICS_ERROR:
    case RETRIEVE_GLOBAL_MACROECONOMICS_END:
      return state.setIn(['global_macroeconomics', 'loading'], false);

    case RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_ACTION:
      return state.setIn(['global_macroeconomics_countries', 'loading'], true);

    case RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_OK:
      return state.setIn(['global_macroeconomics_countries', 'data'], action.data);

    case RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_ERROR:
    case RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_END:
      return state.setIn(['global_macroeconomics_countries', 'loading'], false);

    case RETRIEVE_UPCOMING_EARNINGS_ACTION:
      return state.setIn(['upcoming_earnings', 'loading'], true);

    case RETRIEVE_UPCOMING_EARNINGS_OK:
      return state.setIn(['upcoming_earnings', 'data'], action.data.data);

    case RETRIEVE_UPCOMING_EARNINGS_ERROR:
    case RETRIEVE_UPCOMING_EARNINGS_END:
      return state.setIn(['upcoming_earnings', 'loading'], false);

    case SET_TIMEFRAME_ACTION:
      return state.set('timeFrame', action.params);
    default:
      return state;
  }
};

export default macroeconomicsReducer;
