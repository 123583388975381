const POLICY = {
  title: 'Terms of Use, Policies and Disclaimers',
  description:
    'Your affirmative act of using our website located at www.traidify.com (and all associated sites linked to it) or services ("traidify") signifies that you agree to the following terms and conditions of use ("Terms of Use"). If you do not agree, do not use traidify. traidify is the property of traidify Inc. ("us", "we" or "o	ur").',
  items: [
    {
      title: 'Changes to the terms of use',
      description:
        'We may change these Terms of Use at any time. If you continue to use traidify after we post changes to these Terms of Use, you are signifying your acceptance of the new terms. You will always have access to our Terms of Use and will be able to check it at any time. By reloading this page, you will have the most up-to-date version available to traidify users.',
    },
    {
      title: 'Changes to traidify',
      description:
        'We may discontinue or change any service or feature on traidify at any time without notice. We do not guarantee backward compatibility of our services and Application Programming Interface (API) in this case.',
    },
    {
      title: 'Ownership of information; License to use traidify; redistribution of data',
      description: `Unless otherwise noted, all rights, titles, and interests in traidify, and all information made available through traidify or our services, in all languages, formats, and media throughout the world, including, but not limited to, all copyrights and trademarks therein, are the exclusive property of traidify, our affiliates or our Data Providers, as defined in section 6 (disclaimer regarding content) below.
      All rights, title and interest in and to any data or/and other information provided by Interactive Data (IDCO), and all such data/information made available by Interactive Data (IDCO) through traidify or our services, in all languages, formats and media throughout the world, including all copyrights and trademarks therein, are the property of Interactive Data (IDCO), our affiliates or our Data Providers (as applicable).
      You may not use any data mining, robots or similar data gathering and extraction tools on the traidify content, frame any portion of traidify or its content, sublicense, assign, transfer, sell, loan, or otherwise distribute for payment the traidify content without our prior written consent. You may not circumvent any mechanisms included in the traidify content for preventing the unauthorized reproduction or distribution of the traidify content. For additional information on commercial and partnership inquiries with us, please see the Contacts page.
      Except as otherwise expressly permitted by the preceding paragraph, you agree not to sell any of the services or materials in any manner or for any purposes, without the prior expressed written consent of traidify and/or our Data Providers. In addition, you shall not, without the prior expressed written consent of traidify and the relevant Data Providers, make copies of any of the software or documentation that may be provided, electronically or otherwise, including, but not limited to, translating, decompiling, disassembling or creating derivative works.
      Except as otherwise expressly permitted by additional agreement, we do not permit commercial usage of any of our services or APIs. We make no warranty and assume no obligation or liability for third party services or software. Please see section 19 (third party software) below for further information.`,
    },
    {
      title: 'Attribution',
      description: `traidify grants all users of traidify.com, and all other available versions of the site, to use snapshots of traidify charts in analysis, press releases, books, articles, blog posts and other publications. In addition, traidify grants the use of all previously mentioned materials in education sessions, the display of traidify charts during video broadcasts, which includes overviews, news, analytics and otherwise use or promote traidify charts or any products from the traidify website on the condition that traidify attribution is clearly visible at all times when such charts and products are used.
      Attribution must include a reference to traidify, including, but not limited to, those described herein.
      Use of traidify charts during video or other promotions where traidify attribution is not visible, must include a description of the product used therein. For instance: "Charting platform used for this analysis is provided by traidify," or simply "Charts by traidify." Font size should not be less than 10 PT = 13PX = 3.4MM.
      You can use the "Snapshot" button in the top toolbar of the chart to instantly create a picture file of your current chart. traidify will automatically take a snapshot with all required attributions and provide a link where you can view your chart, download it or share it. The use of any traidify products outside the traidify website, without a proper attribution of traidify, is not allowed. This extends to any tools (such as widgets) obtained on the traidify website and utilized on the outside resources, where attribution should remain as was originally designed and intended. Users who disobey this attribution rule herein may be banned permanently, and other legal actions may be taken to ensure compliance. This includes, but is not limited to, cease-and-desist warnings, court orders, injunctions, fines, damages relief, and so on.`,
    },
    {
      title: 'Third party sites and advertisers',
      description: `traidify may include links to third party websites. Some of these sites may contain materials that are objectionable, unlawful, or inaccurate. You agree that traidify shall not be held liable for any trading activities or other activities that occur on any website you access through links on traidify. We provide these links as a convenience, and do not endorse the content or services offered by these other sites. Any dealings that you have with advertisers found on traidify are between you and the advertiser and you acknowledge and agree that we are not liable for any loss or claim you may have against an advertiser.
      Unauthorized soliciting on traidify is strictly prohibited and may result in penalties, including, but not limited to, temporary or permanent bans of the account found in violation, and any appropriate and available legal action for monetary and other damages.`,
    },
    {
      title: 'Disclaimer regarding content',
      description: `traidify cannot and does not represent or guarantee that any of the information available through our services or on traidify is accurate, reliable, current, complete or appropriate for your needs. Various information available through our services or on traidify may be specially obtained by traidify from professional businesses or organizations, such as exchanges, news providers, market data providers and other content providers (e.g. Cboe BZX and possibly other established financial exchanges such as the New York Stock Exchange, NASDAQ, New York Mercantile Exchange and Dow Jones), who are believed to be sources of reliable information (collectively, the "Data Providers"). Nevertheless, due to various factors — including the inherent possibility of human and mechanical error — the accuracy, completeness, timeliness, results obtained from use, and correct sequencing of information available through our services and website are not and cannot be guaranteed by traidify. We make no warranty and assume no obligation or liability for scripts, indicators, ideas and other content of third parties. Your use of any third-party scripts, indicators, ideas and other content is at your sole risk.`,
    },
    {
      title: 'Disclaimer regarding investment decisions and trading',
      description: `Decisions to buy, sell, hold or trade in securities, commodities and other investments involve risk and are best made based on the advice of qualified financial professionals. Any trading in securities or other investments involves a risk of substantial losses. The practice of "Day Trading" involves particularly high risks and can cause you to lose substantial sums of money. Before undertaking any trading program, you should consult a qualified financial professional. Please consider carefully whether such trading is suitable for you in light of your financial condition and ability to bear financial risks. Under no circumstances shall we be liable for any loss or damage you or anyone else incurs as a result of any trading or investment activity that you or anyone else engages in based on any information or material you receive through traidify or our services.`,
    },
    {
      title: 'Disclaimer regarding hypothetical performance results',
      description: `Hypothetical performance results have many inherent limitations, some of which are mentioned below. No representation is being made that any account will or is likely to achieve profits or losses similar to those shown. In fact, there are frequently sharp differences between hypothetical performance results and actual results subsequently achieved by any particular trading program.
      One of the limitations of hypothetical performance results is that they are generally prepared with the benefit of hindsight. In addition, hypothetical trading does not involve financial risk and no hypothetical trading record can completely account for the impact of financial risk in actual trading. For example the ability to withstand losses or to adhere to a particular trading program in spite of the trading losses are material points, which can also adversely affect trading results. There are numerous other factors related to the market in general or to the implementation of any specific trading program which cannot be fully accounted for in the preparation of hypothetical performance results and all of which can adversely affect actual trading results.`,
    },
    {
      title: 'Registered users',
      description: `Certain services, such as saving charts, publishing them or the ability to comment on published charts, are available only to registered users of the traidify website and require you to sign in with a username and password to use them. If you register as a user (a "Subscriber") of any of the features of traidify, during the registration process you may be prompted to click "Register Now", "Submit" or a similar button; your clicking on such button will further confirm your agreement to be legally bound by these Terms of Use.
      In consideration of your use of the traidify website, you represent that you are of legal age to form a binding contract and are not a person barred from receiving traidify services under the laws of the United States or other applicable jurisdiction. You also agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the traidify's registration form (the "Registration Data") and (b) maintain and promptly update the Registration Data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or traidify has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, traidify has the right to suspend or terminate your account and refuse any and all current or future use of the traidify services (or any portion thereof).
      You represent and warrant that you have all intellectual property rights, including all necessary patent, trademark, trade secret, copyright or other proprietary rights, in and to your content. If you use third-party materials, you represent and warrant that you have the right to distribute third-party material in the content. You agree that you will not submit material that is copyrighted, protected by trade secret or otherwise subject to third party proprietary rights, including patent, privacy and publicity rights, unless you are the owner of such rights or have permission from their rightful owner to submit the material.
      We may remove your content without notice if we reasonably believe that you're in violation of our Terms of Use.`,
    },
    {
      title: 'Non-Professional subscriber status',
      description: `As a vendor of official real-time market data from exchanges (e.g. Nasdaq) for end users, we are required to identify the 'Non-Professional' status of any Subscriber. By ordering a subscription for market data on traidify (except for the following subscriptions: CME GLOBEX, CBOT, COMEX, NYMEX) you confirm the following:
      •	You use market data solely for personal use, not for your business or any other entity.
      •	You are not registered or qualified with the Securities Exchange Commission (SEC) or the Commodities Futures Trading Commission (CFTC).
      •	You are not registered or qualified with any securities agency, any securities exchange, association or regulatory body in any country.
      •	You do not perform any functions that are similar to those that require an individual to register or qualify with the SEC, the CFTC, any other securities agency, any securities exchange, or association or regulatory body, or any commodities or futures contract market, or association or regulatory body.
      •	You are not engaged as an investment advisor (as that term is defined in Section 202 (a) (11) of the Investment Advisor's Act of 1940) or asset manager and you are not engaged to provide investment advice to any individual or entity.
      •	You are not subscribing to the service in your capacity as a principal, officer, partner, employee, or agent of any business or on behalf of any other individual.
      •	You use your own capital, not provided by any other individual or entity in the conduct of your trading.
      •	You do not conduct trading for the benefit of a corporation, partnership, or other entity.
      •	You have not entered into any agreement to share the profit of your trading activities or receive compensation for your trading activities.
      •	You are not receiving office space, and equipment or other benefits in exchange for your trading or work as a financial consultant to any person, firm or business entity`,
    },
    {
      title: 'Derived Data',
      description: `Our proprietary derivation consumes raw market data and executes a 2-factor derivation process to produce the displayed price, later consumed by the price engine. Derived market data is sourced and supplied from various sources and can therefore be blended.
      The derivation process involves changing the raw price by a factor determined by the volume of trading on the underlying market, a randomized variable and a maximum offset amount. The derived data price is only ever displayed to our non-professional clients, the derived data price then being the proprietary value of an organization.
      At no point is raw data accessible by any client, nor can said data be reverse-engineered in any manner. All markets are passed through the same derivation engine.`,
    },
    {
      title: 'Access and security',
      description: `You accept responsibility for the confidentiality and use of any username and email address that use to register for your access to and use of our services. You are responsible for maintaining the confidentiality of your password and account and are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify traidify of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session. traidify cannot and will not be liable for any loss or damage arising from your failure to comply.
      traidify considers private information on the site (source code of protected or invite-only scripts, etc.) to be confidential to you. traidify protects such private information from unauthorized use, access, or disclosure in the same manner that it protects personal data (please refer to our Privacy Policy for more information).`,
    },
    {
      title: 'Payment and cancellation of service',
      description: `•	By ordering any subscription on www.traidify.com (including a free trial period) you confirm that you have read and accepted our Terms of Use and you authorize traidify to automatically charge your bank card or PayPal account according to the billing period manually selected by you.
      •	Each user can try any paid plan for 30 days free of charge. If the trial is not cancelled before its expiration date, it automatically converts to a monthly or annual paid plan depending on user’s choice. There are no refunds for monthly plans, even if the subscription is canceled on the same day as the autoconversion payment has gone through. Refunds are available only after an automatic deduction for annual payments (i.e. after trial and after renewal) within 14 calendar days after the payment was made. Please note that users who filed a chargeback/dispute request or a claim are not eligible for a refund. PRO, PRO+ and Premium subscriptions automatically include market data that is available without additional per-user fees only. You can see which markets are available for free and which need to be purchased separately on the traidify GoPro page. A cancelled trial and associated services will stop immediately after cancellation, except for any data packages that were purchased.
      •	The service is billed in advance on a monthly, annual, or 2-year basis. There will be no refunds or credits for partial months of service, or refunds for months unused with an open user account. If auto renewal fails for an annual plan you will automatically be switched to a monthly plan.
      •	All billing is recurring, which means you will continue to get billed until you cancel your subscription. You are solely responsible for properly canceling your traidify subscription. An email request or support ticket asking for your subscription to be cancelled is not considered cancellation. You may cancel your subscription at any time by visiting the billing section of your Profile Settings page.
      •	If you cancel the service before the end of your current paid up period, your subscription will remain active until the next due date. After the due date, if no payment received, your subscription will be stopped.
      •	We do not offer refunds for initial payments. If you order the service for the first time, please make sure that the order is correct before the payment is made.
      •	We do not offer refunds for recurring monthly payments. If you have been billed for automatic monthly renewal of the Service, you can cancel the subscription to avoid billing for next months.
      •	We do not offer refunds for upgrades to a more expensive plan or a longer billing cycle. Remaining days are converted into an equivalent value of days on the new subscription.
      •	We offer refunds for recurring annual and 2-year payments on demand. If you have been billed for automatic annual and 2-year renewal of the service, you have 14 calendar days (after the payment was made) to contact our support team. If you don't do this, traidify assumes that renewal of the service is expected and the payment will not be refunded.
      •	Refunds are available only after an automatic deduction for annual payments (i.e. after trial and after renewal) within 14 calendar days after the payment was made.
      •	Our moderators are official traidify representatives, with the authority to warn or ban users (including those with a paid subscription) when they do not abide by the House Rules. We do not offer refunds in case you are banned from publishing content and interacting with others because your paid subscription and all of its data and/or features are still available during any such ban.`,
    },
    {
      title: 'Apple App Store subscriptions',
      description: `Payments will be charged to the user's Apple ID Account at confirmation of purchase in compliance with Apple's privacy policy. We recommend that you familiarize yourself with the terms of payment and Apple in-app subscriptions. If a purchase is made from our iOS app, the refund is only possible in compliance with the App Store policy.
      •	traidify offers a trial period. It is only possible to use the trial period once. Subscription payment begins after the end of your free trial period.
      •	All subscription types are renewed automatically. You can cancel the renewal option in the App Store within at least 24 hours before the end of your free trial period or the current payment date. The cancellation comes into effect at the end of the current billing period. At the same time, you retain access to the subscription from the cancellation moment until the end of the current billing period.
      •	Accounts may be charged for renewal up to 24 hours before the end of the current period.`,
    },
    {
      title: 'Android app subscriptions',
      description: `•	Financial transactions for subscriptions made in our Android app processed by a third-party service (Google Play) in compliance with their terms of use, privacy policy and any applicable payment terms, in particular the response on Google Play refunds. traidify is not responsible for the actions or omissions of any third-party payment processor. We recommend that you familiarize yourself with the terms of payment and Google Play in-app subscriptions. If a purchase is made from our Android app, the refund is only possible in compliance with Google Play policy.
      •	traidify offers a trial period. It is only possible to use the trial period once. Subscription payment begins after the end of your free trial period.
      •	All subscription types are renewed automatically. You can cancel the renewal option in Google Play within at least 24 hours before the end of your free trial period or the current payment date. The cancellation comes into effect at the end of the current billing period. At the same time, you retain access to the subscription from the cancellation moment until the end of the current billing period.
      •	The payment is made according to the payment method that is linked to the user's account in Google Play. The subscription is made according to Google Play Account choice, which does not necessarily conform with the Google Account used to log in to the application.`,
    },
    {
      title: 'Feedback to traidify',
      description: `By submitting ideas, content, suggestions, documents, and/or proposals ("Contributions") to traidify through our contact or feedback webpages, you acknowledge and agree that: (a) your Contributions do not contain confidential or proprietary information; (b) traidify is not under any obligation of confidentiality, expressed or implied, with respect to the Contributions; (c) traidify shall be entitled to use or disclose (or choose not to use or disclose) such Contributions for any purpose, in any way, in any media worldwide; (d) traidify may have something similar to the Contributions already under consideration or in development; (e) your Contributions automatically become the property of traidify, without any obligation of traidify to you; and (f) you are not entitled to any compensation or reimbursement of any kind from traidify under any circumstances.`,
    },
    {
      title: 'Indemnity',
      description: `You agree to indemnify and hold traidify and our subsidiaries, affiliates, officers, agents, employees, partners and licensors harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of content you submit, post, transmit, modify or otherwise make available through the traidify services, your use of the traidify services, your connection to the traidify services, your violation of the Terms of Use, or your violation of any rights of another. `,
    },
    {
      title: 'Termination',
      description: `Upon your request, your account can be deleted from traidify. You may delete your account at any time by visiting your Profile Settings page. If you requested your account be deleted, we will delete the personal data associated with your account, however certain personal data will be retained if it has been integrated into data that is integral to our systems and site, including data in communications you have sent via our site. This data is necessarily retained for the integrity of the data on the site and our legitimate business purposes, including auditing, security and other legitimate interests (please refer to our Privacy Policy for more information). Your published Contributions will remain on the site in our discretion. Once a Contribution has been published, it becomes part of the communal trading knowledge and should stay that way. This policy prevents users from keeping only their best ideas public, thus keeping everyone honest and maintaining public confidence in the reputation and integrity of the site.
      You agree that traidify may, without prior notice, immediately terminate, limit your access to or suspend your traidify account, any associated email address, and access to the traidify services. Cause for such termination, limitation of access or suspension shall include, but not be limited to, (a) breaches or violations of the Terms of Use or other incorporated agreements or guidelines, (b)requests by law enforcement or other government agencies, (c) discontinuance or material modification to the traidify services (or any part thereof), (d) unexpected technical or security issues or problems, (e) extended periods of inactivity, (f) and/or engagement by you in fraudulent or illegal activities. Further, you agree that all terminations, limitations of access and suspensions for cause shall be made in traidify's sole discretion and that traidify shall not be liable to you or any third party for any termination of your account, any associated email address, or access to the traidify services.`,
    },
    {
      title: 'Emails',
      description: `By creating an account with traidify, you agree that traidify can use your email address to send you marketing materials, service-related notices, important information messages, special offers, etc. You can unsubscribe from this by clicking on the link provided in the emails.`,
    },
    {
      title: 'House rules',
      description: `By creating and using an account on traidify you confirm you have read and agree to follow our House Rules.`,
    },
    {
      title: 'Third Party Software',
      description: `Our software, website and/or services may include software components supplied by third parties which are utilized by permission of the respective licensors and/or copyright holders on the terms provided by such parties ("Third Party Software"). traidify expressly disclaims any warranty or other assurance to you regarding Third Party Software. Please note that your use of the Third Party Software will be governed by the terms and conditions of use and privacy policies of the Third Party Software providers and not by these Terms of Use or our Privacy Policy.`,
    },
    {
      title: 'Scripts',
      description: `You represent and warrant that you have all intellectual property rights, including all necessary patent, trademark, trade secret, copyright or other proprietary rights, in and to your script. If you use third-party materials, you represent and warrant that you have the right to distribute third-party material in the script. You agree that you will not submit material that is copyrighted, protected by trade secret or otherwise subject to third party proprietary rights, including patent, privacy and publicity rights, unless you are the owner of such rights or have permission from their rightful owner to submit the material.
      You are solely responsible for any script that you create. traidify is not in any way responsible for the use or misuse of your script by any user. traidify is not in any way responsible for the use or misuse any user’s script by you.
      By using the publish script feature, you grant us a world-wide, irrevocable, perpetual, royalty-free licence to:
        •	publish this script as well as the username of the author;
        •	disclose the source code of the script for open scripts;
        •	perform, display, use and make available your open or protected script for any user or an invite-only script for any user that received an invite.
      We may remove your script without notice if we reasonably believe that you are in violation of these Terms of Use.
      You can publish the script under any license. If you do not include the license in the comment section of a script, you agree that your script is licensed under the Mozilla Public License 2.0.`,
    },
    {
      title: 'Publications - No Recommendation or Advice Status',
      description: `The information and publications are not meant to be, and do not constitute, financial, investment, trading, or other types of advice or recommendations supplied or endorsed by traidify.
      Any statement of view (which may be subject to change without notice) is the author's personal opinion, and the author makes no representations or warranties as to the accuracy or completeness of any information or analysis provided.
      The authors and traidify are not liable for any losses incurred as a result of any investment made on the basis of any perceived recommendation, forecast, or other information presented here.
      The contents of these publications should not be interpreted as an express or implicit promise, guarantee, or indication by traidify that customers will profit or that losses in connection with them can or will be limited if they rely on the information provided.`,
    },
    {
      title: 'traidify Coins Referral Program Rules',
      description: `•	Who is eligible to be a referrer?
      Any traidify account holder can become a referrer.
        •	Who is eligible to become a referee?
      Your friends or social media followers may be eligible to be referees. To receive coin rewards for referring someone who orders a paid plan on traidify, your referee must be a new traidify user and can’t have more than one account.
        •	How can I use my referral link?
      traidify wants you to share your referral link and earn traidify coins, but you agree that you will not:
        •	Try to get referees by spamming, bulk emailing, or sending large numbers of unsolicited emails. The only people you should be emailing are people you know personally;
        •	Use, display, or manipulate traidify intellectual property (such as traidify logos, trademarks, and copyright-protected works) in any way, except as to identify yourself as a traidify user or a traidify referrer;
        •	Create or register any (i) businesses, (ii) URLs, (iii) domain names, (iv) software application names or titles, or (v) social media handles or profiles that include the word "traidify" or any of traidify's other trademarks or any words that are confusingly similar to traidify trademarks.
        •	Purchase keywords (including, but not limited to Google AdWords) that contain any of traidify trademarks;
        •	Use automated systems or bots through any channel to distribute, post, or otherwise share your referral link;
        •	Use scripts or programmed or automatic dialers to send invites or otherwise share your referral link;
        •	Make misleading claims about traidify, use offensive/abusive content, create fake websites/webpages/social media profiles/apps, misrepresent your connection to traidify, or otherwise make any false or misleading statements to get a referee to use your link; or
        •	Use your referral link in any manner that violates the law or traidify House Rules.
        •	How do I earn traidify Coins?
      Referrers get rewarded with traidify coins for inviting new users that purchase subscriptions. As long as you and your referee follow these Rules, as well as traidify's posted policies, you should receive your traidify coins after your referee uses your link to sign up with traidify and makes their first payment within three months of creating their account. As a result, referrers receive traidify coins in the amount of $10 for their purchase of any monthly subscription, or $30 for any yearly subscription. To receive traidify coins for referring someone who orders a paid plan on traidify, your referee must be a new traidify user.
      The requirements for receiving, and the amounts of traidify Coins are subject to change at traidify’s sole discretion. Referral rewards in the form of traidify coins are not transferable, have no cash value, and may expire. traidify may cancel a reward at any time at its sole discretion.
      In case your referee files a chargeback or claims a refund for their paid subscription, the reward that you and your referee both received from traidify will be canceled.
        •	How do I spend traidify Coins?
      You are able to spend traidify Coins by purchasing a new subscription or extending an existing one. Note that traidify Coins can’t be used to upgrade your current subscription to another tier. Please also note that traidify coins can’t be used to pay for exchange market data.
        •	Termination and changes
      traidify reserves the right to change, end, or pause, in whole or in part, any referral program, as well as any referrer’s or referee's ability to participate in any referral program or receive traidify Coins at any time for any reason, including suspected fraud (including by either the referrer and/or referee), abuse, or any violation of these Rules.
      
      traidify may update these Rules at any time.`,
    },
  ],
};

export default POLICY;
