import { debounce } from 'lodash';
import moment from 'moment';
import { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingIndicator } from '../../components/ContainerIndicator';
// import { INTERVALS } from '../../constants/technical_analysis';
import { searchStocksAction } from '../../redux/actions/stock.action';
import {
  editADXParams,
  // retrieveADXStocksAction,
  // retrieveAssetsSNRFrequencyStockAction,
  // retrieveCandleStickAnalysisAction,
  // retrieveComparativePerformanceAction,
  retrieveTieredChartDataAction,
  retrieveTradeSetupAction,
  // retrieveUntouchedSNRAction,
  // retrieveVolatilityATRAction,
  // retrieveVolatilityGaugeAction,
  // selectPerformanceDataAction,
} from '../../redux/actions/technical_analysis.action';
import { adxParams } from '../../redux/reselect/adxParams';
import { authSelector } from '../../redux/reselect/authSelector';
// import { candlestickDataSelector } from '../../redux/reselect/candlestickDataSelector';
import { stocksList } from '../../redux/reselect/stockList';
import { stocksSelector } from '../../redux/reselect/stocksSelector';
import { stockTicker } from '../../redux/reselect/stockTicker';
import { stripeSelector } from '../../redux/reselect/stripeSelector';
import { technicalAnalysisSelector } from '../../redux/reselect/technicalAnalysisSelector';
import stockData from '../Charts/sample-data.json';
// import { DAILY, WEEKLY } from './constant';
// import useStyles from './styles';

const Mobile = lazy(() => import('./Mobile'));

const pivotPoint = {
  rangeSelector: {
    selected: 2,
  },

  yAxis: [
    {
      height: '75%',
      resize: {
        enabled: true,
      },
      labels: {
        align: 'right',
        x: -3,
      },
      title: {
        text: 'AAPL',
      },
    },
    {
      top: '75%',
      height: '25%',
      labels: {
        align: 'right',
        x: -3,
      },
      offset: 0,
      title: {
        text: 'MACD',
      },
    },
  ],

  title: {
    text: 'AAPL Stock Price',
  },

  subtitle: {
    text: 'With MACD and Pivot Points technical indicators',
  },

  series: [
    {
      type: 'ohlc',
      id: 'aapl',
      name: 'AAPL Stock Price',
      data: stockData,
      zIndex: 1,
    },
    {
      type: 'pivotpoints',
      linkedTo: 'aapl',
      zIndex: 0,
      lineWidth: 1,
      dataLabels: {
        overflow: 'none',
        crop: false,
        y: 4,
        style: {
          fontSize: 9,
        },
      },
    },
    {
      type: 'macd',
      yAxis: 1,
      linkedTo: 'aapl',
    },
  ],
};

// SMA and Volume Config
var ohlc = [],
  volume = [],
  dataLength = stockData.length,
  // set the allowed units for data grouping
  groupingUnits = [
    [
      'week', // unit name
      [1], // allowed multiples
    ],
    ['month', [1, 2, 3, 4, 6]],
  ],
  i = 0;

for (i; i < dataLength; i += 1) {
  ohlc.push([
    stockData[i][0], // the date
    stockData[i][1], // open
    stockData[i][2], // high
    stockData[i][3], // low
    stockData[i][4], // close
  ]);

  volume.push([
    stockData[i][0], // the date
    stockData[i][5], // the volume
  ]);
}

const volumeByPrice = {
  rangeSelector: {
    selected: 2,
  },

  title: {
    text: 'AAPL Historical',
  },

  subtitle: {
    text: 'With SMA and Volume by Price technical indicators',
  },

  yAxis: [
    {
      startOnTick: false,
      endOnTick: false,
      labels: {
        align: 'right',
        x: -3,
      },
      title: {
        text: 'OHLC',
      },
      height: '60%',
      lineWidth: 2,
      resize: {
        enabled: true,
      },
    },
    {
      labels: {
        align: 'right',
        x: -3,
      },
      title: {
        text: 'Volume',
      },
      top: '65%',
      height: '35%',
      offset: 0,
      lineWidth: 2,
    },
  ],

  tooltip: {
    split: true,
  },

  plotOptions: {
    series: {
      dataGrouping: {
        units: groupingUnits,
      },
    },
  },

  series: [
    {
      type: 'candlestick',
      name: 'AAPL',
      id: 'aapl',
      zIndex: 2,
      data: ohlc,
    },
    {
      type: 'column',
      name: 'Volume',
      id: 'volume',
      data: volume,
      yAxis: 1,
    },
    {
      type: 'vbp',
      linkedTo: 'aapl',
      params: {
        volumeSeriesID: 'volume',
      },
      dataLabels: {
        enabled: false,
      },
      zoneLines: {
        enabled: false,
      },
    },
    {
      type: 'sma',
      linkedTo: 'aapl',
      zIndex: 1,
      marker: {
        enabled: false,
      },
    },
  ],
};

// All Technicals Config
const allTechnical = {
  chart: {
    height: 600,
  },
  title: {
    text: 'AAPL Historical',
  },
  subtitle: {
    text: 'All indicators',
  },
  legend: {
    enabled: true,
  },
  rangeSelector: {
    selected: 2,
  },
  yAxis: [
    {
      height: '60%',
    },
    {
      top: '60%',
      height: '20%',
    },
    {
      top: '80%',
      height: '20%',
    },
  ],
  plotOptions: {
    series: {
      showInLegend: true,
    },
  },
  series: [
    {
      type: 'candlestick',
      id: 'aapl',
      name: 'AAPL',
      data: stockData,
    },
    {
      type: 'column',
      id: 'volume',
      name: 'Volume',
      data: volume,
      yAxis: 1,
    },
    {
      type: 'pc',
      id: 'overlay',
      linkedTo: 'aapl',
      yAxis: 0,
    },
    {
      type: 'macd',
      id: 'oscillator',
      linkedTo: 'aapl',
      yAxis: 2,
    },
  ],
};

const TechnicalAnalysis = ({ ...props }) => {
  // const classes = useStyles();
  const {
    adx,
    tiered_chart_data,
    selected_performance_data,
    // adx_daily,
    // assets_snr_frequency_stock_data,
    // index_performance_data,
    // performance,
    // comparative_performance_data,
    // volatility_atr,
    // max_amount_modal,
    // trade_setup_data,
    // adx_mi_stocks,
    // loading_candlestick,
    // candlestick_data,
    // untouched_high,
    // untouched_low,
    // volatility_gauge,
  } = useSelector(technicalAnalysisSelector);

  //todo unused, might use in future
  // const candlestick_data = useSelector(candlestickDataSelector);

  const stocks = useSelector(stocksSelector);
  const stock_ticker = useSelector(stockTicker);
  const data = useSelector(stocksList);
  const adx_params = useSelector(adxParams);
  const { lock_modal } = useSelector(authSelector);
  const { subscriptions } = useSelector(stripeSelector);

  const dispatch = useDispatch();
  //todo unused, might use in future
  // const [show_ticker_suggestions, setShowTickerSuggestions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tradeSetupData, setTradeSetupData] = useState({});
  const base_date = moment().subtract(1, 'days');
  // const end_date = moment(base_date).format('YYYY-MM-DD');
  // const start_date = moment(base_date).subtract(1, 'year').format('YYYY-MM-DD');
  const [stockResult, setStockResult] = useState([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchTickerDebounce = useCallback(
    debounce((v) => searchStock(v), 1000),
    []
  );

  useEffect(() => {
    getTieredChartData();
    // setInterval(() => setLoading(false), 2500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (subscriptions[0]?.metadata?.tickers?.length >= 0) {
      const included = subscriptions[0]?.metadata?.tickers?.split(',');
      const sortedList = data.sort(
        (first, second) =>
          included?.indexOf(second?.attributes?.ticker) -
          included.indexOf(first?.attributes?.ticker)
      );
      setStockResult(sortedList);
    } else {
      setStockResult(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // useEffect(() => {
  //   // getADXStocks();
  //   getCandleStickAnalysisData();
  //   // getAssetsSNRData();
  //   // getTradeSetupData();
  //   // getUntouchedSNRData();
  //   // getVolatilityGaugeData();
  //   // getVolatilityATRData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [adx_params.type, stock_ticker]);

  useEffect(() => {
    if (Object.entries(tradeSetupData).length !== 0) {
      getTradeSetupData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeSetupData.entry]);

  // const getADXStocks = () => {
  //   const params = {
  //     ...(adx_params || 14),
  //     type: adx_params?.type || DAILY,
  //     ticker: stock_ticker.ticker || 'SPY',
  //   };

  //   dispatch(retrieveADXStocksAction(params, stock_ticker.type));
  // };

  const getTieredChartData = () => {
    dispatch(
      retrieveTieredChartDataAction({ ticker: stock_ticker.ticker || 'SPY' }, stock_ticker.type)
    );
  };

  // const getComparativePerformanceData = () => {
  //   let scope = adx_params.type;
  //   const tickers = INDEX_PERFORMANCE_TICKERS.map((t, i) => t.value).join(',');

  //   if (!scope) return;

  //   if (scope === HOURLY) scope = 'minute';
  //   if (scope === WEEKLY) scope = 'monthly';

  //   if (!performance.loading) {
  //     dispatch(
  //       retrieveComparativePerformanceAction({
  //         scope,
  //         sectors: tickers,
  //       })
  //     );
  //   }
  // };

  // const getCandleStickAnalysisData = () => {
  //   let interval = INTERVALS.H_1;
  //   if (!stock_ticker.ticker) return;

  //   if (adx_params.type === DAILY) {
  //     interval = INTERVALS.D_1;
  //   } else if (adx_params.type === WEEKLY) {
  //     interval = INTERVALS.MO_1;
  //   }
  //   dispatch(
  //     retrieveCandleStickAnalysisAction({
  //       start_date: start_date,
  //       end_date: end_date,
  //       symbols: stock_ticker.ticker,
  //       interval,
  //       pattern: 'CDLENGULFING',
  //       type: stock_ticker.type,
  //     })
  //   );
  // };

  // const getAssetsSNRData = () => {
  //   dispatch(
  //     retrieveAssetsSNRFrequencyStockAction({
  //       start_date: start_date,
  //       end_date: end_date,
  //       symbol: stock_ticker.ticker,
  //       days: 256,
  //       type: stock_ticker.type,
  //     })
  //   );
  // };

  // TO DO TRADE SETUP
  const getTradeSetupData = () => {
    dispatch(
      retrieveTradeSetupAction({
        entry: tradeSetupData.entry,
        target: tradeSetupData.target,
        stop: tradeSetupData.stop,
      })
    );
  };

  // const getUntouchedSNRData = () => {
  //   dispatch(
  //     retrieveUntouchedSNRAction({
  //       start_date: start_date,
  //       end_date: end_date,
  //       symbol: stock_ticker.ticker,
  //       intervals: '1mo,1wk',
  //     })
  //   );
  // };

  // const getVolatilityGaugeData = () => {
  //   dispatch(
  //     retrieveVolatilityGaugeAction(
  //       {
  //         start_date: start_date,
  //         end_date: end_date,
  //         ticker: stock_ticker.ticker,
  //       },
  //       stock_ticker.type,
  //       adx_params.type
  //     )
  //   );
  // };

  // const getVolatilityATRData = () => {
  //   dispatch(
  //     retrieveVolatilityATRAction(
  //       {
  //         date_from: `${moment(base_date).subtract(5, 'days').format('YYYY-MM-DD')} 00:00:00`,
  //         date_to: `${end_date} 00:00:00`,
  //         ticker: stock_ticker.ticker,
  //         frequency: 'daily',
  //         // frequency: adx_params.type,
  //       },
  //       stock_ticker.type
  //     )
  //   );
  // };

  //todo unused, might use in future
  // const handleItemSelect = (item) => {
  //   dispatch(selectPerformanceDataAction(item));
  // };

  // const handleTickerSearchOnFocus = (e) => {
  //   e.preventDefault();
  //   setShowTickerSuggestions(true);
  // };

  // const handleTickerSearchOnBlur = (e) => {
  //   setShowTickerSuggestions(false);
  // };

  function searchStock(search_str) {
    const clean_search_string = (search_str || '').trim();
    dispatch(editADXParams('ticker', search_str));
    dispatch(searchStocksAction(clean_search_string));
  }

  //todo unused, might use in future
  // const handleSearchOnChange = (e) => {
  //   const { value } = e.target;
  //   searchTickerDebounce(value);
  // };

  // const handleTickerSearchItemOnClick = (item) => {
  //   // e.preventDefault();
  //   setShowTickerSuggestions(false);
  //   dispatch(editADXParams('ticker', `${item.attributes.ticker} - ${item.attributes.name}`));
  //   dispatch(editDataAction('ticker', item.attributes));
  // };

  //todo unused, might use in future
  // const computePercentInterval = (value, interval, range) => {
  //   const y = range;
  //   const x = value - y;
  //   const a = x / y;
  //   const b = interval * a;

  //   return b;
  // };

  //todo unused, might use in future
  // const computeADXValueToPercentage = (value) => {
  //   const interval = 100 / 7;
  //   let total_percentage_value = 0;

  //   if (value >= 0 && value <= 10) {
  //     total_percentage_value += value;
  //   } else if (value > 10 && value <= 15) {
  //     total_percentage_value += computePercentInterval(value, interval, 10);
  //     total_percentage_value += interval * 1;
  //   } else if (value > 15 && value <= 20) {
  //     total_percentage_value += computePercentInterval(value, interval, 15);
  //     total_percentage_value += interval * 2;
  //   } else if (value > 20 && value <= 25) {
  //     total_percentage_value += computePercentInterval(value, interval, 20);
  //     total_percentage_value += interval * 3;
  //   } else if (value > 25 && value <= 30) {
  //     total_percentage_value += computePercentInterval(value, interval, 25);
  //     total_percentage_value += interval * 4;
  //   } else if (value > 30 && value <= 50) {
  //     total_percentage_value += computePercentInterval(value, interval, 30);
  //     total_percentage_value += interval * 5;
  //   } else if (value > 50 && value <= 100) {
  //     total_percentage_value += computePercentInterval(value, interval, 50);
  //     total_percentage_value += interval * 6;
  //   }

  //   return total_percentage_value;
  // };

  const getTrendLabel = (value) => {
    let trend_label = 'Sideways';

    if (value < 0) trend_label = 'Down';
    else if (value > 0) trend_label = 'Up';

    return trend_label;
  };

  //todo unused, might use in future
  // const getTimeframePropertiesByType = () => {
  //   const timeFramesObject = {};
  //   timeFramesObject[HOURLY] = ['15m', 'hourly', 'daily'];
  //   timeFramesObject[DAILY] = ['hourly', 'daily', 'weekly'];
  //   timeFramesObject[WEEKLY] = ['daily', 'weekly', 'monthly'];
  //   return timeFramesObject[adx_params.type];
  // };

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Mobile
        getTrendLabel={getTrendLabel}
        data={stockData}
        pivotConfig={pivotPoint}
        allTechnicalConfig={allTechnical}
        volumeConfig={volumeByPrice}
        adx={adx}
        adx_params={adx_params || {}}
        ticker={stock_ticker || { ticker: 'SPY', name: 'SPDR S&P 500 ETF Trust', type: 'stock' }}
        interval={adx_params.type}
        tiered_chart_data={tiered_chart_data}
        selected_performance_data={selected_performance_data}
        searchTickerDebounce={searchTickerDebounce}
        lock_modal={lock_modal}
        VIXValue={stocks?.vixValue?.price_data?.results || []}
        // computeADXValueToPercentage={computeADXValueToPercentage}
        // adx_daily={adx_daily}
        // search_ticker={adx_params?.ticker || ''}
        // search_ticker_loading={stocks.loading}
        // ticker_results={stockResult || []}
        // show_ticker_suggestions={show_ticker_suggestions}
        // handleSearchOnChange={handleSearchOnChange}
        // handleTickerSearchItemOnClick={handleTickerSearchItemOnClick}
        // handleTickerSearchOnFocus={handleTickerSearchOnFocus}
        // handleTickerSearchOnBlur={handleTickerSearchOnBlur}
        // getTimeframePropertiesByType={getTimeframePropertiesByType}
        // trend_labels={getTimeframePropertiesByType()}
        // asset_snr_data={assets_snr_frequency_stock_data}
        // index_performance_data={index_performance_data}
        // comparative_performance_data={comparative_performance_data}
        // handleItemSelect={handleItemSelect}
        // performance={performance}
        // candlestick_data={candlestick_data}
        // volatility_atr={volatility_atr}
        // max_amount_modal={max_amount_modal}
        // trade_setup_data={trade_setup_data}
        // set_trade_setup_data={setTradeSetupData}
        // getTradeSetupData={getTradeSetupData}
        // setShowTickerSuggestions={setShowTickerSuggestions}
      />
    </Suspense>
  );
};

export default TechnicalAnalysis;
