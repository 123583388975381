import { push } from 'react-router-redux';
import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { CLIENT_ID } from '../../config';
import quiz from '../../services/quiz';

import {
  SET_QUESTION_LIST,
  SET_USER_DETAIL,
  FETCH_QUIZ,
  FETCH_USER,
  FETCH_END,
  SAVE_ANSWER,
  FINISH_QUIZ,
  FETCH_ERROR,
  FETCH_QUIZ_CATEGORY,
  SET_QUIZ_CATEGORY,
  SUBMIT_CATEGORY_ANSWERS,
  FETCH_CATEGORY_ANSWERS,
  SET_CATEGORY_ANSWERS,
  RETAKE_QUIZ,
} from '../constants/quiz.constant';

export function* fetchQuiz(action) {
  try {
    const xhr = yield call(quiz.fetchQuiz);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: SET_QUESTION_LIST, ...xhr.data.data[0].attributes });
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;
    yield put({ type: FETCH_ERROR, ...data });
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* quizSaga() {
  yield takeEvery(FETCH_QUIZ, fetchQuiz);
}

export function* fetchUserQuizDetail(action) {
  try {
    const xhr = yield call(quiz.getUserDetails, 'investment-risk-tolerance-quiz');

    if (xhr.status >= 400) {
      throw xhr;
    }

    const result = xhr.data.data;

    const getData = {
      lastQuestion: result.last_question_id,
      userQuiz: result.quiz.quiztakers_set,
      questionSet: result.quiz.question_set,
    };

    if (
      !result.last_question_id &&
      result.quiz.quiztakers_set.usersanswer_set.every((question) => question.answer === null)
    ) {
      yield put(push('/app/risk-tolerance-quiz/'));
    }

    yield put({ type: SET_USER_DETAIL, ...getData });
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* fetchUserDetail() {
  yield takeEvery(FETCH_USER, fetchUserQuizDetail);
}

export function* saveUserAnswer(action) {
  try {
    const xhr = yield call(quiz.submitAnswer, action.data);

    if (xhr.status >= 400) {
      throw xhr;
    }

    // yield put({ type: SET_USER_DETAIL, ...xhr.data.data[0].attributes });
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* saveAnswer() {
  yield takeEvery(SAVE_ANSWER, saveUserAnswer);
}

export function* submitQuiz(action) {
  try {
    const xhr = yield call(quiz.submitQuiz, action.data);

    if (xhr.status >= 400) {
      throw xhr;
    }

    // yield put({ type: SET_USER_DETAIL, ...xhr.data.data[0].attributes });
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* saveQuiz() {
  yield takeEvery(FINISH_QUIZ, submitQuiz);
}

export function* quizCategories() {
  try {
    const xhr = yield call(quiz.fetchQuizCategories);

    if (xhr.status >= 400) {
      throw xhr;
    }
    yield put({ type: SET_QUIZ_CATEGORY, ...xhr?.data.data });
  } catch (e) {
    console.log('error : ', e);
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* fetchCategories() {
  yield takeEvery(FETCH_QUIZ_CATEGORY, quizCategories);
}

// Submit Quiz Category Answers
export function* categoryAnswers(action) {
  try {
    const xhr = yield call(quiz.submitCategoryQuiz, action);

    if (xhr.status >= 400) {
      throw xhr;
    }

    // yield put({ type: SET_USER_DETAIL, ...xhr.data.data[0].attributes });
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* submitCategoryAnswers() {
  yield takeEvery(SUBMIT_CATEGORY_ANSWERS, categoryAnswers);
}

// Fetch category answers
export function* getUserCategoryDetails(action) {
  try {
    const xhr = yield call(quiz.getUserDetails, action.category);

    if (xhr.status >= 400) {
      throw xhr;
    }

    const result = xhr.data.data;

    const getData = {
      lastQuestion: result.last_question_id,
      userQuiz: result.quiz.quiztakers_set,
      questionSet: result.quiz.question_set,
    };

    yield put({ type: SET_CATEGORY_ANSWERS, ...getData });
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* fetchUserCategoryDetails() {
  yield takeEvery(FETCH_CATEGORY_ANSWERS, getUserCategoryDetails);
}

export function* retakeQuiz(action) {
  try {
    const xhr = yield call(quiz.retakeQuiz);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: FETCH_USER });
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* retakeQuizSaga() {
  yield takeEvery(RETAKE_QUIZ, retakeQuiz);
}

const quiz_sagas = [
  fork(quizSaga),
  fork(fetchUserDetail),
  fork(saveAnswer),
  fork(saveQuiz),
  fork(fetchCategories),
  fork(submitCategoryAnswers),
  fork(fetchUserCategoryDetails),
  fork(retakeQuizSaga),
];
export default quiz_sagas;
