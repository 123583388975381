export const INDEX_PERFORMANCE_TICKERS = [
  { value: 'SPY', label: 'SPY (SP 500 Index)' },
  { value: 'DIA', label: 'DIA (Dow Jones Industrial Index)' },
  { value: 'QQQ', label: 'QQQ (Nasdaq Index)' },
  { value: 'XLY', label: 'XLY (Consumer Discretionary)' },
  { value: 'XLP', label: 'XLP (Consumer Staples)' },
  { value: 'XLE', label: 'XLE (Energy)' },
  { value: 'XLF', label: 'XLF (Financials)' },
  { value: 'XLV', label: 'XLV (Health Care)' },
  { value: 'XLI', label: 'XLI (Industrials)' },
  { value: 'XLB', label: 'XLB (Materials)' },
  { value: 'XLRE', label: 'XLRE (Real Estate)' },
  { value: 'XLK', label: 'XLK (Technology)' },
  { value: 'XLU', label: 'XLU (Utilities)' },
  { value: 'XLC', label: 'XLC (Communication Services)' },
];

export const INTERVALS = {
  M_1: '1m',
  M_2: '2m',
  M_3: '3m',
  M_5: '5m',
  M_15: '15m',
  M_30: '30m',
  M_60: '60m',
  M_90: '90m',
  H_1: '1h',
  D_1: '1d',
  D_5: '5d',
  WK_1: '1wk',
  MO_1: '1mo',
  MO_3: '3mo',
};

export const HOURLY = 'hourly';
export const DAILY = 'daily';
export const WEEKLY = 'weekly';
export const MONTHLY = 'monthly';

export const VOLATILITY_GRAPH_COLORS = [
  '#00DC3E',
  '#34D9A3',
  '#2EEBD9',
  '#2A5CB3',
  '#8048CD',
  '#B40038',
];
