import { useState } from 'react';

import { Box, Container, Menu, MenuItem, Typography } from '@mui/material';

import { BarGraph } from '../../components/Graph';
import useStyle from './index.style';
import Select from './Select';

const Dropdowns = ({ portfolio, securities, handleSecurities, ...props }) => {
  const styles = useStyle();

  const labels = ['Return', 'Risk'];
  const colors = ['rgb(124, 192, 207)', 'rgb(87, 125, 225)'];
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const loadElements = (data) => {
    return (
      <Box className={styles.container}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography className={styles.title} variant="h6" id="dropdown-title">
            {data.title}
            {/* <InfoOutlinedIcon
              id="basic-button"
              aria-controls={openMenu ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
              onMouseOver={handleMenu}
              color="primary"
              sx={{ paddingBottom: '5px' }}
            /> */}
          </Typography>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            disableAutoFocusItem="false"
          >
            <MenuItem>
              <Container>
                <Typography variant="body1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Typography>
                <Typography variant="body1">
                  Phasellus eu rhoncus ligula. Aliquam sodales interdum mi.
                </Typography>
              </Container>
            </MenuItem>
          </Menu>
        </Box>
        <Box display="flex" justifyContent="left">
          <Select options={data.fields} setValue={handleSecurities} placeholder="Select ticker" />
        </Box>
      </Box>
    );
  };

  const loadPortfolioElement = (
    <Box display="flex" justifyContent="center" flexDirection="column" mt={3}>
      <Typography style={{ color: '#7CC0CF', fontWeight: 'bold' }}>
        <em>Expectation of Return</em>
      </Typography>
      <Typography style={{ color: '#5275EE', fontWeight: 'bold' }}>
        <em>Expectation of Risk</em>
      </Typography>
      <Box className={styles.dropdownContainer}>
        {portfolio.map((element, index) => {
          return (
            <Box display="flex" alignItems="center" flexDirection="column">
              <BarGraph labels={labels} colors={colors} size={100} />
              {loadElements(element)}
            </Box>
          );
        })}
      </Box>
    </Box>
  );

  return <>{loadPortfolioElement}</>;
};

export default Dropdowns;
