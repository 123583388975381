import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

export default function TabGroup({ ...props }) {
  const style = useStyles(props);

  const { currentTab, buttons, getTab, onClick, orientation, className, ...other } = props;

  const getLabel = (button) => button.label || button;
  const getValue = (button) => button.value || button;

  const getCurrentTab = (button) => {
    return currentTab === getValue(button) ? style.selectedButton : style.notSelectedButton;
  };

  const handleSwitch = (e, button) => {
    if (!!getTab) getTab(button);
    if (!!onClick) onClick(button, e);
  };

  return (
    <ButtonGroup
      className={classNames(style.buttonContainer, className)}
      variant="contained"
      orientation={orientation}
      {...other}
    >
      {buttons.map((button, index) => {
        return (
          <Button
            key={button.value || button}
            className={getCurrentTab(button)}
            onClick={(e) => handleSwitch(e, button)}
          >
            <Typography>{getLabel(button)}</Typography>
          </Button>
        );
      })}
    </ButtonGroup>
  );
}

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    '&.MuiButtonGroup-root': {
      overflow: 'hidden',
      borderRadius: '20px',
      background: theme.palette.primary.mainGradient,
      padding: '0.5rem',
      width: '100%',
      // display: 'flex',
      display: 'grid',
      gridTemplateColumns: ({ buttons }) => `repeat(${buttons.length}, 1fr)`,
      justifyContent: 'space-evenly',
      alignItems: 'center',

      '& .MuiButton-root': {
        borderRight: 'none !important',
        borderLeft: 'none !important',
        padding: '0.2em',
        minWidth: 'auto !important',
        borderRadius: '20px !important',
        fontSize: '1em',
        // margin: '5px',
        // width: 'auto',
        // fontSize: '0.5em',

        [theme.breakpoints.up('xs')]: {
          padding: '0.2em',
          // width: '7em',
          fontSize: '.75em',
          borderRadius: '20px !important',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1em',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '1.1em',
        },
      },
    },
  },
  selectedButton: {
    height: '100%',
    backgroundColor: 'white !important',
    borderRadius: '0.5em !important',
    padding: '0.25rem !important',
    '& .MuiTypography-root': {
      fontSize: '0.9em',
      fontWeight: 'bold !important',
      background: theme.palette.gradients.blue_linear,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },
  },

  notSelectedButton: {
    // width: '135px',
    color: 'white !important',
    backgroundColor: 'transparent !important',
    '& .MuiTypography-root': {
      padding: '0 5px',
      fontSize: '0.9em',
      fontWeight: 'bold !important',
    },
  },
}));
