import { makeStyles } from '@mui/styles';
import bg from '../../assets/images/portfolio_management/bg.png';
const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: '2700px',
    position: 'relative',
    padding: '0',
  },

  content: {
    background: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    paddingTop: '50px',
    marginTop: '3rem',
    borderRadius: '6px',
    width: '100%',
  },

  card: {
    padding: '2em 0 4em 0',
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    color: theme.text[4],
    fontWeight: 800,
    textAlign: 'center',
    background: theme.palette.gradients.main_linear_1,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  cardContainer: {
    marginRight: '1em',
    marginBottom: '1em',
    cursor: 'pointer',
  },

  videoThumbnail: {
    position: 'relative',
    height: '250px',

    '& .playBtn': {},
  },

  playBtn: {
    display: 'none',

    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },

  rightSide: {
    float: 'right',
  },

  boldText: {
    fontWeight: 'bold',
  },
  brainIcon: {
    marginRight: '5px',
  },
  quizText: {
    display: 'flex',
    alignItems: 'center',
    color: theme.text[4],
    fontWeight: 'bold',
    textAlign: 'center',
    background: '#85B4ED',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2 /* number of lines to show */,
    '-webkit-box-orient': 'vertical',
  },
}));

export default useStyles;
