import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Article from './Article';
import Catalog from './Catalog';
import Main from './Main';

import { Container } from '@mui/material';
import CookieConsent from '../CookieConsent';
import useStyle from './index.style';

const ArticleDetails = () => {
  const classes = useStyle();

  const [showCookieConsent, setShowCookieConsent] = useState(true);

  useEffect(() => {
    const cookiePolicy = Cookies.get('cookie-policy');
    setShowCookieConsent(!cookiePolicy);

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className={classes.container}>
      <Header />
      <Main />
      <Article />
      <Catalog />
      <Footer />
      {showCookieConsent && <CookieConsent />}
    </Container>
  );
};

export default ArticleDetails;
