import Skeleton from '@mui/lab/Skeleton';
import { Box, Card, CardActions, CardContent, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import colors from '../../styles/colors';

import Rating from './Rating';

import clsx from 'clsx';
import arrow_white from '../../assets/images/icons/right_arrow_white.svg';
import { TREND_STR, TYPE_BUY } from './constant';

const SentimentCard = ({ loading, desc, icon, type = TYPE_BUY, trend = TREND_STR, ...props }) => {
  const classes = useStyles({ type });
  const title = `${type === TYPE_BUY ? 'Buy the' : 'Sell on'} ${
    trend === TREND_STR ? 'Strength' : 'Dip'
  }`;

  if (!!loading) {
    return (
      <Card className={clsx(classes.card, classes.cardPlaceholder)}>
        <CardContent>
          <Skeleton className={classes.titlePlaceholder} />
          <Skeleton className={classes.contentPlaceholder} />
        </CardContent>
        <CardActions className={classes.actionsPlaceholderContainer}>
          <Skeleton className={classes.actionsPlaceholder} />
        </CardActions>
      </Card>
    );
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box className={classes.cardHeader}>
          <Typography variant="h5">{title}</Typography>
          <Link to="#">
            <img className={classes.arrowLink} src={arrow_white} />
          </Link>
        </Box>
        <Box display="flex" pt="10px">
          <Box display="flex" flex="1" color={colors.white}>
            <Typography variant="caption">
              {
                'Lorem ipsum dolor sit amet consectetur adipiscing elit vitae semper tellus porttitor taciti lacus magnis, ornare placerat vestibulum ultricies sociis gravida arcu mauris sociosqu urna eleifend tristique enim.'
              }
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardActions className={classes.actions}>
        <Rating card color={colors.gradients.blue_linear} label="Direction" value={40} />
        <Rating card color={colors.gradients.quince_jelly} label="Momentum" value={70} />
        <Rating card color={colors.gradients.prunus_avium} label="Strength" value={70} />
      </CardActions>
    </Card>
  );
};

export default SentimentCard;

const useStyles = makeStyles((theme) => ({
  card: {
    background: (props) =>
      props.type === TYPE_BUY ? colors.gradients.main_linear_1 : colors.gradients.prunus_avium,
    border: (props) => `2px solid ${props.type === TYPE_BUY ? colors.peter_river : colors.red2}`,
    boxSizing: 'border-box',
    borderRadius: '12px',
    maxWidth: '340px',
    width: '100%',

    '& > div:first-child': {
      padding: '1rem 2rem',
    },
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',

    '& h5': {
      color: colors.white,
      fontWeight: 'bold',
    },
  },
  arrowLink: {
    cursor: 'pointer',
  },
  cardImage: {
    width: '90px',
    height: '90px',
    objectFit: 'contain',
  },
  actions: {
    padding: '10px 16px',
    background: colors.white,
  },
  totalScore: {
    paddingLeft: '1rem',
    color: '#FFF',

    '& p:first-child': {
      fontSize: '.875rem',
    },

    '& span': {
      fontSize: '.875rem',
      fontWeight: 'bold',

      '&:first-child': {
        fontSize: '1.25rem',
        color: '#FE8F1A',
      },

      '&:last-child': {
        fontSize: '1.25rem',
      },
    },
  },
  cardPlaceholder: {
    minWidth: '284px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '328px',
      display: 'flex',
      flexDirection: 'column',

      '& > div:first-child': {
        flex: 1,
      },
    },
  },
  titlePlaceholder: {
    width: '75%',
    height: '2rem',
    transform: 'none',
  },
  contentPlaceholder: {
    height: '12rem',
    marginTop: '2rem',
    transform: 'none',
  },
  actionsPlaceholderContainer: {
    padding: 0,
  },
  actionsPlaceholder: {
    width: '100%',
    height: '64px',
    transform: 'none',
    borderRadius: 0,
  },
}));
