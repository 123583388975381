import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Snackbar from '../../components/SnackBar';
import { closeSnackbarAction, openSnackbarAction } from '../../redux/actions/app.action';
import { setCurrentVideo } from '../../redux/actions/training.action';
import useStyles from './index.style';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share';

//unused but might use in future
// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//   marginLeft: 'auto',
//   transition: theme.transitions.create('transform', {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));

export default function VideoItem({ video, category, index, length, ...props }) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [color, setColor] = useState('default');
  const app = useSelector((store) => store.app.toJS());
  const { message, open, notify } = app.snackbar;
  const [clicked, setClicked] = useState(false);
  const [newDescription, setNewDescription] = useState('');

  const { currentVideo = {} } = useSelector((store) => {
    const training_store = store.training;

    if (training_store) return training_store.toJS();

    return {};
  });

  useEffect(() => {
    const videoText = video.description;
    if (video.title === currentVideo?.title) {
      setClicked(true);
      setNewDescription(videoText);
    } else {
      setClicked(false);
      if (videoText.length < 100) {
        setNewDescription(videoText);
      } else {
        setNewDescription(videoText.substr(0, 100) + '...');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVideo?.title]);

  const handleClick = (e, videoItem) => {
    e.stopPropagation();
    dispatch(setCurrentVideo(videoItem));
    history.push({ pathname: `/app/challenge/${category}/?title=${videoItem.title}` });
  };

  const handleShareClick = (video) => {
    navigator.clipboard.writeText(
      window.location.host + `/app/challenge/${category}/?title=${video.title}`
    );
    dispatch(openSnackbarAction('info', 'Link has been copied.'));
  };

  const handleBookmarkClick = (value) => {
    setColor(value);
    // dispatch(openSnackbarAction('info', 'Added to Bookmarks.'));
  };

  const topicClass = () => {
    return clicked ? classes.selectedTopic : classes.notSelectedTopic;
  };

  const { title } = video;

  return (
    <Box className={classes.videoContainer}>
      <Typography className={topicClass()} variant="h5" onClick={(e) => handleClick(e, video)}>
        {index}. {title}
      </Typography>

      <Box className={classes.contentContainer}>
        <Typography variant="body2" color="text.secondary">
          {newDescription}
        </Typography>

        <Grid container justifyContent="space-between">
          <Grid item>
            <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
              <Typography className={classes.italicText} variant="subtitle1">
                8 mins 58sec
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" flexDirection="row" alignItems="center" mr={1}>
              <IconButton aria-label="share">
                <ShareIcon onClick={() => handleShareClick(video)} />
              </IconButton>
              <IconButton aria-label="add to favorites">
                {color === 'error' ? (
                  <FavoriteIcon onClick={() => handleBookmarkClick('action')} color={color} />
                ) : (
                  <FavoriteBorderIcon onClick={() => handleBookmarkClick('error')} color={color} />
                )}
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {index !== length ? <Divider variant="middle" /> : ''}
      <Snackbar message={message} severity={notify} open={open} onClose={closeSnackbarAction()} />
    </Box>
  );
}
