import API from './API';

const stocks = {
  search: (params) => {
    return new API().get(`/markets/list/?search=${params}`);
  },
  indexPerformance: (params) => {
    return new API().get('/stocks/index-performance/', params);
  },
  comparativePerformance: (params) => {
    return new API().get('/stocks/comparative-performance/', params);
  },
  etfComparativePerformance: (params) => {
    return new API().get('/stocks/etf/comparative-performance/', params);
  },
  holdingComparativePerformance: (params) => {
    return new API().get('/stocks/holding/comparative-performance/', params);
  },
  screening: (params) => {
    return new API().get('/stocks/screening/', params);
  },
  relativePriceIndex: (params) => {
    return new API().get('/stocks/relative-price-index/', params);
  },
  dailyStockPrices: (params) => {
    return new API().get('/stocks/prices/daily/', params);
  },
  globalMacroeconomics: ({ timeFrame, params }) => {
    return new API().get(`/stocks/global-macroeconomics/${timeFrame}/`, params);
  },
  stockPrices: ({ market, timeFrame, params }) => {
    return new API().get(`/${market}/prices/${timeFrame}/`, params);
  },
  riskIndicator: ({ timeFrame }) => {
    return new API().get(`/stocks/risk-indicator/${timeFrame}`);
  },
};
//comment

export default stocks;
