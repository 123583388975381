import API from './API';

const landing_home = {
  fetchCategories: () => {
    return new API().get('/blog/categories/');
  },
  fetchPostDetails: (id) => {
    return new API().get(`/blog/posts/${id}`);
  },
};

export default landing_home;
