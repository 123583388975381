import React from 'react';
import cn from 'classnames';
import styles from './Catalog.module.sass';
import Icon from '../../../components/Icon';
import Card from '../../../components/Card';
import { useSelector } from 'react-redux';
// data

const Catalog = () => {
  const { categories } = useSelector((store) => {
    const landing_home_store = store.landing_home;

    if (landing_home_store) return landing_home_store.toJS();

    return {};
  });

  return (
    <div className={cn('section', styles.section)}>
      <div className={cn('container', styles.container)}>
        <div className={styles.list}>
          {categories.map((x, index) => (
            <Card className={styles.card} item={x} key={index} />
          ))}
        </div>
        <div className={styles.btns}>
          <button className={cn('button-stroke button-small', styles.button)}>
            <span>Learn more</span>
            <Icon name="arrow-down" size="16" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Catalog;
