import React from 'react';
import PRIVACY_POLICY from '../../constants/privacy_policy';
import CommonPolicyContent from './CommonPolicyContent';

const PrivacyPolicy = (props) => {
  return (
    <CommonPolicyContent
      title={PRIVACY_POLICY.title}
      description={PRIVACY_POLICY.description}
      items={PRIVACY_POLICY.items}
    />
  );
};

export default PrivacyPolicy;
