import cn from 'classnames';
import React, { useState } from 'react';
// import styles from './Question.module.sass';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Outlined } from '../../../components/Button';
import Dropdown from '../../../components/Dropdown';
import Icon from '../../../components/Icon';
import TextArea from '../../../components/TextArea';
import TextInput from '../../../components/TextInput';
import colors from '../../../styles/colors';
const optionsCurrency = [
  'Have a question about Traidify fees',
  'Have a question about Ethereum fees',
  'Have a question about Dogcoin fees',
];

const optionsCategory = ['Transactions and spending', 'Transactions', 'Spending'];

const Question = () => {
  const [currency, setCurrency] = useState(optionsCurrency[0]);
  const [category, setCategory] = useState(optionsCategory[0]);
  const [topic, setTopic] = useState(true);
  const styles = useStyles();

  return (
    <Box className={cn('section-bg section-mb0', styles.section)} mt={6}>
      <Box className={cn('container', styles.container)}>
        <form className={styles.form} action="">
          <Box className={styles.fieldset}>
            <Box className={styles.field}>
              <Typography className={styles.label} variant="body1" mb={2}>
                Select a topic
              </Typography>
              <Box className={styles.variants}>
                <Box className={styles.radio}>
                  <input
                    className={styles.input}
                    type="radio"
                    name="topic"
                    onChange={() => setTopic(!topic)}
                    checked={topic}
                  />
                  <Box className={styles.inner}>
                    <Box className={styles.icon} style={{ backgroundColor: '#3772FF' }}>
                      <Icon name="wallet" size="20" />
                    </Box>
                    <Typography className={styles.text} variant="body2">
                      Trade Builder
                    </Typography>
                  </Box>
                </Box>
                <Box className={styles.radio}>
                  <input
                    className={styles.input}
                    type="radio"
                    name="topic"
                    onChange={() => setTopic(!topic)}
                    checked={!topic}
                  />
                  <Box className={styles.inner}>
                    <Box className={styles.icon} style={{ backgroundColor: '#9757D7' }}>
                      <Icon name="candlesticks" size="20" />
                    </Box>
                    <Typography className={styles.text} variant="body2">
                      Community
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={styles.field}>
              <Dropdown
                className={styles.dropdown}
                label="What is your issue about?"
                value={currency}
                setValue={setCurrency}
                options={optionsCurrency}
              />
            </Box>
            <Box className={styles.field}>
              <Dropdown
                className={styles.dropdown}
                label="What is your issue about?"
                value={category}
                setValue={setCategory}
                options={optionsCategory}
              />
            </Box>
            <TextInput
              className={styles.field}
              label="Full name"
              name="fullName"
              type="text"
              required
            />
            <TextInput
              className={styles.field}
              label="Email address"
              name="email"
              type="email"
              required
            />
            <TextInput
              className={styles.field}
              label="Subject"
              name="subject"
              type="text"
              required
            />
            <TextArea
              className={styles.field}
              label="message"
              name="message"
              placeholder="Say something"
              required
            />
          </Box>
          <Box className={styles.btns}>
            <Outlined className={cn('button-small', styles.button)} rounded>
              Send message
            </Outlined>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Question;

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: '546px',
    margin: '0 auto',
  },

  fieldset: {
    marginBottom: '40px',
  },

  field: {
    '& .MuiInputBase-root': {
      borderRadius: '12px',
      boxShadow: 'inset 0 0 0 2px #e6e8ec',
      background: '#FCFCFD',
    },
    '& .MuiInputBase-input': {
      height: '1.1876em !important',
    },
    '&:not(:last-child)': {
      marginBottom: '32px',
    },
    '&:last-child': {
      marginBottom: '32px',
    },
  },

  label: {
    marginBottom: '24px',
    fontSize: '12px',
    lineHeight: 1,
    fontWeight: 700,
    textTransform: 'uppercase',
    color: colors.n5,
  },
  variants: {
    display: 'flex',
    margin: '0 -10px',
  },

  radio: {
    position: 'relative',
    flex: '0 0 calc(50% - 20px)',
    width: 'calc(50% - 20px)',
    margin: '0 10px',
    userSelect: 'none',
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
  },
  input: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    '&:checked + .inner': {
      boxShadow: `inset 0 0 0 2px ${colors.p1}`,
    },
  },
  inner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80px',
    padding: '12px',
    borderRadius: '8px',
    background: colors.n8,
    transition: 'box-shadow .2s',
    [theme.breakpoints.down('md')]: {
      height: '48px',
    },
    '&:hover': {
      boxShadow: `inset 0 0 0 2px ${colors.n6}`,
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: '44px',
    height: '44px',
    marginRight: '14px',
    borderRadius: '50%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '& svg': {
      fill: colors.n8,
    },
  },
  text: {
    fontFamily: "'DM Sans', sans-serif",
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 1,
    color: colors.n2,
  },
  btns: {
    textAlign: 'right',
  },
}));
