import React, { useState } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import App from '../containers/App';
import Dashboard from '../containers/Dashboard';
import Home from '../containers/Home';
import Login from '../containers/Login';
import Macroeconomics from '../containers/Macroeconomics';
import TechnicalAnalysis from '../containers/TechnicalAnalysis';
import TechnicalAnalysis1 from '../containers/TechnicalAnalysis1';
import PortfolioManagement from '../containers/PortfolioManagement';
import RiskToleranceQuiz from '../containers/RiskToleranceQuiz';
import RiskAwareProfile from '../containers/RiskAwarePortfolio';
import RiskAwareLandingPage from '../containers/RiskAwareLandingPage';
import RiskAwareCreatePortfolio from '../containers/RiskAwareCreatePortfolio';
import RiskProfile from '../containers/RiskProfile';
import Profile from '../containers/ProfileInfo';
import Sessions from '../containers/ProfileInfo/SessionsAndLoginHistory';
import Challenge from '../containers/Challenge';
import Course from '../containers/Course';
import Sentiment from '../containers/Sentiment';
import Payments from '../containers/Payments';
import TestKnowledge from '../containers/TestYourKnowledge';
import ChangePassword from '../containers/ChangePassword';
import KnowledgeQuiz from '../containers/KnowledgeQuiz';
import { isLoggedIn } from '../helpers/auth';
import { fetchUserDetails } from '../redux/actions/quiz.action';
import { useDispatch } from 'react-redux';
import ResetPassword from '../containers/ResetPassword';
import TermsOfService from '../containers/TermsOfService';
import TermsAndConditions from '../containers/TermsOfService/TermsAndConditions';
import CookiePolicy from '../containers/TermsOfService/CookiePolicy';
import PrivacyPolicy from '../containers/TermsOfService/PrivacyPolicy';
import RiskDisclosureAgreement from '../containers/TermsOfService/RiskDisclosureAgreement';
import RefundPolicy from '../containers/TermsOfService/RefundPolicy';
import PortfolioManagement1 from '../containers/PortfolioManagement1';
import Offers from '../containers/Offers';
import SaveTickers from '../containers/SaveTickers';
import SuccessPayment from '../containers/SuccessPayment';

const private_routes = [
  {
    name: '/app',
    path: ['/app'],
    component: PortfolioManagement1,
    key: '/app',
    exact: true,
  },
  {
    name: '/app/login',
    path: '/app/login',
    component: Login,
    key: '/app/login',
    exact: true,
  },
  {
    name: '/app/portfolio-management',
    path: '/app/portfolio-management',
    component: PortfolioManagement1,
    key: '/app/portfolio-management',
    exact: true,
  },
  {
    name: '/app/risk-tolerance-quiz',
    path: '/app/risk-tolerance-quiz',
    key: '/app/risk-tolerance-quiz',
    component: RiskToleranceQuiz,
    exact: true,
  },
  {
    name: '/app/risk-aware-profile',
    path: '/app/risk-aware-profile',
    component: RiskAwareLandingPage,
    key: '/app/risk-aware-profile',
    exact: true,
  },

  {
    name: '/app/risk-profile',
    path: '/app/risk-profile',
    component: RiskAwareProfile,
    key: '/app/risk-profile',
    exact: true,
  },

  {
    name: '/app/risk-aware-profile/create-profile',
    path: '/app/risk-aware-profile/create-profile',
    component: RiskAwareCreatePortfolio,
    key: '/app/risk-aware-profile/create-profile',
    exact: true,
  },
  {
    name: '/app/challenge',
    path: '/app/challenge',
    component: Challenge,
    key: '/app/challenge',
    exact: true,
  },
  {
    name: '/app/sentiment',
    path: '/app/sentiment',
    component: Sentiment,
    key: '/app/sentiment',
    exact: true,
  },
  {
    name: '/app/home',
    path: '/app/home',
    component: Home,
    key: '/app/home',
    exact: true,
  },
  {
    name: '/app/macroeconomics',
    path: '/app/macroeconomics',
    component: Macroeconomics,
    key: '/app/macroeconomics',
    exact: true,
  },
  {
    name: '/app/technical-analysis1',
    path: '/app/technical-analysis1',
    component: TechnicalAnalysis,
    key: '/app/technical-analysis1',
    exact: true,
  },
  {
    name: '/app/technical-analysis',
    path: '/app/technical-analysis',
    component: TechnicalAnalysis1,
    key: '/app/technical-analysis',
    exact: true,
  },
  {
    name: '/app/challenge/course',
    path: '/app/challenge/:title',
    component: Course,
    key: '/app/challenge/course',
  },
  {
    name: '/app/profile',
    path: '/app/profile',
    component: Profile,
    key: '/app/profile',
  },
  {
    name: '/app/sessions-and-login-history',
    path: '/app/sessions-and-login-history',
    component: Sessions,
    key: '/app/sessions-and-login-history',
  },
  {
    name: '/app/payments',
    path: '/app/payments',
    component: Payments,
    key: '/app/payments',
  },
  {
    name: '/app/success-payment',
    path: '/app/success-payment',
    component: SuccessPayment,
    key: '/app/success-payment',
  },
  {
    name: '/app/test-your-knowledge',
    path: '/app/test-your-knowledge',
    component: TestKnowledge,
    key: '/app/test-your-knowledge',
    exact: true,
  },
  {
    name: '/app/test-your-knowledge/category',
    path: '/app/test-your-knowledge/:title',
    component: KnowledgeQuiz,
    key: '/app/test-your-knowledge/category',
  },
  {
    name: '/app/change-password',
    path: '/app/change-password',
    component: ChangePassword,
    key: '/app/change-password',
  },
  {
    name: '/app/legal',
    path: '/app/legal',
    component: TermsOfService,
    key: '/app/legal',
  },
  {
    name: '/app/terms-and-conditions',
    path: '/app/terms-and-conditions',
    component: TermsAndConditions,
    key: '/app/terms-and-conditions',
  },
  {
    name: '/app/cookie-policy',
    path: '/app/cookie-policy',
    component: CookiePolicy,
    key: '/app/cookie-policy',
  },
  {
    name: '/app/privacy-policy',
    path: '/app/privacy-policy',
    component: PrivacyPolicy,
    key: '/app/privacy-policy',
  },
  {
    name: '/app/risk-disclosure-agreement',
    path: '/app/risk-disclosure-agreement',
    component: RiskDisclosureAgreement,
    key: '/app/risk-disclosure-agreement',
  },
  {
    name: '/app/refund-policy',
    path: '/app/refund-policy',
    component: RefundPolicy,
    key: '/app/refund-policy',
  },
  {
    name: '/app/offers',
    path: '/app/offers',
    component: Offers,
    key: '/app/offers',
    exact: true,
  },
  {
    name: '/app/save-tickers',
    path: '/app/save-tickers',
    component: SaveTickers,
    key: '/app/save-tickers',
    exact: true,
  },
];

const PrivateRoute = (props) => {
  if (!isLoggedIn()) {
    return <Redirect to="/login" />;
  }

  return (
    <App>
      <Switch>
        {private_routes.map(({ key, name, path, exact, component, ...route }, i) => (
          <Route {...route} key={`${key}`} path={path} exact={exact} component={component} />
        ))}
        <Route>
          <Redirect to="/not-found" />
        </Route>
      </Switch>
    </App>
  );
};

export default PrivateRoute;
