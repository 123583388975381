import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Contained } from '../../components/Button';
import { fetchCategoryAnswers, setCategory } from '../../redux/actions/quiz.action';
import useStyles from './index.style';

export default function QuizList(props) {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { quizCategories } = useSelector((store) => {
    const quiz_store = store.quiz;

    if (quiz_store) return quiz_store.toJS();

    return {};
  });

  const handleClick = (quiz) => {
    dispatch(setCategory(quiz));
    dispatch(fetchCategoryAnswers(quiz.slug));

    history.push({ pathname: `/app/test-your-knowledge/${quiz.name}` });
  };
  // const length = quizCategories[1]?.attributes?.quiz_set.length - 1;

  const generateQuiz = (quiz) => {
    return (
      <Box mt={2}>
        <Card className={styles.quizContainer}>
          <Box className={styles.quizContent}>
            <Box display="flex" alignItems="center">
              <Box className={styles.imageContainer}>
                {quiz?.image || (
                  <img
                    alt="Crypto"
                    src="https://u3j7m9h7.rocketcdn.me/wp-content/uploads/2020/12/cryptocurrency-stocks.jpg"
                  />
                )}
              </Box>
            </Box>
            <Box>
              <Box my={1} mx={1}>
                <Typography className={styles.gradientBoldText}>{quiz.name}</Typography>
                <Typography className={styles.description}>{quiz.description}</Typography>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Contained rounded small onClick={() => handleClick(quiz)}>
                  Take Quiz
                  <CheckCircleIcon className={styles.iconContainer} />
                </Contained>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    );
  };
  return (
    <Box>
      <Box mr={1}>
        <Typography className={styles.gradientBoldText} variant="h4">
          Suggested
        </Typography>
        <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="space-around">
          {quizCategories[1]?.attributes?.quiz_set?.map((quiz, index) => {
            return generateQuiz(quiz);
          })}
        </Box>
      </Box>
    </Box>
  );
}
