import { Box, Card, Typography } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { makeStyles } from '@mui/styles';

import { useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function VideoItem({ video = {}, ...props }) {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = useState({
    raised: false,
    shadow: 1,
  });
  const { name, author, description, videos = [] } = video.attributes;

  const getVideo = videos[0] || {};

  const handleVideoClick = (e) => {
    e.stopPropagation();
    history.push({ pathname: `/app/challenge/${name}` });
  };

  return (
    <Box width="100%" height="100%">
      <Card
        className={classes.cardContainer}
        classes={{ root: state.raised ? classes.cardHovered : '' }}
        raised={state.raised}
        zdepth={state.shadow}
        onMouseOver={() => setState({ raised: true, shadow: 3 })}
        onMouseOut={() => setState({ raised: false, shadow: 1 })}
        onClick={handleVideoClick}
      >
        <CardMedia
          className={classes.videoThumbnail}
          component="div"
          height="250"
          image={getVideo.thumbnail}
          alt={name}
        />
        <Box className={classes.cardContent}>
          <Typography className={classes.boldText} variant="h5">
            {name}
          </Typography>
          <Box className={classes.details}>
            <Typography className={classes.subtitle}>Sonia Spirling | 1hr 40mins</Typography>
            <Typography className={classes.description}>{description}</Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    margin: '0.5rem',
    maxHeight: '246px',
    cursor: 'pointer',
    position: 'relative',
    borderRadius: '10px',
    transition: 'transform 0.3s ease-in-out',
    '&:hover $details': {
      display: 'block',
    },
    '&:hover $videoThumbnail': {
      boxShadow: '0 -246px 10px 0 rgba(0,0,0,0.4) inset',
      transition: 'all 0.3s ease',
    },
  },
  cardHovered: {
    transform: 'scale3d(1.05, 1.05, 1) ',
    transition: 'all 0.3s ease',
  },

  cardContent: {
    position: 'absolute',
    margin: '0.5rem',
    bottom: '0px',
    color: 'white',
  },

  details: {
    display: 'none',
  },

  description: {
    lineHeight: '16px',
    fontSize: '0.9rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2 /* number of lines to show */,
    '-webkit-box-orient': 'vertical',
  },

  subtitle: {
    fontWeight: 'light',
  },

  videoThumbnail: {
    position: 'relative',
    height: '160px',
    boxShadow: '0 -50px 10px 0 rgba(0,0,0,0.3) inset',
    '& .playBtn': {},
  },

  playBtn: {
    display: 'none',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },

  boldText: {
    fontWeight: 'bold',
  },
}));
