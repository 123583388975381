// # possible values -> d(daily/real time), w(weekly), m(monthly), y(yearly), ytd(yeartodate), qtd(quartertodate)
export const TIMEFRAME = {
  REAL_TIME: 'realtime',
  WTD: 'weektodate',
  MTD: 'monthtodate',
  TMD: 'threemonth',
  SMD: 'sixmonth',
  QTD: 'quartertodate',
  YTD: 'yeartodate',
  OYTD: 'oneyeartodate',
};

export const TIMEFRAME_LIST = [
  {
    label: 'Real-Time',
    title: 'Real-Time',
    value: TIMEFRAME.REAL_TIME,
    format: 'MMM-DD H:mm',
  },
  {
    label: 'WTD',
    title: 'Week to Date',
    value: TIMEFRAME.WTD,
    format: 'MMM-DD H:mm',
  },
  {
    label: 'MTD',
    title: 'Month to Date',
    value: TIMEFRAME.MTD,
    format: 'MMM-DD',
  },
  {
    label: '3M',
    title: 'Three Month to Date',
    value: TIMEFRAME.TMD,
    format: 'MMM-DD',
  },
  {
    label: '6M',
    title: 'Six Month to Date',
    value: TIMEFRAME.SMD,
    format: 'MMM-DD',
  },
  {
    label: 'QTD',
    title: 'Quarter to Date',
    value: TIMEFRAME.QTD,
    format: 'MMM-DD',
  },
  {
    label: 'YTD',
    title: 'Year to Date',
    value: TIMEFRAME.YTD,
    format: 'MMM-YY',
  },
  {
    label: '1 Yr',
    title: 'One Year to Date',
    value: TIMEFRAME.OYTD,
    format: 'MMM-YY',
  },
];

export const CATEGORY = {
  ASSET_CLASSES: 'asset_class',
  SECTORS: 'sectors',
  THEMATIC_SECTORS: 'thematic_sectors',
  CRYPTOCURRENCY: 'cryptocurrency',
  COUNTRIES: 'countries',
  BONDS: 'bonds',
};

export const CATEGORY_LIST = [
  {
    label: 'Asset Classes',
    value: CATEGORY.ASSET_CLASSES,
  },
  {
    label: 'Sectors',
    value: CATEGORY.SECTORS,
  },
  {
    label: 'Thematic Sectors',
    value: CATEGORY.THEMATIC_SECTORS,
  },
  {
    label: 'Cryptocurrency',
    value: CATEGORY.CRYPTOCURRENCY,
  },
  {
    label: 'Countries',
    value: CATEGORY.COUNTRIES,
  },
  {
    label: 'Bonds',
    value: CATEGORY.BONDS,
  },
];

export const TICKERS = {
  SPY: 'SPY',
  AGG: 'AGG',
  GLD: 'GLD',
};
export const TICKERS_LIST = [
  { value: TICKERS.SPY, label: 'S&P 500' },
  { value: TICKERS.AGG, label: 'US Bond' },
  { value: TICKERS.GLD, label: 'Gold' },
];
