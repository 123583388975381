import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    borderTop: '1px solid #C9C9C9',
    borderBottom: '1px solid #C9C9C9',
    padding: '6px 0 6px 0',
  },
  heightIcon: {
    transform: 'rotate(90deg)',
  },
  convictionHigh: {
    color: '#3ABE47',
    '& > path': {
      opacity: 1,
    },
  },
  convictionLow: {
    color: '#DA3737',
    transform: 'rotate(180deg)',
    '& > path': {
      opacity: 1,
    },
  },
  table: {
    tableLayout: 'fixed !important',
  },
  tableText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default useStyles;
