import React from 'react';
import USER_MEMBERSHIP_AGREEMENT from '../../constants/user_membership_agreement';
import CommonPolicyContent from './CommonPolicyContent';

const UserMembershipAgreement = (props) => {
  return (
    <CommonPolicyContent
      title={USER_MEMBERSHIP_AGREEMENT.title}
      description={USER_MEMBERSHIP_AGREEMENT.description}
      items={USER_MEMBERSHIP_AGREEMENT.items}
    />
  );
};

export default UserMembershipAgreement;
