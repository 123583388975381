import { DRAWER_WIDTH, FOOTER_HEIGHT, MINI_DRAWER_WIDTH } from '../../constants/reponsive';

const useStyles = (theme) => ({
  footer: {
    minHeight: FOOTER_HEIGHT,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    background: 'linear-gradient(71.71deg, #5C62ED 10.43%, #41E7B0 98.98%)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: `1rem 2rem 1rem calc(${MINI_DRAWER_WIDTH} + 2rem)`,
    overflow: 'hidden',
  },
  shiftDrawer: {
    padding: `1rem 2rem 1rem calc(${DRAWER_WIDTH} + 2rem)`,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  mobile_store: {
    overflow: 'hidden',
    borderRadius: '10px',
    marginLeft: '5px',
    marginRight: '5px',
  },
  copyright: {
    paddingTop: '2rem',
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
  },
});

export default useStyles;
