import React from 'react';
// import styles from './Main.module.sass';
import { Box, Container, Typography } from '@mui/material';
import useStyles from './index.styles';

const Main = () => {
  const styles = useStyles();
  return (
    <Container className={styles.main}>
      <Box className={styles.container}>
        <Box className={styles.wrap}>
          <Typography className={styles.title} variant="h2">
            Know More About the Traidify
          </Typography>
          <Typography className={styles.text} variant="h6">
            The global crypto market cap is <strong>$1.86T</strong>
          </Typography>
        </Box>
        <Box className={styles.bg}>
          <img
            srcSet="/images/content/article-pic-6@3x.png 2x"
            src="/images/content/article-pic-6@3x.png"
            alt="Cards"
          />
        </Box>
      </Box>
    </Container>
  );
};

export default Main;
