import { Box, Typography } from '@mui/material';
import cn from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useStyles from './index.styles';
// import styles from './ProfileInfo.module.sass';
import Dropdown from '../../components/Dropdown';
import Profile from '../../components/Profile';
import SubscriptionStatus from '../../components/SubscriptionStatus';
import Switch from '../../components/Switchs';

const breadcrumbs = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Profile info',
  },
];

const languageOptions = ['🇺🇸 USA', '🇻🇳 VIE', '🇷🇺 RUS'];

const settings = [
  {
    title: 'level 1',
    items: [
      {
        title: 'Deposit assets',
        id: 1,
      },
      {
        title: 'Withdraw assets',
        content: 'Enabled $1,000,000/day',
      },
      {
        title: 'Card purchases',
        id: 2,
      },
      {
        title: 'Bank deposit',
        id: 3,
      },
    ],
  },
  {
    title: 'level 2',
    items: [
      {
        title: 'Fiat and Spot wallet',
        id: 4,
      },
      {
        title: 'Margin wallet',
        content: 'Enabled 100x Leverage',
      },
    ],
  },
];

const ProfileInfo = () => {
  const styles = useStyles();
  const [language, setLanguage] = useState(languageOptions[0]);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const { user_details } = useSelector((store) => {
    const login_store = store.login;

    if (login_store) return login_store.toJS();

    return {};
  });

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  return (
    <Profile title="Profile info" breadcrumbs={breadcrumbs}>
      <Box container className={styles.head}>
        <Box className={styles.details}>
          <Typography className={styles.bold} variant="h4">
            {user_details.first_name || user_details.last_name
              ? `${user_details.first_name} ${user_details.last_name}`
              : 'No name'}
          </Typography>
          <Typography className={styles.email}>{user_details?.email}</Typography>
          <Typography className={styles.level}>Level 2 verified</Typography>
        </Box>
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={language}
          setValue={setLanguage}
          options={languageOptions}
        />
      </Box>
      <Box mt={4}>
        <Typography className={styles.title}>Subscription</Typography>
        <SubscriptionStatus />
      </Box>
      <Box className={styles.settings}>
        <Typography className={styles.title}>Features</Typography>
        {settings.map((x, index) => (
          <div className={styles.box} key={index}>
            <div className={styles.subtitle}>{x.title}</div>
            {x.items.map((item, index) => (
              <div className={styles.item} key={index}>
                <div className={styles.category}>{item.title}</div>
                {item.id && (
                  <Switch
                    className={styles.switch}
                    value={selectedFilters.includes(item.id)}
                    onChange={() => handleChange(item.id)}
                  />
                )}
                {item.content && <div className={styles.content}>{item.content}</div>}
              </div>
            ))}
          </div>
        ))}
      </Box>
      <div className={styles.btns}>
        <button className={cn('button', styles.button)}>Save settings</button>
      </div>
    </Profile>
  );
};

export default ProfileInfo;
