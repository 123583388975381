import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
const RadioGroupButton = ({ title, values, placeholder, options, setValue, ...props }) => {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.formControl} size="small">
      <FormLabel id="radio-buttons-group-label">{title}</FormLabel>
      <Box mb={2}>
        <RadioGroup
          aria-labelledby="radio-buttons-group-label"
          defaultValue={values[0].value}
          name="radio-buttons-group"
        >
          {values.map((value, index) => (
            <FormControlLabel value={value.value} control={<Radio />} label={value.label} />
          ))}
        </RadioGroup>
      </Box>
    </FormControl>
  );
};

export default RadioGroupButton;

export { RadioGroupButton };

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: '3px',
    marginBottom: '5px',
    minWidth: '275px',
  },
}));
