export const INPUT_STATUS = {
  INVALID: 0,
  INITIAL: 1,
  VALID: 2,
};

export const INTERVAL = {
  MONTHLY_3: '3mo',
  MONTHLY: '1mo',
  WEEKLY: '1wk',
  DAILY_5: '5d',
  DAILY: '1d',
  HOURLY: '1h',
  MIN_90: '90m',
  MIN_30: '30m',
  MIN_15: '15m',
  MIN_5: '5m',
  MIN_2: '2m',
  MIN_1: '1m',
};

export const BASE = 'BASE';
export const LEG = 'LEG';
