import { makeStyles } from '@mui/styles';
import colors from '../../styles/colors';
const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxwidth: '1500px',
    padding: '0 80px',
    [theme.breakpoints.up('lg')]: {
      padding: '40px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 32px',
    },
  },
  section: {
    borderRadius: '14px',
    margin: '50px auto',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      paddingBottom: '80px',
    },
  },
  sliderContainer: {
    margin: '30px',
  },

  card: {
    width: '100%',
    padding: '50px 32px',
    borderRadius: '20px',
    background: colors.n8,
    textAlign: 'center',
    transition: 'all .2s',
    '&:hover': {
      boxShadow: '0px 64px 64px -48px rgba(15, 15, 15, 0.1)',
    },
    ':root :global(.dark-mode)': {
      background: colors.n2,
      boxShadow: `inset 0 0 0 2px ${colors.n2}`,
      '&:hover': {
        background: 'transparent',
      },
    },
  },
  head: {
    maxWidth: '455px',
    margin: '0 auto 64px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: '40px',
      textAlign: 'left',
    },
  },
  title: {
    marginBottom: '20px',
    fontSize: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  info: {
    color: colors.n4,
    fontSize: theme.typography.bodyBold2.fontSize,
    lineHeight: theme.typography.bodyBold2.lineHeight,
    fontWeight: theme.typography.bodyBold2.fontWeight,
  },
  wrapper: {
    // margin: '0 -12px',
    [theme.breakpoints.down('md')]: {
      margin: '0 -4px',
      '& button': {
        margin: '0',
      },
    },
  },
  logo: {
    marginBottom: '16px',
  },
}));

export default useStyles;
