import React from 'react';

import { Box, Toolbar } from '@mui/material';

import useStyles from './index.style';

import Desktop from './Desktop';

const Header = (props) => {
  const styles = useStyles();
  return (
    <Box className={styles.container}>
      {/* <Hidden smDown> */}
      <Desktop {...props} />
      <Toolbar className={styles.toolbar} />
      {/* </Hidden> */}
    </Box>
  );
};

export default Header;
