import { makeStyles } from '@mui/styles';
import useD3 from '../../hooks/useD3';

import * as d3 from 'd3';

const WIDTH = 200;
const HEIGHT = 70;

const LineChartLarge = ({ trend, value, color, data = [], ...props }) => {
  const classes = useStyles();
  const width = WIDTH;
  const height = HEIGHT - 10;

  const ref = useD3(
    (svg) => {
      const xScale = d3
        .scaleTime()
        .domain(d3.extent(data, (d) => new Date(d.datetime)))
        .range([0, width])
        .nice();
      const yScale = d3
        .scaleLinear()
        .domain([
          0,
          d3.max(data, function (d) {
            return +d.close;
          }),
        ])
        .range([height, 0])
        .nice();

      svg.select('path').remove();

      svg
        .append('path')
        .datum(data)
        .attr('transform', `translate(0, 5)`)
        .attr('fill', `url(#${trend === 'positive' ? 'up' : 'down'}Gradient)`)
        .attr('stroke-width', 1.5)
        .attr(
          'd',
          d3
            .area()
            .x(function (d) {
              return xScale(new Date(d.datetime));
            })
            .y0(height)
            .y1(function (d) {
              return yScale(+d.close);
            })
        );
    },
    [data, trend, color]
  );

  return (
    <svg ref={ref} className={classes.root}>
      <defs>
        <linearGradient id="upGradient" x1="0" x2="0" y1="0" y2="1">
          <stop className={classes.upGradientStop1} offset="0%" />
          <stop className={classes.upGradientStop2} offset="20%" />
          <stop className={classes.upGradientStop3} offset="100%" />
        </linearGradient>
        <linearGradient id="downGradient" x1="0" x2="0" y1="0" y2="1">
          <stop className={classes.downGradientStop1} offset="0%" />
          <stop className={classes.downGradientStop2} offset="20%" />
          <stop className={classes.downGradientStop3} offset="100%" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LineChartLarge;

const useStyles = makeStyles((theme) => ({
  root: {
    width: `${WIDTH}px`,
    height: `${HEIGHT}px`,
  },
  upGradientStop1: {
    stopColor: '#1CD1A1',
  },
  upGradientStop2: {
    stopColor: '#1CD1A1',
  },
  upGradientStop3: {
    stopColor: '#FFF',
  },

  downGradientStop1: {
    stopColor: '#F20049',
  },
  downGradientStop2: {
    stopColor: '#F20049',
  },
  downGradientStop3: {
    stopColor: '#FFF',
  },
}));
