import { Box } from '@mui/material';
import cn from 'classnames';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Favorite from '../../../components/ButtonIcon/Favorite';
import Share from '../../../components/ButtonIcon/Share';
import styles from './Article.module.sass';

const Article = () => {
  const { postDetails } = useSelector((store) => {
    const landing_home_store = store.landing_home;

    if (landing_home_store) return landing_home_store.toJS();

    return {};
  });

  useEffect(() => {
    convertToHTML(postDetails?.attributes?.body);
  }, [postDetails?.attributes?.body]);

  const convertToHTML = (text) => {
    return (document.querySelector('#content').innerHTML = text);
  };

  return (
    <div className={cn('section', styles.main)}>
      <Box className={cn('container', styles.container)}>
        <div id="content" className={styles.body}></div>
        <div className={styles.actions}>
          <Share openUp />
          <Favorite className={styles.favorite} />
        </div>
      </Box>
    </div>
  );
};

export default Article;
