import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1280px',
  },
  imageContainer: {
    width: '400px',
    display: 'contents',
    '& img': {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      '& img': {
        width: '250px',
      },
    },
  },
  desc1: {
    '& h4': {
      fontWeight: 'bold',
      fontSize: '3rem',
      color: theme.text[6],
      maxWidth: '600px',
    },
    [theme.breakpoints.down('sm')]: {
      '& h4': {
        fontSize: '1.5rem',
      },
    },
  },
  desc2: {
    background: '#3F8ED7',
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    margin: '1em 0',
    textAlign: 'center',
    marginTop: '2em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      padding: 0,
    },
  },
}));

export default useStyles;
