import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',

  '& .ReactVirtualized__Grid, ReactVirtualized__List': {
    '&::-webkit-scrollbar': {
      width: '15px',
      height: '15px',
    },

    '&::-webkit-scrollbar-track': {
      backgroundImage: 'linear-gradient(#f3f3f3, #f3f3f3)',
      backgroundSize: '2px 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    },

    '&::-webkit-scrollbar-thumb': {
      width: '15px',
      height: '15px',
      background: '#bebeed',
      borderRadius: '35px',
    },
  },
});
export const StyledTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 700,
  fontSize: '25.1109px',
  lineHeight: '28px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  background: theme.palette.gradients.gradient_title,
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
}));

export const StyledBarItemsContainer = styled(Box)(({ isvertical }) => ({
  display: 'flex',
  flexDirection: isvertical === 'true' ? 'row' : 'column',
  margin: '1rem 0',
  marginTop: '20px',
  height: 'inherit',
  width: '100%',
  justifyContent: isvertical === 'true' ? 'center' : 'none',
}));

export const StyledPercentageRange = styled(Box)(({ isvertical }) => ({
  display: 'flex',
  flexDirection: isvertical === 'true' ? 'column-reverse' : 'row',
  justifyContent: 'space-between',
}));

export const SkeletonBarWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});
