import { Skeleton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo } from 'react';
import PositiveNegativeProgressBar from '../../../components/Charts/PositiveNagativeProgressBar';
import { VERTICAL } from '../../../components/Charts/PositiveNagativeProgressBar/constants';
import ProgressCircular from '../../../components/ProgressCircular';
import { INDEX_PERFORMANCE_TICKERS } from '../../../constants/technical_analysis';
import colors from '../../../styles/colors';
import { DAILY, HOURLY } from '../constant';
const MarketBreadth = ({ data, timeframe, ...props }) => {
  const classes = useStyles();
  if (!data)
    return (
      <Box>
        <ProgressCircular size={50} thickness={5} />
      </Box>
    );

  const getLabelBtTimeframe = (tf) => {
    if (tf === HOURLY) return 'Hourly';
    if (tf === DAILY) return 'Daily';
    return 'Weekly';
  };

  return (
    <Box className={classes.container}>
      <Box display="flex" flex={5}>
        <Box display="flex" flex={1} />
        <Box justifyContent="center" display="flex" flex={2}>
          <Typography>{`${getLabelBtTimeframe(timeframe)} Performance`}</Typography>
        </Box>
        <Box justifyContent="center" flexDirection="row" display="flex" flex={2}>
          <Box display="flex" flex={1} justifyContent="center">
            <Typography>Market Breadth</Typography>
          </Box>
          <Box width="70px" />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" marginY={'1rem'} justifyContent="space-around">
        {INDEX_PERFORMANCE_TICKERS.map((t) => {
          const d = t.value;
          const { ticker, data: p_data, loading } = data[d];
          const { total_holdings, total_holdings_green, total_holdings_red, performance } =
            p_data?.analysis[0] || {};

          return (
            <BarItem
              onClick={() => props.onSelectItem(data[d])}
              name={ticker.label}
              total_holdings={total_holdings}
              total_holdings_green={total_holdings_green}
              total_holdings_red={total_holdings_red}
              performance_index={performance?.performance_index || 0}
              key={ticker.label}
              loading={loading}
            />
          );
        })}
      </Box>
    </Box>
  );
};

const BarItem = ({
  name,
  total_holdings,
  total_holdings_green,
  total_holdings_red,
  performance_index,
  loading,
  onClick,
  ...props
}) => {
  const classes = useStyles();
  const getPercentage = (value, total) => (value / total) * 100;
  const negative = getPercentage(total_holdings_red, total_holdings);
  const positive = getPercentage(total_holdings_green, total_holdings);

  const value = Math.abs(getPercentage(performance_index, 5));
  let pi_negative = performance_index < 0 ? value : 0;
  let pi_positive = performance_index >= 0 ? value : 0;

  return (
    <Box className={classes.item}>
      <Box className={classes.name} onClick={onClick}>
        <Typography variant="caption">{name}</Typography>
      </Box>
      <Box className={classes.bar}>
        {!!loading ? (
          <>
            <Box display="flex" flex="1" px="10px">
              <Skeleton variant="rect" width="100%" height={14} style={{ borderRadius: '1rem' }} />
            </Box>
            <Box display="flex" flex="1" px="10px">
              <Skeleton variant="rect" width="100%" height={14} style={{ borderRadius: '1rem' }} />
            </Box>
          </>
        ) : (
          <>
            <Box display="flex" flex="1" px="10px">
              <PositiveNegativeProgressBar
                inherit_container
                negative={(pi_negative || 0).toFixed(2)}
                positive={(pi_positive || 0).toFixed(2)}
                negative_label={`${performance_index.toFixed(2)}%`}
                positive_label={`${performance_index.toFixed(2)}%`}
                direction={VERTICAL}
                show_value
              />
            </Box>
            <Box display="flex" flex="1" px="10px" pl="1rem">
              <PositiveNegativeProgressBar
                inherit_container
                negative={(negative || 0).toFixed()}
                positive={(positive || 0).toFixed()}
                negative_label={`${(negative || 0).toFixed()}%`}
                positive_label={`${(positive || 0).toFixed()}%`}
                direction={VERTICAL}
                show_value
              />
            </Box>
          </>
        )}
      </Box>
      {!!loading ? (
        <Box className={classes.label}>
          <Skeleton variant="rect" height={14} width="100%" />
        </Box>
      ) : (
        <Box className={classes.label}>
          <Typography variant="caption">{`${total_holdings_red || 0} / ${
            total_holdings_green || 0
          }`}</Typography>
        </Box>
      )}
      {/* <Box className={classes.label}>
        <Typography variant="caption">{`${total_holdings_red || 0} / ${total_holdings_green || 0}`}</Typography>
      </Box> */}
    </Box>
  );
};

const areEqual = (a, b) => {
  return a.data == b.data && a.timeframe == b.timeframe;
};

export default memo(MarketBreadth, areEqual);

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFF',
    minHeight: '200px',
    flex: 1,
    borderRadius: '16px',
    padding: '.875rem 0',
  },
  title: {
    color: '#FFF',
    fontWeight: 'bold',
    color: colors.red3,
    textAlign: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0 5px',
    cursor: 'pointer',
    display: 'flex',
    flex: 1,

    background: theme.palette.gradients.blue_linear,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',

    '& span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  label: {
    display: 'flex',
    padding: '0 5px',
    width: '70px',
  },
  bar: {
    display: 'flex',
    flex: 3,
  },
}));
