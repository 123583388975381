import { all } from 'redux-saga/effects';
import landing_home_sagas from './landing_home.saga';
import login_sagas from './login.saga';
import macroeconomics_sagas from './macroeconomics.saga';
import profile_sagas from './profile.saga';
import quiz_sagas from './quiz.saga';
import save_tickers_sagas from './save_tickers.saga';
import sign_up_sagas from './signup.saga';
import stocks_sagas from './stocks.saga';
import stripe_sagas from './stripe.saga';
import technical_analysis_sagas from './technical_analysis.saga';
import text_sentiment_sagas from './text_sentiment.saga';
import training_sagas from './training.saga';

export default function* rootSaga() {
  yield all([
    ...sign_up_sagas,
    ...login_sagas,
    ...quiz_sagas,
    ...profile_sagas,
    ...training_sagas,
    ...text_sentiment_sagas,
    ...technical_analysis_sagas,
    ...stocks_sagas,
    ...landing_home_sagas,
    ...stripe_sagas,
    ...save_tickers_sagas,
    ...macroeconomics_sagas,
  ]);
}
