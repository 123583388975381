import { RETRIEVE_STOCKS_ACTION, EDIT_TICKER_PARAMS } from '../constants/text_sentiment.constant';

export const retrieveStocksAction = (params) => ({
  type: RETRIEVE_STOCKS_ACTION,
  params,
});

export const editTickerParams = (key, value) => ({
  type: EDIT_TICKER_PARAMS,
  key,
  value,
});
