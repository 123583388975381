import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import Category from './Category';

import useStyles from './index.style';

export default function CategoriesContainer({ categories, ...props }) {
  const styles = useStyles();
  const dispatch = useDispatch();

  const getCategories = () => {
    return categories?.map((category, index) => {
      return <Category category={category} />;
    });
  };

  return (
    <Container className={styles.container}>
      <Box>
        <Typography className={styles.titleContainer} variant="h5">
          Quiz Categories
        </Typography>
        <Grid container justifyContent="space-evenly" className={styles.card}>
          {getCategories()}
        </Grid>
      </Box>
    </Container>
  );
}
