import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { Outlined } from '../../../components/Button';
import Profile from '../../../components/Profile';
import useStyle from './index.style';

const breadcrumbs = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Sessions & login history',
  },
];

const sessions = [
  {
    date: '2021-06-16',
    time: '10:46:09',
    device: 'Macbook Pro',
    system: 'Mac OS Bigsur',
    location: '🇺🇸 USA',
  },
  {
    date: '2021-06-16',
    time: '10:46:09',
    device: 'iMac Pro',
    system: 'Mac OS Bigsur',
    location: '🇺🇸 USA',
  },
];

const history = [
  {
    date: '2021-06-16',
    time: '10:46:09',
    address: '1.53.208.186',
    status: true,
  },
  {
    date: '2021-06-16',
    time: '10:46:09',
    address: '1.53.208.186',
    status: false,
  },
  {
    date: '2021-06-16',
    time: '10:46:09',
    address: '1.53.208.186',
    status: true,
  },
];

const SessionsAndLoginHistory = () => {
  const styles = useStyle();
  return (
    <Profile title="Sessions & login history" breadcrumbs={breadcrumbs}>
      <Box className={styles.section}>
        <Typography className={styles.title}>Active sessions</Typography>
        <Box className={styles.table}>
          <Box className={styles.row}>
            <Box className={styles.col}>Date / time</Box>
            <Box className={styles.col}>Device</Box>
            <Box className={styles.col}>Location</Box>
          </Box>
          {sessions.map((x, index) => (
            <Box className={styles.row} key={index}>
              <Box className={styles.col}>
                <Typography className={styles.content}>{x.date}</Typography>
                <Typography className={styles.note}>{x.time}</Typography>
              </Box>
              <Box className={styles.col}>
                <Typography className={styles.content}>{x.device}</Typography>
                <Typography className={styles.note}>{x.system}</Typography>
              </Box>
              <Box className={styles.col}>
                <Box className={styles.location}>{x.location}</Box>
              </Box>
            </Box>
          ))}
        </Box>
        <Box className={styles.btns}>
          <Outlined rounded>Log out all other devices</Outlined>
        </Box>
      </Box>
      <Box className={styles.section}>
        <Typography className={styles.title}>Login history</Typography>
        <Box className={styles.table}>
          <Box className={styles.row}>
            <Box className={styles.col}>Date / time</Box>
            <Box className={styles.col}>IP address</Box>
            <Box className={styles.col}>Used 2FA</Box>
          </Box>
          {history.map((x, index) => (
            <Box className={styles.row} key={index}>
              <Box className={styles.col}>
                <Typography className={styles.content}>{x.date}</Typography>
                <Typography className={styles.note}>{x.time}</Typography>
              </Box>
              <Box className={styles.col}>
                <Typography className={styles.content}>{x.address}</Typography>
              </Box>
              <Box className={styles.col}>
                {x.status ? (
                  <Box className={styles.successStatus}>yes</Box>
                ) : (
                  <Box className={styles.errorStatus}>no</Box>
                )}
              </Box>
            </Box>
          ))}
        </Box>
        <Box className={styles.btns}>
          <Link to="/contact">
            <Outlined rounded>Contact us</Outlined>
          </Link>
        </Box>
      </Box>
    </Profile>
  );
};

export default SessionsAndLoginHistory;
