import { fromJS } from 'immutable';
import { INDEX_PERFORMANCE_TICKERS } from '../../constants/technical_analysis';
import { DAILY } from '../../containers/TechnicalAnalysis/constant';
import { TIME_FRAMES_VALUES_LIST } from '../../containers/TechnicalAnalysis1/CandleStick/constants';
import {
  EDIT_ADX_PARAMS,
  RETRIEVE_ADX_DAILY,
  RETRIEVE_ADX_DAILY_OK,
  RETRIEVE_ADX_MI_STOCKS_ACTION,
  RETRIEVE_ADX_MI_STOCKS_END,
  RETRIEVE_ADX_MI_STOCKS_ERROR,
  RETRIEVE_ADX_MI_STOCKS_OK,
  RETRIEVE_ADX_STOCKS_ACTION,
  RETRIEVE_ADX_STOCKS_END,
  RETRIEVE_ADX_STOCKS_ERROR,
  RETRIEVE_ADX_STOCKS_OK,
  RETRIEVE_ADX_VIX_STOCKS_ACTION,
  RETRIEVE_ADX_VIX_STOCKS_END,
  RETRIEVE_ADX_VIX_STOCKS_ERROR,
  RETRIEVE_ADX_VIX_STOCKS_OK,
  RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_END,
  RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_ERROR,
  RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_FREQUENCY_ACTION,
  RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_OK,
  RETRIEVE_CANDLESTICK_ANALYSIS_ACTION,
  RETRIEVE_CANDLESTICK_ANALYSIS_END,
  RETRIEVE_CANDLESTICK_ANALYSIS_ERROR,
  RETRIEVE_CANDLESTICK_ANALYSIS_OK,
  RETRIEVE_COMPARATIVE_PERFORMANCE_ACTION,
  RETRIEVE_COMPARATIVE_PERFORMANCE_END,
  RETRIEVE_COMPARATIVE_PERFORMANCE_ERROR,
  RETRIEVE_COMPARATIVE_PERFORMANCE_OK,
  RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_ACTION,
  RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_END,
  RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_ERROR,
  RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_OK,
  RETRIEVE_FUND_SCREENING_ACTION,
  RETRIEVE_FUND_SCREENING_END,
  RETRIEVE_FUND_SCREENING_ERROR,
  RETRIEVE_FUND_SCREENING_OK,
  RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_ACTION,
  RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_END,
  RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_ERROR,
  RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_OK,
  RETRIEVE_INDEX_PERFORMANCE_OK,
  RETRIEVE_TIERED_CHART_DATA_ACTION,
  RETRIEVE_TIERED_CHART_DATA_END,
  RETRIEVE_TIERED_CHART_DATA_ERROR,
  RETRIEVE_TIERED_CHART_DATA_OK,
  RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_ACTION,
  RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_OK,
  RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_ERROR,
  RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_END,
  RETRIEVE_TRADE_BUILDER_SETUP_ACTION,
  RETRIEVE_TRADE_BUILDER_SETUP_END,
  RETRIEVE_TRADE_BUILDER_SETUP_ERROR,
  RETRIEVE_TRADE_BUILDER_SETUP_OK,
  RETRIEVE_TRADE_SETUP_ACTION,
  RETRIEVE_TRADE_SETUP_END,
  RETRIEVE_TRADE_SETUP_ERROR,
  RETRIEVE_TRADE_SETUP_OK,
  RETRIEVE_UNTOUCHED_SNR_OK,
  RETRIEVE_VOLATILITY_ATR_OK,
  RETRIEVE_VOLATILITY_GAUGE_OK,
  SELECT_PERFORMANCE_DATA_ACTION,
  SET_CANDLESTICK_ADJUSTMENTS,
  SET_TRADE_BUILDER_SETUP_MAX_LOSS_ACTION,
  SET_ETF_COMPARATIVE_PERFORMANCE_PARAMS_ACTION,
  SET_FUND_SCREENING_PARAMS_ACTION,
  SET_HOLDING_COMPARATIVE_PERFORMANCE_PARAMS_ACTION,
  SET_SEARCH_TICKER,
  TOGGLE_MAX_AMOUNT_MODAL,
} from '../constants/technical_analysis.constant';

const performance = {};

INDEX_PERFORMANCE_TICKERS.forEach((t) => {
  performance[t.value] = {
    ticker: t,
    loading: false,
    data: undefined,
  };
});

const initial_state = fromJS({
  adx: {},
  adx_vix: {},
  loading: false,
  loading_candlestick: false,
  adx_params: {
    ticker: 'SPY',
    period: '14',
    type: DAILY,
  },
  adx_daily: {},
  tiered_chart_data: {},
  candlestick_data: undefined,
  assets_snr_frequency_stock_data: undefined,
  snrLoading: false,
  trade_setup_data: undefined,
  index_performance_data: undefined,
  comparative_performance_data: undefined,
  selected_performance_data: {
    ...performance.SPY,
  },
  performance,
  untouched_high: [],
  untouched_low: [],
  volatility_gauge: {},
  volatility_atr: [],
  max_amount_modal: false,
  etf_comparative_performance: {
    params: { f: 'd', g: 'us_sectors,us_index' },
    data: [],
    loading: false,
  },
  holding_comparative_performance: {
    params: { f: 'd', e: 'SPY' },
    data: [],
    loading: false,
  },
  fund_screening: {
    params: { f: 'd', s: 'SPY' },
    data: [],
    loading: false,
  },
  relative_price_index: {
    params: { type: 'spy' }, //type = 'spy' | 'bonds'
    data: [],
    loading: false,
  },
  adx_mi_stocks: {
    data: [],
    loading: false,
  },
  atrange_stocks: {
    data: {},
    loading: false,
  },
  trade_builder_setup: TIME_FRAMES_VALUES_LIST.reduce(
    (acc, item) => {
      acc[item] = undefined;
      return acc;
    },
    {
      loading: false,
    }
  ),
  search_ticker: undefined,
  candleStickAdjustments: {
    adj_target: undefined,
    adj_stop: undefined,
    adj_entry: undefined,
  },
});

const technicalAnalysisReducer = (state = initial_state, action) => {
  switch (action.type) {
    case RETRIEVE_ADX_STOCKS_ACTION:
      return state.set('loading', true);

    case RETRIEVE_ADX_STOCKS_OK:
      return state.set('adx', action.data);

    case RETRIEVE_ADX_STOCKS_ERROR:
      return state;

    case RETRIEVE_ADX_STOCKS_END:
      return state.set('loading', false);

    case RETRIEVE_ADX_VIX_STOCKS_ACTION:
      return state.set('loading', true);

    case RETRIEVE_ADX_VIX_STOCKS_OK:
      return state.set('adx_vix', action.data);

    case RETRIEVE_ADX_VIX_STOCKS_ERROR:
      return state;

    case RETRIEVE_ADX_VIX_STOCKS_END:
      return state.set('loading', false);

    case RETRIEVE_ADX_DAILY:
      return state.set('loading', true);

    case RETRIEVE_ADX_DAILY_OK:
      return state.set('adx_daily', action.data);

    case RETRIEVE_TIERED_CHART_DATA_ACTION:
      return state.set('loading', true);

    case RETRIEVE_TIERED_CHART_DATA_OK:
      return state.set('tiered_chart_data', action.data);

    case RETRIEVE_TIERED_CHART_DATA_ERROR:
      return state;

    case RETRIEVE_TIERED_CHART_DATA_END:
      return state.set('loading', false);

    case RETRIEVE_CANDLESTICK_ANALYSIS_ACTION:
      return state.set('loading_candlestick', true);

    case RETRIEVE_CANDLESTICK_ANALYSIS_OK:
      return state.set('candlestick_data', action.data);

    case RETRIEVE_CANDLESTICK_ANALYSIS_ERROR:
      return state;

    case RETRIEVE_CANDLESTICK_ANALYSIS_END:
      return state.set('loading_candlestick', false);

    case RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_FREQUENCY_ACTION:
      return state.set('snrLoading', true);

    case RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_OK:
      return state.set('assets_snr_frequency_stock_data', action.data).set('snrLoading', false);

    case RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_ERROR:
      return state;

    case RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_END:
      return state.set('loading', false);

    case RETRIEVE_TRADE_SETUP_ACTION:
      return state.set('loading', true);

    case RETRIEVE_TRADE_SETUP_OK:
      return state.set('trade_setup_data', action.data);

    case RETRIEVE_TRADE_SETUP_ERROR:
      return state;

    case RETRIEVE_TRADE_SETUP_END:
      return state.set('loading', false);

    case EDIT_ADX_PARAMS:
      return state.setIn(['adx_params', action.key], action.value);

    case RETRIEVE_INDEX_PERFORMANCE_OK:
      return state.set('index_performance_data', action.data);

    case RETRIEVE_COMPARATIVE_PERFORMANCE_ACTION:
      return state.setIn(['performance', 'loading'], true);

    case RETRIEVE_COMPARATIVE_PERFORMANCE_OK:
      // if (!!action.selected) {
      //   return state
      //     .setIn(['performance', 'data'], action.data)
      //     .setIn(['selected_performance_data', 'ticker'], action.ticker)
      //     .setIn(['selected_performance_data', 'data'], action.data);
      // }
      return state.setIn(['performance', 'data'], action.data);

    case RETRIEVE_COMPARATIVE_PERFORMANCE_ERROR:
      return state;

    case RETRIEVE_COMPARATIVE_PERFORMANCE_END:
      return state.setIn(['performance', 'loading'], false);

    case SELECT_PERFORMANCE_DATA_ACTION:
      return state.set('selected_performance_data', action.data);

    case RETRIEVE_UNTOUCHED_SNR_OK:
      return state.set('untouched_high', action.high).set('untouched_low', action.low);

    case RETRIEVE_VOLATILITY_GAUGE_OK:
      return state.set('volatility_gauge', action.data);

    case RETRIEVE_VOLATILITY_ATR_OK:
      return state.set('volatility_atr', action.data);

    case TOGGLE_MAX_AMOUNT_MODAL:
      return state.set('max_amount_modal', action.value);

    case RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_ACTION:
      return state.setIn(['etf_comparative_performance', 'loading'], true);

    case RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_OK:
      return state.setIn(['etf_comparative_performance', 'data'], action.data);

    case RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_ERROR:
    case RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_END:
      return state.setIn(['etf_comparative_performance', 'loading'], false);

    case SET_ETF_COMPARATIVE_PERFORMANCE_PARAMS_ACTION:
      return state.setIn(['etf_comparative_performance', 'params'], action.params);

    case RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_ACTION:
      return state.setIn(['holding_comparative_performance', 'loading'], true);

    case RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_OK:
      return state.setIn(['holding_comparative_performance', 'data'], action.data);

    case RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_ERROR:
    case RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_END:
      return state.setIn(['holding_comparative_performance', 'loading'], false);

    case SET_HOLDING_COMPARATIVE_PERFORMANCE_PARAMS_ACTION:
      return state.setIn(['holding_comparative_performance', 'params'], action.params);

    case RETRIEVE_FUND_SCREENING_ACTION:
      return state.setIn(['fund_screening', 'loading'], true);

    case RETRIEVE_FUND_SCREENING_OK:
      return state.setIn(['fund_screening', 'data'], action.data);

    case RETRIEVE_FUND_SCREENING_ERROR:
    case RETRIEVE_FUND_SCREENING_END:
      return state.setIn(['fund_screening', 'loading'], false);

    case SET_FUND_SCREENING_PARAMS_ACTION:
      return state.setIn(['fund_screening', 'params'], action.params);

    case RETRIEVE_ADX_MI_STOCKS_ACTION:
      return state.setIn(['adx_mi_stocks', 'loading'], true);
    case RETRIEVE_ADX_MI_STOCKS_OK:
      return state
        .setIn(['adx_mi_stocks', 'data'], action.data)
        .setIn(['adx_mi_stocks', 'loading'], false);
    case RETRIEVE_ADX_MI_STOCKS_ERROR:
    case RETRIEVE_ADX_MI_STOCKS_END:
      return state.setIn(['adx_mi_stocks', 'loading'], false);

    case RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_ACTION:
      return state;

    case RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_OK:
      return state;

    case RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_ERROR:
      return state;

    case RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_END:
      return state;

    case RETRIEVE_TRADE_BUILDER_SETUP_ACTION:
      return state.setIn(['trade_builder_setup', 'loading'], true);

    case RETRIEVE_TRADE_BUILDER_SETUP_OK:
      const keys = Object.keys(action?.data);
      return state
        .setIn(['trade_builder_setup', keys[0]], action?.data?.[keys[0]])
        .setIn(['trade_builder_setup', keys[1]], action?.data?.[keys[1]])
        .setIn(['trade_builder_setup', keys[2]], action?.data?.[keys[2]])
        .setIn(['trade_builder_setup', 'loading'], false);

    case RETRIEVE_TRADE_BUILDER_SETUP_ERROR:
      return state
        .setIn(['trade_builder_setup', 'data'], undefined)
        .setIn(['trade_builder_setup', 'loading'], false);

    case RETRIEVE_TRADE_BUILDER_SETUP_END:
      return state.setIn(['trade_builder_setup', 'loading'], false);

    case SET_SEARCH_TICKER:
      return state.set('search_ticker', action.params);

    case SET_CANDLESTICK_ADJUSTMENTS:
      return state.set('candleStickAdjustments', action.params);

    case SET_TRADE_BUILDER_SETUP_MAX_LOSS_ACTION:
      return state;
    // .setIn(['trade_builder_setup', 'data', 'calculated', 'max_loss'], action.data)
    // .setIn(
    //   ['trade_builder_setup', 'data', 'calculated', 'shares'],
    //   parseInt(
    //     action.data /
    //       state.getIn(['trade_builder_setup', 'data', 'calculated', 'potential_loss'])
    //   ) || 1
    // );

    default:
      return state;
  }
};

export default technicalAnalysisReducer;
