import { takeEvery, fork, put, call } from 'redux-saga/effects';
import landing_home from '../../services/landing_home';

import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_OK,
  FETCH_POST_DETAILS,
  FETCH_POST_DETAILS_OK,
  FETCH_END,
  FETCH_ERROR,
} from '../constants/landing_home.constant';

export function* fetchCategoriesData(action) {
  try {
    const xhr = yield call(landing_home.fetchCategories);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: FETCH_CATEGORIES_OK, ...xhr?.data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: FETCH_ERROR, ...e });
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* fetchCategories() {
  yield takeEvery(FETCH_CATEGORIES, fetchCategoriesData);
}

export function* fetchPostData(action) {
  try {
    const xhr = yield call(landing_home.fetchPostDetails, action.id);
    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: FETCH_POST_DETAILS_OK, ...xhr?.data });
  } catch (e) {
    console.log('error : ', e);
    yield put({ type: FETCH_ERROR, ...e });
  } finally {
    yield put({ type: FETCH_END });
  }
}

export function* fetchPost() {
  yield takeEvery(FETCH_POST_DETAILS, fetchPostData);
}

const landing_home_sagas = [fork(fetchCategories), fork(fetchPost)];
export default landing_home_sagas;
