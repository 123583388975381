import API from './API';

const quiz = {
  fetchQuiz: (params) => {
    return new API().get('/quiz/list/', params);
  },

  fetchQuizCategories: () => {
    return new API().get('/quiz/categories/');
  },

  submitAnswer: (data) => {
    return new API().patch('/quiz/save-answer/', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  submitQuiz: (data) => {
    return new API().post('/quiz/submit/investment-risk-tolerance-quiz/', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  submitCategoryQuiz: (data) => {
    return new API().post(`quiz/submit/${data.category}/?quiztaker&question&answer`, data.data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  getUserQuiz: (params) => {
    return new API().post('/quiz/registration/', params);
  },

  getUserDetails: (type) => {
    return new API().get(
      `/quiz/detail/${type}/`,
      {},
      {
        'Content-Type': 'application/json',
      }
    );
  },

  retakeQuiz: () => {
    return new API().get('/quiz/detail/investment-risk-tolerance-quiz/?retake=true');
  },
};

export default quiz;
