import { Box, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../../../components/Icon';
import useStyles from './index.styles';

const Item = ({ className, item }) => {
  const styles = useStyles();
  return (
    <Link className={styles.item} to={item.url}>
      <Box className={styles.preview}>
        <img srcSet={`${item.image2x} 2x`} src={item.image} alt="Item" />
      </Box>
      <Box className={styles.details}>
        {item.categoryText && (
          <Chip
            label={item.categoryText}
            sx={{ bgcolor: item.category === 'green' ? 'green' : 'red', color: 'white' }}
          />
        )}
        <Typography className={styles.title} variant="h4">
          <Box fontWeight="bold">{item.title}</Box>
        </Typography>
        <Typography className={styles.currency} variant="subtitle1">
          {item.currency}
        </Typography>
        <Typography className={styles.content} variant="body1">
          {item.content}
        </Typography>
      </Box>
      <Box className={styles.arrow}>
        <Icon name="arrow-next" size="14" />
      </Box>
    </Link>
  );
};

export default Item;
