export const FETCH_END = 'stripe/FETCH_END';
export const FETCH_ERROR = 'stripe/FETCH_ERROR';
export const FETCH_PRODUCTS = 'stripe/FETCH_PRODUCTS';
export const FETCH_PRODUCTS_OK = 'stripe/FETCH_PRODUCTS_OK';
export const SUBSCRIBE_CUSTOMER = 'stripe/SUBSCRIBE_CUSTOMER';
export const SUBSCRIBE_CUSTOMER_OK = 'stripe/SUBSCRIBE_CUSTOMER_OK';
export const TOGGLE_SUCCESS_MODAL = 'stripe/TOGGLE_SUCCESS_MODAL';
export const FETCH_SUBSCRIPTIONS = 'stripe/FETCH_SUBSCRIPTIONS';
export const FETCH_SUBSCRIPTIONS_OK = 'stripe/FETCH_SUBSCRIPTIONS_OK';
export const CANCEL_SUBSCRIPTION = 'stripe/CANCEL_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION = 'stripe/UPDATE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION_OK = 'stripe/UPDATE_SUBSCRIPTION_OK';
export const SET_SELECTED_PLAN = 'stripe/SET_SELECTED_PLAN';
export const TOGGLE_SAVE_TICKER_MODAL = 'stripe/TOGGLE_SAVE_TICKER_MODAL';
