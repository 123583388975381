import { Box, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BlurredContent from '../../../components/BlurredContent';
import ProgressCircular from '../../../components/ProgressCircular';
import {
  retrieveRelativeBondsIndexAction,
  retrieveRelativeSPIndexAction,
} from '../../../redux/actions/macroeconomics.action';
import { macroeconomicsSelector } from '../../../redux/reselect/macroeconomicsSelector';
import useCommonStyles from '../styles';
import RelativeIndex from './blocks/RelativeIndex';

const useCategories = (data) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => Array.from(new Set(data.map((item) => item.category))), [data]);
};

const useSummary = (data, categories) => {
  return useMemo(() => {
    const categoryObj = categories.reduce((o, key) => ({ ...o, [key]: [] }), {});

    data.forEach((_data) => {
      categoryObj[_data.category].push(_data);
    });
    return categoryObj;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
};

const RelativeAnalysisSummary = ({ isBlurred = false, ...props }) => {
  //Styles
  const commonClasses = useCommonStyles();

  //Dispatch
  const dispatch = useDispatch();

  //Redux Stores
  const { relative_sp_index, relative_bonds_index } = useSelector(macroeconomicsSelector);
  const { data: dataSP, loading: loadingSP } = relative_sp_index;
  const { data: dataBonds, loading: loadingBonds } = relative_bonds_index;

  const categoriesSP = useCategories(dataSP);
  const categoriesBonds = useCategories(dataBonds);
  const summarySP = useSummary(dataSP, categoriesSP);
  const summaryBonds = useSummary(dataBonds, categoriesBonds);

  useEffect(() => {
    dispatch(retrieveRelativeSPIndexAction());
    dispatch(retrieveRelativeBondsIndexAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderHeader = (title) => (
    <Box className={commonClasses.headerContainer}>
      <Typography className={commonClasses.header} variant="h5">
        {title}
      </Typography>
    </Box>
  );

  const renderSPIndex = () => (
    <Box sx={{ marginBottom: '32px' }}>
      {loadingSP ? (
        <ProgressCircular size={50} thickness={5} />
      ) : (
        <RelativeIndex
          categories={categoriesSP}
          summary={summarySP}
          headerTitle={'Relative to S&P Index'}
        />
      )}
    </Box>
  );

  const renderBondIndex = () => (
    <Box sx={{ marginBottom: '32px' }}>
      {loadingBonds ? (
        <ProgressCircular size={50} thickness={5} />
      ) : (
        <RelativeIndex
          categories={categoriesBonds}
          summary={summaryBonds}
          headerTitle={'Relative to Barclays Aggregate Bond Index'}
        />
      )}
    </Box>
  );

  return (
    <Box className={commonClasses.commonContainer}>
      {renderHeader('Relative Analysis Summary')}
      <BlurredContent isBlurred={isBlurred}>
        {renderSPIndex()}
        {renderBondIndex()}
      </BlurredContent>
    </Box>
  );
};

export default RelativeAnalysisSummary;
