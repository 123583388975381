import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import colors from '../../styles/colors';
const TieredChart = ({ data = {}, props }) => {
  const classes = useStyles();
  const items = Object.keys(data).map((k) => ({ label: k, key: k, ...data[k] }));

  const transformDataToColors = ({ close, sma, ema, atr, volume, key }) => {
    const data_to_colors = [];

    // for SMA - close > sma
    data_to_colors[0] = !!sma ? (close > sma ? 2 : 1) : 0;

    // for EMA - close > ema
    data_to_colors[1] = !!ema ? (close > ema ? 2 : 1) : 0;

    // for IV - current > prev
    data_to_colors[2] = !!atr ? (atr[key] > atr[`prev_${key}`] ? 2 : 1) : 0;

    // for V - today > prev volume ? 2 : 1 -- if no volume, just -1(purple)
    data_to_colors[3] = !!volume ? (volume['today'] > volume[key] ? 2 : 1) : 0;

    return data_to_colors;
  };

  const _renderCell = (
    data = [
      { value: 0, str: 0 },
      { value: 0, str: 0 },
      { value: 0, str: 0 },
    ]
  ) => {
    return (
      <Box
        display={'flex'}
        flex="1"
        flexDirection={'column'}
        paddingX="3px"
        justifyContent="center"
      >
        <Box
          className={clsx(classes.bar, {
            [classes.barPurple]: data[0].value === 0,
            [classes.barRed]: data[0].value === 1,
            [classes.barGreen]: data[0].value === 2,
            [classes.strStrong]: !!data[0].value && data[0].str === 1,
          })}
        />
      </Box>
    );
  };

  const _renderheader = (title) => {
    return (
      <Box display={'flex'} flexDirection={'row'}>
        <Box
          display={'flex'}
          flex="1"
          alignItems={'flex-end'}
          justifyContent={'flex-end'}
          padding="5px"
          className={classes.timeframe}
        ></Box>
        <Box
          height="50px"
          maxWidth="80px"
          display={'flex'}
          flex="1"
          flexDirection={'column'}
          paddingX="3px"
        >
          <Typography className={classes.headerLabel}>Simple Moving Average</Typography>
        </Box>
        <Box
          height="50px"
          maxWidth="80px"
          display={'flex'}
          flex="1"
          flexDirection={'column'}
          paddingX="3px"
        >
          <Typography className={classes.headerLabel}>Exponential Moving Average</Typography>
        </Box>
        <Box
          height="50px"
          maxWidth="80px"
          display={'flex'}
          flex="1"
          flexDirection={'column'}
          paddingX="3px"
        >
          <Typography className={classes.headerLabel}>Implied Volatility</Typography>
        </Box>
        <Box
          height="50px"
          maxWidth="80px"
          display={'flex'}
          flex="1"
          flexDirection={'column'}
          paddingX="3px"
        >
          <Typography className={classes.headerLabel}>Volume</Typography>
        </Box>
      </Box>
    );
  };
  const _renderRow = (item) => {
    const item_data = transformDataToColors(item);
    return (
      <Box display={'flex'} flexDirection={'row'}>
        <Box
          display={'flex'}
          flex="1"
          alignItems={'flex-end'}
          justifyContent={'flex-end'}
          padding="5px"
          className={classes.timeframe}
        >
          {item.label}
        </Box>
        {_renderCell([{ value: item_data[0], str: 1 }])}
        {_renderCell([{ value: item_data[1], str: 1 }])}
        {_renderCell([{ value: item_data[2], str: 1 }])}
        {_renderCell([{ value: item_data[3], str: 1 }])}
      </Box>
    );
  };
  return (
    <Box className={classes.cardContainer}>
      <Box>
        <Box>
          {_renderheader()}
          {React.Children.toArray(items.map((d) => <>{_renderRow(d)}</>))}
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    backgroundColor: colors.black.black_1,
    width: '100%',
    paddingTop: '10rem',
    borderRadius: '16px',
    padding: '1.5rem 2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bar: {
    display: 'flex',
    flex: 1,
    background: colors.grey.grey_1,
    maxWidth: '80px',
    maxHeight: '12px',
    borderRadius: 3,
    margin: '3px 0',
    opacity: 0.7,
  },
  barGreen: {
    background: colors.gradients.light_greenish_blue,
  },
  barPurple: {
    background: colors.purple.purple_1,
  },
  barRed: {
    background: colors.red3,
  },
  strStrong: {
    opacity: 1,
  },
  headerLabel: {
    whiteSpace: 'nowrap',
    transform: 'rotate(-45deg)',
    color: colors.light_greenish_blue,
    color: colors.green.green_3,
  },
  timeframe: {
    color: colors.grey.grey_1,
    width: '80px',
  },
}));

export default TieredChart;
