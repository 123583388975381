const POLICY = {
  title: 'Risk Disclosure Agreement',
  description: `Required agreement related to investment security risk and the platform.

  You acknowledge and agree to this Risk Disclosure Agreement which is also incorporated by reference in the Company's Terms of Use:`,
  items: [
    {
      title: 'Options Trading‍',
      description: `Options trading generally is not appropriate for someone of limited resources and/or limited investment or trading experience or someone with low-risk tolerance. The risk of loss in trading options and other securities can be substantial. Consider all relevant risk factors, including your own personal financial situation, before engaging in any options trading. The possibility exists that you could sustain a substantial loss which could total more than your initial investment in a short period of time. Therefore you should not invest money that you cannot afford to lose. In particular, you should not fund options trading activities with retirement savings, student loans, second mortgages, emergency funds, or funds required to meet your living expenses. If you have any questions or concerns regarding the risks associated with trading, you should confer with a trusted and reliable, independent financial advisor. None of the Information or the Website provided by the Company constitutes a solicitation to trade any option or any investment or security of any kind.‍`,
    },
    {
      title: 'Options‍',
      description: `Options are widely considered a volatile investment class and are priced according to complex factors involving (among other factors) price, the volatility of the underlying asset, time until expiration, and interests rates. By their nature, therefore, options involve a high degree of financial risk. Risks associated with acquiring options include (without limitation): (i) the risk of losing your entire investment increases as the option goes out of the money and as expiration approaches; (ii) the risk that certain options do not have secondary markets on which to sell them, meaning that their value can only be realized upon expiration; (iii) the risk that a particular option contract may have specific exercise provisions that create additional risk; (iv) U.S. or other regulatory agencies may impose regulations or rules that impair your ability to realize value from options; and (v) the risk of losing your entire investment in a relatively short period of time.‍ You should familiarize yourself with the type of Options (i.e. put or call) which you contemplate trading and the risks associated with them (not all of which are captured in this disclosure statement). In addition, options (unlike several other classes of securities) expire, thereby losing any value they might have if not sold or exercised prior to that expiration. This imposes a particular obligation on an option trader to ensure that his or her options are traded or exercised in a timely fashion.`,
    },
    {
      title: 'Claims about Option Trading',
      description: `Be wary of advertisements or other claims of large profits in options trading.`,
    },
    {
      title: 'Option Contracts‍',
      description: `Options, and the trading of options, are typically governed by a series of complex contracts. The intent of the legal documents and contracts might be unclear, and even clear drafting could be misconstrued by counterparties or by judges and arbitrators. A dispute over the interpretation of any of these documents or contracts could arise, which may result in unenforceability of the contract or other outcome that is adverse to you.‍`,
    },
    {
      title: 'Underlying Securities',
      description: `Options relate to underlying securities whose identifying characteristics, such as ticker symbols or other attributes, may change with little warning. A company might undergo a stock split, reverse stock split, spin-off, consolidation, or other corporate transaction that could affect the value of its equity interests independently of market trading. This, in turn, might have an effect on the effectiveness of your option positions. The Company is not obligated to provide you information regarding any change to the capital structure or other change to underlying issuers, and you are responsible for performing your own research in connection with the issuers to which your options relate.`,
    },
    {
      title: 'General Economic and Market Conditions‍',
      description: `The options market will be affected by general economic and market conditions, as well as by changes in laws, currency exchange controls, and international, national, and regional political and socio-economic circumstances. The options market may be sensitive to general downward swings in the overall economy or particular industries or geographies. Factors affecting economic conditions, including, for example, inflation rates, currency devaluation, exchange rate fluctuations, industry conditions, competition, technological developments, domestic and worldwide political, military and diplomatic events and trends, and innumerable other factors outside of your control and that of the Company, which can materially and adversely affect the availability or desirability of segments of the options market.‍`,
    },
    {
      title: 'Regulators of Options and the Options Market',
      description: `The courts, the Securities and Exchange Commission, another regulatory agency (whether the U.S. or otherwise), or the options markets themselves may in the future impose additional restrictions on option trading. This could have an adverse effect on your existing portfolio of options or your options-trading generally.`,
    },
    {
      title: 'Taxation‍',
      description: `The trading of options can impose tax burdens and tax filing obligations on trade participants. Such considerations are complex and highly personal. You are urged to consult with your tax advisors with respect to your tax situation and the effect of engaging in options trading.‍`,
    },
    {
      title: 'Counterparty Risk',
      description: `The trading of options will leave you exposed to the risk that third parties that owe you money, securities, or other assets will not perform their obligations. These parties include trading counterparties, brokers, clearing agents, exchanges, clearinghouses, custodians, and other financial intermediaries. These parties may default on their obligations to you or to another third party due to bankruptcy, lack of liquidity, internal failure, or other reasons. Even outside these risks, certain counterparties (such as brokers, exchanges, and other intermediaries) may charge you commissions or impose other transaction expenses on you, which will be payable even if the associated transaction is not profitable. Accordingly, when trading options, you should determine the extent to which the value of the options must increase for your position to become profitable, taking into account all transaction costs.`,
    },
    {
      title: '‍Past Results Are No Guarantee of Future Performance',
      description: `There can be no assurance as to any particular financial outcome based on the use of the Website. Past results of any individual trader or trading system published by the Company are not indicative of future returns that may be realized by that individual trader, trading system, or you. Similarly, it should not be assumed that the methods, techniques, or indicators presented in these products and/or services will be profitable or that they will not result in losses. Any earnings or income statements or examples shown in the Information or through the Website are only estimates of what might be possible now or in the future and are provided only for informational and educational purposes.`,
    },
    {
      title: 'Risks Associated with Investment Activity',
      main_title: 'Brokerage Risk',
      description: `The Website is not a trading platform, nor does it allow you to trade options or other securities; it instead is a portal through which you may be able to, if desired, access your personal brokerage account that you maintain with a third-party broker. You should refer to the brokerage agreement between your broker and you for how, if at all, any losses arising from these risks are allocated between your broker and you, for which the Company bears no responsibility. Delays within and between your system, as well as those of your broker and the market in which you are seeking to effect trades, might cause orders, corrections, and cancels to be placed or not placed in ways that are not desired. You may receive incorrect information, or be unable to get information, about your orders, your positions, or market conditions. Incorrect actions may be taken, or correct actions may not be taken because of inaccurate or missing information.‍`,
    },
    {
      title: 'No Assurance of Investment Return',
      description: `The Company cannot provide assurance that you will be able to choose, make and realize trades in options in any particular company or portfolio of companies. There is no assurance that you will be able to generate returns or that the returns will be commensurate with the risks of investing in options generally, or that you will receive a return of its capital. Trading in options should only be considered by persons who can afford a loss of their entire investment. Neither the Company nor any Company Person has made any representations as to the health, physical, mental, emotional, spiritual or health benefits, future income, expenses, sales volume or potential profitability or loss of any kind that may be derived as a result of your accessing of, or use of, the Information or the Website. The Company cannot and does not guarantee that you will attain a particular result, positive or negative, financial or otherwise, through your access of or use of the Information (including, without any limitation, any Information) and the Website.‍`,
    },
    {
      title: 'No Investment Advice',
      description: `Although the Information and the Website may contain general, academic, or educational discussions of securities, such discussions are not solicitations of any kind nor a recommendation to buy or sell a particular financial security or product. Such discussions are not, and should not be construed to be, investment advice under any circumstances. Accordingly, you should not rely solely on the Information or the Website in making any investment. The Company strongly recommends that you seek the advice of your licensed financial, investment, tax, legal or other professional advisors to determine the suitability of any investment.‍`,
    },
    {
      title: 'No Legal, Accounting, or Financial Advice',
      description: `The Information and the Website are not intended to be perceived as, or relied upon, as legal, accounting, or financial advice. The Company is not a law firm, accounting firm, or financial advisory firm and does not provide legal, accounting, or financial advice. The information provided through the Information and the Website is not intended to be a substitute for the professional advice that can be provided by your own accountant, lawyer, or financial advisor.‍`,
    },
    {
      title: 'Regulatory Considerations',
      description: `Neither the Company nor any Company Person is, in such capacities, a licensed financial advisor, registered investment advisor, registered broker-dealer, or FINRA | SIPC | NFA-member firm. As a result, you may not receive the protections or benefits that you might be entitled to were this not the case.`,
    },
    {
      title: 'Risks Involving the Information, the Website, and this Agreement',
      main_title: `Assumption of Risk‍`,
      description: `As with all situations, unknown individual risks and circumstances can arise during the use of the Information and the Website that cannot be foreseen, and that can influence or reduce results. You recognize and agree that any mention of any suggestion or recommendation contained, or that you believe to be contained, in the Information, or the Website is to be taken at your own sole risk, with no liability on the Company or any Company Person’s part.‍`,
    },
    {
      title: `Errors and Omissions‍`,
      description: `The Information and the Website may contain inaccuracies or typographical errors. The Company is not responsible for the views, opinions, or accuracy of facts referenced on or through the Information or the Website, or of those of any other individual or company affiliated with the Company or any Company Person in any way. The Company is not responsible for the accuracy of the Information or the Website, or for any errors or omissions that may occur in connection therewith.‍`,
    },
    {
      title: 'Technology‍',
      description: `The Company does not guarantee that your access to the Information and the Website will not be suspended or restricted from time to time, including (without limitation) to allow for repairs, maintenance or updates, as a result of third-party denial-of-service or other attacks, or through circumstances outside the Company’s reasonable control. To the fullest extent permitted by law, we will not be liable to you for damages or refunds, or for any other recourse, should any Information or the Website become unavailable or should access to them becomes slow or incomplete for any reason, such as (without limitations) system back-up procedures, internet traffic volume, upgrades, overload of requests to the servers, general network failures or delays, or any other cause which may from time to time make the Information or the Website inaccessible to you.‍`,
    },
    {
      title: 'No Endorsement‍',
      description: `References or links in the Information and the Website to the information, opinions, advice, programs, products, or services of any other individual, business, or entity do not constitute a formal endorsement by the Company of such other person. The Company is not responsible for the website content, blogs, e-mails, videos, social media, programs, products, and/or services of any other person, business, or entity that may be linked or referenced in my Website. Conversely, should the Information or a Website link appear in any other individual’s, business’s or entity’s website, program, product, or services, such appearance does not constitute the Company’s endorsement of them, their business, or their website.‍`,
    },
    {
      title: 'Affiliates‍',
      description: `From time to time, the Company may promote, affiliate with, or partner with other individuals or businesses whose Information or Website align with the Company’s. In some cases in connection therewith, the Company may receive financial compensation or other consideration from such individuals or businesses. You acknowledge that such promotion or marketing by the Company does not serve as any form of endorsement of such product or service whatsoever. You acknowledge and agree that you are still required to use your own judgment to determine that any such program, product, or service is appropriate for you and that you assume all risks in connection with your use of any program, product, or service that may promote, market, share or sell on or through the Website.‍`,
    },
    {
      title: 'Testimonials‍',
      description: `The Website may present experiences, testimonials, and insights by users of our products and/or services. Such experiences reflect the opinions of such users and are provided for the purposes of illustration only. The experiences are personal to those particular users and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume that all users will have the same experiences. YOUR INDIVIDUAL RESULTS MAY VARY. The testimonials on the Website are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the Website as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity, where the full testimonial contained extraneous information not relevant to the general public. The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions. Some users were solicited and compensated $100 Amazon gift cards.`,
    },
    {
      title: 'Arbitration‍',
      description: `This Agreement requires the use of arbitration, rather than a trial by jury or a bench trial, to resolve disputes arising under or in connection with this Agreement, the Information, and the Website. This may limit the remedies available to you in the event of a dispute. You fully understand and agree that by accessing or using the Information and the Website (including, without limitation, by enrolling in, purchasing, and/or using any of the Information) that you are thereby voluntarily waiving certain legal rights.‍ `,
    },
    {
      title: 'Links to Other Websites; Third-Party Information and Websites',
      description: `The Website or Information may provide links and pointers to other websites maintained by third parties that may take you outside of the Website or Information. These links are provided for your convenience, and the inclusion of any link in the Information or Website to any other website does not imply our endorsement, sponsorship, or approval of that website or its owner. We do not endorse, and we are not responsible for the views, opinions, facts, advice, or statements provided by external resources referenced in the Website or the Information, or their accuracy or reliability. We assume no responsibility for errors or omissions caused by other websites that may be included in the Information or the Website. We have no control over the contents or functionality of those websites and so we accept no responsibility for any loss, damage, or otherwise, that may arise from your use of them, and therefore we do not guarantee the accuracy, completeness, or usefulness of any other website or their content. It is your responsibility to review the terms and conditions and privacy policies of those linked websites to confirm that you understand and agree with those policies.‍`,
    },
    {
      title: 'Risks Involving ”Bots” and User Automations',
      main_title: 'User Automations, Generally',
      description: `Through the Website, you may create, or have access to other Website users’ creations of, option trading automations, sometimes referred to as “bots” or "templates" (the “User Automations”). You agree not to use any User Automation until you have read the Company’s guidance with respect to User Automations. You understand that neither the Company nor any Company Person is in any way responsible to you for the contents of, performance of, or results from using any User Automation or User Automations generally. You acknowledge that the Company does not recommend, endorse or encourage the use of any specific User Automation. You also acknowledge that, while the Company may make User Automations available to you and may advertise them and their availability to you, the Company also does not recommend, endorse or encourage your use of User Automations generally. You agree to manage your own open positions on a brokerage’s platform should a User Automation fail with respect to such positions for any reason. You acknowledge that User Automations are a sophisticated tool and represent that you have the requisite sophistication to use them, if you do, prudently. You acknowledge that the Company has not guaranteed your access to the User Automations and that the Company may suspend or end the operations of any or all User Automations at any time, or limit or completely restrict your access to them, for any or no reason. Finally, you agree that any use by you of any and all User Automations is strictly at your own risk.‍`,
    },
    {
      title: 'Relation to Other Risk Disclosures',
      description: `Because User Automations can, by enabling communication with a third party broker, involve the trading of options, their use is subject to the “General Marketplace Risks” and “Risks Associated with Investment Activity” set forth in this Disclosure Statement. In particular, because the User Automations can only interact with a third party broker, and that third party broker will make the independent determination of whether or not to place any orders communicated through a User Automation, the use of User Automations is subject to the risks set forth in “Brokerage Risk” above. Finally, because User Automations are an element of the Website and provided through this Website, their use is subject to the “Risks Involving the Information, the Website and this Agreement” set forth above.‍`,
    },
    {
      title: 'Your Use of User Automations',
      description: `You understand that a User Automation, once turned on by you, may, by acting independently of you according to governing rules that you previously established and agreed to, lead to consequences unforeseen by you or differing from those that would have resulted had you acted directly rather than through such User Automation. Risks inherent in creating, testing, and executing trading strategies through User Automations include: (i) risk that the User Automation (i) may not function as you had intended; (ii) may not achieve the desired result; (iii) may not perform or react to market conditions or data as expected; and (iv) may not be appropriate for the market conditions at the time of execution. No matter how well designed and tested a User Automation may be, the results are not wholly predictable, and performance may be subject to numerous unforeseen factors. Past performance is not an indication of future results. A User Automation may be designed on the basis of an incorrect understanding of: (i) how the Website works; (ii) how a User Automation works; (iii) the operation of the trading markets for the securities to which your User Automation is addressed; (iv) how the different systems comprising those markets interact; (v) the costs of executing the trades performed by your User Automation; or (vi) how your User Automations interact with your brokerage account, your broker or other third parties or third-party websites or systems.`,
    },
    {
      title:
        '‍“Allocation” Field in the User Automation Menu Only Sets Opening, Not Closing, Cap for Options',
      description: `Setting the “Allocation” field in the User Automation menu only establishes the maximum price for opening a position and does not affect the maximum price for closing that position. It is possible that the costs of closing a position could exceed the value you set in the “Allocation” field for opening that position. In other words, the “Allocation” field does not cap the amount of money that may be at risk in connection with a trade.`,
    },
    {
      title: '‍User Automations Will Close Positions Automatically',
      description: `Each User Automation is designed to close all trades initiated by your broker at your instruction through that User Automation, in accordance with the instructions you select in programming your User Automation (which are then communicated to that broker). User Automations are not intended to instruct a broker to leave a position open indefinitely, as this could expose you to risks associated with after-hours trading and other adverse consequences. Nonetheless, although User Automations are designed to send closing instructions at set times, the Company disclaims any responsibility for closing such positions on your behalf, or for the consequences should such positions not be closed, and you agree that you and/or your broker are the sole parties responsible for ensuring that your trades are opened and closed.‍`,
    },
    {
      title: 'User Override of User Automations’ Positions',
      description: `While users can set capital and position limits while designing a User Automation, you have the ability to manually override that User Automation even after it is turned on. This option, although it gives you greater control and flexibility, also means you can--due to emotion or otherwise--depart from your previously-considered strategy with a User Automation. This could lead to the assumption of greater risk than was originally contemplated with the initial User Automation configuration.`,
    },
    {
      title: '‍Investment Strategy of User Automations',
      description: `All users of User Automations must determine for themselves what specific investments to make or not make and are urged to consult with their own independent financial advisers with respect to any investment decision. You bear responsibility for your own investment research and decisions, your decision whether or not to seek the advice of a qualified securities professional before making any investment, and your investigation of any and all risks before employing a User Automation. The fact that the User Automation allows you to do something is not an endorsement of that action by the Company.`,
    },
    {
      title: 'Accounting for Changes to Underlying Securities',
      description: `User Automations will not automatically reflect changes to the underlying securities to which their option strategy relates. For instance, an issuer may change its ticker symbol, which could render a User Automation unable to complete a pre-programmed trade that relied on the old symbol. Similarly, an issuer might undertake a stock split, reverse stock split, spin-off, consolidation or other corporate transaction that could affect the value of its equity interests independently of the range of value contemplated by a User Automation. For instance, if an issuer splits its common stock 100%, the value of each share of such stock will decrease 50% automatically, even though the value of the Company remains constant. The Company is not obligated to provide you information regarding any change to the capital structure or other change to underlying issuers, and you are responsible for performing your own research in connection with the issuers whose securities are the subject of your User Automations.`,
    },
    {
      title: '‍User’s Human Error in User Automations',
      description: `Your design or use of User Automations may be subject to user error. Such errors could include entering incorrect fields (including ticker symbols) or inadvertently turning the User Automations “on” or “off.” Even if your User Automation works as intended, it could produce adverse results through non-optimal strategies or “setting and forgetting.”‍`,
    },
    {
      title: 'Counterparty Risk of User Automations',
      description: `Even if your User Automation is working as expected, your broker may reject orders in error or by design, incorrectly execute orders, or induce errors through unexpected behavior (such as returning messages out of sequence, incorrectly acknowledging orders, or posting incorrect execution reports).‍`,
    },
    {
      title: 'Risks Involving Templates',
      main_title: 'General',
      description: `Although the Website provides you with option or stock trading templates and/or samples as an educational feature, the Company makes no endorsements or representations about their use in active trading or the trading or investment strategy that these templates and/or samples represent or might be believed to represent. The Company does not intend for these templates and/or samples to be used in active trading and gives no representations about the results that would occur should they be so used.`,
    },
    {
      title: 'Limited Features',
      description: `The templates provided by the Website are by nature limited in scope and do not purport to demonstrate the full range of tools and features that are available in using the order platform.`,
    },
    {
      title: 'Relation to Other Risk Disclosures',
      description: `The use of trading templates could be used in the trading of options and stocks, and accordingly, their use is subject to the “General Marketplace Risks” and “Risks Associated with Investment Activity,” and all other relevant risks, as set forth in this Risk Disclosure Agreement.‍

      If you have any questions, please contact us at team@traidify.com. Thank you.`,
    },
  ],
};

export default POLICY;
