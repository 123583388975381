import React from 'react';
import { useHistory } from 'react-router-dom';

import cn from 'classnames';
import useStyles from './index.style';
// import styles from "./Main.module.sass"
import { Box, Container, Typography } from '@mui/material';
import { Ticker } from 'react-tradingview-embed';
import { Button, Outlined } from '../../../components/Button';

const Main = ({ scrollToRef }) => {
  const styles = useStyles();
  const history = useHistory();

  return (
    <Container id="main" className={cn('section', styles.main)}>
      <Box className={cn('container', styles.container)}>
        <Box className={styles.centerItems}>
          <Box className={styles.wrap}>
            <Typography className={styles.title} variant="h2">
              <Box fontWeight="bold">Your Trade Assistant</Box>
            </Typography>
            <Box className={styles.text}>
              {/* <Typography variant="h5">Your plug and play Trade Assistant.</Typography> */}
              <Typography variant="h5">No assembly Required.</Typography>
            </Box>
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box mb={1}>
                <Outlined rounded onClick={() => history.push({ pathname: `/sign-up` })}>
                  Sign Up
                </Outlined>
              </Box>
              <Box>
                <Button
                  style={{ width: '200px' }}
                  rounded
                  onClick={() => history.push({ pathname: `/login` })}
                >
                  Login
                </Button>
              </Box>
            </Box>
          </Box>
          <Box className={styles.bg}>
            <img src="/images/content/candlestick.png" alt="Cards" />
          </Box>
        </Box>
        {/* <Cards />  */}
        <Ticker
          widgetProps={{
            symbols: [
              {
                proName: 'FOREXCOM:SPXUSD',
                title: 'S&P 500',
              },
              {
                proName: 'FOREXCOM:NSXUSD',
                title: 'US 100',
              },
              {
                proName: 'FX_IDC:EURUSD',
                title: 'EUR/USD',
              },
              {
                proName: 'BITSTAMP:BTCUSD',
                title: 'Bitcoin',
              },
              {
                proName: 'BITSTAMP:ETHUSD',
                title: 'Ethereum',
              },
            ],
            colorTheme: 'light',
            isTransparent: false,
            showSymbolLogo: true,
            locale: 'en',
          }}
        />
      </Box>
    </Container>
  );
};

export default Main;
