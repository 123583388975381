import { Box, Checkbox, Grid, Hidden, IconButton, InputAdornment, Typography } from '@mui/material';
import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import { Link } from 'react-router-dom';
import { Contained } from '../../components/Button';
import RegisterButton from './RegisterButton';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import apple_icon from '../../assets/images/signup/apple_icon.png';
import fb_icon from '../../assets/images/signup/fb_icon.png';
import google_icon from '../../assets/images/signup/google_icon.png';
import TextInput from '../../components/TextInput';
import SuccessModal from './SuccessModal';

import { ERROR, INFO } from '../../constants';

const Desktop = ({
  classes,
  validForm,

  termsAgree,
  setTermsAgree,
  showPassword,

  form,
  errors,
  loading,
  successModal,

  email_resend_loading,
  email_resend_done,

  handleRecaptchaOnChange,
  handleSubmit,
  handleOnChange,
  handleValidate,
  handleModalClose,
  handleTogglePasswordVisibility,
  handleResendEmail,
}) => {
  return (
    <Hidden smDown>
      <Box className={classes.container}>
        <SuccessModal
          handleResendEmail={handleResendEmail}
          email_resend_loading={email_resend_loading}
          email_resend_done={email_resend_done}
          open={successModal}
          handleClose={handleModalClose}
        />
        <Box className={classes.content}>
          <Grid className={classes.gridContainer} container spacing={0}>
            <Grid item className={classes.formGridItem}>
              <Box alignItems="center" display="flex" flex="1" flexDirection="column" width="100%">
                <Box minWidth="30%">
                  <Box className={classes.signUpText} textAlign="center">
                    <Typography variant="h5">Sign Up</Typography>
                  </Box>
                  <Box className={classes.registerOptions}>
                    <RegisterButton name="fb" src={fb_icon} disabled={true} />
                    <RegisterButton name="fb" src={apple_icon} disabled={true} />
                    <RegisterButton name="fb" src={google_icon} disabled={true} />
                  </Box>
                  <Box paddingTop="1.2rem" className={classes.or} textAlign="center">
                    <Typography>or</Typography>
                  </Box>
                  <Box paddingTop="1rem" width="100%">
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                      <Box>
                        <TextInput
                          id="first_name"
                          onChange={handleOnChange}
                          magic_label="First Name"
                          variant="standard"
                          conditions={errors?.first_name}
                          inputState={errors?.first_name?.length ? ERROR : INFO}
                          value={form?.first_name || ''}
                          onBlur={handleValidate}
                        />
                      </Box>
                      <Box paddingTop="1rem">
                        <TextInput
                          id="last_name"
                          onChange={handleOnChange}
                          magic_label="Last Name"
                          variant="standard"
                          conditions={errors?.last_name}
                          inputState={errors?.last_name?.length ? ERROR : INFO}
                          value={form?.last_name || ''}
                          onBlur={handleValidate}
                        />
                      </Box>
                      <Box paddingTop="1rem">
                        <TextInput
                          id="email"
                          onChange={handleOnChange}
                          magic_label="Email"
                          variant="standard"
                          conditions={errors?.email}
                          inputState={errors?.email?.length ? ERROR : INFO}
                          value={form?.email || ''}
                          onBlur={handleValidate}
                          inputMode="email"
                        />
                      </Box>
                      <Box paddingTop="1rem">
                        <TextInput
                          id="password"
                          onChange={handleOnChange}
                          magic_label="Password"
                          variant="standard"
                          conditions={errors?.password}
                          inputState={errors?.password?.length ? ERROR : INFO}
                          value={form?.password || ''}
                          onBlur={handleValidate}
                          type={showPassword[0] ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment className={classes.inputAdornment} position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => handleTogglePasswordVisibility(0)}
                                  tabIndex={-1}
                                >
                                  {showPassword[0] ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box paddingTop="1rem">
                        <TextInput
                          id="confirm_password"
                          onChange={handleOnChange}
                          magic_label="Confirm Password"
                          variant="standard"
                          conditions={errors?.confirm_password}
                          inputState={errors?.confirm_password?.length ? ERROR : INFO}
                          value={form?.confirm_password || ''}
                          onBlur={handleValidate}
                          type={showPassword[1] ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment className={classes.inputAdornment} position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => handleTogglePasswordVisibility(1)}
                                  tabIndex={-1}
                                >
                                  {showPassword[1] ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box paddingTop="1rem" className={classes.terms}>
                        <Checkbox
                          color="primary"
                          value={termsAgree}
                          onChange={(e) => setTermsAgree(e.target.checked)}
                        />
                        <Typography variant="caption">
                          I agree with the{' '}
                          <Typography
                            variant="caption"
                            component={Link}
                            target="_blank"
                            rel="noopener"
                            to="/terms-and-conditions"
                          >
                            Terms and Conditions
                          </Typography>
                        </Typography>
                      </Box>
                      <Box>
                        {/* <ReCAPTCHA sitekey={RECAPTCHA_API_KEY} onChange={handleRecaptchaOnChange} /> */}
                      </Box>
                      <Box paddingTop="2rem" textAlign="center">
                        <Contained
                          miniRounded
                          onClick={handleSubmit}
                          disabled={loading || !termsAgree || !validForm}
                        >
                          {!loading ? (
                            'Sign Up'
                          ) : (
                            <Stack sx={{ color: 'white.500' }} spacing={2} direction="row">
                              <CircularProgress color="inherit" size={25} />
                            </Stack>
                          )}
                        </Contained>
                      </Box>
                      <Box paddingTop="1rem" className={classes.account}>
                        <span>Already have an account?</span>
                        <Link to="/login">Sign In</Link>
                      </Box>
                    </form>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* <Grid item xl={7} lg={7} md={7}>
              <Box className={classes.bgRoot}>
                <Box className={classes.bgText}>
                  <Typography>Surf</Typography>
                  <Typography>The</Typography>
                  <Typography>Wave</Typography>
                </Box>
              </Box>
            </Grid> */}
          </Grid>
        </Box>
      </Box>
    </Hidden>
  );
};

export default Desktop;
