import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import News from '../../components/News';
import Popular from '../../components/Popular';
import Learn from './Learn';
import Main from './Main';
import Steps from './Steps';
import Trend from './Trend';

import { Container } from '@mui/material';
import Cookies from 'js-cookie';
import Header from '../../components/Header';
import { fetchCategories } from '../../redux/actions/landing_home.action';
import CookieConsent from '../CookieConsent';
import useStyle from './index.style';

const Home = () => {
  const dispatch = useDispatch();
  const scrollToRef = useRef(null);
  const styles = useStyle();
  const [showCookieConsent, setShowCookieConsent] = useState(true);

  useEffect(() => {
    const cookiePolicy = Cookies.get('cookie-policy');
    setShowCookieConsent(!cookiePolicy);

    dispatch(fetchCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className={styles.container}>
      <Header />
      <Main scrollToRef={scrollToRef} />
      <Learn scrollToRef={scrollToRef} />
      <Trend />
      <Popular classSection="section-bg section-mb0" />
      <News classSection="section-bg" />
      <Steps />
      {/* <Footer /> */}
      {showCookieConsent && <CookieConsent />}
    </Container>
  );
};

export default Home;
