export const LOGIN_ACTION = 'auth/LOGIN_ACTION';
export const LOGOUT_ACTION = 'auth/LOGOUT_ACTION';

export const SET_CREATING_NEW_PASSWORD_ACTION = 'auth/SET_CREATING_NEW_PASSWORD_ACTION';

export const FORGOT_PASSWORD_ACTION = 'auth/FORGOT_PASSWORD_ACTION';
export const FORGOT_PASSWORD_OK = 'auth/FORGOT_PASSWORD_OK';
export const FORGOT_PASSWORD_ERROR = 'auth/FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_END = 'auth/FORGOT_PASSWORD_END';

export const CREATE_NEW_PASSWORD_ACTION = 'auth/CREATE_NEW_PASSWORD_ACTION';
export const CREATE_NEW_PASSWORD_OK = 'auth/CREATE_NEW_PASSWORD_OK';
export const CREATE_NEW_PASSWORD_ERROR = 'auth/CREATE_NEW_PASSWORD_ERROR';
export const CREATE_NEW_PASSWORD_END = 'auth/CREATE_NEW_PASSWORD_END';

export const SAVE_USER_DETAILS_ACTION = 'auth/SAVE_USER_DETAILS_ACTION';
export const SAVE_USER_DETAILS_OK = 'auth/SAVE_USER_DETAILS_OK';
export const SAVE_USER_DETAILS_ERROR = 'auth/SAVE_USER_DETAILS_ERROR';
export const SAVE_USER_DETAILS_END = 'auth/SAVE_USER_DETAILS_END';

export const GET_USER_PERMISSION_ACTION = 'auth/GET_USER_PERMISSION_ACTION';
export const GET_USER_PERMISSION_OK = 'auth/GET_USER_PERMISSION_OK';
export const GET_USER_PERMISSION_END = 'auth/GET_USER_PERMISSION_END';
export const GET_USER_PERMISSION_ERROR = 'auth/GET_USER_PERMISSION_ERROR';

export const TOGGLE_SUCCESS_MODAL = 'auth/TOGGLE_SUCCESS_MODAL';
export const TOGGLE_LOCK_MODAL = 'auth/TOGGLE_LOCK_MODAL';
