import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: 'max-content',
    width: '100vw',
    // backgroundColor: theme.palette.base,
    display: 'flex',
    flexDirection: 'column',
  },

  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    maxWidth: '1350px',
    margin: '0 auto',
    paddingTop: '100px',
    border: '1px solid red',
  },
}));

export default useStyles;
