import { makeStyles } from '@mui/styles';
import bg from '../../assets/images/portfolio_management/bg.png';
const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    width: '100%',
    maxWidth: '1700px',
    position: 'relative',
  },

  content: {
    background: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    paddingTop: '50px',
    marginTop: '3rem',
    borderRadius: '6px',
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    color: theme.text[4],
    fontWeight: 'bold',
    margin: '1em',
    textAlign: 'center',
  },
  searchBarWrapper: {
    padding: '5px 0',
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-end',

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  content2: {
    padding: '25px 55px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '18px 18px 6px 6px',
    //boxShadow: '0px 20px 30px rgba(167, 209, 233, 0.45)',

    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },

  nextBtn: {
    marginTop: '2em',
  },

  backBtn: {
    background: '#868686',
    fontWeight: 700,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    fontSize: '1rem',
    margin: '2.3em',
    cursor: 'pointer',
  },

  contentBlue: {
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    margin: '1em 0',
  },

  imgIcon: {
    marginLeft: '1em',
  },

  imgBackIcon: {
    marginRight: '1em',
    pointer: 'cursor',
  },

  // Result CSS

  resultTitle: {
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    margin: '1em 0',
  },

  resultItem: {
    color: '#383636',
    fontWeight: '600',
    textAlign: 'center',
  },

  chartContainer: {
    margin: '2em 0',
  },

  quizText: {
    display: 'flex',
    alignItems: 'center',

    '& .MuiTypography-root': {
      color: theme.text[4],
      fontWeight: 'bold',
      textAlign: 'center',
      background: '#21C2FD',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-h4': {
        fontSize: '2rem',
      },
    },
  },

  arrowIcon: {
    marginTop: '5px',
    marginRight: '9px',
  },

  brainIcon: {
    marginRight: '5px',
    width: '45px',
    height: '45px',

    [theme.breakpoints.down('sm')]: {
      width: '35px',
      height: '35px',
    },
  },

  scoreText: {
    display: 'flex',
    alignItems: 'center',
    color: theme.text[4],
    fontWeight: 'bold',
    textAlign: 'center',
    background: '#3F8ED7',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  choice: {
    '& .MuiTypography-root .MuiTypography-body1': {
      display: 'flex',
      alignItems: 'center',
      color: theme.text[4],
      fontWeight: 'bold',
      textAlign: 'center',
      background: '#3F8ED7',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },
  },

  backLessonButton: {
    '&.MuiButton-root': {
      borderRadius: '20px',
      backgroundColor: '#3F8ED7',
    },
  },

  resultContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '12rem',
  },

  boldText: {
    '& .MuiFormControlLabel-root': {
      '& .span .MuiTypography-root': {
        fontWeight: 'bold',
      },
    },
  },
}));

export default useStyles;
