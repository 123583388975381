import { Box, Divider, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GlobeChart } from 'traidify-common-components';
import ProgressCircular from '../../../components/ProgressCircular';
import {
  retrieveGlobalMacroeconomicsCountriesAction,
  setTimeFrameAction,
} from '../../../redux/actions/macroeconomics.action';
import { macroeconomicsSelector } from '../../../redux/reselect/macroeconomicsSelector';
import { TIMEFRAME, TIMEFRAME_LIST } from './constants';
import useStyles from './styles';
import { mapData } from './testData';

const Globe = () => {
  //Styles
  const classes = useStyles();

  //Dispatch
  const dispatch = useDispatch();

  //States
  const [timeFrame, setTimeFrame] = useState(TIMEFRAME.REAL_TIME);

  //Redux stores
  const { global_macroeconomics_countries } = useSelector(macroeconomicsSelector);
  const { data, loading } = global_macroeconomics_countries;
  const formattedDateRange = useMemo(() => {
    if (!data) return '';

    const relative_prices = data[0]?.relative_prices
      .map((_relative_prices) => new Date(_relative_prices?.datetime))
      .sort((a, b) => a - b);

    if (!relative_prices) return '';

    const from_time = relative_prices[0];
    const to_time = relative_prices[relative_prices.length - 1];
    if (timeFrame === TIMEFRAME.REAL_TIME) return moment(to_time).format('MM/DD/YYYY');

    return `${moment(from_time).format('MM/DD/YYYY')} - ${moment(to_time).format('MM/DD/YYYY')}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (typeof timeFrame === 'undefined') return;

    dispatch(setTimeFrameAction(timeFrame === TIMEFRAME.REAL_TIME ? 'daily' : timeFrame));
    dispatch(
      retrieveGlobalMacroeconomicsCountriesAction({
        timeFrame,
        params: {
          b: 'SPY',
          t: 'countries',
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFrame]);

  /** Handlers */
  /**
   * Handles on click of each time frame
   * - sets time frame
   */
  const onClickTimeFrame = (val) => {
    setTimeFrame(val);
  };

  const renderLoader = () => <ProgressCircular size={50} thickness={5} />;

  const renderTimeFrames = () => (
    <Grid container className={classes.timeFrame}>
      {React.Children.toArray(
        TIMEFRAME_LIST.map(({ value, label }, index) => {
          return (
            <>
              <Grid
                item
                justifyContent="center"
                className={clsx(
                  classes.timeFrameLabel,
                  timeFrame === value ? classes.activeTimeFrameLabel : ''
                )}
                onClick={(e) => onClickTimeFrame(value)}
              >
                {label}
              </Grid>
              {index < TIMEFRAME_LIST.length - 1 && (
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  style={{ margin: '0px', borderColor: '#000000' }}
                />
              )}
            </>
          );
        })
      )}
    </Grid>
  );

  const renderGlobe = (data) => <GlobeChart data={data} mapData={mapData} />;

  return (
    <Box className={classes.globeChartParentContainer}>
      <Box className={classes.globeChartContainer}>
        <Box className={classes.globeChart}>{loading ? renderLoader() : renderGlobe(data)}</Box>
      </Box>

      {!loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
          {renderTimeFrames()}
          <Typography variant="body2">{formattedDateRange}</Typography>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default Globe;
