import { Box, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
const Topics = ({ title, items, ...props }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
      flex={1}
      color="white"
      alignSelf="flex-start"
    >
      <Box>
        <Typography variant="h6">{title}</Typography>
      </Box>
      {React.Children.toArray(
        items.map((i) => (
          <Link href={i.to} style={{ cursor: 'pointer' }}>
            <Typography variant="caption">{i.label}</Typography>
          </Link>
        ))
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& a': {
      color: 'white',
    },
  },
}));

export default Topics;
