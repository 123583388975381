import { makeStyles } from '@mui/styles';
import colors from '../../../styles/colors';
const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxwidth: '1500px',
    padding: '0 80px',
    [theme.breakpoints.up('lg')]: {
      padding: '40px',
    },
    [theme.breakpoints.only('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 32px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },

  main: {
    paddingTop: '156px',
    minHeight: '692px',
    background: '#DECBE9',
    position: 'relative',
    marginTop: '100px',
    borderRadius: '20px',
    marginBottom: 0,

    [theme.breakpoints.down('xl')]: {
      marginBottom: 0,
    },

    [theme.breakpoints.down('md')]: {
      minHeight: 'auto',
      padding: '64px 0 112px',
      marginTop: '8rem',
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '64px 0',
      marginTop: '4rem',
    },
  },

  wrap: {
    position: 'relative',
    zIndex: 3,
    maxWidth: '545px',
    marginBottom: '143px',

    [theme.breakpoints.only('md')]: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto 0',
    },

    [theme.breakpoints.down('sm')]: {
      margin: '0 auto 30px auto',
    },
  },

  title: {
    marginBottom: '32px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '16px',
      fontSize: '40px',
      lineHeight: '48px',
      textAlign: 'center',
    },
  },

  text: {
    fontSize: '24px',
    lineHeight: 'calc(32 / 24) * 1px',
    letterSpacing: '-.01em',
    color: colors.n3,
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      textAlign: 'center',
    },
  },

  bg: {
    position: 'absolute',
    top: 0,
    right: 'calc(50% - 710px)',
    width: '780px',
    pointerEvents: 'none',
    [theme.breakpoints.down('lg')]: {
      right: 'calc(50% - 690px)',
    },
    [theme.breakpoints.down('md')]: {
      position: 'static',
      width: 'auto',
      margin: '0 -76px 24px',
    },

    '& img': {
      width: '100%',
    },
  },
}));

export default useStyles;
