import React from 'react';
import COOKIE_POLICY from '../../constants/cookie_policy';
import CommonPolicyContent from './CommonPolicyContent';

const CookiePolicy = (props) => {
  return (
    <CommonPolicyContent
      title={COOKIE_POLICY.title}
      description={COOKIE_POLICY.description}
      items={COOKIE_POLICY.items}
    />
  );
};

export default CookiePolicy;
