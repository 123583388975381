import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { PERFORMANCE_RATIOS, PERFORMANCE_RISK_RETURN } from '../constant';
import useStyles from '../index.style';

const Performance = ({ performances }) => {
  const styles = useStyles();
  const risk_return = performances?.filter((performance) =>
    PERFORMANCE_RISK_RETURN.includes(performance.label)
  );
  const ratios = performances?.filter((performance) =>
    PERFORMANCE_RATIOS.includes(performance.label)
  );

  return (
    <Grid container spacing={6} className={styles.performance}>
      <Grid item xs={12} md={6}>
        {risk_return.length > 0 && (
          <>
            <Typography variant="body1" fontWeight="bold" textAlign="center" mb={2}>
              Risk and Return Statistics
            </Typography>
            {risk_return.map((performance) => {
              return (
                <Box display="flex" justifyContent="space-between">
                  <Typography>{performance?.label}</Typography>
                  <Typography>{performance?.value}</Typography>
                </Box>
              );
            })}
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {ratios.length > 0 && (
          <>
            <Typography variant="body1" fontWeight="bold" textAlign="center" mb={2}>
              Performance Ratios
            </Typography>
            {ratios.map((performance) => {
              return (
                <Box display="flex" justifyContent="space-between">
                  <Typography>{performance?.label}</Typography>
                  <Typography>{performance?.value}</Typography>
                </Box>
              );
            })}
          </>
        )}
      </Grid>
      {/* {performances?.length > 0 &&
        performances.map((performance) => {
          return (
            <Box display="flex" justifyContent="space-between">
              <Typography>{performance?.label}</Typography>
              <Typography>{performance?.value}</Typography>
            </Box>
          );
        })} */}
    </Grid>
  );
};

export default Performance;
