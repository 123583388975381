import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import HeightIcon from '@mui/icons-material/Height';
import { Box, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { ProgressBar } from 'traidify-common-components';
import useCommonStyles from '../../styles';
import useStyles from '../styles';

const quadrants = {
  SM: {
    title: 'Sell Momentum',
    conviction: {
      status: 'low',
      count: 3,
      value: -(1 / 5) * 100,
    },
  },
  BM: {
    title: 'Buy Momentum',
    conviction: {
      status: 'high',
      count: 3,
      value: (5 / 5) * 100,
    },
  },
  SR: {
    title: 'Sell the Rip',
    conviction: {
      status: 'low',
      count: 2,
      value: -(2 / 5) * 100,
    },
  },
  BD: {
    title: 'Buy the Dip',
    conviction: {
      status: 'high',
      count: 2,
      value: (3 / 5) * 100,
    },
  },
};

const RelativeIndex = ({ categories, summary, headerTitle }) => {
  //Styles
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const renderCategoryHeader = (title) => (
    <Box className={commonClasses.categoryHeaderContainer} mb={2}>
      <Typography className={commonClasses.categoryHeader} variant="body1">
        {title}
      </Typography>
    </Box>
  );

  const renderCategoryTitle = (categoryName) => (
    <Box className={commonClasses.categoryHeaderContainer}>
      <Grid container>
        <Grid item xs={6}>
          <Typography className={commonClasses.categoryHeader} variant="body1">
            {categoryName}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={commonClasses.categoryHeader} variant="body1">
            UW
            <HeightIcon className={classes.heightIcon} />
            OW
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={commonClasses.categoryHeader} variant="body1">
            Conviction
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );

  const renderCategoryTable = (category) => {
    return (
      <Table className={classes.table} sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
          {React.Children.toArray(
            summary[category].map((row) => (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell width="25%" align="left">
                  <Typography className={classes.tableText} variant="body1">
                    {row.fund_name}
                  </Typography>
                </TableCell>
                <TableCell width="25%" align="left">
                  <Typography className={classes.tableText} variant="body1">
                    {quadrants[row.quadrant].title}
                  </Typography>
                </TableCell>
                <TableCell width="33%" align="left">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <ProgressBar
                      height="14px"
                      width="100%"
                      value={quadrants[row.quadrant].conviction.value}
                    />
                  </Box>
                </TableCell>
                <TableCell width="17%" align="left">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    {React.Children.toArray(
                      [...Array(quadrants[row.quadrant].conviction.count)].map(() => {
                        if (quadrants[row.quadrant].conviction.status === 'low')
                          return <ChangeHistoryTwoToneIcon className={classes.convictionLow} />;

                        return <ChangeHistoryTwoToneIcon className={classes.convictionHigh} />;
                      })
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    );
  };

  return (
    <Box>
      {renderCategoryHeader(headerTitle)}
      {React.Children.toArray(
        categories.map((category) => (
          <>
            {renderCategoryTitle(category)}
            {renderCategoryTable(category)}
          </>
        ))
      )}
    </Box>
  );
};

export default RelativeIndex;
