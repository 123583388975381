import React, { useEffect } from 'react';

import useStyle from './index.style';

import brain from '../../assets/images/challenge/brain.png';
import user1 from '../../assets/images/challenge/user1.png';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorIndicator, LoadingIndicator } from '../../components/ContainerIndicator';
import Snackbar from '../../components/SnackBar';
import { closeSnackbarAction } from '../../redux/actions/app.action';
import { fetchQuizCategory } from '../../redux/actions/quiz.action';
import { fetchTrainingVideos, setLoading } from '../../redux/actions/training.action';
import ProgressModal from './ProgressModal';
import QuizList from './quizList';
import VideoItem from './VideoItem';

import { Avatar, Box, Card, Chip, Container, Grid, Tab, Tabs, Typography } from '@mui/material';

const CourseTopic = ({ ...props }) => {
  const dispatch = useDispatch();
  const app = useSelector((store) => store.app.toJS());
  const { message, open, notify } = app.snackbar;

  const {
    currentCategory = {},
    currentVideo = {},
    progressModal,
    loading,
    isError,
  } = useSelector((store) => {
    const training_store = store.training;

    if (training_store) return training_store.toJS();

    return {};
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchTrainingVideos(props.match.params.title));
    dispatch(fetchQuizCategory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVideo?.title]);

  const handleShareClickClose = () => {
    dispatch(closeSnackbarAction());
  };

  const styles = useStyle();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { name, description, videos } = currentCategory?.attributes || {};
  const { author, title, thumbnail } = currentVideo;

  const length = videos?.length;

  const getTopics = () => {
    return videos?.map((video, index) => {
      return <VideoItem video={video} category={name} index={index + 1} length={length} />;
    });
  };

  const getContent = isError ? (
    <ErrorIndicator
      title={'Oops! Something went wrong!'}
      description={`It's on us, we moved the content to a different page.
    The search below should help!
    `}
    />
  ) : (
    <Card className={styles.container}>
      <Box paddingTop="1em" paddingBottom="1em">
        <Grid container>
          <Grid xs={12} sm={12} md={8} lg={9} xl={9}>
            <Typography className={styles.title} variant="h4">
              {name} - {title}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          <Box>
            {/* <iframe
        width="95%"
        height="500px"
        src="https://www.youtube.com/embed/mzJqxT1UGho"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe> */}

            <video className={styles.video} poster={thumbnail} controls>
              <source src={currentVideo.video} type="video/mp4" />
              <source src={currentVideo.video} type="video/ogg" />
              Your browser does not support the video tag.
            </video>

            <Box sx={{ width: '100%' }}>
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Description" {...a11yProps(0)} />
                <Tab label="Discussion" wrapped {...a11yProps(1)} />
                <Tab label="Other Materials" wrapped {...a11yProps(2)} />
                <Tab
                  className={styles.styledTab}
                  icon={<img alt="Brain" src={brain} className={styles.brainIcon} />}
                  iconPosition="top"
                  label={<Typography className={styles.quizText}>Quiz</Typography>}
                  {...a11yProps(3)}
                />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Grid container>
                <Grid item xs={8} sm={8} display="flex" alignItems="center" container>
                  <Grid mr={4}>
                    <Avatar className={styles.avatar} src={user1} />
                  </Grid>
                  <Box ml={2}>
                    <Typography className={styles.gradientBoldText} variant="h5">
                      {author || 'Unnamed Author'}{' '}
                    </Typography>
                    <Typography className={styles.italicText} variant="subtitle1">
                      Profession
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Typography className={styles.paragraph} variant="body1">
                {description}
              </Typography>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {currentVideo.description}
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Typography className={styles.boldText} variant="h4">
                  No materials added yet
                </Typography>
                <Typography variant="subtitle1">
                  The instructor hasn’t added any materials to this course yet.
                </Typography>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <QuizList />
            </TabPanel>
          </Box>
        </Grid>

        <Grid item display="flex" flexDirection="column" xs={12} sm={12} md={12} lg={4} xl={4}>
          <Box mb={3} display="flex" flexDirection="row">
            <Typography className={styles.gradientBoldText} variant="h4">
              {length} Lessons
            </Typography>
            <Box mt={0.5} ml={2}>
              <Chip label="1hr 40mins" variant="outlined" />
            </Box>
          </Box>
          <Card className={styles.topicsContainer}>{getTopics()}</Card>
        </Grid>
      </Grid>
    </Card>
  );

  return (
    <Container className={styles.container}>
      {loading ? <LoadingIndicator /> : getContent}
      <ProgressModal open={progressModal} />
      <Snackbar message={message} severity={notify} open={open} onClose={handleShareClickClose} />
    </Container>
  );
};

export default CourseTopic;
