import React from 'react';
import ReactECharts from 'echarts-for-react';
import { ResponsiveContainer } from 'recharts';

const dummyData = [
  {
    name: 'Page A',
    value: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    value: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    value: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    value: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    value: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    value: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    value: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const AreaChart = (props) => {
  const { data, minValue, maxValue, xLabels } = props;
  const option = {
    tooltip: {
      trigger: 'axis',
      position: function (pt) {
        return [pt[0], '10%'];
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xLabels || dummyData.map((item) => item.name),
    },
    yAxis: {
      type: 'value',
      min: minValue || 0,
      max: maxValue || 4500,
      boundaryGap: [0, '100%'],
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
      },
      {
        start: 0,
        end: 10,
      },
    ],
    series: [
      {
        type: 'line',
        symbol: 'none',
        sampling: 'lttb',
        itemStyle: {
          color: 'rgb(103, 131, 215)',
        },
        areaStyle: {},
        data: data || dummyData,
      },
    ],
  };
  return (
    <ResponsiveContainer width="99%" height={430}>
      <ReactECharts option={option} style={{ height: '100%' }} />
    </ResponsiveContainer>
  );
};

export default AreaChart;
