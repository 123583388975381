import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import * as React from 'react';
import brain from '../../assets/images/challenge/brain.png';
import useStyles from './index.style';

import { useHistory } from 'react-router-dom';

//unused but might use in future
// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//   marginLeft: 'auto',
//   transition: theme.transitions.create('transform', {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));

export default function VideoItem({ video = {}, ...props }) {
  const history = useHistory();
  const classes = useStyles();
  const { name, author, description, videos = [] } = video.attributes;

  const getVideo = videos[0] || {};
  // const [expanded, setExpanded] = React.useState(false);
  const [color, setColor] = React.useState('default');

  //unused but might use in future
  // const handleExpandClick = (e) => {
  //   e.stopPropagation();
  //   setExpanded(!expanded);
  // };

  const handleBookmarkClick = (value) => {
    setColor(value);
  };

  const handleVideoClick = (e) => {
    e.stopPropagation();
    history.push({ pathname: `/app/challenge/${name}` });
  };

  return (
    <Grid xs={12} sm={6} md={6} lg={4} xl={4}>
      <Card className={classes.cardContainer} sx={{ maxWidth: 345 }}>
        <CardMedia
          className={classes.videoThumbnail}
          height="250"
          image={getVideo.thumbnail}
          alt={name}
        ></CardMedia>
        <CardContent>
          <Grid container marginBottom="1em">
            {/* <Box display="flex" flexDirection="column" justifyContent="center">
              <Avatar src={user1} /> <Typography> {author || 'Unnmaed Author'} </Typography>
            </Box> */}

            {/* <Grid item xs={2} md={2}>
              <Typography> {total_videos} Videos </Typography>
            </Grid> */}
          </Grid>

          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item onClick={handleVideoClick}>
              <Typography className={classes.title} variant="h6">
                {name}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton>
                {color === 'error' ? (
                  <FavoriteIcon onClick={() => handleBookmarkClick('action')} color={color} />
                ) : (
                  <FavoriteBorderIcon onClick={() => handleBookmarkClick('error')} color={color} />
                )}
              </IconButton>
            </Grid>
          </Grid>

          <Typography variant="body2" color="text.secondary" className={classes.description}>
            {description}
          </Typography>

          {/* <UserIcon className={classes.topicProgress} /> */}
        </CardContent>
        <CardActions disableSpacing>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Box display="flex" flexDirection="row" alignItems="center" ml={1} mr={1}>
                <Box mr={1}>
                  <Typography className={classes.boldText} variant="subtitle1">
                    {author || 'Unnmaed Author'}
                  </Typography>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box ml={1}>
                  <Typography className={classes.boldText} variant="subtitle1">
                    1hr 40mins
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex" flexDirection="row" alignItems="center" mr={1}>
                <img alt="Brain" src={brain} className={classes.brainIcon} />
                <Typography className={classes.quizText} variant="h6">
                  Quiz
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {/* <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore> */}
        </CardActions>
        {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <VideoSubTopic subTopic={videos} category={name} />
          </CardContent>
        </Collapse> */}
      </Card>
    </Grid>
  );
}
