import React from 'react';
import REFUND_POLICY from '../../constants/refund_policy';
import CommonPolicyContent from './CommonPolicyContent';

const RefundPolicy = (props) => {
  return (
    <CommonPolicyContent
      title={REFUND_POLICY.title}
      description={REFUND_POLICY.description}
      items={REFUND_POLICY.items}
    />
  );
};

export default RefundPolicy;
