import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  globeChartParentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  globeChartContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '300px',
    marginBottom: '48px',
  },
  globeChart: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    '& > svg': {
      // width: '70%',
    },
  },
  timeFrame: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '12px',

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    // position: 'absolute',
    // marginTop: '260px',
  },
  timeFrameLabel: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.2rem',
    lineHeight: '19px',
    padding: '0 12px',
    cursor: 'pointer',
    '&:first-child': {
      paddingLeft: '0px',
    },
  },
  activeTimeFrameLabel: {
    background: theme.palette.gradients.gradient_title,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
  },
  separator: {
    height: '12px',
    border: '1px solid #1D2228',
  },
}));

export default useStyles;
