import { Box, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import colors from '../../styles/colors';
const Rating = ({ card, color, label, value, style, variant, ...props }) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const limit = small ? 10 : 15;
  const percentage = Math.floor(value * (limit / 100));
  const flame_scale = computeFlameScale(value, 'positive');
  const classes = useStyles({ percentage: flame_scale });
  const bars = [];

  /**
    flame scale logic
    positive = 200%
  **/
  function computeFlameScale(percent, sentiment) {
    let scale_value = percent;

    if (sentiment === 'positive') {
      scale_value += 100;
    }
    return scale_value;
  }

  for (let i = 1; i <= limit; i++) {
    let bg = '#C4C4C4';

    if (percentage >= i) {
      bg = color || colors.peter_river;
    }
    bars.push(<Box bgcolor={bg} className={clsx(classes.bar, { [classes.cardBar]: card })} />);
  }

  const getVariant = (type) => {
    return type === 'value' ? 'h' + (variant - 1) : 'h6';
  };

  return (
    // <Box display="flex" flexDirection="row">
    //   <Box display="flex" textAlign="center" flexDirection="column">
    //     <Typography className={style} variant={"h" + variant}>
    //       {label}
    //     </Typography>
    //     <Typography className={style} variant={"h" + (variant-1)}>
    //       {value || '0'}%
    //     </Typography>
    //   </Box>
    //   <Box className={classes.barsContainer}>
    //     {bars}
    //   </Box>
    // </Box>
    <Box display="flex" flexDirection="row">
      <Box display="flex" textAlign="center" flexDirection="column">
        <Typography className={style} variant="h6">
          {label}
        </Typography>
        <Typography className={style} variant="h4">
          {value || '0'}%
        </Typography>
      </Box>
      <Box className={classes.barsContainer}>{bars}</Box>
    </Box>
  );
};

export default Rating;

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '.875rem',
  },
  value: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  barsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 1rem 0 2rem',
    [theme.breakpoints.down('sm')]: {
      margin: '0 1rem',
    },
  },
  bar: {
    width: '7px',
    height: '20px',
    borderRadius: '3px',
  },
  cardLabel: {
    color: '#FFF',
    fontSize: '.875rem',
  },
  cardValue: {
    color: '#FFF',
    fontSize: '1rem',
  },
  cardBar: {
    width: '3px',
    height: '13px',
  },
  flame: {
    '& span': {
      transform: (props) => `scale(${props.percentage}%)`,
      transition: 'all ease 1s',
    },
  },
}));
