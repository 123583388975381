import moment from 'moment';

export const generateLineChartData = (data) => {
  const overPerformingData = [];
  const underPerformingData = [];

  data.forEach((d) => {
    const chartData = (d.relative_prices || [])
      .sort((a, b) => {
        // sort dates asc
        return new Date(a.datetime) - new Date(b.datetime);
      })
      .map((d) => ({
        //format dates by 'Y-MM-DD H:mm:ss'
        x: moment(new Date(d.datetime)).format('Y-MM-DD H:mm:ss').toString(),
        y: Number(d.delta_value),
      }));

    if (d.delta < 0) {
      underPerformingData.push({
        id: d.relticker,
        data: chartData,
      });
    } else {
      overPerformingData.push({
        id: d.relticker,
        data: chartData,
      });
    }
  });

  return { overPerformingData, underPerformingData };
};

export const getTickersX = (data) => {
  const tempData = [...data.data];
  return tempData
    .sort((a, b) => {
      return new Date(a.x) - new Date(b.x);
    })
    .filter((_data, index) => {
      const divisor = tempData.length > 9 ? Math.floor(tempData.length / 10) : 1;

      if ((index + 1) % divisor === 0) return true;

      return false;
    })
    .map((_data) => _data.x);
};

export const getTickersY = (data) => {
  const tempData = [...data.data];
  return tempData
    .sort((a, b) => {
      return Number(a.y) - Number(b.y);
    })
    .filter((_data, index) => {
      const count = Number(data.data.length) - 1;
      if (index === 0 || index === count || index === Math.round(count / 2)) return true;

      return false;
    })
    .map((_data) => _data.y);
};

export const capitalizeFirstLetter = (str) => {
  return str
    .split('_')
    .map((str) => str.charAt(0).toUpperCase() + str.substr(1))
    .toString()
    .replaceAll(',', ' ');
};
