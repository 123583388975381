import { useSelector, useStore } from 'react-redux';
import { store } from '..';

export const getAuthToken = () => {
  const app = store.getState().auth.toJS();

  return app.token;
};

export const isLoggedIn = () => !!getAuthToken();
