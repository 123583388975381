import { makeStyles } from '@mui/styles';
import bg from '../../assets/images/portfolio_management/bg.png';
const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    width: '100%',
    maxWidth: '1700px',
  },

  content: {
    background: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    marginTop: '3rem',
    borderRadius: '6px',
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    color: theme.text[4],
    fontWeight: 'bold',
  },
  searchBarWrapper: {
    padding: '5px 0',
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-end',

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  content2: {
    padding: '25px 0',
    backgroundColor: theme.palette.common.white,
    borderRadius: '18px 18px 6px 6px',

    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  cardItemContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  linkItem: {
    borderRadius: '12px',
    margin: '14px',
    flex: 1,
    height: '200px',
  },
  markdown: {
    whiteSpace: 'pre-wrap',
    listStyleType: 'auto !important',
  },
}));

export default useStyles;
