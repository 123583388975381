import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    maxWidth: '100%',
  },
  title: {
    background: theme.palette.gradients.blue_linear,
    fontSize: '4rem',
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.6rem',
    },
  },
  title2: {
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  listContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: '30px 15px',

      '& > div': {
        margin: '15px',
      },
    },
  },

  cardContainer: {
    margin: '1em 0',
    padding: '1.5em',
    borderRadius: '1.5em',
    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-h6': {
        fontSize: '1.2rem',
      },
    },
  },

  graphContainer: {
    position: 'relative',
    margin: '1em 1.5em 0 1.5em',
    borderRadius: '1.5em',
    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-h6': {
        fontSize: '1.2rem',
      },
      '& .MuiTypography-subtitle1': {
        fontSize: '1rem',
      },
    },
  },

  tradeContainer: {
    padding: 0,
    '&.MuiCardContent-root': {
      padding: 0,
    },
    '&.MuiCardContent-root:last-child': {
      paddingBottom: 0,
    },
  },

  riskIndexContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(138.19deg, #FFCA1B 2.08%, #F77455 50.52%, #FE8F1A 94.79%)',
    color: '#fff',
    borderRadius: '1em',
    padding: '1em',
    width: '150px',
    margin: '1.5em auto',
  },

  riskNumber: {
    fontWeight: 'bold',
  },

  gainText: {},

  lossText: {
    background: 'linear-gradient(138.19deg, #F60638 2.08%, #F40469 50.52%, #F20094 94.79%)',
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  bottomArea: {
    borderTop: '1px solid #B9B9B9',
    padding: '1.5em',
  },

  centerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  detailsFocus: {
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    fontSize: '1.8rem',
    display: 'inline',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },

  boldText: {
    fontWeight: 'bold',
  },

  allocationTitle: {
    paddingLeft: '1em',
    fontWeight: 'bold',
  },

  allocationDetails: {
    paddingLeft: '1em',
  },

  underlineText: {
    textDecoration: 'underline',
    color: '#789CC8',
  },
  timePeriodContainer: {
    borderRadius: '20px',
  },

  annualReturn: {
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    fontSize: '3rem',
    display: 'inline',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  typesContainer: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-h4': {
        fontSize: '1.5rem',
      },
      '& .MuiTypography-h5': {
        fontSize: '1.2rem',
      },
    },
  },
}));

export default useStyles;
