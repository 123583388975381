import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  timeFrame: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeFrameLabel: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.2rem',
    lineHeight: '19px',
    padding: '0 .75rem',
    cursor: 'pointer',
  },
  activeTimeFrameLabel: {
    background: theme.palette.gradients.gradient_title,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
  },
  separator: {
    height: '12px',
    border: '1px solid #1D2228',
    width: '1px',
  },
  card: {
    height: '350px',
    background: '#FFFFFF',
    boxShadow: ' 0px 1px 6px rgba(0, 0, 0, 0.15), 5px 6px 9px rgba(0, 0, 0, 0.25)',
    borderRadius: '16.4337px',
    padding: '12px',
    marginTop: '16px !important',
  },
  title: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    height: '25px',
    borderRadius: '35px',
    color: '#FFFFFF',
    textAlign: 'center',
    '&:after': {
      background: '#F9FFFE',
      height: 'inherit',
      width: '25px',
      display: 'inline-block',
      content: "''",
      position: 'relative',
    },
  },
  titleLeft: {
    background: 'linear-gradient(90.08deg, #5C62ED -48.14%, #41E7B0 108.38%)',
    '&:after': {
      background: '#F9FFFE',
      borderTopRightRadius: '35px',
      borderBottomRightRadius: '35px',
      float: 'left',
    },
  },
  titleRight: {
    background: 'linear-gradient(96.03deg, #5D5FEF -8.18%, #EF5DA8 116.11%)',
    '&:after': {
      background: '#F9FFFE',
      borderTopLeftRadius: '35px',
      borderBottomLeftRadius: '35px',
      float: 'right',
    },
  },
  bull: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '35px',
    height: '30px',
    transform: 'matrix(-1, 0, 0, 1, 0, 0)',
    borderRadius: '100%',
    position: 'absolute',
    zIndex: 999,
  },
  bullRight: {
    background: '#D15DB6',
    right: -15,
    '& > svg': {
      transform: 'scaleX(-1)',
    },
  },
  bullLeft: {
    background: '#538CD9',
    left: -15,
    '& > svg': {
      transform: 'scaleX(1)',
    },
  },
  categoryHeaderContainer: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  activeCategoryHeader: {
    backgroundColor: 'white',
    borderRadius: '35px',

    '& > p': {
      background:
        'linear-gradient(96.48deg, rgba(93, 95, 239, 0.7) -9.74%, rgba(65, 231, 176, 0.7) 103.84%)',
      backgroundClip: 'text',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },
  },
  detailsHeader: {},
  detailsBodyContainer: {
    paddingLeft: '3px',
    position: 'relative',

    '& > div': {
      backgroundColor: '#f9fffe',
    },
  },
  detailsBodyContainerLow: {
    background: 'linear-gradient(96.03deg, #5D5FEF -8.18%, #EF5DA8 116.11%)',
  },
  detailsBodyContainerHigh: {
    background: 'linear-gradient(90.08deg, #5C62ED -48.14%, #41E7B0 108.38%)',
  },
  compareText: {
    background:
      'linear-gradient(96.48deg, rgba(93, 95, 239, 0.7) -9.74%, rgba(65, 231, 176, 0.7) 103.84%)',
    backgroundClip: 'text',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  compareAgainst: {
    display: 'flex',
    flexDirection: 'column',

    '& > h6': {
      marginBottom: '12px',
    },
    '& > div': {
      flexDirection: 'column',
      display: 'flex',
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      '& > div': {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '100%',
        display: 'flex',
      },
    },
  },
  relativeAnalysisDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: '24px',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
  },
  tooltip: {
    backgroundColor: '#FFFFFF',
    padding: '12px',
    borderRadius: '8px',
    boxShadow: '10px 10px 28px -11px rgba(0,0,0,0.65)',
    '-webkit-box-shadow': '10px 10px 28px -11px rgba(0,0,0,0.65)',
    '-moz-box-shadow': '10px 10px 28px -11px rgba(0,0,0,0.65)',
    '& > div': {
      textAlign: 'center',
    },
  },
}));

export default useStyles;
