import format from 'date-fns/format';
import { UpcomingEarning } from 'traidify-components';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from '@mui/material';

import { macroeconomicsSelector } from '../../../redux/reselect/macroeconomicsSelector';
import { retrieveUpcomingEarnings } from '../../../redux/actions/macroeconomics.action';

const UpcomingEarningList = ({}) => {
  const dispatch = useDispatch();
  const macro = useSelector(macroeconomicsSelector);

  useEffect(() => {
    const current = new Date();
    const startDate = new Date(current).setMonth(current.getMonth() - 9);
    fetchUpcomingEarning(startDate);
  }, []);

  const fetchUpcomingEarning = (start) => {
    const current = new Date();
    const payload = {
      start_date: format(start, 'yyyy-MM-dd'),
      end_date: format(current, 'yyyy-MM-dd'),
      top: 20,
    };
    dispatch(retrieveUpcomingEarnings(payload));
  };

  return (
    <Container>
      <UpcomingEarning
        earnings={macro.upcoming_earnings.data}
        loading={macro.upcoming_earnings.loading}
        fetchUpcomingEarning={fetchUpcomingEarning}
      />
    </Container>
  );
};

export default UpcomingEarningList;
