import { Box, Grid } from '@mui/material';
import BlurredContent from '../../components/BlurredContent';
import Globe from './Globe';
import LocationStats from './LocationStats';
import RiskIndicator from './RiskIndicator/RiskIndicator';

const CountryRelativeStrength = ({ isBlurred = false }) => {
  const locations = [
    {
      name: 'Europe',
      value: 2.04,
      rating: 60,
    },
    {
      name: 'Asia',
      value: 1.79,
      rating: 30,
    },
    {
      name: 'Africa',
      value: 0.65,
      rating: 40,
    },
    {
      name: 'Australia',
      value: 1.25,
      rating: 15,
    },
    {
      name: 'America',
      value: 3.23,
      rating: 45,
    },
  ];

  return (
    <Box>
      <BlurredContent isBlurred={isBlurred}>
        <Globe />
        <Box pt="2.5rem">
          <Grid container spacing={6}>
            <Grid item md={12} lg={5} spacing={2}>
              <LocationStats />
            </Grid>
            <Grid item md={12} lg={7} width="100%" minHeight="600px">
              <Box
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                px={4}
              >
                <RiskIndicator />
                {/* <RiskAppetite /> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </BlurredContent>
    </Box>
  );
};

export default CountryRelativeStrength;
