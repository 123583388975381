import { Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import mini_logo_1 from '../assets/images/header/mini_logo_1.png';

const RoundLogo = (props) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent>
        <img src={mini_logo_1} alt="mini logo" width="60px" height="60px" />
      </CardContent>
    </Card>
  );
};

export default RoundLogo;

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '50%',
    width: '90px',
    height: '90px',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
  },
}));
