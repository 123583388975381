import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useState } from 'react';
import { useSelector } from 'react-redux';
const Select = ({
  id,
  labelId,
  value,
  placeholder,
  options,
  setValue,
  className,
  fullWidth = false,
  multiple = true,
  group,
  isChip = true,
  savedItems = [],
  category = '',
  ...props
}) => {
  const { tickers } = useSelector((store) => {
    const profile_store = store.profile;

    if (profile_store) return profile_store.toJS();

    return {};
  });
  const classes = useStyles();
  const [selected, setSelected] = useState(tickers.map((ticker) => ticker.label));

  // useEffect(() => {
  //   setSelected([...savedItems]);
  //   console.log("HERE SAVED",savedItems);
  // }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelected(typeof value === 'string' ? value.split(',') : value);
  };

  const handleClick = (value, type) => {
    setValue(value, type);
  };

  const handleIncluded = (value) => {
    return;
  };

  return (
    <Box display="flex" justifyContent="center">
      <FormControl
        variant="outlined"
        classes={{
          root: clsx(classes.formControl),
        }}
      >
        <InputLabel labelId={labelId || `${id}Label`}>{placeholder}</InputLabel>
        <MuiSelect
          id="grouped-select"
          multiple={multiple}
          autoWidth
          value={selected}
          onChange={handleChange}
          label={placeholder}
          variant="standard"
          renderValue={(values) => values.join(', ')}
          {...props}
        >
          {/* <MenuItem value="" disabled>
              <Typography variant="subtitle1">{placeholder}</Typography>
            </MenuItem> */}
          {options.map(({ label, value, type }) => (
            <MenuItem key={label} value={label} onClick={() => handleClick(label, type)}>
              <Checkbox checked={selected.indexOf(label) > -1} />
              {label}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </Box>
  );
};

export default Select;

export { Select };

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: '3px',
    paddingLeft: '8px',
    marginBottom: '5px',
    width: '200px',
    maxWidth: '200px',
  },

  coloredText: {
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '1.2rem',
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(90.85deg, #5D5FEF -48.5%, #41E7B0 116.69%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  fullWidth: {
    width: '100%',
  },
}));
