import { call, fork, put, takeLatest } from 'redux-saga/effects';
import stocks from '../../services/stocks';
import portfolio from '../../services/portfolio';

import {
  RETRIEVE_GLOBAL_MACROECONOMICS_ACTION,
  RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_ACTION,
  RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_END,
  RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_ERROR,
  RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_OK,
  RETRIEVE_GLOBAL_MACROECONOMICS_END,
  RETRIEVE_GLOBAL_MACROECONOMICS_ERROR,
  RETRIEVE_GLOBAL_MACROECONOMICS_OK,
  RETRIEVE_RELATIVE_BONDS_INDEX_ACTION,
  RETRIEVE_RELATIVE_BONDS_INDEX_END,
  RETRIEVE_RELATIVE_BONDS_INDEX_ERROR,
  RETRIEVE_RELATIVE_BONDS_INDEX_OK,
  RETRIEVE_RELATIVE_PRICE_INDEX_ACTION,
  RETRIEVE_RELATIVE_PRICE_INDEX_END,
  RETRIEVE_RELATIVE_PRICE_INDEX_ERROR,
  RETRIEVE_RELATIVE_PRICE_INDEX_OK,
  RETRIEVE_RELATIVE_SP_INDEX_ACTION,
  RETRIEVE_RELATIVE_SP_INDEX_END,
  RETRIEVE_RELATIVE_SP_INDEX_ERROR,
  RETRIEVE_RELATIVE_SP_INDEX_OK,
  RETRIEVE_UPCOMING_EARNINGS_ACTION,
  RETRIEVE_UPCOMING_EARNINGS_END,
  RETRIEVE_UPCOMING_EARNINGS_ERROR,
  RETRIEVE_UPCOMING_EARNINGS_OK,
} from '../constants/macroeconomics.constant';

export function* retrieveRelativePriceIndex(action) {
  try {
    const xhr = yield call(stocks.relativePriceIndex, action.params);
    if (xhr.status >= 400) {
      throw xhr;
    }
    yield put({
      type: RETRIEVE_RELATIVE_PRICE_INDEX_OK,
      data: xhr.data.data,
    });
  } catch (e) {
    yield put({ type: RETRIEVE_RELATIVE_PRICE_INDEX_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_RELATIVE_PRICE_INDEX_END });
  }
}

export function* retrieveRelativeSPIndex(action) {
  try {
    const xhr = yield call(stocks.relativePriceIndex, { type: 'spy' });
    if (xhr.status >= 400) {
      throw xhr;
    }
    yield put({
      type: RETRIEVE_RELATIVE_SP_INDEX_OK,
      data: xhr.data.data,
    });
  } catch (e) {
    yield put({ type: RETRIEVE_RELATIVE_SP_INDEX_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_RELATIVE_SP_INDEX_END });
  }
}

export function* retrieveRelativeBondsIndex(action) {
  try {
    const xhr = yield call(stocks.relativePriceIndex, { type: 'bonds' });
    if (xhr.status >= 400) {
      throw xhr;
    }
    yield put({
      type: RETRIEVE_RELATIVE_BONDS_INDEX_OK,
      data: xhr.data.data,
    });
  } catch (e) {
    yield put({ type: RETRIEVE_RELATIVE_BONDS_INDEX_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_RELATIVE_BONDS_INDEX_END });
  }
}

export function* retrieveGlobalMacroeconomics(action) {
  try {
    const xhr = yield call(stocks.globalMacroeconomics, action.params);
    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({
      type: RETRIEVE_GLOBAL_MACROECONOMICS_OK,
      data: xhr.data,
    });
  } catch (e) {
    yield put({ type: RETRIEVE_GLOBAL_MACROECONOMICS_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_GLOBAL_MACROECONOMICS_END });
  }
}

export function* retrieveGlobalMacroeconomicsCountries(action) {
  try {
    const xhr = yield call(stocks.globalMacroeconomics, action.params);
    if (xhr.status >= 400) {
      throw xhr;
    }

    //replace `delta_value` to `relative_price`
    const data = xhr.data.map((_data) => ({
      ..._data,
      relative_prices: [
        ..._data.relative_prices.map(({ delta_value, ...otherRelative_prices }) => ({
          ...otherRelative_prices,
          relative_price: delta_value,
        })),
      ],
    }));

    yield put({
      type: RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_OK,
      data,
    });
  } catch (e) {
    yield put({ type: RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_END });
  }
}

export function* retrieveUpcomingEarnings(action) {
  try {
    const xhr = yield call(portfolio.upcomingEarnings, action.params);
    if (xhr.status >= 400) {
      throw xhr;
    }
    yield put({
      type: RETRIEVE_UPCOMING_EARNINGS_OK,
      data: xhr.data.data,
    });
  } catch (e) {
    yield put({ type: RETRIEVE_UPCOMING_EARNINGS_ERROR, ...e });
  } finally {
    yield put({ type: RETRIEVE_UPCOMING_EARNINGS_END });
  }
}

export function* retrieveRelativePriceIndexeSaga() {
  yield takeLatest(RETRIEVE_RELATIVE_PRICE_INDEX_ACTION, retrieveRelativePriceIndex);
}

export function* retrieveRelativeSPIndexeSaga() {
  yield takeLatest(RETRIEVE_RELATIVE_SP_INDEX_ACTION, retrieveRelativeSPIndex);
}

export function* retrieveRelativeBondsIndexeSaga() {
  yield takeLatest(RETRIEVE_RELATIVE_BONDS_INDEX_ACTION, retrieveRelativeBondsIndex);
}

export function* retrieveGlobalMacroeconomicsSaga() {
  yield takeLatest(RETRIEVE_GLOBAL_MACROECONOMICS_ACTION, retrieveGlobalMacroeconomics);
}

export function* retrieveGlobalMacroeconomicsCountriesSaga() {
  yield takeLatest(
    RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_ACTION,
    retrieveGlobalMacroeconomicsCountries
  );
}

export function* retrieveUpcomingEarningsSaga() {
  yield takeLatest(RETRIEVE_UPCOMING_EARNINGS_ACTION, retrieveUpcomingEarnings);
}

const macroeconomics_sagas = [
  fork(retrieveRelativePriceIndexeSaga),
  fork(retrieveRelativeSPIndexeSaga),
  fork(retrieveRelativeBondsIndexeSaga),
  fork(retrieveGlobalMacroeconomicsSaga),
  fork(retrieveGlobalMacroeconomicsCountriesSaga),
  fork(retrieveUpcomingEarningsSaga),
];
export default macroeconomics_sagas;
