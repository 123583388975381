import { Box } from '@mui/material';

const GradientIcon = ({ Icon, size, height, width, ...props }) => {
  return (
    <Box>
      <svg width={width} height={height}>
        <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
          <stop stopColor="#21D4FD" offset="0%" />
          <stop stopColor="#2152FF" offset="100%" />
        </linearGradient>

        <linearGradient id="purple-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
          <stop stopColor="#281AC8" offset="0%" />
          <stop stopColor="#FD749B" offset="100%" />
        </linearGradient>
        {Icon}
      </svg>
    </Box>
  );
};

export default GradientIcon;

export { GradientIcon };
