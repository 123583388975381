import React, { useRef } from 'react';
import News from '../../components/News';
import Catalog from './Catalog';
import Main from './Main';
import Releases from './Releases';

import { Container } from '@mui/material';

import Header from '../../components/Header';
import useStyle from './index.style';

const LearnCrypto = () => {
  const scrollToRef = useRef(null);
  const scrollToRefCatalog = useRef(null);
  const classes = useStyle();

  return (
    <Container className={classes.container}>
      <Header />

      <Main scrollToRef={scrollToRef} scrollToRefCatalog={scrollToRefCatalog} />
      <Releases scrollToRef={scrollToRef} />
      <Catalog scrollToRefCatalog={scrollToRefCatalog} />
      <News classSection="section-bg section-mb0" />

      {/* <Footer /> */}
    </Container>
  );
};

export default LearnCrypto;
