import { makeStyles } from '@mui/styles';
import login_bg from '../../assets/images/signin/login_bg_2.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.base,
    display: 'flex',
    flexDirection: 'column',
  },
  gridContainer: {
    zIndex: 2,

    '& > div': {
      zIndex: 'inherit',
    },
  },
  mobileMisc: {
    background: `url(${login_bg})`,
    overflow: 'hidden',
    position: 'relative',
    minHeight: '150px',
    display: ' flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    color: theme.palette.common.white,

    '& > div': {
      backgroundColor: theme.palette.common.white,
    },

    '& h4': {
      fontWeight: 'bold',
    },
  },
  content: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  bgContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    height: '100%',
    '& div': {
      height: '100%',
    },
  },
  signIn: {
    '& h4': {
      background: theme.palette.gradients.blue_linear,
      fontWeight: 'bold',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },
  },
  title: {
    background: theme.palette.gradients.blue_linear,
    fontWeight: 'bold',
    fontSize: 80,
    textAlign: 'center',
    top: '5%',
    width: '100%',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
}));

export default useStyles;
