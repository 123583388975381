import React from 'react';
import ReactCookieConsent from 'react-cookie-consent';

const CookieConsent = () => {
  return (
    <ReactCookieConsent
      debug
      // onAccept={() => alert('HELLO !!!')}
      cookieName="cookie-policy"
      style={{
        display: 'flex',
        alignItems: 'center',
        minHeight: '5vh',
        background: 'linear-gradient(90.08deg, #5C62ED -48.14%, #41E7B0 108.38%)',
        color: 'white',
      }}
      buttonStyle={{ backgroundColor: '#3F8ED7', color: 'white' }}
    >
      This website uses cookies to improve your browsing experience and provide you with
      personalized content.
    </ReactCookieConsent>
  );
};

export default CookieConsent;
