import { Box } from '@mui/material';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Play from '../../../components/Play';
import useStyles from '../index.style';
import styles from './Main.module.sass';

const breadcrumbs = [
  {
    title: 'Learn crypto',
    url: '/learn-crypto',
  },
  {
    title: 'Trading',
    url: '/learn-crypto',
  },
  {
    title: 'Bitcoin',
  },
];

const Main = () => {
  const styles2 = useStyles();
  const { postDetails } = useSelector((store) => {
    const landing_home_store = store.landing_home;

    if (landing_home_store) return landing_home_store.toJS();

    return {};
  });

  return (
    <div className={cn('section', styles.main)}>
      <div className={cn('container', styles.container)}>
        <Box className={styles.head} mt={4}>
          <h1 className={cn('h1', styles.title)}>{postDetails?.attributes?.title}</h1>
          <Breadcrumbs className={styles.breadcrumbs} items={breadcrumbs} />
        </Box>
        <Box className={styles2.preview}>
          {postDetails?.attributes?.thumbnail ? (
            <img src={postDetails?.attributes?.thumbnail} alt="Card" />
          ) : (
            <img alt="Release" src="/images/content/releases-pic-1.jpg" />
          )}
          <Play />
        </Box>
      </div>
    </div>
  );
};

export default Main;
