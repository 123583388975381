import { Box, Card, Grid, Typography } from '@mui/material';
import { Contained } from '../../../components/Button';
import useStyle from '../index.style';

const UnfinishedQuiz = (props) => {
  const styles = useStyle();
  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <Card className={styles.cardContainer}>
          <Grid xs={12} sm={12} md={7} lg={7} xl={7}>
            <Typography className={styles.whiteText} variant="h5">
              Take the Risk Profile Quiz to find out your investor risk profile.
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={4}
              flexDirection="column"
            >
              <Contained white={true} variant="contained" color="primary" rounded>
                Take the Risk Profile Quiz
              </Contained>
              <button>
                <Typography className={styles.underlineText}>Learn More</Typography>
              </button>
            </Box>
          </Grid>
        </Card>
      </Box>
    </Box>
  );
};

export default UnfinishedQuiz;
