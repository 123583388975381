import { fromJS } from 'immutable';
import { ERROR, SUCCESS } from '../../constants';
import { TOKEN_KEY } from '../../constants/auth';
import { TRAINING_MODAL, QUIZ_ENTRY_MODAL } from '../../constants/modal';
import {
  LOGOUT_ACTION,
  SET_CREATING_NEW_PASSWORD_ACTION,
  FORGOT_PASSWORD_ACTION,
  FORGOT_PASSWORD_OK,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_END,
  CREATE_NEW_PASSWORD_ACTION,
  CREATE_NEW_PASSWORD_OK,
  CREATE_NEW_PASSWORD_ERROR,
  CREATE_NEW_PASSWORD_END,
  SAVE_USER_DETAILS_ACTION,
  SAVE_USER_DETAILS_OK,
  SAVE_USER_DETAILS_END,
  SAVE_USER_DETAILS_ERROR,
  TOGGLE_SUCCESS_MODAL,
  TOGGLE_LOCK_MODAL,
  GET_USER_PERMISSION_ACTION,
  GET_USER_PERMISSION_OK,
  GET_USER_PERMISSION_END,
  GET_USER_PERMISSION_ERROR,
} from '../constants/auth.constant';
import { LOGIN_OK } from '../constants/login.constant';

const initial_state = fromJS({
  user: 1,
  token: undefined,
  refresh_token: undefined,
  set_new_password: false,
  loading: false,
  message: '',
  notify: '',
  redirect: undefined,
  user_data: undefined,
  success: false,
  user_permission: undefined,
  lock_modal: false,
});

const authStore = (state = initial_state, action) => {
  switch (action.type) {
    case LOGOUT_ACTION:
      localStorage.removeItem(TRAINING_MODAL);
      localStorage.removeItem(QUIZ_ENTRY_MODAL);

      return state
        .set('token', undefined)
        .set('refresh_token', undefined)
        .set('user', undefined)
        .set('redirect', action.redirect);

    case LOGIN_OK:
      return state
        .set('token', action.data.access_token)
        .set('refresh_token', action.data.refresh_token);

    case SET_CREATING_NEW_PASSWORD_ACTION:
      return state.set('set_new_password', action.value);

    case FORGOT_PASSWORD_ACTION:
      return state.set('loading', true);

    case FORGOT_PASSWORD_OK:
      return state.set('message', action.data.message).set('notify', SUCCESS);

    case FORGOT_PASSWORD_ERROR:
      return state.set('message', action.data.message).set('notify', ERROR);

    case FORGOT_PASSWORD_END:
      return state.set('message', '').set('notify', '').set('loading', false);

    case CREATE_NEW_PASSWORD_ACTION:
      return state.set('loading', true);

    case CREATE_NEW_PASSWORD_OK:
      return state.set('message', action.data.message).set('notify', SUCCESS);

    case CREATE_NEW_PASSWORD_ERROR:
      let code = (action?.data.non_field_errors || []).filter((e) => !!e.code);
      let msg = !!code[0] ? code[0].code : '';
      return state.set('message', msg || '').set('notify', ERROR);

    case CREATE_NEW_PASSWORD_END:
      return state.set('message', '').set('notify', '').set('loading', false);

    case SAVE_USER_DETAILS_ACTION:
      return state.set('loading', true).set('success', false);

    case SAVE_USER_DETAILS_OK:
      return state.set('user_data', action).set('notify', SUCCESS).set('success', true);

    case SAVE_USER_DETAILS_ERROR:
      return state.set('message', action.data.message).set('notify', ERROR);

    case SAVE_USER_DETAILS_END:
      return state.set('message', '').set('notify', '').set('loading', false);

    case GET_USER_PERMISSION_ACTION:
      return state.set('loading', true);

    case GET_USER_PERMISSION_OK:
      return state.set('user_permission', action).set('loading', false);

    case GET_USER_PERMISSION_ERROR:
      return state.set('message', action.data.message).set('notify', ERROR);

    case GET_USER_PERMISSION_END:
      return state.set('message', '').set('notify', '').set('loading', false);

    case TOGGLE_SUCCESS_MODAL:
      return state.set('success', action.value);

    case TOGGLE_LOCK_MODAL:
      return state.set('lock_modal', action.value);

    default:
      return state;
  }
};

export default authStore;
