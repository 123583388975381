import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import ProgressCircular from '../ProgressCircular';
// import TextInput from '../TextInput';
import { useTheme } from '@emotion/react';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import TextInput3 from '../TextInput/TextInput3';
const AutoComplete = ({ renderItem, items, loading, value, onBlur, onChange, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [textValue, setTextValue] = useState(value);

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  const _renderItem = (item) => {
    return (
      <li>
        <Typography variant="body2" fontSize="0.5em">
          {item.title}
        </Typography>
      </li>
    );
  };

  const _renderLoading = () => {
    return (
      <Box pt="5px" position="relative">
        <ul className={classes.loadingContainer}>
          <li>
            <Box>
              <ProgressCircular size={30} />
            </Box>
          </li>
        </ul>
      </Box>
    );
  };

  // unused might use in future
  // const _renderNoItems = () => {
  //   return (
  //     <Box pt="5px" position="relative">
  //       <ul className={classes.itemsContainer}>
  //         <li>
  //           <Typography variant="body2" fontSize="0.5em">
  //             No Items to Show
  //           </Typography>
  //         </li>
  //       </ul>
  //     </Box>
  //   );
  // };

  const _renderList = () => {
    const rows = items.map((item) => renderItem(item) || _renderItem(item));

    if (!items?.length) return;

    return (
      <Box pt="5px" position="relative">
        <ul className={classes.itemsContainer}>
          <Typography fontSize="0.5em">{rows}</Typography>
        </ul>
      </Box>
    );
  };

  const searchIcon = () => {
    return <SearchIcon style={{ color: '#41E7B0' }} />;
  };

  return (
    <OutsideClickHandler display="flex" onOutsideClick={() => onBlur()}>
      <Box className={classes.root}>
        <Box>
          <TextInput3
            icon={searchIcon()}
            fullWidth
            value={textValue}
            borderColor={theme.palette.peter_river}
            onChange={(e) => {
              setTextValue(e.target.value);
              onChange?.(e);
            }}
            {...props}
          />
        </Box>
        {!loading && !!items?.length && _renderList()}
        {!!loading && _renderLoading()}
      </Box>
    </OutsideClickHandler>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
  },
  itemsContainer: {
    margin: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    borderRadius: '10px',
    padding: 0,
    backgroundColor: '#FFF',
    zIndex: 100,

    '& li': {
      backgroundColor: '#FFF',
      listStyleType: 'none',
      padding: '1rem',
      borderBottom: `solid #EFEFEF .5px`,
      borderLeft: `solid #EFEFEF .5px`,
      borderRight: `solid #EFEFEF .5px`,

      '&:last-child': {
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
      },
      '&:first-child': {
        borderTop: `solid #EFEFEF .5px`,
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
      },

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#EFEFEF',
      },
    },
  },
  loadingContainer: {
    margin: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    borderRadius: '10px',
    padding: 0,
    backgroundColor: '#FFF',
    zIndex: 100,

    '& li': {
      backgroundColor: '#FFF',
      listStyleType: 'none',
      padding: '1rem',
      borderBottom: `solid #EFEFEF .5px`,
      borderLeft: `solid #EFEFEF .5px`,
      borderRight: `solid #EFEFEF .5px`,
    },
  },
}));

export default AutoComplete;
