import { Box } from '@mui/material';
import React, { useState } from 'react';
import { EconomicCalendar } from 'react-tradingview-embed';

import { WeeklyEarning } from 'traidify-components';
import Upgrade from '../../components/Upgrade';
import CountryRelativeStrength from './CountryRelativeStrength';
import RelativeAnalysis from './RelativeAnalysis';
import RelativeAnalysisSummary from './RelativeAnalysisSummary/RelativeAnalysisSummary';
import useStyles from './styles';
import UpcomingEarningList from './UpcomingEarningList';

const Mobile = ({ data, lock_modal, ...props }) => {
  const classes = useStyles();
  const [tab, setTab] = useState('');
  const handleTab = (tab) => {
    setTab(tab);
  };

  return (
    <Box className={classes.container}>
      <CountryRelativeStrength />
      <WeeklyEarning />
      <Box
        height="50vh"
        my={4}
        className="tradingview-widget-container"
        sx={{ '& > div': { height: '100%' } }}
      >
        <EconomicCalendar
          widgetProps={{
            colorTheme: 'light',
            isTransparent: false,
            width: '100%',
            height: '100%',
            locale: 'en',
            importanceFilter: '-1,0,1',
            currencyFilter: 'USD,EUR,ITL,NZD,CHF,AUD,FRF,JPY,ZAR,TRL,CAD,DEM,MXN,ESP,GBP',
          }}
        />
      </Box>
      <UpcomingEarningList />
      {/* <Box pt="2.5rem">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <TrendingStrategy />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <List title="Employment & Prices" />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <List title="Consumer Confidence" />
            </Box>
          </Grid>
        </Grid>
      </Box> */}

      <RelativeAnalysis />
      <RelativeAnalysisSummary />
      <Upgrade open={lock_modal} />
    </Box>
  );
};

export default Mobile;
