import { Box, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import warning_icon from '../../assets/images/risk_tolerance/unfinished-icon.png';
import { Contained as ContainedButton, Outlined as OutlinedButton } from '../../components/Button';
import Modal from '../../components/Modal';
import { toggleUnfinishedModal } from '../../redux/actions/quiz.action';

const QuizModal = ({ open, handleClose, history, ...props }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const redirectToQuiz = () => {
    history.push('/app/risk-tolerance-quiz');
    setTimeout(() => {
      closeModal();
    }, 500);
  };

  const closeModal = () => {
    dispatch(toggleUnfinishedModal(false));
  };

  return (
    <Modal open={open} handleClose={closeModal}>
      <Box className={classes.container}>
        <Box display="flex" justifyContent="center">
          <img src={warning_icon} alt="warning-icon" />
        </Box>
        <Box display="flex" justifyContent="center" paddingTop="1.5rem">
          <Typography variant="h5" className={classes.checkEmail}>
            It appears that you have not yet completed the quiz. Do you want to do it right now?
          </Typography>
        </Box>

        <Box className={classes.btnAlignment}>
          <ContainedButton
            className={classes.btnNext}
            onClick={redirectToQuiz}
            small={smallScreen}
            rounded
          >
            Take the Quiz
          </ContainedButton>
          <OutlinedButton onClick={closeModal} small={smallScreen} rounded>
            Cancel
          </OutlinedButton>
        </Box>
        <Box display="flex" justifyContent="center" paddingTop="1rem" textAlign="center">
          <Typography variant="subtitle1" className={classes.subText}>
            You must finish first answering the Risk Tolerance Quiz to be able to give an assessment
            to your Risk Appetite in terms of investing in the Stock Market or BlockChain.
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default QuizModal;

const useStyles = makeStyles((theme) => {
  return {
    container: {
      padding: '2rem 5rem',
      maxWidth: '650px',

      [theme.breakpoints.down('sm')]: {
        '& img': {
          maxWidth: '200px',
          height: 'auto',
        },
        padding: '0 1rem',
      },
    },
    checkEmail: {
      background: theme.palette.gradients.blue_linear,
      fontWeight: 'bold',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2rem',
      },
    },
    subText: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    tryOtherEmailLink: {
      color: theme.palette.peter_river,
    },

    textBlack: {
      color: theme.palette.common.black,
    },

    btnNext: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: '1em',
      },
    },

    btnAlignment: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      paddingTop: '1.5rem',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
  };
});
