import {
  RETRIEVE_GLOBAL_MACROECONOMICS_ACTION,
  RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_ACTION,
  RETRIEVE_RELATIVE_BONDS_INDEX_ACTION,
  RETRIEVE_RELATIVE_PRICE_INDEX_ACTION,
  RETRIEVE_RELATIVE_SP_INDEX_ACTION,
  RETRIEVE_UPCOMING_EARNINGS_ACTION,
  SET_RELATIVE_PRICE_INDEX_PARAMS_ACTION,
  SET_TIMEFRAME_ACTION,
} from '../constants/macroeconomics.constant';

export const retrieveRelativePriceIndexAction = (params) => {
  return {
    type: RETRIEVE_RELATIVE_PRICE_INDEX_ACTION,
    params,
  };
};

export const retrieveRelativeSPIndexAction = () => {
  return {
    type: RETRIEVE_RELATIVE_SP_INDEX_ACTION,
  };
};

export const retrieveRelativeBondsIndexAction = () => {
  return {
    type: RETRIEVE_RELATIVE_BONDS_INDEX_ACTION,
  };
};

export const setRelativePriceIndexParamsAction = (params) => ({
  type: SET_RELATIVE_PRICE_INDEX_PARAMS_ACTION,
  params,
});

export const retrieveGlobalMacroeconomicsAction = (params) => {
  return {
    type: RETRIEVE_GLOBAL_MACROECONOMICS_ACTION,
    params,
  };
};

export const retrieveGlobalMacroeconomicsCountriesAction = (params) => {
  return {
    type: RETRIEVE_GLOBAL_MACROECONOMICS_COUNTRIES_ACTION,
    params,
  };
};

export const retrieveUpcomingEarnings = (params) => {
  return {
    type: RETRIEVE_UPCOMING_EARNINGS_ACTION,
    params,
  };
};

export const setTimeFrameAction = (params) => ({ type: SET_TIMEFRAME_ACTION, params });
