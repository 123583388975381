import { makeStyles } from '@mui/styles';
import colors from '../../../styles/colors';
const useStyles = makeStyles((theme) => ({
  section: {
    padding: '136px 0',
    marginTop: '100px',
    borderRadius: '20px',
    [theme.breakpoints.down('lg')]: {
      padding: '112px 0',
      marginBottom: 0,
    },
    [theme.breakpoints.down('md')]: {
      padding: '64px 0',
      marginBottom: 0,
    },
  },

  container: {
    width: '100%',
    maxWidth: '1280px',
    margin: '0 auto',
    padding: '0 40px',
    [theme.breakpoints.down('lg')]: {
      padding: '0 20px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 16px',
    },
  },

  form: {
    position: 'relative',
    marginBottom: '48px',
    paddingBottom: '32px',
    borderBottom: `1px solid ${colors.n6}`,
    [theme.breakpoints.down('md')]: {
      marginBottom: '24px',
    },
  },

  input: {
    width: '100%',
    height: '48px',
    paddingRight: '64px',
    borderRadius: 0,
    background: 'none',
    border: 'none',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '24px',
    lineHeight: 'calc(32 / 24) * 1px',
    fontHeight: 600,
    letterSpacing: '-.01em',
    color: colors.n2,
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      fontWeight: 500,
    },

    '&::placeholder': {
      color: colors.n2,
    },
  },

  result: {
    position: 'absolute',
    top: 0,
    right: 0,
    flex: '0 0 48px',
    width: '48px',
    height: '48px',
    padding: 0,
    borderRadius: '50%',
    '& svg': {
      margin: 0,
    },
  },

  sorting: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '64px',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },

  dropdown: {
    width: '256px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      '&:not(:last-child)': {
        marginBottom: '16px',
      },
    },
  },

  nav: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },

  link: {
    display: 'flex',
    padding: '6px 12px',
    borderRadius: '14px',
    background: 'none',
    fontSize: '14px',
    lineHeight: 'calc(16 / 14) * 1px',
    fontWeight: 700,
    color: colors.n4,
    transition: 'all .2s',
    '&:hover': {
      color: colors.n8,
    },
    '&.active': {
      background: colors.n3,
      color: colors.n8,
    },
    '&:not(:last-child)': {
      marginRight: '12px',
    },
  },

  list: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-32px -16px 0',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      margin: 0,
    },
  },

  card: {
    flex: '0 0 calc(33.333% - 32px) !important',
    width: 'calc(33.333% - 32px) !important',
    margin: '32px 16px !important',
    [theme.breakpoints.down('lg')]: {
      flex: '0 0 calc(50% - 32px) !important',
      width: 'auto !important',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      margin: 0,
      '&:not(:last-child)': {
        marginBottom: '32px',
      },
    },
  },

  btns: {
    marginTop: '32px',
    textAlign: 'center',
  },
}));

export default useStyles;
